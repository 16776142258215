import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";
import { v4 as uuidv4 } from "uuid";

export type salesInfo = {
	scholasticDollars: {
		totalRedeemed: number;
		taxExemptSales: number;
		taxCollected: number;
		cashEquivalent: number;
		taxable: number;
	};
	tenderTotals: {
		cashAndChecks: number;
		creditCards: number;
		purchaseOrders: number;
		scholasticDollarsCashEquivalent: number;
		total: number;
	};
	grossSales: {
		taxExemptSales: number;
		taxableSales: number;
		total: number;
		taxTotal: number;
		taxableSalesLessTax: number;
	};
	netSales: {
		discounts: 0;
		shareTheFairFunds: {
			collected: number;
			redeemed: number;
		};
		giftCertificates: {
			purchased: number;
			redeemed: number;
			unredeemed: number;
		};
	};
};

export const defaultSalesInfo: salesInfo = {
	scholasticDollars: {
		totalRedeemed: 0,
		taxExemptSales: 0,
		taxCollected: 0,
		cashEquivalent: 0,
		taxable: 0,
	},
	tenderTotals: {
		cashAndChecks: 0,
		creditCards: 0,
		purchaseOrders: 0,
		scholasticDollarsCashEquivalent: 0,
		total: 0,
	},
	grossSales: {
		taxExemptSales: 0,
		taxableSales: 0,
		total: 0,
		taxTotal: 0,
		taxableSalesLessTax: 0,
	},
	netSales: {
		discounts: 0,
		shareTheFairFunds: {
			collected: 0,
			redeemed: 0,
		},
		giftCertificates: {
			purchased: 0,
			redeemed: 0,
			unredeemed: 0,
		},
	},
};

export type PurchaseOrderType = {
	_id: string;
	number: string;
	amount: string;
	contactName: string;
	agencyName: string;
	address: string;
	city: string;
	state: string;
	zipcode: string;
	errors: {
		number: boolean;
		amount: boolean;
		greaterThanZero: boolean;
		contactName: boolean;
		agencyName: boolean;
		address: boolean;
		city: boolean;
		state: boolean;
		zipcode: boolean;
		numberNotUnique: boolean;
	};
};

const useSalesData = () => {
	const { bookfairsAPI } = useConfig();

	// Scholastic Dollars
	// Scholastic Dollars Redeemed
	const [SDRedeemed, setSDRedeemed] = useState<string>("");
	// Tax-Exempt Scholastic Dollar Sales
	const [SDTaxExemptSales, setSDTaxExemptSales] = useState<string>("");
	// Taxable Scholastic Dollar Sales
	const [SDTaxableSales, setSDTaxableSales] = useState<number>(0);
	// Tax Collected in Scholastic Dollars
	const [SDTaxCollected, setSDTaxCollected] = useState<string>("");

	// Tender Totals
	// Cash & Checks
	const [cashChecks, setCashChecks] = useState<string>("");
	// Credit Cards
	const [creditCards, setCreditCards] = useState<string>("");
	// Purchase Orders
	const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderType[]>(
		[]
	);
	// Scholastic Dollars Cash Equivalent
	const [SDCashEquivalent, setSDCashEquivalent] = useState<number>(0);
	// Total (Includes Unredeemed Total)
	// TODO: rename
	const [SDTotal, setSDTotal] = useState<number>(0);

	// Gross Sales
	// Tax Exempt Sales
	const [taxExemptSales, setTaxExemptSales] = useState<string>("");
	const [taxExemptSalesEdited, setTaxExemptSalesEdited] =
		useState<boolean>(false);
	// Taxable Sales
	const [taxableSales, setTaxableSales] = useState<number>(0);
	// Gross Sales Total
	const [grossSalesTotal, setGrossSalesTotal] = useState<number>(0);
	// Tax Total
	const [taxTotal, setTaxTotal] = useState<number>(0);
	// Taxable Sales (Less Sales Tax)
	const [taxableSalesLessSalesTax, setTaxableSalesLessSalesTax] =
		useState<number>(0);

	// Net Sales
	// Discounts
	const [netSalesDiscounts, setNetSalesDiscounts] = useState<string>("");
	// Gift Certificates Purchased
	const [GCPurchased, setGCPurchased] = useState<string>("");
	// Gift Certificates Redeemed
	const [GCRedeemed, setGCRedeemed] = useState<string>("");
	// Gift Certificates Unredeemed
	const [GCUnredeemed, setGCUnredeemed] = useState<string>("");
	// Share The Fair Funds Collected
	const [STFFCollected, setSTFFCollected] = useState<string>("");
	// Share the Fair Funds Redeemed
	const [STFFRedeemed, setSTFFRedeemed] = useState<string>("");

	// Fair Sales Total
	const [fairSalesTotal, setFairSalesTotal] = useState<number>(0);

	const [taxRate, setTaxRate] = useState<string>("");

	const [fairId, setFairId] = useState("");
	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	// Used to calculate taxExemptSales when SDTaxExemptSales is modified
	const [defaultTaxExemptSales, setDefaultTaxExemptSales] =
		useState<string>("");
	const [defaultSDTaxExemptSales, setDefaultSDTaxExemptSales] =
		useState<string>("");

	const handleSetTaxExemptSalesDirectly = (value: string) => {
		if (!taxExemptSalesEdited) {
			setTaxExemptSalesEdited(true);
		}
		setTaxExemptSales(value);
	};

	useEffect(() => {
		const fetchSalesData = async () => {
			const currentFair = getFairIdFromUrl();
			if (checkSpsUd()) {
				setIsLoading(true);
				try {
					const salesResponse = await axios.get(
						`${bookfairsAPI}/user/fairs/${currentFair}/financials/form/sales`,
						{
							withCredentials: true,
						}
					);

					if (salesResponse.status !== 200) {
						handleGenericSuccess(salesResponse);
						return;
					}
					// Store API response in state variables
					setCashChecks(
						salesResponse.data.tenderTotals.cashAndChecks.toFixed(2)
					);
					setCreditCards(
						salesResponse.data.tenderTotals.creditCards.toFixed(2)
					);
					setDefaultSDTaxExemptSales(
						salesResponse.data.scholasticDollars.taxExemptSales.toFixed(
							2
						)
					);
					setDefaultTaxExemptSales(
						salesResponse.data.grossSales.taxExemptSales.toFixed(2)
					);
					setFairId(salesResponse.headers["sbf-jarvis-resource-id"]);
					setFairSalesTotal(salesResponse.data.sales);
					setGCPurchased(
						salesResponse.data.netSales.giftCertificates.purchased.toFixed(
							2
						)
					);
					setGCRedeemed(
						salesResponse.data.netSales.giftCertificates.redeemed.toFixed(
							2
						)
					);
					setGCUnredeemed(
						salesResponse.data.netSales.giftCertificates.unredeemed.toFixed(
							2
						)
					);
					setGrossSalesTotal(salesResponse.data.grossSales.total);
					setNetSalesDiscounts(
						salesResponse.data.netSales.discounts.toFixed(2)
					);
					setSDCashEquivalent(
						salesResponse.data.tenderTotals.scholasticDollarsCashEquivalent.toFixed(
							2
						)
					);
					setSDRedeemed(
						salesResponse.data.scholasticDollars.totalRedeemed.toFixed(
							2
						)
					);
					setSDTaxCollected(
						salesResponse.data.scholasticDollars.taxCollected.toFixed(
							2
						)
					);
					setSDTaxExemptSales(
						salesResponse.data.scholasticDollars.taxExemptSales.toFixed(
							2
						)
					);
					setSDTaxableSales(
						salesResponse.data.scholasticDollars.taxable.toFixed(2)
					);
					setSDTotal(
						salesResponse.data.tenderTotals.total.toFixed(2)
					);
					setSTFFCollected(
						salesResponse.data.netSales.shareTheFairFunds.collected.toFixed(
							2
						)
					);
					setSTFFRedeemed(
						salesResponse.data.netSales.shareTheFairFunds.redeemed.toFixed(
							2
						)
					);
					setTaxExemptSales(
						salesResponse.data.grossSales.taxExemptSales.toFixed(2)
					);
					setTaxRate(salesResponse.data.taxRate);
					setTaxableSales(
						salesResponse.data.grossSales.taxableSales.toFixed(2)
					);
					setTaxableSalesLessSalesTax(
						salesResponse.data.grossSales.taxableSalesLessTax
					);
					setTaxTotal(
						salesResponse.data.grossSales.taxTotal.toFixed(2)
					);

					// Get purchase order data
					const purchaseOrderResponse = await axios.get(
						`${bookfairsAPI}/user/fairs/${currentFair}/financials/form/purchase-orders`,
						{
							withCredentials: true,
						}
					);

					// If not a 200, handle generic success logic (i.e. 204, NO_SCHL)
					if (purchaseOrderResponse.status !== 200) {
						handleGenericSuccess(salesResponse);
						return;
					}

					// Assign a unique id to each purchase order, create an initial error object
					for (const purchaseOrder of purchaseOrderResponse.data
						.list) {
						purchaseOrder._id = uuidv4();
						purchaseOrder.amount = purchaseOrder.amount.toFixed(2);
						purchaseOrder.errors = {
							number: false,
							amount: false,
							contactName: false,
							agencyName: false,
							city: false,
							state: false,
							zipcode: false,
							numberNotUnique: false,
						};
					}
					setPurchaseOrders(purchaseOrderResponse.data.list);
				} catch (err) {
					setIsError(true);
					handleGenericErrors(err);
				} finally {
					setIsLoading(false);
				}
			}
		};
		fetchSalesData();
	}, [bookfairsAPI]);

	return {
		cashChecks,
		creditCards,
		defaultSDTaxExemptSales,
		defaultTaxExemptSales,
		fairId,
		fairSalesTotal,
		GCPurchased,
		GCRedeemed,
		GCUnredeemed,
		grossSalesTotal,
		isError,
		isLoading,
		netSalesDiscounts,
		purchaseOrders,
		SDCashEquivalent,
		SDRedeemed,
		SDTaxableSales,
		SDTaxCollected,
		SDTaxExemptSales,
		SDTotal,
		STFFCollected,
		STFFRedeemed,
		taxExemptSales,
		taxExemptSalesEdited,
		taxableSales,
		taxableSalesLessSalesTax,
		taxRate,
		taxTotal,
		handleSetTaxExemptSalesDirectly,
		setCashChecks,
		setCreditCards,
		setFairSalesTotal,
		setGrossSalesTotal,
		setPurchaseOrders,
		setSDCashEquivalent,
		setSDRedeemed,
		setSDTaxableSales,
		setSDTaxCollected,
		setSDTaxExemptSales,
		setSDTotal,
		setSTFFCollected,
		setSTFFRedeemed,
		setTaxExemptSales,
		setTaxableSales,
		setTaxableSalesLessSalesTax,
		setTaxTotal,
	};
};

export default useSalesData;
