import IframeContainer from "../IframeContainer";
import SchoolSelectorBar from "./SchoolSelectorBar";

export const SchoolInfoBarStandAlone = () => {
	return (
		<IframeContainer
			renderItems={() => (
				<>
					<SchoolSelectorBar StandAlone={true} />
				</>
			)}
		/>
	);
};

export default SchoolInfoBarStandAlone;
