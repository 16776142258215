//converts time from 00:00:00 format to 12:00 AM format
export const convertToStandardTime = (time: string) => {
	const timeParts = time.split(":");
	if (parseInt(timeParts[0]) < 12) {
		if (parseInt(timeParts[0]) === 0) {
			return `12:${timeParts[1]} AM`;
		}
		return `${parseInt(timeParts[0])}:${timeParts[1]} AM`;
	} else {
		if (parseInt(timeParts[0]) === 12) {
			return `12:${timeParts[1]} PM`;
		}
		return `${parseInt(timeParts[0]) - 12}:${timeParts[1]} PM`;
	}
};

//Converts time from 12:00 AM format to 00:00:00 format
export const convertToMilitaryTime = (time: string) => {
	const timeParts = time.split(":");
	const timePartEnd = timeParts[1].split(" ")[0];
	if (time.includes("AM")) {
		if (timeParts[0] === "12") {
			return `00:${timePartEnd}:00`;
		}
		if (timeParts[0] === "11" || timeParts[0] === "10") {
			return `${timeParts[0]}:${timePartEnd}:00`;
		}
		return `0${timeParts[0]}:${timePartEnd}:00`;
	} else {
		if (timeParts[0] === "12") {
			return `12:${timePartEnd}:00`;
		}
		return `${parseInt(timeParts[0]) + 12}:${timePartEnd}:00`;
	}
};
