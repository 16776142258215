import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { RedButton, WhiteButton } from "../../Button";
import Checkbox from "components/Checkbox";
import Navigation from "../Navigation";
import { FinancialFormGrid } from "components/Grid";
import {
	FinFormProgress,
	FinFormUrls,
	checkFinFormUrlsLocalStorage,
	getFinFormProgress,
	setFinFormCurrentPageLocalStorage,
	setFinFormUrlsLocalStorage,
} from "utils/FinFormLocalStorageUtility";
import { useNavigate } from "react-router-dom";
import { useConfig } from "providers/ConfigContext";
import axios from "axios";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import useFairBasicInfo from "hooks/FinancialForm/useFairBasicInfo";
import {
	trackReviewSubmitBackButton,
	trackReviewSubmitSubmitButton,
} from "DumbleData";
import FairSalesTotal from "../FairSalesTotal";

interface Props {
	fairId: string;
	grossSales: number;
	taxExemptSales: number;
	taxTotal: number;
	scholasticDollarsRedeemed: number;
	scholasticDollarBalance: number;
	appliedScholasticDollars: number;
	fairEarningsScholasticDollarsDue: number;
	fairSpendingScholasticDollarsDue: number;
	scholasticDollarsEarned: number;
	profitSelected: number;
	cashProfitSelected: number;
	earningsDollarFairLevel: string;
	fairSalesTotal: number;
}

export const FinancialFormReviewContent = ({
	fairId,
	grossSales,
	taxExemptSales,
	taxTotal,
	scholasticDollarsRedeemed,
	scholasticDollarBalance,
	appliedScholasticDollars,
	fairEarningsScholasticDollarsDue,
	fairSpendingScholasticDollarsDue,
	scholasticDollarsEarned,
	profitSelected,
	cashProfitSelected,
	earningsDollarFairLevel,
	fairSalesTotal,
}: Props) => {
	const navigate = useNavigate();
	const { supressEarnings } = useFairBasicInfo({ fairId });

	const handleClickBackButton = () => {
		trackReviewSubmitBackButton();
		if (supressEarnings) {
			navigate(FinFormUrls.Spending);
			return;
		} else {
			navigate(FinFormUrls.Earnings);
		}
	};

	const { bookfairsAPI } = useConfig();

	const [acceptChecked, setAcceptChecked] = useState(false);
	const [submitClicked, setSubmitClicked] = useState(false);

	const handleAcceptCheckbox = () => {
		acceptChecked ? setAcceptChecked(false) : setAcceptChecked(true);
	};

	const clickSubmit = () => {
		if (!submitClicked) {
			setSubmitClicked(true);
		}
	};

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	// if user tries to directly access the /review page without completing previous steps, redirect them to the first incomplete step
	useEffect(() => {
		const progress = getFinFormProgress(fairId);
		if (progress === FinFormProgress.Confirmation) {
			navigate(FinFormUrls.Confirmation);
		} else if (progress < FinFormProgress.Review) {
			const savedFinFormLocation = checkFinFormUrlsLocalStorage(fairId);
			if (savedFinFormLocation) {
				navigate(savedFinFormLocation);
			}
		} else {
			setFinFormCurrentPageLocalStorage(fairId, FinFormUrls.Review);
		}
	}, [navigate, fairId]);

	const submitRequest = useCallback(async () => {
		axios
			.put(
				`${bookfairsAPI}/user/fairs/${fairId}/financials/form/confirmation`,
				{}, // empty body
				{ withCredentials: true }
			)
			.then((response) => {
				if (response.status === 200) {
					const savedFinFormProgress = getFinFormProgress(fairId);
					if (savedFinFormProgress === FinFormProgress.Review) {
						setFinFormUrlsLocalStorage(
							fairId,
							FinFormUrls.Confirmation
						);
					}
					navigate(FinFormUrls.Confirmation);
				} else {
					handleGenericSuccess(response);
				}
			})
			.catch(function (error) {
				handleGenericErrors(error);
			});
	}, [bookfairsAPI, fairId, navigate]);

	useEffect(() => {
		if (submitClicked) {
			trackReviewSubmitSubmitButton();
			submitRequest();
		}
	}, [submitClicked, submitRequest]);

	return (
		<FinancialFormGrid>
			<FullWidth>
				<FFReview>
					<Navigation
						activeLink={FinFormProgress.Review}
						fairId={fairId}
					></Navigation>
					<Column>
						<StyledHeading>Review and Submit</StyledHeading>
						<StyledSubHeading>
							Please confirm all details and submit your Financial
							Form.
						</StyledSubHeading>
						<StyledBox>
							<BoxHeading>Totals</BoxHeading>
							<BoxLine>
								<div>Gross Sales Total</div>
								<div>{formatter.format(grossSales)}</div>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<div>Tax-Exempt Sales</div>
								<div>{formatter.format(taxExemptSales)}</div>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<div>Tax Total</div>
								<div>{formatter.format(taxTotal)}</div>
							</BoxLine>
						</StyledBox>
						<FairSalesTotal fairSalesTotal={fairSalesTotal} />
						<StyledBox>
							<BoxHeading>Fair Spending</BoxHeading>
							<BoxLine>
								<div>Total Scholastic Dollars Redeemed</div>
								<div>
									{formatter.format(
										scholasticDollarsRedeemed
									)}
								</div>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<div>
									Apply Existing Scholastic Dollar Balance{" "}
									<div>
										(
										{formatter.format(
											scholasticDollarBalance
										)}
										)
									</div>
								</div>
								<div>
									{formatter.format(appliedScholasticDollars)}
								</div>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<div>Scholastic Dollars Due</div>
								<div>
									{formatter.format(
										fairSpendingScholasticDollarsDue
									)}
								</div>
							</BoxLine>
						</StyledBox>
						{!supressEarnings && (
							<StyledBox>
								<BoxHeading>Fair Earnings</BoxHeading>
								<BoxLine>
									<div>
										Scholastic Dollars Earned from This Fair
										<LineSubText>
											(Fair Sales x{" "}
											{earningsDollarFairLevel}%)
										</LineSubText>
									</div>
									<div>
										{formatter.format(
											scholasticDollarsEarned
										)}
									</div>
								</BoxLine>
								<HorizontalLineBreak />
								<BoxLine>
									<div>Scholastic Dollars Due</div>
									<div>
										{formatter.format(
											fairEarningsScholasticDollarsDue
										)}
									</div>
								</BoxLine>
								<HorizontalLineBreakBold />
								<BoxLine>
									<BoldText>
										Scholastic Dollars Profit Selected
									</BoldText>
									<BoldText>
										{formatter.format(profitSelected)}
									</BoldText>
								</BoxLine>
								<HorizontalLineBreak />
								<BoxLine>
									<BoldText>Cash Profit Selected</BoldText>
									<BoldText>
										{formatter.format(cashProfitSelected)}
									</BoldText>
								</BoxLine>
							</StyledBox>
						)}
						<ContactForQuestions>
							For questions on completing your Financial Form,
							contact Customer Success at{" "}
							<PhoneNumber>877-245-0903</PhoneNumber> or{" "}
							<Underlined>
								CPtoolkit@scholasticbookfairs.com
							</Underlined>
						</ContactForQuestions>
						<StyledCheckbox
							checked={acceptChecked}
							onChange={() => handleAcceptCheckbox()}
							label={
								"I have reviewed the information above and accept it as correct."
							}
							alignItemsStart={true}
						/>
						<ButtonContainer>
							<StyledWhiteButton
								whiteBackground={true}
								handleClick={handleClickBackButton}
							>
								Back
							</StyledWhiteButton>
							<StyledRedButton
								disabled={!acceptChecked || submitClicked}
								handleClick={clickSubmit}
							>
								Submit
							</StyledRedButton>
						</ButtonContainer>
					</Column>
				</FFReview>
			</FullWidth>
		</FinancialFormGrid>
	);
};

const FullWidth = styled.div`
	grid-column-start: 1;
	grid-column-end: 24;
`;

const FFReview = styled.div`
	display: flex;
	justify-content: center;
	font-family: museo-sans;
	column-gap: 112px;
	width: 100%;
	margin-bottom: 56px;
	@media (max-width: 950px) {
		flex-direction: column;
		align-items: center;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	max-width: 600px;
	gap: 20px;
	margin-top: 56px;
	@media (max-width: 500px) {
		align-items: center;
	}
`;

const StyledHeading = styled.div`
	color: #333333;
	font-size: 39px;
	font-weight: 300;
	line-height: 42px;
	letter-spacing: 0px;
	text-align: left;
	@media (max-width: 500px) {
		font-size: 32px;
		line-height: 35px;
	}
`;

const StyledSubHeading = styled.div`
	color: #333333;
	font-size: 13px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	margin-bottom: 22px;
	@media (max-width: 700px) {
		/* width: 279px;
		text-align: center;
		font-size: 32px;
		line-height: 35px; */
	}
`;

const StyledBox = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	padding: 48px 80px;
	background-color: #f5f5f5;
	color: #333333;
	box-sizing: border-box;
	@media (max-width: 550px) {
		padding: 48px 24px;
	}
`;

const BoldText = styled.div`
	font-weight: 600;
`;

const BoxHeading = styled.div`
	font-size: 28px;
	font-weight: 300;
	line-height: 32px;
	letter-spacing: 0px;
	text-align: left;
	margin-bottom: 35px;
`;

const BoxLine = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@media (max-width: 550px) {
		flex-direction: column;
	}
`;

const LineSubText = styled.div`
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
`;

const HorizontalLineBreak = styled.hr`
	height: 1px;
	background: #d7d7d7;
	width: 100%;
	margin: 12px 0px;
	border: none;
`;

const HorizontalLineBreakBold = styled(HorizontalLineBreak)`
	height: 2px;
	background: #333;
`;

const ContactForQuestions = styled.div`
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	color: #333;
	margin-bottom: 19px;
`;

const Underlined = styled(ContactForQuestions)`
	text-decoration: underline;
`;

const StyledCheckbox = styled(Checkbox)`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-top: 31px;
	@media (max-width: 500px) {
		flex-direction: column-reverse;
		align-items: flex-start;
		row-gap: 32px;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 178px;
	height: 40px;
`;

const StyledRedButton = styled(RedButton)`
	width: 178px;
	height: 40px;
`;

const PhoneNumber = styled.span`
	white-space: nowrap;
`;
