import IframeContainer from "components/IframeContainer";
import { FinancialFormEarningsContent } from "components/FinancialForm/Earnings/FinancialFormEarningsContent";
import FairInfoBar from "components/FairInfoBar";
import useEarningsData from "hooks/FinancialForm/useEarningsData";
export const FinancialFormEarnings = () => {
	const { earningsInfo, fairId, isLoading } = useEarningsData();
	return (
		<IframeContainer
			renderItems={() => (
				<>
					{!isLoading && (
						<>
							<FairInfoBar />
							<FinancialFormEarningsContent
								earningsInfo={earningsInfo}
								fairId={fairId}
							/>
						</>
					)}
				</>
			)}
		/>
	);
};
