import React from "react";
import InvoicePrintablePages from "../FinancialForm/Invoice/InvoicePrintablePages";
import useSalesHistoryInvoiceButtonData from "hooks/SalesHistory/useSalesHistoryInvoiceButtonData";

interface Props {
	printClicked: boolean;
	handlePrint: Function;
	fairIdSalesHistory: string;
	schoolId?: string;
}
const SalesHistoryInvoice = ({
	printClicked,
	handlePrint,
	fairIdSalesHistory,
	schoolId,
}: Props) => {
	const {
		invoiceBasicInfo,
		invoiceSalesInfo,
		invoiceEarningsInfo,
		invoiceSpendingInfo,
		isLoading,
		fairId,
	} = useSalesHistoryInvoiceButtonData({
		fairIdSalesHistory: fairIdSalesHistory,
		schoolId: schoolId,
	});

	return (
		<InvoicePrintablePages
			printClicked={printClicked}
			handlePrint={handlePrint}
			isLoading={isLoading}
			invoiceBasicInfo={invoiceBasicInfo}
			invoiceSalesInfo={invoiceSalesInfo}
			invoiceEarningsInfo={invoiceEarningsInfo}
			invoiceSpendingInfo={invoiceSpendingInfo}
			fairId={fairId}
		/>
	);
};

export default SalesHistoryInvoice;
