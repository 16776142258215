import React from "react";
import styled from "styled-components/macro";
import Card from "components/Homepage/Card";
import BodyText from "components/BodyText";
import { RedButton } from "components/Button";
import { useConfig } from "providers/ConfigContext";
import { trackEWalletGetStarted } from "DumbleData";

type Props = {
	fairId: number;
	endDate: Date;
	eWalletStartDate: Date;
	eWalletEndDate: Date;
};

const EWallet = ({ fairId, eWalletStartDate, eWalletEndDate }: Props) => {
	const { bookfairsOrigin, damAssetsOrigin } = useConfig();

	// Lists for days of the week and month abbreviations
	const daysOfTheWeek = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	// Get info for start day
	const startDay = daysOfTheWeek[eWalletStartDate.getDay()];
	const startMonth = months[eWalletStartDate.getMonth()];
	const startDate = eWalletStartDate.getDate();

	// Get info for end day
	const endDay = daysOfTheWeek[eWalletEndDate.getDay()];
	const endMonth = months[eWalletEndDate.getMonth()];
	const endDate = eWalletEndDate.getDate();

	const eWalletHref = `${bookfairsOrigin}content/fairs/how-ewallet-works.html?fairId=${fairId}`;

	// Determine if the get started button should be shown
	let showGetStarted = false;
	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);

	const dayAfterEWalletEndDate = new Date(eWalletEndDate);
	dayAfterEWalletEndDate.setDate(endDate + 1);
	if (
		eWalletStartDate <= currentDate &&
		currentDate <= dayAfterEWalletEndDate
	) {
		showGetStarted = true;
	}

	return (
		<Card>
			<WalletContainer>
				<Header>
					<StyledEWalletIcon
						src={`${damAssetsOrigin}LiveHomepage/Icons/Section/eWallet.png`}
					/>
				</Header>
				<StyledEwalletText>eWallet Setup Dates:</StyledEwalletText>
				<StyledEwalletDates>
					{startDay}, {startMonth} {startDate} - {endDay}, {endMonth}{" "}
					{endDate}
				</StyledEwalletDates>
				<StyledBodyText>
					Students can shop without cash thanks to Book Fair eWallet.
					Teachers can use eWallet to build their classroom libraries.
				</StyledBodyText>
				{showGetStarted && (
					<StyledRedButton
						href={eWalletHref}
						handleClick={() => trackEWalletGetStarted(eWalletHref)}
						target="_top"
					>
						Get Started
					</StyledRedButton>
				)}
			</WalletContainer>
		</Card>
	);
};
const StyledEwalletText = styled.p`
	margin-top: 24px;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	text-transform: uppercase;
`;

const StyledEwalletDates = styled.p`
	color: #e81111;
	font-size: 21px;
	font-weight: 700;
	line-height: 24px;
	text-align: center;
`;

const StyledEWalletIcon = styled.img`
	width: 83px;
	margin-bottom: 9px;
	margin-top: 19px;
	@media (max-width: 720px) {
		margin-top: 12.6px;
	}
`;
const StyledRedButton = styled(RedButton)`
	margin-top: 60px;
	width: 150px;
	@media (max-width: 670px) {
		margin-top: 46px;
	}
	@media (max-width: 375px) {
		margin-top: 74px;
	}
`;
const StyledBodyText = styled(BodyText)`
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 24px;
	text-align: center;
	color: #333333;
	height: 72px;
	max-width: 340px;
	margin-top: 20px;
	@media (max-width: 670px) {
		font-size: 20px;
	}
	@media (max-width: 375px) {
		max-width: 287px;
		margin-top: 13px;
		padding: 0 24px 0 24px;
	}
`;
const WalletContainer = styled.div`
	max-height: 400px;
	max-width: 440px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const Header = styled.div`
	width: 392px;
	border-bottom: 1px solid #ffd7c7;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 850px) {
		width: 274.4px;
	}
`;
export default EWallet;
