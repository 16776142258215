import React, { useCallback, useEffect, useState } from "react";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components/macro";
import {
	StyledNumber,
	NumberCaption,
	StyledDisableButton,
} from "./FairDetailsCard";
import {
	convertDateToMonthDayYear,
	convertMDYStringToDate,
} from "utils/DateConversion";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import {
	EnableOnlineShoppingModal,
	DisableOnlineShoppingModal,
} from "./OnlineShoppingModal";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

type Props = {
	sales: string;
	startDate: string;
	endDate: string;
	enabled: boolean;
};

const OnlineFairCard = ({ sales, startDate, endDate, enabled }: Props) => {
	const { bookfairsAPI } = useConfig();
	const [isEnabled, setIsEnabled] = useState<Boolean>(enabled);
	const [enableModalOpen, setEnableModalOpen] = useState<Boolean>(false);
	const [disableModalOpen, setDisableModalOpen] = useState<Boolean>(false);
	const [afterFair, setAfterFair] = useState(true);

	useEffect(() => {
		setIsEnabled(enabled);
	}, [enabled]);

	const toggleEnabled = () => {
		setIsEnabled(!isEnabled);
	};

	const handleEnabledOnlineShoppingModal = async () => {
		const currentFair = getFairIdFromUrl();
		if (checkSpsUd()) {
			try {
				await axios
					.put(
						`${bookfairsAPI}/user/fairs/${currentFair}/settings/online-fair`,
						{ enabled: true },
						{ withCredentials: true }
					)
					.then((response) => {
						if (response.status === 200) {
							setEnableModalOpen(true);
							toggleEnabled();
							window._satellite.track("online-fairs-enablement", {
								"button-text": "online-fairs-enablement",
							});
							window.parent.postMessage(
								"openModal",
								window.location.origin
							);
							document.documentElement.classList.add(
								"bookfairs-modal--open"
							);
						} else {
							handleGenericSuccess(response);
						}
					})
					.catch((err) => {
						handleGenericErrors(err);
					});
			} catch (err: any) {
				handleGenericErrors(err);
			}
		}
	};

	const handleDisabledOnlineShoppingModal = () => {
		setDisableModalOpen(true);
		window._satellite.track("online-fairs-disablement", {
			"button-text": "online-fairs-disablement",
		});
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};

	const checkOnlineShoppingWindow = useCallback(() => {
		const start = convertMDYStringToDate(startDate);
		const afterStart = new Date(start.setDate(start.getDate() + 14));
		if (new Date() < afterStart) {
			setAfterFair(false);
		}
	}, [startDate]);

	useEffect(() => {
		checkOnlineShoppingWindow();
	}, [checkOnlineShoppingWindow]);

	return (
		<>
			{enableModalOpen && (
				<EnableOnlineShoppingModal
					closeModal={() => {
						setEnableModalOpen(false);
					}}
				/>
			)}
			{disableModalOpen && (
				<DisableOnlineShoppingModal
					closeModal={() => {
						setDisableModalOpen(false);
					}}
					setEnabled={toggleEnabled}
				/>
			)}
			<FairDetailsCard title={"Online Shopping"}>
				<OuterContainer>
					<InnerContainer>
						<NumberCaption>Online shopping sales</NumberCaption>
						<StyledNumber>${sales}</StyledNumber>
					</InnerContainer>
					<StyledInnerContainer>
						{afterFair ? (
							<BodyText>
								The online shopping period earning 25% has
								ended. Your school still earns 2% in Scholastic
								Dollars with every purchase.
							</BodyText>
						) : (
							<>
								<BodyText>
									All online purchases benefit your school and
									earn 25% in Scholastic Dollars from:
								</BodyText>
								<StyledDate>
									{convertDateToMonthDayYear(
										convertMDYStringToDate(startDate)
									)}{" "}
									→{" "}
									{convertDateToMonthDayYear(
										convertMDYStringToDate(endDate)
									)}
								</StyledDate>
							</>
						)}
					</StyledInnerContainer>
					<InnerContainer>
						{!afterFair && (
							<>
								{isEnabled ? (
									<StyledDisableButton
										onClick={
											handleDisabledOnlineShoppingModal
										}
									>
										DISABLE
									</StyledDisableButton>
								) : (
									<StyledDisableButton
										onClick={
											handleEnabledOnlineShoppingModal
										}
									>
										ENABLE
									</StyledDisableButton>
								)}
							</>
						)}
					</InnerContainer>
				</OuterContainer>
			</FairDetailsCard>
		</>
	);
};

const StyledDate = styled.div`
	font-weight: 700;
	font-size: 13px;
	line-height: 15px;
	color: #666666;
`;

const OuterContainer = styled.div`
	display: flex;
	gap: 16px;
	flex-direction: column;
	justify-content: left;
`;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledInnerContainer = styled(InnerContainer)`
	gap: 8px;
`;

const BodyText = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
`;

export default OnlineFairCard;
