import React from "react";
import styled from "styled-components/macro";

interface Props {
	children: React.ReactNode;
	className?: string;
}

const Subheading = ({ children, className }: Props) => {
	return (
		<StyledSubHeading className={className}>{children}</StyledSubHeading>
	);
};

const StyledSubHeading = styled.p`
	font-family: museo-sans;
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
	line-height: 28px;
	color: #333333;
`;

export default Subheading;
