import React, { useCallback, useEffect, useRef, useState } from "react";
import { useConfig } from "providers/ConfigContext";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Page } from "components/HomepageBuilder/Page";
import Heading from "components/Heading";
import Subheading from "components/Subheading";
import PageIcon from "components/HomepageBuilder/PageIcon";
import GoalIcon from "images/Goal.svg";
import BodyText from "components/BodyText";
import TextInput from "components/TextInput";
import Checkbox from "components/Checkbox";
import LimitedTextArea from "components/LimitedTextArea";
import { RedButton } from "components/Button";
import TextLink from "components/TextLink";
import { useGoalData } from "./Builder";
import axios from "axios";
import SaveChangesModal from "components/HomepageBuilder/SaveChangesModal";
import Prompt from "components/HomepageBuilder/Prompt";
import { trackSaveAndPublish } from "DumbleData";
import IframeContainer from "components/IframeContainer";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

enum GoalType {
	books = "books",
	dollars = "dollars",
}

export const GoalPage = () => {
	const { bookfairsOrigin, damAssetsOrigin, bookfairsAPI } = useConfig();
	const { goalData, nextLocation, setNextLocation } = useGoalData();
	const navigate = useNavigate();
	const [goalAmountError, setGoalAmountError] = useState<string>();
	const [goalSalesError, setGoalSalesError] = useState<string>();
	const [goalPurposeError, setGoalPurposeError] = useState<string>();
	const [attemptedToSubmit, setAttemptedToSubmit] = useState(false);
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const [allowNavigation, setAllowNavigation] = useState(false);
	const firstRender = useRef(true);
	const [savedData] = useState(goalData);
	const validateNumString = (value: string) => {
		const re = /^\d+(,\d+)*$/;
		return value === "" || re.test(value);
	};

	//Adds commas after every 3 digits for larger numbers
	const formatNumber = (value: string) =>
		value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

	const updateGoalBooks = (e: any, goalType: string) => {
		//remove commas if large number
		const value = e.replace(/,/gi, "");
		if (validateNumString(value)) {
			if (goalType === GoalType.books) {
				goalData.setGoalBooks(formatNumber(value));
				goalData.setBookFairSalesAmount(
					formatNumber((value * 8).toString())
				);
			} else {
				goalData.setGoalBooks(
					formatNumber(Math.ceil(value / 8).toString())
				);
				goalData.setBookFairSalesAmount(formatNumber(value));
			}
		}
	};

	const updateCurrentBooks = (e: any, goalType: string) => {
		//remove commas if large number
		const value = e.replace(/,/gi, "");
		if (validateNumString(e)) {
			if (goalType === GoalType.books) {
				goalData.setActualAmount(formatNumber(value));
				goalData.setActualSales(formatNumber((value * 8).toString()));
			} else {
				goalData.setActualAmount(
					formatNumber(Math.ceil(value / 8).toString())
				);
				goalData.setActualSales(formatNumber(value));
			}
		}
	};

	//callback to validate data
	//will first be triggered by first submit and then will run each time an input field is changed
	const dataValidate = useCallback(() => {
		let isAllValid = true;
		if (goalData.bookFairGoalCkbox) {
			if (!goalData.goalAmount) {
				setGoalAmountError("Please enter a goal.");
				isAllValid = false;
			} else {
				setGoalAmountError(undefined);
			}
			if (!goalData.bookFairSalesAmount) {
				setGoalSalesError("Please enter a goal.");
				isAllValid = false;
			} else {
				setGoalSalesError(undefined);
			}
			if (!goalData.goalPurpose) {
				setGoalPurposeError("Please enter a goal message.");
				isAllValid = false;
			} else {
				setGoalPurposeError(undefined);
			}
		} else {
			setGoalAmountError(undefined);
			setGoalPurposeError(undefined);
		}
		return isAllValid;
	}, [
		goalData.goalAmount,
		goalData.bookFairSalesAmount,
		goalData.bookFairGoalCkbox,
		goalData.goalPurpose,
	]);

	//calls validation function and then sends post request if all inputs are valid, otherwise notifies useEffect to check for errors
	const saveAndContinueGoals = async () => {
		const currentFair = getFairIdFromUrl();
		setAllChangesSaved(true);
		let checkboxClean = "N";
		if (goalData.bookFairGoalCkbox) {
			checkboxClean = "Y";
		}
		if (dataValidate()) {
			if (checkSpsUd()) {
				try {
					await axios
						.put(
							`${bookfairsAPI}/user/fairs/${currentFair}/homepage/goals`,
							{
								booksGoal:
									goalData.goalAmount.replace(",", "") || "0",
								booksSales:
									goalData.actualAmount.replace(",", "") ||
									"0",
								dollarsGoal:
									goalData.bookFairSalesAmount.replace(
										",",
										""
									) || "0",
								dollarsSales:
									goalData.actualSales.replace(",", "") ||
									"0",
								bookFairGoalCkbox: checkboxClean,
								goalPurpose: goalData.goalPurpose,
							},
							{ withCredentials: true }
						)
						.then((response) => {
							if (response.status === 200) {
								trackSaveAndPublish(
									"set-goal",
									"Set or Update Goal"
								);
								setAllowNavigation(true);
							} else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							handleGenericErrors(err);
						});
				} catch (err) {
					handleGenericErrors(err);
				}
			}
		} else {
			setSaveChangesModalOpen(false);
			setAttemptedToSubmit(true);
		}
	};

	//If submitted at least once, checks for input errors at every change of input field
	useEffect(() => {
		if (attemptedToSubmit) {
			dataValidate();
		}
	}, [
		attemptedToSubmit,
		dataValidate,
		goalData.goalAmount,
		goalData.goalPurpose,
		goalData.bookFairGoalCkbox,
	]);

	// Displays the two most recent fairs from a user's sales history
	const renderFairHistory = () => {
		const virtualFairs = ["OC", "OS", "OT", "VF"];
		const fairElements = [];
		for (let i = 0; i < 2 && i < goalData.fairHistory.length; i++) {
			const fair = goalData.fairHistory[i];
			const [year, month, day] = fair.historyFairDate.split("-");
			const formattedDate = `${month}/${day}/${year}`;
			const fairType = virtualFairs.includes(fair.historyFairProductId)
				? "Virtual book fair"
				: "In-person book fair";
			const fairSales = parseInt(fair.historyFairSales);
			const numBooks = Math.ceil(fairSales / 8);
			const formattedSales = formatNumber(fair.historyFairSales);
			const id =
				fair.historyFairSales +
				fair.historyFairDate +
				fair.historyFairProductId;
			const saleHistory = (
				<FlexRow key={id}>
					<InlineElement>
						<SaleTitle>{formattedDate}</SaleTitle>
						<SaleTitle>{fairType}</SaleTitle>
					</InlineElement>
					<InlineElement>
						<SaleValue>
							{numBooks} book{numBooks !== 1 ? "s" : ""} ($
							{formattedSales})
						</SaleValue>
					</InlineElement>
				</FlexRow>
			);
			fairElements.push(saleHistory);
			if (i === 0 && goalData.fairHistory.length !== 1) {
				fairElements.push(<SaleDivider key={"divider"} />);
			}
		}
		return fairElements;
	};

	//
	// Logic to handle navigation and rendering the SaveChangesModal
	//

	//Tracks changes made
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		} else {
			setAllChangesSaved(false);
		}
	}, [
		goalData.goalAmount,
		goalData.goalPurpose,
		goalData.bookFairGoalCkbox,
		goalData.bookFairSalesAmount,
		goalData.actualSales,
	]);

	//Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			navigate(nextLocation);
		}
	}, [
		allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		nextLocation,
	]);

	/////////////////////////////////////

	return (
		<IframeContainer
			renderItems={() => (
				<>
					<StyledGoalPage>
						<PageIcon
							src={`
						${damAssetsOrigin}/HomepageBuilder/Icons/Section/Goal.svg
					`}
							defaultImg={GoalIcon}
							alt="Goal Page Icon"
						/>
						<Heading>Set or update goal</Heading>
						<StyledBodyText>
							Making goals visible on your homepage will inspire
							everyone to work together to achieve them. Choose a
							sales goal or the number of books you hope to sell.
							Keep in mind that every $8 in sales is the
							equivalent of one book sold. Tell your school
							community how achieving your goal would benefit the
							school.
						</StyledBodyText>
						<Wrapper>
							<StyledGoalAmount order={1}>
								<StyledSubheading>Set a goal</StyledSubheading>
								<FlexRow>
									<InlineElement>
										<TextInput
											label="Books"
											value={goalData.goalAmount}
											onChange={(e) =>
												updateGoalBooks(
													e,
													GoalType.books
												)
											}
											error={goalAmountError}
										></TextInput>
									</InlineElement>
									<InlineElement>
										{goalData.bookFairSalesAmount && (
											<CurrencyPlaceholder>
												$
											</CurrencyPlaceholder>
										)}
										<TextInput
											label="Dollars"
											value={goalData.bookFairSalesAmount}
											onChange={(e) =>
												updateGoalBooks(
													e,
													GoalType.dollars
												)
											}
											error={goalSalesError}
										></TextInput>
									</InlineElement>
								</FlexRow>
								<Checkbox
									label="Display book goal on homepage (Dollar amount will not be displayed)"
									checked={goalData.bookFairGoalCkbox}
									onChange={goalData.setBookFairGoalCkbox}
								></Checkbox>
							</StyledGoalAmount>
							<StyledPromoteGoal order={3}>
								<StyledSubheading>
									Promote your goal
								</StyledSubheading>
								<StyledLimitedTextArea
									value={goalData.goalPurpose}
									onChange={goalData.setGoalPurpose}
									limit={175}
									error={goalPurposeError}
								></StyledLimitedTextArea>
							</StyledPromoteGoal>
							<StyledSalesAmount order={2}>
								<StyledSubheading>
									Update current sales
								</StyledSubheading>
								<FlexRow>
									<InlineElement>
										<TextInput
											label="Books"
											value={goalData.actualAmount}
											onChange={(e) =>
												updateCurrentBooks(
													e,
													GoalType.books
												)
											}
										></TextInput>
									</InlineElement>
									<InlineElement>
										{goalData.actualSales && (
											<CurrencyPlaceholder>
												$
											</CurrencyPlaceholder>
										)}
										<TextInput
											label="Dollars"
											value={goalData.actualSales}
											onChange={(e) =>
												updateCurrentBooks(
													e,
													GoalType.dollars
												)
											}
										></TextInput>
									</InlineElement>
								</FlexRow>
							</StyledSalesAmount>
							<FlexSection order={4}>
								<StyledSubheading>
									Sales history
								</StyledSubheading>
								{renderFairHistory()}
								<SalesLink
									href={`${bookfairsOrigin}/content/fairs/hub/sales-history.html?hideElements=true`}
									target="_blank"
									rel="noreferrer"
								>
									View sales history
								</SalesLink>
							</FlexSection>
						</Wrapper>
						<StyledRedButton
							handleClick={() => {
								setNextLocation(
									"/reactapp/customize/information"
								);
								saveAndContinueGoals();
							}}
						>
							save & publish
						</StyledRedButton>
						<StyledTextLink
							handleClick={() => {
								if (!allChangesSaved) {
									setNextLocation(
										"/reactapp/customize/information"
									);
									setSaveChangesModalOpen(
										!saveChangesModalOpen
									);
								} else {
									navigate("/reactapp/customize/information");
								}
							}}
						>
							Skip
						</StyledTextLink>
					</StyledGoalPage>

					<Prompt
						message={() => {
							setSaveChangesModalOpen(true);
						}}
						when={!allChangesSaved}
					/>
					<SaveChangesModal
						saveAndPublish={saveAndContinueGoals}
						discardChanges={() => {
							goalData.setActualAmount(savedData.actualAmount);
							goalData.setActualSales(savedData.actualSales);
							goalData.setBookFairGoalCkbox(
								savedData.bookFairGoalCkbox
							);
							goalData.setBookFairSalesAmount(
								savedData.bookFairSalesAmount
							);
							goalData.setGoalPurpose(savedData.goalPurpose);
							goalData.setGoalBooks(savedData.goalAmount);
							setAllChangesSaved(true);
							setAllowNavigation(true);
						}}
						modalIsOpen={saveChangesModalOpen}
						handleModal={() => {
							setSaveChangesModalOpen(!saveChangesModalOpen);
						}}
					/>
				</>
			)}
		/>
	);
};

/*
	Page Specific Styles
*/
const StyledGoalPage = styled(Page)`
	padding-bottom: 105px;
	@media (max-width: 374px) {
		padding-bottom: 80px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 40px;
	width: 100%;
	max-width: 600px;
	@media (max-width: 719px) {
		gap: 0;
	}
`;

const StyledRedButton = styled(RedButton)`
	width: 181px;
	margin-top: 71.5px;
`;

const StyledTextLink = styled(TextLink)`
	margin-top: 23px;
`;

const StyledSubheading = styled(Subheading)`
	margin-bottom: 19px;
`;

const StyledBodyText = styled(BodyText)`
	max-width: 377px;
	padding-bottom: 32px;
	@media (max-width: 719px) {
		padding-bottom: 47px;
	}
	@media (max-width: 374px) {
		padding-bottom: 40px;
	}
`;

const StyledLimitedTextArea = styled(LimitedTextArea)`
	textarea {
		height: 144px;
		padding: 12px 19.88px 8px 18.12px;
	}
`;

interface FlexSectionProps {
	order?: number;
}

const FlexSection = styled.div<FlexSectionProps>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: calc(50% - 20px);
	min-width: 280px;
	min-height: 160px;
	@media (max-width: 719px) {
		width: 100%;
		min-height: min-content;
		order: ${(props) => props.order};
	}
`;

const StyledGoalAmount = styled(FlexSection)`
	@media (max-width: 719px) {
		margin-bottom: 60px;
	}
`;

const StyledSalesAmount = styled(FlexSection)`
	@media (max-width: 719px) {
		margin-bottom: 33px;
	}
`;

const StyledPromoteGoal = styled(FlexSection)`
	@media (max-width: 719px) {
		margin-bottom: 70px;
	}
`;

const FlexRow = styled.div`
	display: inline-flex;
	width: 100%;
	gap: 40px;
`;

const InlineElement = styled.div`
	position: relative;
	flex: 1 auto;
	width: calc(50% - 20px);
`;

const SaleTitle = styled.p`
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
	color: #333333;
`;

const SaleValue = styled(SaleTitle)`
	font-weight: 600;
	float: right;
`;

const SaleDivider = styled.hr`
	width: 100%;
	margin: 12px 0px;
	background: #e3e3e3;
	height: 1px;
	border: none;
`;

const SalesLink = styled(TextLink)`
	letter-spacing: normal;
	font-size: 13px;
	line-height: 15px;
	font-weight: normal;
	margin-top: 17px;
	text-transform: none;
`;

const CurrencyPlaceholder = styled.span`
	position: absolute;
	font-family: "museo-sans";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	left: 7px;
	top: 34px;
`;

export default GoalPage;
