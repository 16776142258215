import React, { useEffect } from "react";
import styled from "styled-components/macro";
import CaseFair from "./CaseFair";
import VirtualFair from "./VirtualFair";
import BogoFair from "./BogoFair";
import BogoTableTopFair from "./BogoTableTopFair";
import TableTopFair from "./TabletopFair";
import DiscountedFair from "./DiscountedFair";
import { convertDateToMDY, convertStringToDate } from "utils/DateConversion";
import {
	ChairpersonData,
	ConsultantData,
	FairInfoData,
	OrganizationData,
} from "hooks/ConfirmYourFair/useCoaPageData";
import useCurrentServiceAgreement from "hooks/ConfirmYourFair/ServiceAgreement/useCurrentServiceAgreement";

interface Props {
	emailedPage: boolean;
	handlePrint: Function | undefined;
	printClicked: boolean | undefined;
	fairInfoData: FairInfoData;
	chairpersonData: ChairpersonData;
	consultantData: ConsultantData;
	organizationData: OrganizationData;
	isLoading: boolean;
}

const LegalCopyContent = ({
	emailedPage,
	handlePrint,
	printClicked,
	fairInfoData,
	chairpersonData,
	consultantData,
	organizationData,
	isLoading,
}: Props) => {
	let coaDate = "";
	if (fairInfoData && fairInfoData?.coaStatus === "Accepted") {
		coaDate = convertDateToMDY(
			convertStringToDate(fairInfoData?.coaAcceptedDate)
		);
	}

	const isCurrentServiceAgreement = useCurrentServiceAgreement({
		coaAcceptedDate: fairInfoData.coaAcceptedDate,
	});

	useEffect(() => {
		// open print dialogue after page content is populated
		if (printClicked && handlePrint && !isLoading) {
			if (
				fairInfoData &&
				chairpersonData &&
				consultantData &&
				organizationData
			) {
				handlePrint();
			}
		}
	}, [
		printClicked,
		handlePrint,
		fairInfoData,
		chairpersonData,
		consultantData,
		organizationData,
		isLoading,
	]);

	return (
		<PDF isEmailed={emailedPage}>
			<Heading>
				<p>Scholastic Book Fairs (SBF)</p>
				<p>Certificate of Agreement</p>
			</Heading>
			{fairInfoData?.fairType === "case" && (
				<CaseFair
					isCurrentServiceAgreement={isCurrentServiceAgreement}
					emailedPage={emailedPage}
				/>
			)}
			{fairInfoData?.fairType === "Virtual" && <VirtualFair />}
			{fairInfoData?.fairType === "bogo case" && <BogoFair />}
			{fairInfoData?.fairType === "bogo tabletop" && (
				<BogoTableTopFair emailedPage={emailedPage} />
			)}
			{fairInfoData?.fairType === "tabletop" && (
				<TableTopFair
					isCurrentServiceAgreement={isCurrentServiceAgreement}
				/>
			)}
			{fairInfoData?.fairType.toLowerCase() === "discounted 25%" && (
				<DiscountedFair emailedPage={emailedPage} />
			)}
			<Receipt>
				{fairInfoData?.coaStatus === "Accepted" && (
					<>
						<ReceiptText>
							Your receipt of the confirmation email that this
							Agreement has been accepted confirms and secures
							your Book Fair dates, reserving product for your
							Fair.
						</ReceiptText>
						<Row>
							<ChairInfo>
								<InfoTextHeading>
									Book Fair Host Information
								</InfoTextHeading>
								<InfoText>
									{chairpersonData?.firstName}{" "}
									{chairpersonData?.lastName}
								</InfoText>
								<InfoText>{chairpersonData?.email}</InfoText>
								<InfoText>
									{chairpersonData?.phoneNumber}
								</InfoText>
							</ChairInfo>
							<ConsultantInfo>
								<InfoTextHeading>
									Book Fair Consultant
								</InfoTextHeading>
								<InfoText>
									{consultantData?.firstName}{" "}
									{consultantData?.lastName}
								</InfoText>
								<InfoText>
									{consultantData?.phoneNumber}
								</InfoText>
								<InfoText>{consultantData?.email}</InfoText>
							</ConsultantInfo>
						</Row>
					</>
				)}
				<Row>
					<SchoolInfo>
						<InfoTextHeading>School Information</InfoTextHeading>
						<InfoText>{organizationData?.name}</InfoText>
						<InfoText>{organizationData?.addressLine1}</InfoText>
						<InfoText>
							{organizationData?.city}, {organizationData?.state}{" "}
							{organizationData?.zipcode}
						</InfoText>
						<InfoText>
							Account #: {organizationData?.bookfairAccountId}
						</InfoText>
					</SchoolInfo>
					<FairInfo>
						<InfoTextHeading>Fair Information</InfoTextHeading>
						<InfoText>
							Fair Dates: {fairInfoData?.startDate} to{" "}
							{fairInfoData?.endDate}
						</InfoText>
						<InfoText>Fair ID: #{fairInfoData?.fairId}</InfoText>
					</FairInfo>
				</Row>
				{fairInfoData?.coaStatus === "Accepted" && (
					<>
						<AgreementText>
							<b>Agreement Date:</b> {coaDate}
						</AgreementText>
						<AgreementText>
							Accepted Online Electronically
						</AgreementText>
					</>
				)}
			</Receipt>
		</PDF>
	);
};

const AgreementText = styled.div`
	font-size: 9px;
	font-weight: 500;
	margin-bottom: 15px;
	margin-top: 30px;
`;

interface PDFProps {
	isEmailed?: boolean;
}

const PDF = styled.div<PDFProps>`
	display: flex;
	flex-direction: column;
	margin: ${(props) => (props.isEmailed ? "0.5in 1in 1in 1in" : "1in")};
	align-items: center;
`;

const Heading = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	font-weight: 700;
	gap: 10px;
`;

const Receipt = styled.div`
	width: 6.5in;
`;

const ReceiptText = styled.div`
	font-size: 9px;
	font-weight: 700;
	margin-bottom: 15px;
	margin-top: 30px;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	gap: 150px;
`;
const ChairInfo = styled.div`
	width: 200px;
`;
const InfoText = styled.div`
	font-size: 10.5px;
`;
const InfoTextHeading = styled.div`
	font-size: 10.5px;
	font-weight: 700;
`;
const ConsultantInfo = styled.div`
	width: 200px;
	border-left: 1px solid black;
	padding-left: 10px;
`;
const SchoolInfo = styled.div`
	width: 200px;
`;
const FairInfo = styled.div`
	width: 200px;
	border-left: 1px solid black;
	padding-left: 10px;
`;

export default LegalCopyContent;
