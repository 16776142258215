import React from "react";
import styled from "styled-components";

interface Props {
	children: any;
	className?: string;
	handleClick?: any;
}

const RedTextButton = ({ children, className, handleClick }: Props) => {
	const handleEnterKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") {
			handleClick();
		}
	};
	return (
		<Text
			tabIndex={1}
			className={className}
			onClick={handleClick}
			onKeyDown={(e) => handleEnterKeyPress(e)}
		>
			{children}
		</Text>
	);
};

interface TextProps {
	textAlign?: string;
}

export const Text = styled.div<TextProps>`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #e81111;
	&.header {
		font-weight: 500;
		font-size: 13px;
		line-height: 15px;
		text-align: right;
	}
	&.fairTermsCard {
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 19px;
	}
	&:hover {
		font-style: unset;
		text-decoration: underline;
		cursor: pointer;
	}
	&:focus-visible {
		outline: 2px solid #e81111;
		color: #e81111;
		outline-offset: 1px;
		margin-top: 1px;
	}
`;
export default RedTextButton;
