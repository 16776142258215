import Navigation from "components/HomepageBuilder/Navigation/Navigation";
import React from "react";
import { Outlet, useOutletContext, useLocation } from "react-router-dom";
import useHomepageData from "hooks/HomepageBuilder/useHomepageData";
import Cookie from "universal-cookie";
import { trackPageLoad } from "DumbleData";
import IframeContainer from "components/IframeContainer";

export const Builder = () => {
	const cookie = new Cookie();
	const fairId = cookie.get("cpt_bookfair")?.split("|")[0];
	const location = useLocation();

	const homepageData = useHomepageData({
		fairId: fairId,
		url: "",
		isPublic: false,
	});

	if (homepageData.isLoading) {
		return null;
	} else {
		trackPageLoad(location.pathname, fairId, homepageData.schoolUCN);
		return (
			<IframeContainer
				renderItems={() => (
					<React.Fragment>
						<Navigation
							handleNextLocation={homepageData.setNextLocation}
							isVirtual={homepageData.isVirtual}
							fairType={homepageData.fairType}
						/>
						<Outlet context={homepageData} />
					</React.Fragment>
				)}
			/>
		);
	}
};

type ContactType = {
	fairId: string;
	nextLocation: string;
	setNextLocation: (value: string) => void;
	contactData: {
		firstName: string;
		setFirstName: (value: string) => void;
		lastName: string;
		setLastName: (value: string) => void;
		email: string;
		setEmail: (value: string) => void;
		phoneNumber: string;
		setPhoneNumber: (value: string) => void;
		displayPhoneNumber: boolean;
		setDisplayPhoneNumber: (value: boolean) => void;
	};
};

type GoalType = {
	fairId: string;
	nextLocation: string;
	setNextLocation: (value: string) => void;
	goalData: {
		goalAmount: string;
		setGoalBooks: (value: string) => void;
		bookFairSalesAmount: string;
		setBookFairSalesAmount: (value: string) => void;
		bookFairGoalCkbox: boolean;
		setBookFairGoalCkbox: (value: boolean) => void;
		goalPurpose: string;
		setGoalPurpose: (value: string) => void;
		actualAmount: string;
		setActualAmount: (value: string) => void;
		actualSales: string;
		setActualSales: (value: string) => void;
		fairHistory: {
			historyFairSales: string;
			historyFairDate: string;
			historyFairProductId: string;
		}[];
	};
};

type FairInfoType = {
	fairId: string;
	isVirtual: boolean;
	fairType: string;
	nextLocation: string;
	setNextLocation: (value: string) => void;
	fairInfoData: {
		schoolName: string;
		setSchoolName: (value: string) => void;
		defaultStartDate: Date;
		defaultEndDate: Date;
		endDate: Date;
		setEndDate: (value: Date) => void;
		startDate: Date;
		setStartDate: (value: Date) => void;
		location: string;
		setLocation: (value: string) => void;
		address: string;
		setAddress: (value: string) => void;
		city: string;
		setCity: (value: string) => void;
		state: string;
		setState: (value: string) => void;
		zipcode: string;
		setZipcode: (value: string) => void;
		welcomeMessage: string;
		setWelcomeMessage: (value: string) => void;
		cash: boolean;
		setCash: (value: boolean) => void;
		checks: boolean;
		setChecks: (value: boolean) => void;
		creditCard: boolean;
		setCreditCard: (value: boolean) => void;
		thankYouMessage: string;
		setThankYouMessage: (value: string) => void;
		payableTo: string;
		setPayableTo: (value: string) => void;
	};
};

type VolunteerType = {
	fairId: string;
	nextLocation: string;
	setNextLocation: (value: string) => void;
	volunteerData: {
		volunteerSignUpUrl: string;
		setVolunteerSignUpUrl: (value: string) => void;
	};
};

type EventType = {
	fairId: string;
	nextLocation: string;
	setNextLocation: (value: string) => void;
	fairInfoData: {
		endDate: Date;
		startDate: Date;
	};
	eventsData: {
		endDate: Date;
		setEndDate: (value: Date) => void;
		startDate: Date;
		setStartDate: (value: Date) => void;
		schedule: {
			fairId: string;
			scheduleDate: string;
			createDate: Date;
			eventCategory: string;
			eventName: string;
			startTime: string;
			endTime: string;
			description: string;
			id: number;
		}[];
		setSchedule: (value: object[]) => void;
	};
};

type ShareHomepageType = {
	fairId: string;
	nextLocation: string;
	setNextLocation: (value: string) => void;
	shareHomepageData: {
		fairId?: string;
		customUrl: string;
		setCustomUrl: (value: string) => void;
		schoolId: string;
		setSchoolId: (value: string) => void;
		urlStatus: string;
		setUrlStatus: (value: string) => void;
		shareUrl: boolean;
		setShareUrl: (value: boolean) => void;
	};
};

export function useContactData() {
	return useOutletContext<ContactType>();
}

export function useGoalData() {
	return useOutletContext<GoalType>();
}

export function useFairInfoData() {
	return useOutletContext<FairInfoType>();
}

export function useVolunteerData() {
	return useOutletContext<VolunteerType>();
}

export function useShareHomepageData() {
	return useOutletContext<ShareHomepageType>();
}

export function useEventsData() {
	return useOutletContext<EventType>();
}
