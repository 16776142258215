// in Javascripts native toFixed-function, the floating point number 5
// does not round up
function toFixed(number: number, decimals: number): string {
	const x = Math.pow(10, Number(decimals) + 2);
	// adds a small value (1 / x) to the number to ensure that the number is rounded up
	return (Number(number) + 1 / x).toFixed(decimals);
}

function roundFloatNumberWithoutTrailingZeroes(
	num: number,
	dp: number
): number {
	var numToFixedDp = toFixed(num, dp);
	return Number(numToFixedDp);
}

// round number to 2 decimal places, following Java's RoundingMode.HALF_UP rounding convention
export function roundToPrecision(num: number, dp: number): number {
	let result = num;
	for (let i = dp; i > 1; i--) {
		result = roundFloatNumberWithoutTrailingZeroes(result, i);
	}
	return result;
}
