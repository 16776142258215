import { useState, useEffect } from "react";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import {
	updateFairData,
	updateSchoolBasicData,
	updateSchoolData,
} from "DumbleData";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

export type OrganizationData = {
	ucn: string;
	name: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	zipcode: string;
	schoolType: string;
	taxStatus: string;
	bookfairAccountId: string;
};

export type ConsultantData = {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
};

export type FairInfoData = {
	fairId: number;
	name: string;
	startDate: string;
	endDate: string;
	deliveryDate: string;
	pickupDate: string;
	fairType: string;
	remoteRoutingWindowFlag: string;
	coaStatus: string;
	coaAcceptedDate: string;
	taxRate: number;
};

export type ChairpersonData = {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	phoneExtension: string;
};

const fairInfoDefault = {
	fairId: 0,
	name: "",
	startDate: "",
	endDate: "",
	deliveryDate: "",
	pickupDate: "",
	fairType: "",
	remoteRoutingWindowFlag: "",
	coaStatus: "",
	coaAcceptedDate: "",
	taxRate: 0,
};

const chairpersonDataDefault = {
	firstName: "",
	lastName: "",
	email: "",
	phoneNumber: "",
	phoneExtension: "",
};

const consultantDataDefault = {
	firstName: "",
	lastName: "",
	email: "",
	phoneNumber: "",
};

const organizationDataDefault = {
	ucn: "",
	name: "",
	addressLine1: "",
	addressLine2: "",
	city: "",
	state: "",
	zipcode: "",
	schoolType: "",
	taxStatus: "",
	bookfairAccountId: "",
};

interface CoaPageProps {
	withToken: boolean;
	fairId?: string;
}

const useCoaPageData = (props: CoaPageProps) => {
	const { bookfairsAPI } = useConfig();

	const [fairInfoData, setFairInfoData] =
		useState<FairInfoData>(fairInfoDefault);
	const [chairpersonData, setChairpersonData] = useState<ChairpersonData>(
		chairpersonDataDefault
	);
	const [consultantData, setConsultantData] = useState<ConsultantData>(
		consultantDataDefault
	);
	const [organizationData, setOrganizationData] = useState<OrganizationData>(
		organizationDataDefault
	);

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const getCoaPageData = async () => {
			let token;
			if (props.withToken) {
				const queryParameters = new URLSearchParams(
					window.location.search
				);
				token = queryParameters.get("token");
			}

			const currentFair = props.fairId
				? props.fairId
				: getFairIdFromUrl();
			if (props.withToken) {
				try {
					await axios
						.get(`${bookfairsAPI}/public/coa/pdf-links/${token}`, {
							withCredentials: true,
						})
						.then((response) => {
							if (response.status === 200) {
								const {
									fairInfo,
									chairperson,
									consultant,
									organization,
								} = response.data;

								if (fairInfo) {
									setFairInfoData(fairInfo);
									if (!props.withToken && !props.fairId) {
										updateFairData(
											fairInfo.fairId,
											fairInfo.fairType
										);
									}
								}
								if (chairperson) {
									setChairpersonData(chairperson);
								}
								if (consultant) {
									setConsultantData(consultant);
								}
								if (organization) {
									setOrganizationData(organization);
									if (!props.withToken && !props.fairId) {
										updateSchoolData(
											organization.schoolType
										);
										updateSchoolBasicData(
											organization.name
										);
									}
								}
							} else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							setIsError(true);
							handleGenericErrors(err);
						});
				} catch (err: any) {
					handleGenericErrors(err);
					setIsError(true);
					console.error(err);
				} finally {
					setIsLoading(false);
				}
			} else if (checkSpsUd()) {
				try {
					await axios
						.get(`${bookfairsAPI}/user/fairs/${currentFair}/coa`, {
							withCredentials: true,
						})
						.then((response) => {
							if (response.status === 200) {
								const {
									fairInfo,
									chairperson,
									consultant,
									organization,
								} = response.data;

								if (fairInfo) {
									setFairInfoData(fairInfo);
									if (!props.withToken && !props.fairId) {
										updateFairData(
											fairInfo.fairId,
											fairInfo.fairType
										);
									}
								}
								if (chairperson) {
									setChairpersonData(chairperson);
								}
								if (consultant) {
									setConsultantData(consultant);
								}
								if (organization) {
									setOrganizationData(organization);
									if (!props.withToken && !props.fairId) {
										updateSchoolData(
											organization.schoolType
										);
										updateSchoolBasicData(
											organization.name
										);
									}
								}
							} else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							setIsError(true);
							handleGenericErrors(err);
						});
				} catch (err: any) {
					handleGenericErrors(err);
					setIsError(true);
					console.error(err);
				} finally {
					setIsLoading(false);
				}
			}
		};
		getCoaPageData();
	}, [bookfairsAPI, props.withToken, props.fairId]);

	return {
		fairInfoData,
		chairpersonData,
		consultantData,
		organizationData,
		isLoading,
		isError,
	};
};

export { useCoaPageData };
