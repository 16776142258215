import React from "react";
import styled from "styled-components";
import Tooltip from "./Tooltip";
import AddToCalendarRed from "images/AddToCalendar-red.svg";

type Props = {
	isOpenOrCloseEvent: boolean;
	handleAddToCalendar(): any;
	tabindex: number;
	eventName: string;
};

const AddToCalendarButton = ({
	isOpenOrCloseEvent,
	handleAddToCalendar,
	tabindex,
	eventName,
}: Props) => {
	const openOrCloseEvent = isOpenOrCloseEvent
		? "openOrCloseEvent"
		: undefined;

	const handleClick = (e: any) => {
		e.currentTarget.blur();
		handleAddToCalendar();
	};

	return (
		<StyledAddToCalendarButton>
			<Tooltip openOrCloseEvent={openOrCloseEvent}>
				Add to Calendar
			</Tooltip>
			<Button tabIndex={tabindex} onClick={(e) => handleClick(e)}>
				<Icon
					alt={`Add ${eventName} to calendar`}
					src={AddToCalendarRed}
				/>
			</Button>
		</StyledAddToCalendarButton>
	);
};

const StyledAddToCalendarButton = styled.div`
	border-radius: 40px;
	position: relative;
	&:hover .tooltip {
		visibility: visible;
		opacity: 1;
	}
`;

const Icon = styled.img`
	margin-left: auto;
	margin-right: auto;
	width: 22px;
	position: relative;
	top: 1px;
	@media (max-width: 670px) {
		border-color: none;
		box-shadow: 0 0 0;
	}
`;

const Button = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	filter: grayscale(1);
	width: 40px;
	height: 40px;
	border-radius: 40px;
	&:focus {
		filter: none;
		border-radius: 10px;
		outline-offset: -6px;
		outline: 2px solid #e81111;
	}

	&:hover {
		filter: none;
		overflow: visible;
		cursor: pointer;
		outline: none;
		border-radius: 40px;
		background-color: white;
		box-shadow: 0 0 6px #d7d7d7;
		@media (max-width: 670px) {
			box-shadow: 0 0 0 0;
			filter: grayscale(1);
		}
	}

	&:active {
		box-shadow: 0 0 0 0;
		@media (max-width: 670px) {
			filter: none;
		}
	}

	&:disabled {
		box-shadow: 0 0 0 0;
		outline: none;
	}

	@media (max-width: 670px) {
		box-shadow: 0 0 0 0;
		margin-bottom: 6px;
	}
`;

export default AddToCalendarButton;
