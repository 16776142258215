import React from "react";
import styled from "styled-components/macro";

interface Props {
	emailedPage: boolean;
}

const BogoTableTopFair = ({ emailedPage }: Props) => {
	return (
		<BogoTableTopFairContainer>
			<OpeningParagraph>
				<b>Thank you for hosting a Scholastic Book Fair!</b> We look
				forward to working with you. Please read the following Services
				Agreement and guidelines, which have recently changed.
				Scholastic Book Fairs is the retailer of books sold at the Fair.
				State and local sales tax must be collected on all purchases at
				the Fair (except for purchases made by the school with school
				funds for school use when the school is registered with
				Scholastic as tax-exempt).
			</OpeningParagraph>
			<BogoTableTopFairText>
				<p> As always, Scholastic will provide: </p>
				<BogoTableTopFairList>
					<li>Books and educational products</li>
					<li>A dedicated support and service team</li>
					<li>Online planning resources and tools</li>
					<li>Quick and easy product restocks</li>
					<li>Setup Kit & Kick-Off Kit with promotional materials</li>
					<li>An Online Fair by way of The Scholastic Store</li>
					<li>
						Convenient payment system that allows you to accept
						digital payments and major credit cards
					</li>
				</BogoTableTopFairList>
				<p>The school or organization listed agrees to:</p>
				<BogoTableTopFairList>
					<li>
						Use Scholastic Book Fairs as the exclusive provider and
						retailer of all books, merchandise, and promotional
						materials during the Book Fair event.
					</li>
					<li>
						Store and display all merchandise, cash, checks, credit
						card machines, and sale slips in a locked and secure
						location when not in use at the Fair.
					</li>
					<li>
						Make products available to all qualified event attendees
						at the listed price designated by Scholastic.
					</li>
					<li>
						Collect sales tax on behalf of Scholastic Book Fairs as
						required by state and local law.
						<BogoTableTopFairSublist>
							<li>
								Ensure proper tax-exempt certificate or other
								documentation as required by state and local law
								is on file with Scholastic Book Fairs prior to
								making any tax-exempt purchases.
							</li>
						</BogoTableTopFairSublist>
					</li>
					<li>
						Return all payment systems, unsold products, and
						merchandising materials/displays (unless otherwise
						marked) to Scholastic Book Fairs promptly at the
						conclusion of the Fair.
					</li>
					<li>
						Review and comply with the following credit card
						security procedures:
						<BogoTableTopFairSublist>
							<li>
								Do not share or distribute the payment system or
								data that you collect (such as credit card
								numbers) on behalf of Scholastic Book Fairs.
							</li>
							<li>
								Do not write down or copy any data from a
								customer's credit card (ie, card numbers,
								expiration dates, or security codes).
							</li>
							<li>
								Payment systems are not to be used for personal
								use/gain; these devices are only to be used for
								transactions at Scholastic Book Fairs events.
							</li>
							<li>
								If a credit card is found, please call the
								toll-free number on the back of the credit card,
								report the card lost, and request further
								directions from the credit card company.
							</li>
							<li>
								All unattended equipment or credit card receipts
								must be in a locked or secure location.
							</li>
							<li>
								Do not connect the payment system to any
								unauthorized networks.
							</li>
							<li>
								Volunteers should use good and reasonable
								judgment in the event of any issues or contact
								the Book Fair host or the payment system Help
								Line for guidance.
							</li>
							<li>
								The Book Fair host is responsible for informing
								other volunteers of this credit card security
								instructional awareness information provided by
								Scholastic. Acceptance of the Services Agreement
								acknowledges notice of and agreement to this
								credit card security instructional awareness
								information.
							</li>
						</BogoTableTopFairSublist>
					</li>
					<li>
						Repack all unsold products, supplies, and displays in
						the original shipping boxes (please do not consolidate
						extra books into the trays) and affix prepaid UPS return
						labels (not applicable to Alaska Fairs) on the outside
						of original shipping boxes. These labels are located in
						the large envelope provided with your Book Fair).{" "}
						<b>
							Be sure not to throw shipper cartons, trays, or
							easels away.
						</b>
					</li>
					<li>
						Please give the boxes to UPS on a normal delivery stop
						or call 1-800-877-1497 to schedule a pickup. (For Alaska
						Fairs, please refer to the return paperwork provided
						with your Fair or call your Book Fair Consultant at
						1-800-635-7323.)
					</li>
					<li>
						Contact your Book Fair Consultant at 1-800-557-7323 if
						your Book Fair did not run as scheduled for any reason
						that would impact the return process.
					</li>
					<li>
						Process the Book Fair Financial Forms and payment within
						two (2) working days after the Fair has ended. For
						Alaska Fairs, please complete your financials with your
						Book Fair Consultant.
					</li>
				</BogoTableTopFairList>
				<p>
					In a Buy One, Get One Free Book Fair, all profit is passed
					on to the purchaser in the form of buying one book and
					receiving one free book of equal or lesser value in lieu of
					cash or Scholastic Dollars earnings. Online purchases earn
					25% in Scholastic Dollars profit but do not qualify for BOGO
					pricing or cash profit. Scholastic Dollars will be
					automatically added to your school account.
				</p>
				<p>
					Changes to this Services Agreement may be made solely at the
					discretion of Scholastic Book Fairs as permissible by law
					and/or as business conditions deem appropriate. We will
					notify you of any such changes by posting the revised
					Services Agreement in the Book Fair Host Hub. Under certain
					circumstances, we may also notify you of changes to this
					Services Agreement by additional means, including, but not
					limited to, posting a notice on the Scholastic Book Fairs
					homepage, sending you an email if we have your email
					address, or by contacting you through your Book Fair
					Consultant. At that time, you will have the opportunity to
					review and accept a new Services Agreement.
				</p>
			</BogoTableTopFairText>
			{!emailedPage && <PageBreak />}
		</BogoTableTopFairContainer>
	);
};

const PageBreak = styled.span`
	height: 300px;
`;

const BogoTableTopFairContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 6.5in;
`;

const OpeningParagraph = styled.p`
	margin-top: 20px;
	font-size: 10px;
`;

const BogoTableTopFairText = styled.div`
	font-size: 9px;
	padding: 10px 0;
	p {
		padding: 10px 0;
	}
`;

const BogoTableTopFairList = styled.ul`
	list-style-type: disc; /* Change the bullet point to a disc */
	margin: 0; /* Remove default margin */
	padding-left: 0; /* Remove default padding */
	li {
		margin-left: 0.25in;
	}
`;

const BogoTableTopFairSublist = styled.ul`
	list-style-type: circle; /* Change the bullet point to a circle */
	margin: 0; /* Remove default margin */
	padding-left: 1.5em; /* Add left padding for indentation */
`;

export default BogoTableTopFair;
