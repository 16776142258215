import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { convertYMDStringToDate, convertYMDToMDY } from "utils/DateConversion";
import { RedButton, WhiteButton } from "components/Button";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd } from "utils/HubUtility";
import { SavedFairBasicInfo } from "components/FairInfoBar";
import { trackChangeFair } from "DumbleData";

interface Props {
	isModal?: boolean;
}

export const FairSelectorContent = ({ isModal }: Props) => {
	const [fairData, setFairData] = useState<any[]>([]);
	const { bookfairsAPI } = useConfig();

	const handleSetFairId = useCallback(
		async (fairId: string) => {
			try {
				// set fair id in session storage
				sessionStorage.setItem("bfSelectedFairId", fairId);
				const response = await axios.get(
					bookfairsAPI + `/user/fairs/${fairId}?mode=SELECT`,
					{
						withCredentials: true,
					}
				);
				if (response.status !== 200) {
					handleGenericSuccess(response);
					return;
				}

				// Hit basic-info endpoint to get/store fair type, name, start date, and end date
				const recievedFairID = response.data.fair.id;
				const basicInfoResponse = await axios.get(
					`${bookfairsAPI}/user/fairs/${recievedFairID}/basic-info`,
					{ withCredentials: true }
				);
				if (basicInfoResponse.status !== 200) {
					handleGenericSuccess(basicInfoResponse);
					return;
				}

				const tempfairBasicInfo: SavedFairBasicInfo = {
					type: basicInfoResponse.data.fair.type,
					name: basicInfoResponse.data.fair.name,
					startDate: basicInfoResponse.data.fair.startDate,
					endDate: basicInfoResponse.data.fair.endDate,
				};
				localStorage.setItem(
					`fairBasicInfo-${recievedFairID}`,
					JSON.stringify(tempfairBasicInfo)
				);

				return basicInfoResponse.headers["sbf-jarvis-resource-id"];
			} catch (e: any) {
				handleGenericErrors(e);
			}
		},
		[bookfairsAPI]
	);

	useEffect(() => {
		const fetchFairs = async () => {
			if (checkSpsUd()) {
				try {
					const response = await axios.get(
						bookfairsAPI + "/private/fairs",
						{
							withCredentials: true,
						}
					);
					if (response.status === 200) {
						setFairData(response.data);
					} else {
						handleGenericSuccess(response);
					}
				} catch (e: any) {
					handleGenericErrors(e);
				}
			}
		};
		fetchFairs();
	}, [bookfairsAPI]);

	async function handleClickConfirmFair(fairId: string) {
		// set fair id in backend
		const responseFairId = await handleSetFairId(fairId);
		window.open(
			`/content/fairs/hub/coa.html?fairId=${responseFairId}`,
			"_top"
		);
	}

	async function handleClickSelectFair(fairId: string) {
		// set fairId in backend
		await handleSetFairId(fairId);
		trackChangeFair();
		// send message to redirect to bfDestinationLogin
		window.parent.postMessage(
			{
				id: "redirectToBfDestinationLogin",
				fairId: fairId,
			},
			window.location.origin
		);
	}

	// Prints a list of fairs
	const renderFairList = (fairList: any) => {
		return (
			<div>
				{fairList.map((fair: any) => {
					return (
						<StyledFairContainer key={fair.fairId}>
							<div>
								<StyledFairHeading>
									{convertYMDToMDY(fair.startDate)} -{" "}
									{convertYMDToMDY(fair.endDate)}
								</StyledFairHeading>
								<StyledFairInfo>
									Fair ID: {fair.fairId}
								</StyledFairInfo>
							</div>
							{fair.coaStatus === "Accepted" ? (
								<StyledRedButton
									handleClick={() =>
										handleClickSelectFair(fair.fairId)
									}
								>
									Select Fair
								</StyledRedButton>
							) : (
								<StyledWhiteButton
									whiteBackground={true}
									handleClick={() =>
										handleClickConfirmFair(fair.fairId)
									}
								>
									Confirm Fair
								</StyledWhiteButton>
							)}
						</StyledFairContainer>
					);
				})}
			</div>
		);
	};

	// Separates fairs into scheduled and past fairs and prints them
	const renderFairs = (school: any) => {
		const todaysDate = new Date();
		todaysDate.setHours(0, 0, 0, 0);
		// Includes upcoming or current fairs
		const scheduledFairs = school.fairInfoList.filter((fair: any) => {
			const fairEndDate = convertYMDStringToDate(fair.endDate);
			return fairEndDate >= todaysDate;
		});
		// Includes fairs that have ended
		const pastFairs = school.fairInfoList.filter((fair: any) => {
			const fairEndDate = convertYMDStringToDate(fair.endDate);
			return fairEndDate < todaysDate;
		});
		return (
			<>
				{/* Only show if there are upcoming/current fairs */}
				{scheduledFairs.length > 0 && (
					<>
						<StyledScheduledFairs>
							Scheduled Fairs
						</StyledScheduledFairs>
						{renderFairList(scheduledFairs)}
					</>
				)}
				{/* Only show if there are ended fairs */}
				{pastFairs.length > 0 && (
					<>
						<StyledScheduledFairs>Past Fairs</StyledScheduledFairs>
						{renderFairList(pastFairs)}
					</>
				)}
			</>
		);
	};

	return (
		<>
			{/* do not render page until multiple fairs are counted */}
			{fairData.length > 0 && (
				<Container isModal={isModal}>
					<StyledHeading>Please Select a Fair</StyledHeading>
					<StyledSchoolList isModal={isModal}>
						{fairData.map((school) => {
							return (
								<StyledSchoolContainer key={school.ucn}>
									<StyledSchoolHeadingContainer>
										<StyledSchoolName>
											{school.name}
										</StyledSchoolName>
										<StyledAccountNumber>
											Account: {school.bookfairAccountId}
										</StyledAccountNumber>
									</StyledSchoolHeadingContainer>
									{renderFairs(school)}
								</StyledSchoolContainer>
							);
						})}
					</StyledSchoolList>
				</Container>
			)}
		</>
	);
};

const StyledRedButton = styled(RedButton)`
	width: 130px;
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 130px;
`;

interface ContainerProps {
	isModal?: boolean;
}
const Container = styled.div<ContainerProps>`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-left: 72px;
	padding-right: 72px;
	padding-top: 24px;
	/* Hide Scrollbar */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
	::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
	@media (max-width: 719px) {
		padding: 24px 24px 0px 24px;
	}
`;

const StyledHeading = styled.h1`
	padding-top: 17px;
	font-size: 28px;
	line-height: 32px;
	font-weight: 500;
	color: #002d70;
`;

interface SchoolListProps {
	isModal?: boolean;
}
const StyledSchoolList = styled.div<SchoolListProps>`
	width: 100%;
	padding-top: 48px;
	padding-bottom: ${(props) => (props.isModal ? "30px" : "0px")};
`;

const StyledSchoolContainer = styled.div`
	&:not(:first-child) {
		margin-top: 48px;
	}
`;

const StyledSchoolHeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 719px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const StyledSchoolName = styled.h2`
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	color: #002d70;
`;

const StyledAccountNumber = styled.p`
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
	color: #666666;
	@media (max-width: 719px) {
		margin-top: 4px;
	}
`;

const StyledScheduledFairs = styled.p`
	font-weight: 500;
	font-size: 21px;
	line-height: 24px;
	padding-top: 44px;
	padding-bottom: 12px;
	border-bottom: 1px solid #b5b5b5;
	color: #b90000;
`;

const StyledFairContainer = styled.div`
	border-top: 1px solid #d7d7d7;
	padding-top: 16px;
	padding-bottom: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (max-width: 719px) {
		flex-direction: column;
		align-items: flex-start;
		row-gap: 12px;
	}
`;

const StyledFairHeading = styled.h3`
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #002d70;
`;

const StyledFairInfo = styled.p`
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
	margin-top: 8px;
	color: #666666;
`;
