import React, { useCallback, useEffect, useState } from "react";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components/macro";
import { CoChair } from "hooks/FairDetails/useFairDetailsData";
import { AddCoChairModal } from "./CoHostModals/AddCoChairModal";
import { RemoveSelfModal } from "./CoHostModals/RemoveSelfModal";
import { disableScrolling, enableScrolling } from "utils/ToggleScrolling";
import { useConfig } from "providers/ConfigContext";
import axios from "axios";
import { RemoveCoChairModal } from "./CoHostModals/RemoveCoChairModal";
import { handleGenericErrors } from "utils/ErrorUtility";
import { getFairIdFromUrl } from "utils/HubUtility";

type Props = {
	coChairData?: Array<CoChair>;

	currentUserEmail: string;
	consultantEmail: string;
};

const CoHostAccessCard = ({
	coChairData,

	currentUserEmail,
	consultantEmail,
}: Props) => {
	const [coChairs, setCoChairs] = useState(coChairData);
	const [count, setCount] = useState(0);
	const [openAddCoChairModal, setOpenAddCoChairModal] = useState(false);
	const [openRemoveModal, setOpenRemoveModal] = useState(false);
	const [openRemoveSelfModal, setOpenRemoveSelfModal] = useState(false);
	const [deleteEnabled, setDeleteEnabled] = useState(true);
	const [updatedCoHostArray, setUpdatedCoHostArray] = useState<
		Array<CoChair>
	>([]);
	const { bookfairsAPI } = useConfig();

	//Makes request to backend to update cohost list
	const handleCoChairUpdate = async (coChairList: Array<CoChair>) => {
		const currentFair = getFairIdFromUrl();
		try {
			// update cochairs and count before making request to avoid lag
			setCoChairs(coChairList);
			setCount(coChairList.length);
			// prevent co-chairs from being deleted while request is being made
			setDeleteEnabled(false);
			await axios
				.put(
					`${bookfairsAPI}/private/fairs/${currentFair}/settings/co-chairs`,
					{ "co-chairs": coChairList },
					{ withCredentials: true }
				)
				.then(() => {
					// Allow co-chairs to be deleted upon successful response
					setDeleteEnabled(true);
				})
				.catch((err) => {
					handleGenericErrors(err);
				});
		} catch (err: any) {
			handleGenericErrors(err);
		}
	};

	//Call when you close a modal to update styles on aem side.
	const handleCloseModalOnAEM = useCallback(() => {
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
		enableScrolling();
	}, []);

	//Call when you open a modal to update styles on aem side.
	const handleOpenModalOnAEM = () => {
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
		disableScrolling();
	};

	const handleRemoveCoChairRequest = () => {
		window._satellite.track("remove-cohost", {
			coHostQuantity: `${updatedCoHostArray.length}`,
			"button-text": "remove-cohost",
		});
		handleCoChairUpdate(updatedCoHostArray);
		setOpenRemoveModal(false);
		setOpenRemoveSelfModal(false);
		handleCloseModalOnAEM();
	};

	const handleAddCoChairRequest = (coChairInfo: CoChair) => {
		if (coChairs) {
			const tempCoChairArray = coChairs;
			tempCoChairArray.push(coChairInfo);
			handleCoChairUpdate(tempCoChairArray);
			window._satellite.track("add-co-host", {
				coHostQuantity: `${tempCoChairArray.length}`,
				"button-text": "add-co-host",
			});
		}
		setOpenAddCoChairModal(false);
		handleCloseModalOnAEM();
	};

	const handleRemoveCoChair = (email: string) => {
		if (coChairs) {
			let removingCurrentUser = false;
			const tempCoChairArray = coChairs.filter((coChair) => {
				if (coChair.email === email) {
					if (coChair.email === currentUserEmail) {
						removingCurrentUser = true;
					}
					return false;
				} else {
					return true;
				}
			});
			setUpdatedCoHostArray(tempCoChairArray);
			if (!removingCurrentUser) {
				setOpenRemoveModal(true);
				handleOpenModalOnAEM();
			} else {
				setOpenRemoveSelfModal(true);
				handleOpenModalOnAEM();
			}
		}
	};

	// update cochairs on page load
	useEffect(() => {
		if (coChairData) {
			setCoChairs(coChairData);
		}
	}, [coChairData]);

	// update count when cochair is added or deleted
	useEffect(() => {
		if (coChairs) {
			setCount(coChairs.length);
		} else {
			setCount(0);
		}
	}, [coChairs, setCoChairs]);

	return (
		<>
			{openAddCoChairModal && (
				<AddCoChairModal
					closeModal={() => {
						setOpenAddCoChairModal(false);
						handleCloseModalOnAEM();
					}}
					submitCoChair={handleAddCoChairRequest}
					coHostEmailList={coChairs && coChairs.map((a) => a.email)}
					currentUserEmail={currentUserEmail}
					consultantEmail={consultantEmail}
				/>
			)}
			{openRemoveSelfModal && (
				<RemoveSelfModal
					closeModal={() => {
						setOpenRemoveSelfModal(false);
						handleCloseModalOnAEM();
					}}
					removeSelfRequest={handleRemoveCoChairRequest}
					disableRemove={!deleteEnabled}
				/>
			)}
			{openRemoveModal && (
				<RemoveCoChairModal
					closeModal={() => {
						setOpenRemoveModal(false);
						handleCloseModalOnAEM();
					}}
					handleRemoveCoChair={handleRemoveCoChairRequest}
					disableRemove={!deleteEnabled}
				/>
			)}
			<FairDetailsCard
				title={"Co-host Access"}
				subtitle={`(${count} of 5 Co-hosts)`}
				linkText={
					count >= 5
						? "Please remove a Co-host to add another"
						: "+ ADD CO-HOST"
				}
				handleLinkClicked={
					count >= 5
						? undefined
						: () => {
								setOpenAddCoChairModal(true);
								handleOpenModalOnAEM();
						  }
				}
			>
				<OuterContainer>
					<HeaderRow>
						<HeaderName>Name</HeaderName>
						<HeaderEmail>Email</HeaderEmail>
						<HeaderEmpty />
					</HeaderRow>
					{coChairs &&
						coChairs.map((coChair: any, index: number) => (
							<Row key={index}>
								<Name>
									{coChair.firstName} {coChair.lastName}
								</Name>
								<Email>{coChair.email}</Email>
								<RemoveButton
									tabIndex={1}
									onClick={() =>
										handleRemoveCoChair(coChair.email)
									}
								>
									Remove
								</RemoveButton>
							</Row>
						))}
				</OuterContainer>
			</FairDetailsCard>
		</>
	);
};

const OuterContainer = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 500px) {
		row-gap: 18px;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: 8px;
	border-bottom: 1px solid #d7d7d7;
	align-items: center;
	min-height: 35px;
	&:last-of-type {
		border-bottom: none;
	}
	@media (max-width: 500px) {
		flex-direction: column;
		align-items: flex-start;
		row-gap: 16px;
		padding-bottom: 18px;
	}
`;

const HeaderRow = styled(Row)`
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	width: 100%;
	background: #f4f4f4;
	border-bottom: 1px solid #d7d7d7;
	min-height: 35px;
	@media (max-width: 500px) {
		display: none;
	}
`;

const HeaderValue = styled.div`
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
`;

const HeaderName = styled(HeaderValue)`
	width: 30%;
`;

const HeaderEmail = styled(HeaderValue)`
	width: 50%;
`;

const HeaderEmpty = styled.div`
	width: 15%;
	@media (max-width: 1100px) {
		width: 20%;
	}
`;

const Value = styled.div`
	margin: 10px 10px 10px 0px;
	width: 30%;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	@media (max-width: 500px) {
		flex-direction: column;
		width: 100%;
		margin: 0px;
	}
`;

const Name = styled(Value)`
	width: 30%;
	@media (max-width: 500px) {
		width: 100%;
	}
`;

const Email = styled(Value)`
	width: 50%;
	@media (max-width: 500px) {
		width: 100%;
	}
`;

const RemoveButton = styled.div`
	width: 15%;
	text-align: right;
	padding-right: 14px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #e81111;
	&:focus-visible {
		outline: 2px solid #e81111;
		color: #e81111;
		outline-offset: 1px;
		margin-top: 1px;
	}
	&:hover {
		font-style: unset;
		text-decoration: underline;
		cursor: pointer;
	}
	&:focus-visible {
		outline: 2px solid #e81111;
		color: #e81111;
		outline-offset: 1px;
		margin-top: 1px;
	}
	@media (max-width: 1100px) {
		width: 20%;
	}
	@media (max-width: 500px) {
		text-align: left;
		width: 100%;
		margin: none;
	}
`;
export default CoHostAccessCard;
