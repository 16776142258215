import React from "react";
import styled from "styled-components/macro";
import Heading from "components/Heading";
import Subheading from "components/Subheading";
import Print from "images/Print.svg";
import Union from "images/Union.svg";
import { IndividualRegisterInfo } from "hooks/FinancialForm/useSummaryPageData";

interface Props {
	registerInfo: IndividualRegisterInfo;
	isFirst: boolean;
}

export const FinancialFormRegisterCard = ({ registerInfo, isFirst }: Props) => {
	const handlePrint: any = () => {
		window.print();
	};

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	return (
		<RegisterContainer>
			{isFirst && (
				<>
					<StyledPrintButton onClick={() => handlePrint()}>
						<img src={Print} alt="Print Button" />
					</StyledPrintButton>
					<RegisterName>Sales Summary</RegisterName>
					<RegisterDescription>
						Below is a summarization of all sales totals from all
						payment devices. Each payment device totals are listed
						individually below.
					</RegisterDescription>
				</>
			)}
			{!isFirst && (
				<RegisterName>
					Register {registerInfo.registerNumber}
				</RegisterName>
			)}
			<SubContainer>
				<StyledSubHeading>Scholastic Dollars</StyledSubHeading>
				<ContentSeperator />
				<ContentRow>
					<ContentText>Total Scholastic Dollars Redeemed</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.scholasticDollars.totalRedeemed
						)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>
						Tax-Exempt Scholastic Dollar Sales
					</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.scholasticDollars.taxExempt
						)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>Taxable Scholastic Dollar Sales</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.scholasticDollars.taxable
						)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>
						Tax Collected in Scholastic Dollars
					</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.scholasticDollars.taxCollected
						)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<BoldContentText>
						Cash Equivalent (Redeemed x 50%)
					</BoldContentText>
					<BoldContentText>
						{formatter.format(
							registerInfo.scholasticDollars.cashEquivalent
						)}
					</BoldContentText>
				</ContentRow>
			</SubContainer>

			<SubContainer>
				<StyledSubHeading>Tender Totals</StyledSubHeading>
				<ContentSeperator />
				<SizedContentRow>
					<SizedContentText>Tender</SizedContentText>
					<SubContentRow>
						<ContentText>Qty</ContentText>
						<ContentText>Amt</ContentText>
					</SubContentRow>
				</SizedContentRow>
				<ContentSeperator />
				<SizedContentRow>
					<SizedContentText>Cash & Checks</SizedContentText>
					<SubContentRow>
						<ContentText>
							{registerInfo.tenderTotals.cash.quantity}
						</ContentText>
						<ContentText>
							{formatter.format(
								registerInfo.tenderTotals.cash.amount
							)}
						</ContentText>
					</SubContentRow>
				</SizedContentRow>
				<ContentSeperator />
				<SizedContentRow>
					<SizedContentText>
						Credit Cards
						<br />
						(Including eWallet & eGift Card/Campaign)
					</SizedContentText>
					<SubContentRow>
						<ContentText>
							{registerInfo.tenderTotals.creditCard.quantity}
						</ContentText>
						<ContentText>
							{formatter.format(
								registerInfo.tenderTotals.creditCard.amount
							)}
						</ContentText>
					</SubContentRow>
				</SizedContentRow>
				<ContentSeperator />
				<SizedContentRow>
					<SizedContentText>Purchase Orders</SizedContentText>
					<SubContentRow>
						<ContentText>
							{registerInfo.tenderTotals.purchaseOrders.quantity}
						</ContentText>
						<ContentText>
							{formatter.format(
								registerInfo.tenderTotals.purchaseOrders.amount
							)}
						</ContentText>
					</SubContentRow>
				</SizedContentRow>
				<ContentSeperator />
				<SizedContentRow>
					<SizedContentText>
						Scholastic Dollars (Cash equivalent)
					</SizedContentText>
					<SubContentRow>
						<ContentText>
							{
								registerInfo.tenderTotals
									.scholasticDollarsCashEquivalent.quantity
							}
						</ContentText>
						<ContentText>
							{formatter.format(
								registerInfo.tenderTotals
									.scholasticDollarsCashEquivalent.amount
							)}
						</ContentText>
					</SubContentRow>
				</SizedContentRow>
				<ContentSeperator />
				<SizedContentRow>
					<SizedContentText>
						<b>Total</b> (Includes Unredeemed Total)
					</SizedContentText>
					<SubContentRow>
						<ContentText>
							{registerInfo.tenderTotals.total.quantity}
						</ContentText>
						<ContentText>
							{formatter.format(
								registerInfo.tenderTotals.total.amount
							)}
						</ContentText>
					</SubContentRow>
				</SizedContentRow>
			</SubContainer>

			<SubContainer>
				<StyledSubHeading>Gross Sales</StyledSubHeading>
				<ContentSeperator />
				<ContentRow>
					<ContentText>Tax-Exempt Sales</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.grossSales.taxExemptSales
						)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>Taxable Sales</ContentText>
					<ContentText>
						{formatter.format(registerInfo.grossSales.taxableSales)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>
						<b>Gross Sales Total</b> (Excludes Unredeemed Total)
					</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.grossSales.grossSalesTotal
						)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>
						Tax Total ({registerInfo.taxRate}%)
					</ContentText>
					<ContentText>
						{formatter.format(registerInfo.grossSales.taxTotal)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>Taxable Sales (Less Sales Tax)</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.grossSales.taxableSalesLessTax
						)}
					</ContentText>
				</ContentRow>
			</SubContainer>

			<SubContainer>
				<StyledSubHeading>Net Sales</StyledSubHeading>
				<ContentSeperator />
				<ContentRow>
					<ContentText>Discounts</ContentText>
					<ContentText>
						{formatter.format(registerInfo.netSales.discounts)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>Gift Certificates Purchased</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.netSales.giftCertificates.purchased
						)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>
						Gift Certificates Redeemed (Included in Gross)
					</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.netSales.giftCertificates.redeemed
						)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>Gift Certificates Unredeemed</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.netSales.giftCertificates.unredeemed
						)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>
						Share the Fair Funds Collected{" "}
						<FairFundsCollectedTooltip>
							Funds collected online and through your Payment
							Systems to benefit Share the Fair.
						</FairFundsCollectedTooltip>
						<StyledUnionButton src={Union} alt="Info Button" />
						<br />
						(Included in Tender Totals)
					</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.netSales.shareTheFairFunds.collected
						)}
					</ContentText>
				</ContentRow>
				<ContentSeperator />
				<ContentRow>
					<ContentText>
						Share the Fair Funds Redeemed{" "}
						<FairFundsRedeemedTooltip>
							Share the Fair funds redeemed to complete
							transactions during the Fair.{" "}
						</FairFundsRedeemedTooltip>
						<StyledUnionButton src={Union} alt="Info Button" />
						<br />
						(Included in Gross Sales)
					</ContentText>
					<ContentText>
						{formatter.format(
							registerInfo.netSales.shareTheFairFunds.redeemed
						)}
					</ContentText>
				</ContentRow>
			</SubContainer>
		</RegisterContainer>
	);
};

const StyledTooltip = styled.div`
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s;
	font-family: museo-sans;
	position: absolute;
	background: #333333;
	color: #ffffff;
	border-radius: 4px;
	padding: 10px;
	text-align: center;
	font-size: 13px;
	line-height: 15px;
	font-family: "museo-sans";
	weight: 400;
	box-shadow: 0 0 6px #d7d7d7;
	top: 25px;
	z-index: 1000;
	width: 210px;
	left: 26%;
	text-align: left;
	@media (min-width: 700px) {
		::after {
			// CSS for triangle on top of tooltip
			content: " ";
			position: absolute;
			top: -15%; /* Place at the top of the tooltip */
			rotate: 180deg;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: #333333 transparent transparent transparent;
		}
	}
	@media (max-width: 700px) {
		left: 0%;
	}
`;

export const FairFundsCollectedTooltip = styled(StyledTooltip)`
	left: 18.5%;
	@media (max-width: 700px) {
		left: 0%;
	}
`;

export const FairFundsRedeemedTooltip = styled(StyledTooltip)`
	left: 19.75%;
	@media (max-width: 700px) {
		left: 0%;
	}
`;

const RegisterContainer = styled.div`
	position: relative;
	display: flex;
	padding: 48px 80px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	flex-shrink: 0;
	@media (max-width: 520px) {
		padding: 48px 20px;
	}
`;

const RegisterName = styled(Heading)`
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	padding-bottom: 0px;
`;

const RegisterDescription = styled.div`
	font-size: 13px;
	font-weight: 400;
	line-height: 15px;
	text-align: left;
	color: #333333;
	margin-right: 10px;
`;

const StyledSubHeading = styled(Subheading)`
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px;
`;

const SubContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	width: 100%;
`;

const ContentRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	flex-wrap: wrap;
	width: 100%;
	position: relative;
	&:hover {
		${StyledTooltip} {
			visibility: visible;
			opacity: 1;
		}
	}
	@media (max-width: 620px) {
		flex-direction: column;
		text-align: left;
		align-items: flex-start;
		align-content: flex-start;
		gap: 4px;
	}
`;

const ContentText = styled.p`
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	@media (max-width: 620px) {
		text-align: left;
	}
`;

const SizedContentText = styled(ContentText)`
	width: 260px;
	margin-right: 40px;
`;

const SizedContentRow = styled.div`
	display: flex;
	width: 100%;
	@media (max-width: 620px) {
		flex-direction: column;
		text-align: left;
		align-items: flex-start;
		align-content: flex-start;
		gap: 4px;
	}
`;

const SubContentRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	float: right;
	flex: 1;
	@media (max-width: 620px) {
		flex-direction: column;
		text-align: left;
		align-items: flex-start;
		align-content: flex-start;
		gap: 4px;
	}
`;

const ContentSeperator = styled.div`
	width: 100%;
	height: 1px;
	background: #d7d7d7;
`;

const BoldContentText = styled(ContentText)`
	font-weight: 600;
`;
const StyledPrintButton = styled.button`
	position: absolute;
	top: 7px;
	right: 79px;
	background: none;
	border: none;
	cursor: pointer;
	:focus-visible {
		outline-color: #e81111;
		outline-offset: 4px;
	}
`;

const StyledUnionButton = styled.img`
	position: relative;
	top: 4px;
	left: 4px;
`;
