import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { RedButton } from "../../Button";
import Heading from "components/Heading";
import Subheading from "components/Subheading";
import BodyText from "components/BodyText";
import FFReady from "images/FFReady.svg";
import FFNotReady from "images/FFNotReady.svg";
import FFRunningLate from "images/FFRunningLate.svg";
import FFGiveUsACall from "images/FFGiveUsACall.svg";
import {
	FinFormProgress,
	FinFormUrls,
	checkFinFormCurrentPageLocalStorage,
	getFinFormProgress,
	setFinFormUrlsLocalStorage,
} from "utils/FinFormLocalStorageUtility";
import { useNavigate } from "react-router-dom";

interface Props {
	availabilityState: string;
	fairId: string;
}

export const FinancialFormIntroContent = ({
	availabilityState,
	fairId,
}: Props) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (availabilityState === "ready") {
			const progress = getFinFormProgress(fairId);
			if (progress > FinFormProgress.Intro) {
				const savedFinFormLocation =
					checkFinFormCurrentPageLocalStorage(fairId);
				if (savedFinFormLocation) {
					window.location.href = savedFinFormLocation;
				}
			}
		} else if (availabilityState === "confirmed") {
			setFinFormUrlsLocalStorage(fairId, FinFormUrls.Confirmation);
			navigate(FinFormUrls.Confirmation);
		}
	}, [navigate, fairId, availabilityState]);

	const clickGetStarted = () => {
		setFinFormUrlsLocalStorage(fairId, FinFormUrls.Sales);
		navigate(FinFormUrls.Sales);
	};

	return (
		<FFIntro>
			<StyledHeading>Financial Form</StyledHeading>
			{availabilityState === "ready" && (
				<Row>
					<Image src={FFReady} />
					<Column>
						<StyledSubHeading>Let's go!</StyledSubHeading>
						<StyledBodyText ready={true}>
							Your Book Fair Financial Form is ready to review and
							submit.
						</StyledBodyText>
						<StyledRedButton handleClick={clickGetStarted}>
							Get Started
						</StyledRedButton>
					</Column>
				</Row>
			)}
			{availabilityState === "notReady" && (
				<Row>
					<Image src={FFNotReady} />
					<Column>
						<StyledSubHeading>Almost ready!</StyledSubHeading>
						<StyledBodyText>
							We're still preparing your Book Fair Financial Form.
							You'll receive an email when your form is ready to
							review, which could take up to two weeks from the
							end of your Fair. Please come back soon to review
							and submit your information.
						</StyledBodyText>
					</Column>
				</Row>
			)}
			{availabilityState === "runningLate" && (
				<Row>
					<Image src={FFRunningLate} />
					<Column>
						<StyledSubHeading>
							Sorry we're running late!
						</StyledSubHeading>
						<StyledBodyText>
							We’re having trouble preparing your Financial Form.
							Please check back soon to see if it’s ready to
							review and submit. If you have questions in the
							meantime, please call our Customer Success team at
							877-245-0903.
						</StyledBodyText>
					</Column>
				</Row>
			)}
			{availabilityState === "notApplicable" && (
				<Row>
					<Image src={FFReady} />
					<Column>
						<StyledSubHeading>We've got this.</StyledSubHeading>
						<StyledBodyText>
							No need to review your financial form! Instead,
							please visit the Host Hub to see your sales totals
							and your Fair earnings.
						</StyledBodyText>
					</Column>
				</Row>
			)}
			{availabilityState === "call" && (
				<Row>
					<Image src={FFGiveUsACall} />
					<Column>
						<StyledSubHeading>Give us a call!</StyledSubHeading>
						<StyledBodyText>
							To review the information on your financial form,
							please call our Customer Success team at
							877-245-0903.
						</StyledBodyText>
					</Column>
				</Row>
			)}
		</FFIntro>
	);
};

const FFIntro = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 700px) {
		align-items: center;
	}
`;
const StyledHeading = styled(Heading)`
	display: flex;
	position: relative;
	margin-left: calc((100% - 1240px) / 2);
	margin-top: 56px;
	font-size: 39px;
	font-weight: 500;
	line-height: 42px;

	@media (max-width: 1599px) {
		margin-left: 180px;
	}
	@media (max-width: 1279px) {
		margin-left: 140px;
	}
	@media (max-width: 959px) {
		margin-left: 40px;
	}
	@media (max-width: 700px) {
		margin-left: 0;
		font-size: 32px;
		line-height: 35px;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	position: relative;
	gap: 198px;
	margin: 64px 40px 134px 180px;
	justify-content: center;
	@media (max-width: 1100px) {
		gap: 87px;
		margin-left: 40px;
		margin-bottom: 140px;
	}
	@media (max-width: 700px) {
		flex-direction: column;
		align-items: center;
		margin-top: 36px;
		gap: 68px;
		margin-bottom: 79px;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: 700px) {
		align-items: center;
	}
`;

const Image = styled.img`
	height: 280px;
	width: 280px;
	@media (max-width: 1000px) {
		height: 250px;
		width: 250px;
	}
	@media (max-width: 700px) {
		height: 183px;
		width: 183px;
	}
`;

const StyledSubHeading = styled(Subheading)`
	font-size: 39px;
	font-weight: 300;
	line-height: 42px;
	max-width: 512px;
	@media (max-width: 700px) {
		width: 279px;
		text-align: center;
		font-size: 32px;
		line-height: 35px;
	}
`;

interface FFProps {
	ready?: boolean;
}
const StyledBodyText = styled(BodyText)<FFProps>`
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	text-align: left;
	width: ${(props) => (props.ready ? `328px` : "359px")};
	margin-top: ${(props) => (props.ready ? `35px` : "14px")};
	@media (max-width: 1000px) {
		width: 305px;
	}
	@media (max-width: 700px) {
		width: 279px;
		margin-top: 15px;
		text-align: center;
	}
`;

const StyledRedButton = styled(RedButton)`
	margin-top: 35px;
	width: 150px;
`;
