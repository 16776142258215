import React from "react";
import styled from "styled-components";

interface Props {
	children: React.ReactNode;
	className?: any;
	disabled?: boolean;
	handleClick?: any;
	href?: string;
	target?: string;
	rel?: string;
	tabIndex?: number;
}

const TextLink = ({
	children,
	className,
	disabled,
	handleClick,
	href,
	target,
	rel,
	tabIndex,
}: Props) => {
	// If href attribute is present, render as an <a> tag and not a <button>
	const tag: any = href ? "a" : undefined;
	return (
		<StyledTextLink
			// Special styled-components prop, changes the HTML element that is rendered. Documentation here: https://styled-components.com/docs/api#as-polymorphic-prop
			as={tag}
			onClick={handleClick}
			disabled={disabled}
			className={className}
			href={href}
			target={target}
			rel={rel}
			tabIndex={tabIndex}
		>
			{children}
		</StyledTextLink>
	);
};

const StyledTextLink = styled.button`
	font-family: museo-sans;
	text-decoration: none;
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 12px;
	text-align: center;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #e81111;
	background: none;
	border: none;
	&:hover {
		cursor: pointer;
		color: #b90000;
		text-decoration: underline;
		text-underline-offset: 4px;
	}
	&:focus,
	:focus-visible {
		color: #b90000;
		outline: 2px solid #b90000;
		outline-offset: 2px;
		border-radius: 2px;
	}
	&:active {
		color: #660000;
		outline: none;
	}
	&:disabled {
		color: #333333;
		mix-blend-mode: normal;
		opacity: 0.5;
	}
`;

export default TextLink;
