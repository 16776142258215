import React, { useState } from "react";
import styled from "styled-components/macro";
import BlackDropdownArrow from "images/BlackDropdownArrow.svg";
import BodyText from "components/BodyText";
import FilterItem from "./FilterItem";

interface Props {
	className?: any;
	label?: string;
	options: any;
	disabled?: boolean;
	error?: string;
	isOpen?: boolean;
	optionsChosen: Array<string>;
	defaultText?: string;
	removeFilter: (value: string) => void;
	addFilter: (value: string) => void;
}

const DropDownFilter = ({
	className,
	label,
	options,
	disabled,
	error,
	optionsChosen,
	defaultText,
	addFilter,
	removeFilter,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggling = () => {
		if (!disabled) {
			setIsOpen(!isOpen);
		}
	};

	return (
		<div>
			<StyledLabel className={disabled ? "disabled" : ""}>
				{label}
			</StyledLabel>
			<StyledContainer className={className}>
				<DropDownHeader
					tabIndex={1}
					onClick={toggling}
					onKeyPress={toggling}
					className={`${className}
					dropDownElements ${disabled ? "disabled" : ""}`}
					error={error}
					isOpen={isOpen}
				>
					<StyledSpan>{defaultText}</StyledSpan>
					<StyledImg
						isOpen={isOpen}
						src={BlackDropdownArrow}
						className={className}
					/>
				</DropDownHeader>
				{isOpen && (
					<DropDownListContainer
						className={"dropDownElements"}
						id={"dropDownElementsContainer"}
					>
						<DropDownList>
							<FilterItemContainer>
								{options.map(
									(option: string, index: number) => (
										<FilterItem
											onClick={(e: any) => {
												optionsChosen.includes(option)
													? removeFilter(option)
													: addFilter(option);
											}}
											key={index}
											isSelectedOption={optionsChosen.includes(
												option
											)}
											index={index}
											label={option}
										/>
									)
								)}
							</FilterItemContainer>
						</DropDownList>
					</DropDownListContainer>
				)}
				{error && <ErrorMessage>{error}</ErrorMessage>}
			</StyledContainer>
		</div>
	);
};

const ErrorMessage = styled(BodyText)`
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 115%;
	color: #e81111;
	text-align: left;
	margin-top: 4px;
`;

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 17px;
	position: relative;
	&.virtual {
		pointer-events: none;
	}
`;

const StyledLabel = styled.label`
	display: block;
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
	color: #333333;
	padding-bottom: 8px;
	&.disabled {
		opacity: 0.2;
	}
`;

interface HeaderProps {
	error?: string;
	isOpen?: boolean;
}

const DropDownHeader = styled.div<HeaderProps>`
	width: 280px;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	height: 50px;
	background: #ffffff;
	box-sizing: border-box;
	padding: 14px 16px 10px;
	position: relative;
	background: #ffffff;
	border: 1px solid #919191;
	border-radius: 4px;
	border-radius: ${(props) => (props.isOpen ? "5px 5px 0 0 " : "5px")};
	border: ${(props) =>
		props.error ? "1px solid #e81111" : "1px solid #919191"};
	&:focus,
	&:active {
		outline: 2px auto #116eee;
	}
	&:disabled {
		opacity: 0.2;
		border: 1px solid #333333;
	}

	&.disabled {
		opacity: 0.2;
	}

	&.virtual {
		border: none;
		padding: 0;
		pointer-events: none;
	}
`;

const DropDownListContainer = styled("div")`
	position: relative;
	z-index: 100;
	width: 280px;
`;

const FilterItemContainer = styled.div`
	margin: 24px;
`;

const DropDownList = styled("ul")`
	background: #ffffff;
	border: 1px solid #919191;
	box-sizing: border-box;
	border-radius: 0 0 5px 5px;
	width: 100%;
	border-top: none;
	position: absolute;
	overflow-y: scroll;
	padding: 7px 19px 7px 5.6px;
	font-size: 16px;
	line-height: 19px;
	background: #ffffff;
	border: 1px solid #d7d7d7;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	-webkit-scrollbar {
		width: 1em;
		padding-left: 7px;
		color: #d7d7d7;
	}
	-webkit-scrollbar-thumb {
		outline: 6px solid #d7d7d7;
		height: 100px;
		color: #d7d7d7;
	}
	-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px #d7d7d7;
	}
`;

interface ImgProps {
	isOpen?: boolean;
}

const StyledImg = styled.img<ImgProps>`
	position: absolute;
	right: 14.29px;
	top: ${(props) => (props.isOpen ? "19px" : "20px")};
	transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "none")};
	&.virtual {
		display: none;
	}
`;

const StyledSpan = styled.span`
	&.notSelected {
		color: #666666;
	}
`;

export default DropDownFilter;
