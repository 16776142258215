import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { InvoiceLineItem, InvoiceSectionItem } from "./InvoiceItems";
import { InvoicePrintablePage } from "./InvoicePrintablePage";
import Heading from "components/Heading";

interface Props {
	printClicked: boolean;
	handlePrint: Function;
	isLoading: boolean;
	invoiceBasicInfo: any;
	invoiceSalesInfo: any;
	invoiceEarningsInfo: any;
	invoiceSpendingInfo: any;
	fairId: string;
}

const InvoicePrintablePages = ({
	printClicked,
	handlePrint,
	isLoading,
	invoiceBasicInfo,
	invoiceSalesInfo,
	invoiceEarningsInfo,
	invoiceSpendingInfo,
	fairId,
}: Props) => {
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	useEffect(() => {
		// open print dialogue after page content is populated
		if (printClicked && handlePrint) {
			// If data is not populated, do not open print dialogue
			if (!isLoading) {
				handlePrint();
			}
		}
	}, [printClicked, handlePrint, isLoading]);

	return (
		<StyledRegisterInvoice>
			<InvoicePrintablePage
				FirstPage={true}
				number="1"
				invoiceData={invoiceBasicInfo}
				fairId={fairId}
			>
				<StyledHeading>Fair Summary</StyledHeading>
				<InvoiceSectionItem SectionTitle="Tender Totals">
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSalesInfo.tenderTotals.cashAndChecks
						)}`}
						ItemTitleText="Cash & Checks"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSalesInfo.tenderTotals.creditCards
						)}`}
						ItemTitleText="Credit Cards"
						ItemSubTitleText="(eWallet & eGift Card/Campaign, etc.)"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSalesInfo.tenderTotals.purchaseOrders
						)}`}
						ItemTitleText="Purchase Orders"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSalesInfo.scholasticDollars.cashEquivalent
						)}`}
						ItemTitleText="Scholastic Dollars Cash Equivalent"
						ItemSubTitleText=" (Redeemed x 50%)"
						OneLine={true}
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceBasicInfo.amountDetails.totalCollected
						)}`}
						ItemTitleText="Total Collected"
						LastItem={true}
					/>
				</InvoiceSectionItem>
				<InvoiceSectionItem SectionTitle="Gross Sales">
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSalesInfo.grossSales.taxExemptSales
						)}`}
						ItemTitleText="Tax Exempt Sales"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSalesInfo.grossSales.taxableSales
						)}`}
						ItemTitleText="Taxable Sales"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSalesInfo.grossSales.taxTotal
						)}`}
						ItemTitleText="Tax Total"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSalesInfo.grossSales.total
						)}`}
						ItemTitleText="Gross Sales"
						LastItem={true}
					/>
				</InvoiceSectionItem>
				<InvoiceSectionItem SectionTitle="Share the Fair (At the Fair Only)">
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSalesInfo.netSales.shareTheFairFunds
								.collected
						)}`}
						ItemTitleText="Share the Fair Collected"
						LastItem={true}
					/>
				</InvoiceSectionItem>
			</InvoicePrintablePage>
			<InvoicePrintablePage LastPage={true} number="2">
				<InvoiceLineItem
					ItemAmountText={`${formatter.format(
						invoiceSalesInfo.netSales.shareTheFairFunds.redeemed
					)}`}
					ItemTitleText="Share the Fair Redeemed"
					LastItem={true}
					ContinuedItem={true}
				/>
				<InvoiceSectionItem SectionTitle="Fair Spending & Earnings">
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSpendingInfo.scholasticDollars.totalRedeemed
						)}`}
						ItemTitleText="Scholastic Dollars Redeemed at Fair"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceEarningsInfo.scholasticDollars.selected
						)}`}
						ItemTitleText="Scholastic Dollars Profit Selected"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceEarningsInfo.cash.selected
						)}`}
						ItemTitleText="Cash Profit Selected"
						LastItem={true}
					/>
				</InvoiceSectionItem>
				<StyledHeading>Amount Due</StyledHeading>
				<InvoiceSectionItem>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceBasicInfo.amountDetails.totalCollected
						)}`}
						ItemTitleText="Total Collected"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceBasicInfo.amountDetails
								.digitalPaymentsCollected
						)}`}
						ItemTitleText="Digital Payments Collected"
						ItemSubTitleText="(Credit Cards, eWallet & eGift Card/Campaign, etc.)"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSalesInfo.tenderTotals.purchaseOrders
						)}`}
						ItemTitleText="Purchase Orders"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceSalesInfo.scholasticDollars.cashEquivalent
						)}`}
						ItemTitleText="Scholastic Dollars Cash Equivalent"
						ItemSubTitleText=" (Redeemed x 50%)"
						OneLine={true}
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceEarningsInfo.cash.selected
						)}`}
						ItemTitleText="Cash Profit"
					/>
					<InvoiceLineItem
						ItemAmountText={`${formatter.format(
							invoiceBasicInfo.amountDetails.amountDue
						)}`}
						ItemTitleText="Amount Due"
						LastItem={true}
					/>
				</InvoiceSectionItem>
			</InvoicePrintablePage>
		</StyledRegisterInvoice>
	);
};

const StyledRegisterInvoice = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledHeading = styled(Heading)`
	font-size: 21px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: left;
`;

export default InvoicePrintablePages;
