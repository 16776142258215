import React from "react";
import styled from "styled-components/macro";
import Heading from "components/Heading";
import RadioButton from "components/RadioButton";
import TextInput from "components/TextInput";
import BodyText from "components/BodyText";
import { earningsInfo } from "hooks/FinancialForm/useEarningsData";

interface Props {
	earningsInfo: earningsInfo;
	fairId: string;
	setCash: any;
	setScholasticDollars: any;
	cash: string;
	scholasticDollars: string;
	formatNumber: any;
	useCash: boolean;
	toggleSetCash: any;
	maxCashValue: number;
	maxScholasticDollarValue: number;
}

export const EarningsCalculator = ({
	earningsInfo,
	setCash,
	setScholasticDollars,
	cash,
	scholasticDollars,
	formatNumber,
	useCash,
	toggleSetCash,
	maxCashValue,
	maxScholasticDollarValue,
}: Props) => {
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	const validateNumString = (value: string, maxValue: number) => {
		const re = /^\d*(\.\d{0,2})?$/;
		const valueWithoutCommas = value.replace(/,/g, "");
		return (
			valueWithoutCommas === "" ||
			(re.test(valueWithoutCommas) &&
				parseFloat(valueWithoutCommas) <= maxValue)
		);
	};

	const calculateValues = (e: any, cashValue: boolean) => {
		const value = e.replace(/,/g, "");
		if (
			validateNumString(
				value,
				cashValue ? maxCashValue : maxScholasticDollarValue
			)
		) {
			if (cashValue) {
				setCash(value);
				setScholasticDollars(
					formatNumber(
						(maxScholasticDollarValue - value * 2).toString()
					)
				);
			} else {
				setCash(
					formatNumber(
						((maxScholasticDollarValue - value) / 2).toString()
					)
				);
				setScholasticDollars(value);
			}
		}
	};

	return (
		<Calculator>
			{earningsInfo.dollarFairLevel >= 50 && (
				<StyledHeading>
					Choose how you would like to receive your earnings
				</StyledHeading>
			)}
			{earningsInfo.dollarFairLevel < 50 && (
				<StyledHeading>Here are your Fair earnings:</StyledHeading>
			)}
			<RadioButton
				checked={!useCash}
				onChange={() => {
					toggleSetCash();
				}}
				label={`${formatter.format(
					earningsInfo.scholasticDollars.balance
				)} in Scholastic Dollars`}
				radioButtonName={"scholasticDollars"}
			/>
			{earningsInfo.dollarFairLevel >= 50 && (
				<>
					<RadioButton
						checked={useCash}
						onChange={() => {
							toggleSetCash();
						}}
						label={
							"Split Between Scholastic Dollars and Cash or Take All Cash"
						}
						radioButtonName={"cashAndScholasticDollars"}
					/>
					{useCash && (
						<InputRow>
							<InputSection leftSide={true}>
								<InnerInputSection>
									<TopLabel>Scholastic Dollars</TopLabel>
									<StyledTextInput
										label={"(Fair Sales x 50%)"}
										value={scholasticDollars}
										onChange={(e) => {
											calculateValues(e, false);
										}}
										onBlur={(e: any) => {
											setScholasticDollars(
												formatNumber(e.target.value)
											);
										}}
										marginBottom={"8px"}
									/>
									<MaxiumLabel>
										Maximum:
										{formatter.format(
											maxScholasticDollarValue
										)}
									</MaxiumLabel>
								</InnerInputSection>
							</InputSection>

							<InputSection>
								<InnerInputSection>
									<TopLabel>Cash</TopLabel>
									<StyledTextInput
										label={"(Fair Sales x 25%)"}
										value={cash}
										onChange={(e) => {
											calculateValues(e, true);
										}}
										onBlur={(e: any) => {
											setCash(
												formatNumber(e.target.value)
											);
										}}
										marginBottom={"8px"}
									/>
									<MaxiumLabel>
										Maximum:
										{formatter.format(maxCashValue)}
									</MaxiumLabel>
								</InnerInputSection>
							</InputSection>
						</InputRow>
					)}
				</>
			)}
		</Calculator>
	);
};
const Calculator = styled.div`
	display: inline-flex;
	padding: 48px 80px;
	flex-direction: column;
	align-items: flex-start;
	gap: 35px;
	border-radius: 16px;
	background: #f5f5f5;
	@media (max-width: 450px) {
		padding: 48px 25px 80px 25px;
	}
`;
const StyledHeading = styled(Heading)`
	font-size: 28px;
	font-style: normal;
	font-weight: 300;
	line-height: 32px; /* 114.286% */
	text-align: left;
	padding-bottom: 0px;
	@media (max-width: 450px) {
		font-size: 24px;
		line-height: 28px;
	}
	@media (max-width: 375px) {
		max-width: 231px;
	}
`;
const InputRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	@media (max-width: 450px) {
		flex-direction: column;
		gap: 20px;
	}
`;
interface InputSectionProps {
	leftSide?: boolean;
}
const InputSection = styled.div<InputSectionProps>`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-right: ${(props) =>
		props.leftSide ? "1px solid #D7D7D7;" : "none"};
	justify-content: ${(props) => (props.leftSide ? "flex-start" : "flex-end")};
	@media (max-width: 450px) {
		border-right: none;
		border-bottom: ${(props) =>
			props.leftSide ? "1px solid #D7D7D7;" : "none"};
		padding-bottom: ${(props) => (props.leftSide ? "35px;" : "none")};
		justify-content: flex-start;
	}
`;
const InnerInputSection = styled.div`
	display: flex;
	flex-direction: column;
`;
const TopLabel = styled(Heading)`
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px; /* 125% */
	text-align: left;
	padding-bottom: 0px;
`;
const MaxiumLabel = styled(BodyText)`
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px; /* 115.385% */
	text-align: left;
`;
const StyledTextInput = styled(TextInput)`
	width: 120px;
`;
