import React, { useState } from "react";
import styled from "styled-components";
import ArrowClosed from "../../images/ArrowClosed.svg";
import DataTableTransactions from "./DataTableTransactions";
import { Transaction } from "hooks/EWalletRoster/useEWalletRosterData";
import { useConfig } from "providers/ConfigContext";
import { getFairIdFromUrl } from "utils/HubUtility";

interface Props {
	grade: string;
	classroom: string;
	recipient: string;
	type: string;
	balance: number;
	transactions: Array<Transaction>;
	id: number;
	apiMapping: any;
}

const DataTableRow = ({
	grade,
	classroom,
	recipient,
	type,
	balance,
	transactions,
	id,
	apiMapping,
}: Props) => {
	const { bookfairsOrigin } = useConfig();
	const [isExpanded, setExpanded] = useState(false);

	const handleExpandRow = () => {
		setExpanded(!isExpanded);
	};

	const handleExpandRowOnKeyPress = (e: any) => {
		if (e.type === "keydown" && (e.key === "Enter" || e.key === " ")) {
			handleExpandRow();
		}
	};

	const fairId = getFairIdFromUrl();

	return (
		<>
			<Row>
				<td onClick={handleExpandRow}>
					{apiMapping[grade as keyof typeof apiMapping]}
				</td>
				<td onClick={handleExpandRow}>{classroom}</td>
				<td onClick={handleExpandRow}>{recipient}</td>
				<td onClick={handleExpandRow}>
					{apiMapping[type as keyof typeof apiMapping]}
				</td>
				<BalanceCell
					onClick={handleExpandRow}
					tabIndex={1}
					onKeyDown={(e) => handleExpandRowOnKeyPress(e)}
				>
					<Arrow src={ArrowClosed} rowOpen={isExpanded} />${balance}
				</BalanceCell>
				<td>
					{balance !== 0 && (
						<PrintEWalletButton
							href={`${bookfairsOrigin}content/fairs/hub/scansheet/individual.html?fairid=${fairId}&uuid=${id}`}
							target="_blank"
							rel="noopener noreferrer"
							tabIndex={1}
							onClick={() => {
								window._satellite.track("print-ewallet", {
									"button-text": "print-ewallet",
								});
							}}
						>
							Print eWallet
						</PrintEWalletButton>
					)}
				</td>
			</Row>
			<DataTableTransactions
				transactions={transactions}
				isExpanded={isExpanded}
			/>
		</>
	);
};

const Row = styled.tr`
	height: 36px;
	color: #333333;
	td {
		border-bottom: 1px solid rgba(51, 51, 51, 0.25);
		padding-left: 8px;
	}
`;

const PrintEWalletButton = styled.a`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #e81111;
	text-decoration: none;
	&:hover {
		font-style: unset;
		text-decoration: underline;
		cursor: pointer;
	}
	&:focus-visible {
		outline-color: #e81111;
		outline-offset: 5px;
	}
`;

const BalanceCell = styled.td`
	height: 36px;
	&:focus-visible {
		outline-color: #e81111;
	}
`;

interface StyledArrowProps {
	rowOpen: boolean;
}

const Arrow = styled.img<StyledArrowProps>`
	transform: ${(props) =>
		props.rowOpen ? "rotate(180deg)" : "rotate(0deg)"};
	transition: 0.25s;
	width: 12px;
	margin-right: 10px;
	margin-bottom: 2px;
`;

export default DataTableRow;
