import React from "react";
import styled from "styled-components/macro";
import { RedButton } from "components/Button";
import { useConfig } from "providers/ConfigContext";
import DigitalFlyerInfo from "components/Homepage/PreviewFlyerSection/DigitalFlyerInfo.json";
import { trackExploreDigitalFlyer } from "DumbleData";

type Props = {
	productId: string;
	k8Flag: string;
};

const PreviewFlyerComponent = ({ productId, k8Flag }: Props) => {
	const { damAssetsOrigin } = useConfig();

	let flyerLink = "";
	let fairType;
	const flyerInfo = DigitalFlyerInfo.find((flyer) =>
		flyer["Product IDs"].includes(productId)
	);
	if (flyerInfo) {
		flyerLink = flyerInfo["Flyer Link"];
		fairType = flyerInfo["Fair Type"];
	}

	let secondaryFlyer = "";
	if (k8Flag === "Y") {
		const flyerInfoK8 = DigitalFlyerInfo.find(
			(flyer) =>
				flyer["Product IDs"].includes(productId) &&
				flyer["Product IDs"].includes("K8 flag")
		);
		if (flyerInfoK8) {
			secondaryFlyer = flyerInfoK8["Flyer Link"];
		}
	} else if (fairType === "Tabletop Mail K8") {
		secondaryFlyer =
			"https://bookfairsfiles.scholastic.com/homepages/flyer_TT_elem/flyer_TT_elem.html";
	}

	return (
		<PreviewFlyer>
			<SectionContainer>
				<FlyerImage
					src={`${damAssetsOrigin}LiveHomepage/DigitalFlyer/img_flyer.png`}
				/>
			</SectionContainer>
			<SectionContainer>
				<Heading>Preview Books from the Fair</Heading>
				<BodyText>
					Watch book trailers with your child in our interactive book
					flyer for a sneak peek of our upcoming Fair.
				</BodyText>
				<ButtonContainer>
					<StyledRedButton
						href={flyerLink}
						handleClick={() => trackExploreDigitalFlyer(flyerLink)}
						target="_blank"
					>
						Explore Digital Flyer
					</StyledRedButton>
					{secondaryFlyer && (
						<StyledRedButton
							href={secondaryFlyer}
							handleClick={() =>
								trackExploreDigitalFlyer(secondaryFlyer)
							}
							target="_blank"
						>
							Explore Grades 6+ Flyer
						</StyledRedButton>
					)}
				</ButtonContainer>
			</SectionContainer>
		</PreviewFlyer>
	);
};

const PreviewFlyer = styled.div`
	display: flex;
	justify-content: center;
	gap: 40px;
	width: 100%;
	min-height: 396px;
	padding: 48px;
	background: #ffffff;
	box-sizing: border-box;
	@media (max-width: 670px) {
		flex-flow: column;
		align-items: center;
		padding: 32px 38px;
	}
`;

const FlyerImage = styled.img`
	max-width: 100%;
`;

const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 440px;
	flex: 1 50%;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	@media (max-width: 950px) {
		flex-direction: column;
	}
`;

const Heading = styled.p`
	font-family: museo-sans;
	font-weight: 600;
	font-size: 32px;
	line-height: 35px;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #333333;
	margin-bottom: 8px;
`;

const BodyText = styled.p`
	font-family: museo-sans;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #333333;
	margin-bottom: 32px;
`;

const StyledRedButton = styled(RedButton)``;

export default PreviewFlyerComponent;
