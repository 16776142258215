import BodyText from "components/BodyText";
import Heading from "components/Heading";
import React from "react";
import styled from "styled-components/macro";
import ScholasticLogo from "images/ScholasticLogo.svg";
import BFLogo from "images/BFLogo.png";
import { InvoiceBasicInfo } from "hooks/FinancialForm/useInvoicePageData";

interface Props {
	number: string;
	children: React.ReactNode;
	FirstPage?: boolean;
	LastPage?: boolean;
	invoiceData?: InvoiceBasicInfo;
	fairId?: string;
}

/**
 * Page container for the invoice
 */
export const InvoicePrintablePage = ({
	number,
	children,
	FirstPage,
	LastPage,
	invoiceData,
	fairId,
}: Props) => {
	return (
		<InvoicePrintablePageContainer>
			{FirstPage && (
				<>
					<PageHeader>
						<PageHeaderLeft>
							<StyledScholasticLogo src={ScholasticLogo} />
							<StyledBFLogo src={BFLogo} />
						</PageHeaderLeft>
						<HeaderText>Invoice</HeaderText>
					</PageHeader>
					<InvoiceInfo>
						<Column1>
							<StyledBodyText>
								<b>School Name:</b> {invoiceData?.school.name}
							</StyledBodyText>
							<Group>
								<StyledBodyText>
									<b>Host Contact Information:</b>
								</StyledBodyText>
								<StyledBodyText>
									{invoiceData?.host.name}
								</StyledBodyText>
								<StyledBodyText>
									{invoiceData?.host.email}
								</StyledBodyText>
							</Group>
						</Column1>
						<Column2>
							<StyledBodyText>
								<b>Invoice #:</b> {invoiceData?.number}
							</StyledBodyText>
							<StyledBodyText>
								<b>Account #:</b>{" "}
								{invoiceData?.school.bookfairAccountId}
							</StyledBodyText>
						</Column2>
						<Column2>
							<StyledBodyText>
								<b>Fair ID: {fairId}</b>
							</StyledBodyText>
						</Column2>
					</InvoiceInfo>
				</>
			)}
			{children}
			<PageNumber>Page {number}</PageNumber>
			{LastPage && (
				<PayableToScholastic>
					<PayableHeader>
						Make Check Payable to Scholastic Book Fairs
					</PayableHeader>
					<PayableText>Scholastic Book Fairs - 04</PayableText>
					<PayableText>PO BOX: 639849</PayableText>
					<PayableText>Cincinnati, OH 45263-9849</PayableText>
				</PayableToScholastic>
			)}
		</InvoicePrintablePageContainer>
	);
};

const InvoicePrintablePageContainer = styled.div`
	margin: 0 70px 14px 70px;
	height: 1020px;
	position: relative;
`;

const PageNumber = styled(BodyText)`
	font-size: 10px;
	font-weight: 300;
	line-height: 15px;
	letter-spacing: 0px;
	position: absolute;
	bottom: 13.47px;
	right: 0px;
`;
const InvoiceInfo = styled.div`
	border-top: 3px solid #666666;
	display: flex;
	flex-direction: row;
	padding-top: 20px;
	padding-bottom: 22px;
	justify-content: space-between;
`;
const Column1 = styled.div`
	display: flex;
	flex-direction: column;
	width: 220px;
	align-items: flex-start;
	gap: 13px;
`;
const Column2 = styled.div`
	display: flex;
	flex-direction: column;
	width: 139px;
	align-items: flex-start;
	gap: 13px;
`;
const StyledBodyText = styled(BodyText)`
	color: var(--text-primary, #333);
	font-size: 11px;
	font-style: normal;
	font-weight: 300;
	line-height: 15px;
`;
const Group = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const PageHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 26px;
	margin-top: 20px;
`;
const PageHeaderLeft = styled.div`
	display: flex;
	flex-direction: row;
	gap: 13.28px;
`;
const HeaderText = styled(Heading)`
	color: var(--text-primary, #333);
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	padding: 0;
`;
const StyledScholasticLogo = styled.img``;
const StyledBFLogo = styled.img`
	width: 140.267px;
	height: 16px;
	flex-shrink: 0;
`;

const PayableToScholastic = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-top: 2px solid #666666;
	padding-top: 10px;
`;
const PayableHeader = styled(Heading)`
	color: var(--text-primary, #333);
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 20px;
	padding-bottom: 0px;
`;
const PayableText = styled(BodyText)`
	color: var(--text-primary, #333);
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	line-height: 15px;
	margin-bottom: 12px;
`;
