import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DataTableRow from "./DataTableRow";
import TableHeaderCell from "./TableHeaderCell";
import { sortDateAscend, sortDateDescend } from "./SortingFunctions";
import { Transaction } from "hooks/SDBalance/useSDBalanceData";

interface Props {
	transactions: Array<Transaction>;
}

export const SDBDataTable = ({ transactions }: Props) => {
	// sorted and/or filtered data
	const [sortedData, setSortedData] = useState(transactions);

	// sets sorting arrow styles
	const [sortedBy, setSortedBy] = useState("");

	// Sorting functions, sortedBy controls up/down arrow styling
	const sortDate = () => {
		if (sortedBy === "dateDescend") {
			setSortedBy("dateAscend");
			setSortedData(sortDateAscend(sortedData));
		} else {
			setSortedBy("dateDescend");
			setSortedData(sortDateDescend(sortedData));
		}
	};

	useEffect(() => {
		setSortedData(transactions);
	}, [transactions]);

	return (
		<>
			<HeaderContainer>
				<StyledEWalletCount>Transaction Details</StyledEWalletCount>
			</HeaderContainer>
			<Container>
				<table className="min-w-full text-center">
					<thead>
						<tr>
							<TableHeaderCell
								label={"Date"}
								onClick={sortDate}
								sortedBy={sortedBy}
								upArrow={"dateAscend"}
								downArrow={"dateDescend"}
								className="date"
							/>
							<th>Description</th>
							<th className="transaction">Transaction</th>
							<th className="balance">Balance</th>
						</tr>
					</thead>
					<tbody>
						{sortedData.map((row, index) => (
							<DataTableRow
								key={index}
								date={row.date}
								description={row.description}
								transactionAmount={row.transactionAmount}
								balance={row.balance}
							/>
						))}
					</tbody>
				</table>
				{sortedData.length === 0 && (
					<NoResultsContainer>
						<StyledNoResultsText>
							No Results Found.
						</StyledNoResultsText>
					</NoResultsContainer>
				)}
			</Container>
		</>
	);
};

const Container = styled.div`
	width: 100%;
	overflow-x: scroll;
	min-height: 480px;
	table {
		width: 100%;
		border-collapse: collapse;
		text-align: left;
		margin-bottom: 20px;
	}
	th {
		background-color: #f4f4f4;
		height: 36px;
		text-align: left;
		border-bottom: 1px solid rgba(51, 51, 51, 0.25);
		max-width: 145px;
		padding-left: 8px;
	}
	th.date,
	th.transaction,
	th.balance {
		max-width: 160px;
		width: 160px;
	}
	@media (max-width: 720px) {
		th.date,
		th.transaction,
		th.balance {
			max-width: 128px;
			width: 128px;
		}
	}
`;

const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 62px 0px 24px 0px;
	@media (max-width: 470px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const StyledEWalletCount = styled.div`
	font-weight: 400;
	font-size: 24px;
	line-height: 28px;
	height: 28px;
	color: #333333;
`;

const NoResultsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
`;

const StyledNoResultsText = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	margin-top: 20px;
`;

export default SDBDataTable;
