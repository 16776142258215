import React, { useState } from "react";
import styled from "styled-components";
import magnifyingGlass from "images/MagnifyingGlass.svg";

interface Props {
	onSearchFormSubmit: Function;
}

const SearchBar = ({ onSearchFormSubmit }: Props) => {
	const [searchInput, setSearchInput] = useState("");

	function handleChange(event: any) {
		setSearchInput(event.target.value);
	}

	function handleSubmit(event: any) {
		event.preventDefault();
		if (searchInput.trim().length !== 0) {
			onSearchFormSubmit(searchInput);
		}
	}

	return (
		<Container>
			<StyledForm
				className="fairFinderSearch__form"
				onSubmit={(e) => handleSubmit(e)}
			>
				<StyledMagnifyingGlass
					onClick={(e) => handleSubmit(e)}
					src={magnifyingGlass}
					alt="Search"
				/>
				<StyledSearchInput
					className="fairFinderSearch__input"
					type="text"
					onChange={handleChange}
				/>
			</StyledForm>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 67px;
	@media (max-width: 500px) {
		padding-bottom: 41px;
	}
`;

const StyledForm = styled.form`
	display: flex;
	flex-direction: row;
	margin: 5px 20px 5px 5px;
	max-width: 916px;
	width: 58%;
	@media (max-width: 1300px) {
		width: 54%;
	}
	@media (max-width: 800px) {
		width: 70%;
	}
	@media (max-width: 500px) {
		width: 95%;
	}
`;

const StyledSearchInput = styled.input`
	background: #ffffff;
	border: 1px solid #919191;
	border-radius: 4px;
	font-family: museo-sans;
	font-style: normal;
	font-weight: 400;
	font-size: 21px;
	padding-left: 40px;
	line-height: 24px;
	height: 50px;
	width: 100%;
`;

const StyledMagnifyingGlass = styled.img`
	position: relative;
	width: 16px;
	left: 30px;
`;

export default SearchBar;
