import React, { useState } from "react";
import { AEMModalContainer } from "components/AEMModalContainer";
import { RedButton, WhiteButton } from "components/Button";
import styled from "styled-components/macro";
import TextInput from "components/TextInput";
import { validateEmail } from "utils/InputValidations";

interface Props {
	closeModal: any;
	submitCoChair: any;
	coHostEmailList: Array<string> | undefined;
	currentUserEmail: string;
	consultantEmail: string;
}

export const AddCoChairModal = ({
	closeModal,
	submitCoChair,
	coHostEmailList,
	currentUserEmail,
	consultantEmail,
}: Props) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [firstNameError, setFirstNameError] = useState<string>();
	const [lastNameError, setLastNameError] = useState<string>();
	const [emailError, setEmailError] = useState<string>();

	const handleSubmit = () => {
		let valid = true;
		const namePattern = /^[0-9A-Za-z\s-]+$/;
		if (
			!firstName ||
			!namePattern.test(firstName) ||
			firstName.replace(/\s/g, "").length < 2 ||
			firstName.replace(/\s/g, "").length > 30
		) {
			valid = false;
			setFirstNameError("Please enter a valid name.");
		} else {
			setFirstNameError(undefined);
		}
		if (
			!lastName ||
			!namePattern.test(lastName) ||
			lastName.replace(/\s/g, "").length < 2 ||
			lastName.replace(/\s/g, "").length > 30
		) {
			valid = false;
			setLastNameError("Please enter a valid name.");
		} else {
			setLastNameError(undefined);
		}
		if (
			!email ||
			!validateEmail(email) ||
			email.replace(/\s/g, "").length < 5 ||
			email.replace(/\s/g, "").length > 75
		) {
			valid = false;
			setEmailError("Please enter a valid email address.");
		} else if (
			(coHostEmailList && coHostEmailList.includes(email)) ||
			currentUserEmail === email ||
			consultantEmail === email
		) {
			valid = false;
			setEmailError("Please enter a unique email address.");
		} else {
			setEmailError(undefined);
		}
		if (valid) {
			submitCoChair({
				firstName: firstName,
				lastName: lastName,
				email: email,
			});
		}
	};
	return (
		<AEMModalContainer closeModal={closeModal}>
			<Container>
				<InputContainer>
					<Title>Add Co-Host</Title>
					<StyledTextInput
						label="First Name"
						value={firstName}
						onChange={setFirstName}
						height={"50px"}
						error={firstNameError}
					/>
					<StyledTextInput
						label="Last Name"
						value={lastName}
						onChange={setLastName}
						height={"50px"}
						error={lastNameError}
					/>
					<StyledTextInput
						label="Email Address"
						value={email}
						onChange={setEmail}
						height={"50px"}
						error={emailError}
					/>
				</InputContainer>
				<ButtonContainer>
					<StyledWhiteButton
						whiteBackground={true}
						handleClick={closeModal}
					>
						Cancel
					</StyledWhiteButton>
					<StyledRedButton handleClick={handleSubmit}>
						Add
					</StyledRedButton>
				</ButtonContainer>
			</Container>
		</AEMModalContainer>
	);
};
const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	@media (max-width: 719px) {
		align-items: center;
		width: 100%;
	}
`;
const StyledTextInput = styled(TextInput)``;
const Container = styled.div`
	position: relative;
	width: 600px;
	padding: 24px;
	box-sizing: border-box;
	@media (max-width: 719px) {
		height: 100vh;
		width: 100vw;
	}
`;
const ButtonContainer = styled.div`
	padding-top: 41px;
	padding-bottom: 24px;
	display: flex;
	flex-direction: row;
	gap: 16px;
	justify-content: flex-end;
	@media (max-width: 719px) {
		padding-top: 13px;
	}
`;
const Title = styled.p`
	font-size: 16px;
	font-weight: 700;
	line-height: 19px;
	letter-spacing: 0px;
	text-align: left;
	padding-top: 38px;
	padding-bottom: 35px;
	@media (max-width: 719px) {
		width: 100%;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 130px;
	margin-bottom: 15px;
`;
const StyledRedButton = styled(RedButton)`
	width: 130px;
	margin-bottom: 15px;
`;
