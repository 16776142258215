import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import SortArrowUp from "../../images/SortArrowUp.svg";
import SortArrowDown from "../../images/SortArrowDown.svg";

interface Props {
	label: string;
	onClick: MouseEventHandler;
	sortedBy: string;
	upArrow: string;
	downArrow: string;
}

const TableHeaderCell = ({
	label,
	onClick,
	sortedBy,
	upArrow,
	downArrow,
}: Props) => {
	const handlePressEnter = (e: any) => {
		if (e.key === "Enter") {
			onClick(e);
		}
	};
	return (
		<Container
			onClick={onClick}
			onKeyDown={(e) => handlePressEnter(e)}
			tabIndex={1}
		>
			<ArrowContainer>
				<Arrow src={SortArrowUp} active={upArrow === sortedBy} />
				<Arrow src={SortArrowDown} active={downArrow === sortedBy} />
			</ArrowContainer>
			<div>{label}</div>
		</Container>
	);
};

const Container = styled.th`
	color: #333333;
	word-wrap: break-word;
	&:focus-visible {
		outline-offset: -2px;
		outline-color: #e81111;
	}
`;

const ArrowContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
	padding-right: 6px;
	float: left;
`;

type ArrowProps = {
	active: boolean;
};

const Arrow = styled.img<ArrowProps>`
	width: 11px;
	opacity: ${(props) => (props.active ? "100%" : "50%")};
`;

export default TableHeaderCell;
