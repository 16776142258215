import React from "react";
import styled from "styled-components/macro";
import Heading from "components/Heading";
import BodyText from "components/BodyText";
import GoalComponent from "components/Homepage/GoalComponent";

interface Props {
	afterFair: boolean;
	fairInfoData: {
		schoolName: string;
		endDate: Date;
		startDate: Date;
		location: string;
		address: string;
		city: string;
		state: string;
		zipcode: string;
		welcomeMessage: string;
		thankYouMessage: string;
	};
	goalData: {
		goalAmount: string;
		actualAmount: string;
		goalPurpose: string;
		bookFairGoalCkbox: boolean;
	};
}

const FairInformationComponent = ({
	fairInfoData,
	goalData,
	afterFair,
}: Props) => {
	const {
		schoolName,
		address,
		city,
		state,
		zipcode,
		location,
		startDate,
		endDate,
		welcomeMessage,
		thankYouMessage,
	} = fairInfoData;
	const { goalAmount, actualAmount, goalPurpose, bookFairGoalCkbox } =
		goalData;
	const formattedStart = startDate.toLocaleString("en-US", {
		month: "short",
		day: "numeric",
	});
	const formattedEnd = endDate.toLocaleString("en-US", {
		month: "short",
		day: "numeric",
	});

	return (
		<FairInformationSection>
			<SectionContainer>
				<StyledHeading>{schoolName}</StyledHeading>
				<Address>
					{address}, {city}, {state} {zipcode}
				</Address>
				<Address>{location}</Address>
				<FairDatesLabel>Fair dates</FairDatesLabel>
				<FairDates>
					{formattedStart} - {formattedEnd}
				</FairDates>
				<Message showingGoal={bookFairGoalCkbox}>
					{afterFair ? thankYouMessage : welcomeMessage}
				</Message>
				{bookFairGoalCkbox && (
					<GoalComponent
						goalBooks={parseInt(goalAmount.replace(/,/g, ""))}
						soldBooks={parseInt(actualAmount.replace(/,/g, ""))}
						goalPurpose={goalPurpose}
					/>
				)}
			</SectionContainer>
		</FairInformationSection>
	);
};

const SectionContainer = styled.div`
	padding: 32px 30px 0 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 760px;
	@media (max-width: 800px) {
		padding: 0;
		width: 100%;
	}
	@media (max-width: 580px) {
		width: 315px;
	}
	@media (max-width: 375px) {
		padding: 0;
	}
`;

const Message = styled(BodyText)<{ showingGoal: boolean }>`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0px;
	text-align: center;
	max-width: 453px;
	margin: 21px 0 ${(props) => (props.showingGoal ? "44px" : "57px")} 0;
	@media (max-width: 375px) {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		width: 325px;
		margin-bottom: 21px 0 31px 0;
	}
`;
const FairDates = styled(BodyText)`
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: 35px;
	letter-spacing: 0px;
	text-align: center;
	color: #015e8c;
	margin-top: 12px;
	@media (max-width: 375px) {
		font-weight: 600;
		font-size: 28px;
		line-height: 32px;
	}
`;

const Address = styled(BodyText)`
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 1.5px;
	text-align: center;
	text-transform: uppercase;
	@media (max-width: 375px) {
		font-weight: 600;
		font-size: 13px;
		line-height: 20px;
		width: 315px;
	}
`;

const FairDatesLabel = styled(BodyText)`
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 1.5px;
	text-align: center;
	background: #015e8c;
	color: #ffffff;
	padding: 4px 12px;
	text-transform: uppercase;
	margin-top: 34px;
	width: 77px;
`;
const StyledHeading = styled(Heading)`
	text-transform: uppercase;
	font-size: 44px;
	font-style: normal;
	font-weight: 600;
	line-height: 47px;
	letter-spacing: 2px;
	text-align: center;
	max-width: 760px;
	color: #015e8c;
	margin-top: 32px;
	@media (max-width: 375px) {
		font-size: 34px;
		font-weight: 600;
		line-height: 37px;
	}
`;

const FairInformationSection = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
export default FairInformationComponent;
