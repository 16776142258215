import React from "react";
import styled from "styled-components/macro";

interface Props {
	isCurrentServiceAgreement: boolean;
}
const TableTopFair = ({ isCurrentServiceAgreement }: Props) => {
	return (
		<TableTopFairContainer>
			<OpeningParagraph>
				<b>Thank you for hosting a Scholastic Book Fair!</b> We look
				forward to working with you. Please read the following Services
				Agreement and guidelines, which have recently changed.
				Scholastic Book Fairs is the retailer of books sold at the Fair.
				State and local sales tax must be collected on all purchases at
				the Fair (except for purchases made by the school with school
				funds for school use when the school is registered with
				Scholastic as tax-exempt).
			</OpeningParagraph>
			<TableTopFairText>
				<p> As always, Scholastic will provide: </p>
				<TableTopFairList>
					<li>Books and educational products</li>
					<li>A dedicated support and service team</li>
					<li>Online planning resources and tools</li>
					<li>Quick and easy product restocks</li>
					<li>Setup Kit & Kick-Off Kit with promotional materials</li>
					<li>An Online Fair by way of The Scholastic Store</li>
					<li>
						Credit card acceptance program that allows you to accept
						digital payments and major credit cards
					</li>
				</TableTopFairList>
				<p>The school or organization listed agrees to:</p>
				<TableTopFairList>
					<li>
						Use Scholastic Book Fairs as the exclusive provider and
						retailer of all books, merchandise, and promotional
						materials during the Book Fair event.
					</li>
					<li>
						Store and display all merchandise, cash, checks, and
						credit card sales information in a locked and secure
						location when not in use at your Book Fair.
					</li>
					<li>
						Make products for sale by you available to all qualified
						event attendees at the listed price designated by
						Scholastic.
					</li>
					<li>
						Collect sales tax on behalf of Scholastic Book Fairs as
						required by state and local law.
						<TableTopFairSublist>
							<li>
								Ensure proper tax-exempt certificate or other
								documentation as required by state and local law
								is on file with Scholastic Book Fairs prior to
								making any tax-exempt purchases.
							</li>
						</TableTopFairSublist>
					</li>
					<li>
						Return all payment systems you received, if any, unsold
						products, and merchandising materials/displays (unless
						otherwise marked) to Scholastic Book Fairs promptly at
						the conclusion of the Fair.
					</li>
					<li>
						Process the Financial Forms and payment within two (2)
						working days after the Fair has ended.
					</li>
					<li>
						Review and comply with the following credit card
						security procedures (this section is not applicable to
						Virtual Fairs):
						<TableTopFairSublist>
							<li>
								Do not share or distribute the card information
								that you collect (such as credit card numbers)
								on behalf of Scholastic Book Fairs.
							</li>
							<li>
								Do not write down or copy any data from a
								customer's credit card (ie, card numbers,
								expiration dates, or security codes).
								Information should be completed by the customer.
							</li>
							<li>
								Credit card forms are not to be used for
								personal use/gain; forms are only to be used for
								transactions at Scholastic Book Fairs events.
							</li>
							<li>
								If a credit card is found, please call the
								toll-free number on the back of the credit card,
								report the card lost, and request further
								directions from the credit card company.
							</li>
							<li>
								All unattended credit card forms or receipts
								must be in a locked or secure location.
							</li>
							<li>
								Volunteers should use good and reasonable
								judgment in the event of any issues or contact
								the Book Fair host for guidance.
							</li>
							<li>
								The Book Fair host is responsible for informing
								other volunteers of this credit card security
								instructional awareness information provided by
								Scholastic. Acceptance of the Services Agreement
								acknowledges notice of and agreement to this
								credit card security instructional awareness
								information.
							</li>
						</TableTopFairSublist>
					</li>
					<li>
						Repack all unsold products, supplies, and displays in
						the original shipping boxes (please do not consolidate
						extra books into the trays) and affix prepaid UPS return
						labels (not applicable to Alaska Fairs) on the outside
						of original shipping boxes. These labels are located in
						the large envelope provided with your Book Fair).{" "}
						<b>
							Be sure not to throw shipper cartons, trays, or
							easels away.
						</b>
					</li>
					<li>
						Please give the boxes to UPS on a normal delivery stop
						or call 1-800-877-1497 to schedule a pickup. (For Alaska
						Fairs, please refer to the return paperwork provided
						with your Fair or call your host at 1-800-635-7323.)
					</li>
					<li>
						Contact your Book Fair Consultant at 1-800-557-7323 if
						your Book Fair did not run as scheduled for any reason
						that would impact the return process.
					</li>
					<li>
						Process the Book Fair Financial Forms and payment within
						two (2) working days after the Fair has ended. For
						Alaska Fairs, please complete your financials with your
						Book Fair Consultant.
					</li>
				</TableTopFairList>
				<p>
					Changes to this Services Agreement may be made solely at the
					discretion of Scholastic Book Fairs as permissible by law
					and/or as business conditions deem appropriate. We will
					notify you of any such changes by posting the revised
					Services Agreement in the Book Fair Host Hub. Under certain
					circumstances, we may also notify you of changes to this
					Services Agreement by additional means, including, but not
					limited to, posting a notice on the Scholastic Book Fairs
					homepage, sending you an email if we have your email
					address, or by contacting you through your Book Fair
					Consultant. At that time, you will have the opportunity to
					review and accept a new Services Agreement.
				</p>
			</TableTopFairText>
			{isCurrentServiceAgreement ? (
				<>
					<ProfitText>
						<ProfitHeading>Profit</ProfitHeading>
						<p>
							<b>
								<i>
									Effective for Fairs scheduled between
									8/12/24 and 8/10/25
								</i>
							</b>
						</p>
						If your Book Fair sales are $3,800 and above, you may
						opt to take profit in Scholastic Dollars, cash, or a
						combination of Scholastic Dollars and cash. See the
						chart below for all other sales levels. Online Fairs are
						not eligible for cash profit.
					</ProfitText>
					<ProfitList>
						<li>
							When you elect to take a combination, Scholastic
							Dollars are worth double the value in cash.
						</li>
						<li>
							The total profit amount cannot exceed 25% of sales
							at cash value.
						</li>
						<li>
							Use the Profit Split page on the Financial Form to
							automatically calculate and optimize your profit
							elections.
						</li>
					</ProfitList>
					<Gap />
					<DollarsProfitHeading>
						Scholastic Dollars Profit
					</DollarsProfitHeading>
					<ChartInnerContainer>
						<table>
							<tbody>
								<tr>
									<th>Book Fair Sales (excluding tax)</th>
									<th>Scholastic Dollars Value</th>
									<th></th>
									<th>Cash Value</th>
								</tr>
								<tr>
									<td>$3,800 and up</td>
									<td>50% of Sales</td>
									<td>and/or</td>
									<td>25% of Sales</td>
								</tr>
								<tr>
									<td>$1,600 - $3,799.99</td>
									<td>40% of Sales</td>
									<td></td>
									<td>$0</td>
								</tr>
								<tr>
									<td>$0 - $1,599.99</td>
									<td>30% of Sales</td>
									<td></td>
									<td>$0</td>
								</tr>
								<tr>
									<td>Virtual Fairs</td>
									<td>25% of Sales*</td>
									<td></td>
									<td>$0</td>
								</tr>
							</tbody>
						</table>
						<p>*Excluding tax & shipping.</p>
					</ChartInnerContainer>
				</>
			) : (
				<>
					<ProfitText>
						<ProfitHeading>Profit</ProfitHeading>
						<p>
							<b>
								<i>
									Effective for Fairs scheduled between 8/7/23
									and 8/11/24
								</i>
							</b>
						</p>
						If your Book Fair sales are $3,500 and above, you may
						opt to take profit in Scholastic Dollars, cash, or a
						combination of Scholastic Dollars and cash. See the
						chart below for all other sales levels. Online Fairs are
						not eligible for cash profit.
					</ProfitText>
					<ProfitList>
						<li>
							When you elect to take a combination, Scholastic
							Dollars are worth double the value in cash.
						</li>
						<li>
							The total profit amount cannot exceed 25% of sales
							at cash value.
						</li>
						<li>
							Use the Profit Split page on the Financial Form to
							automatically calculate and optimize your profit
							elections.
						</li>
					</ProfitList>
					<DollarsProfitHeading>
						Scholastic Dollars Profit
					</DollarsProfitHeading>
					<Gap />
					<ChartInnerContainer>
						<table>
							<tbody>
								<tr>
									<th>Book Fair Sales (excluding tax)</th>
									<th>Scholastic Dollars Value</th>
									<th></th>
									<th>Cash Value</th>
								</tr>
								<tr>
									<td>$3,500 and up</td>
									<td>50% of Sales</td>
									<td>and/or</td>
									<td>25% of Sales</td>
								</tr>
								<tr>
									<td>$1,500 - $3,499.99</td>
									<td>40% of Sales</td>
									<td></td>
									<td>$0</td>
								</tr>
								<tr>
									<td>$0 - $1,499.99</td>
									<td>30% of Sales</td>
									<td></td>
									<td>$0</td>
								</tr>
								<tr>
									<td>Virtual Fairs</td>
									<td>25% of Sales*</td>
									<td></td>
									<td>$0</td>
								</tr>
							</tbody>
						</table>
					</ChartInnerContainer>
				</>
			)}
		</TableTopFairContainer>
	);
};

const Gap = styled.span`
	height: 20px;
`;

const TableTopFairContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 6.5in;
`;

const OpeningParagraph = styled.p`
	margin-top: 20px;
	font-size: 10px;
`;

const TableTopFairText = styled.div`
	font-size: 9px;
	padding: 10px 0;
	p {
		padding: 10px 0;
	}
`;

const TableTopFairList = styled.ul`
	list-style-type: disc; /* Change the bullet point to a disc */
	margin: 0; /* Remove default margin */
	padding-left: 0; /* Remove default padding */
	li {
		margin-left: 0.25in;
	}
`;

const TableTopFairSublist = styled.ul`
	list-style-type: circle; /* Change the bullet point to a circle */
	margin: 0; /* Remove default margin */
	padding-left: 1.5em; /* Add left padding for indentation */
`;

const ProfitHeading = styled.div`
	font-size: 10px;
	font-weight: 700;
`;

const DollarsProfitHeading = styled.div`
	font-size: 10px;
	font-weight: 700;
	margin: 10px 0px;
`;

const ProfitList = styled.ul`
	font-size: 10px;
	list-style-type: disc; /* Change the bullet point to a disc */
	margin: 0; /* Remove default margin */
	padding-left: 0; /* Remove default padding */
	li {
		margin-left: 0.25in;
	}
`;

const ChartInnerContainer = styled.div`
	width: 100%;
	font-size: 9px;
	margin-bottom: 20px;
	table {
		width: 100%;
		border-collapse: collapse;
		text-align: center;
		margin-bottom: 20px;
	}
	th {
		text-align: center;
		padding: 3px 0px 10px;
		border-right: 1px solid black;
		border-bottom: 1px solid black;
		font-weight: 700;
	}
	td {
		padding: 3px 0px 10px;
		border-right: 1px solid black;
		border-bottom: 1px solid black;
	}
`;

const ProfitText = styled.div`
	font-size: 9px;
	p {
		padding: 10px 0;
	}
`;

export default TableTopFair;
