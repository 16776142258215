import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Heading from "components/Heading";
import BodyText from "components/BodyText";
import { RedButton } from "components/Button";
import { useConfig } from "providers/ConfigContext";
import { trackOnlineShopping } from "DumbleData";

interface Props {
	fairId: number;
	ofeData: {
		ofeStartDate: Date;
		ofeEndDate: Date;
	};
}

const ShopMoreComponent = ({ fairId, ofeData }: Props) => {
	const { ofeStartDate, ofeEndDate } = ofeData;
	const formattedStart = ofeStartDate.toLocaleString("en-US", {
		month: "short",
		day: "numeric",
	});

	const formattedEnd = ofeEndDate.toLocaleString("en-US", {
		month: "short",
		day: "numeric",
	});

	const [beforeOfeStart, setBeforeOfeStart] = useState<boolean>(false);
	const [beforeOfeEnd, setBeforeOfeEnd] = useState<boolean>(false);

	useEffect(() => {
		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);
		if (currentDate < ofeStartDate) {
			setBeforeOfeStart(true);
			setBeforeOfeEnd(true);
		}
		if (currentDate <= ofeEndDate) {
			setBeforeOfeEnd(true);
		} else {
			setBeforeOfeEnd(false);
		}
	}, [beforeOfeStart, beforeOfeEnd, ofeStartDate, ofeEndDate]);

	const { onlineShopOrigin, damAssetsOrigin } = useConfig();

	const handleSubmit = (event: any) => {
		event.preventDefault();
		const shopUrl: string = `${onlineShopOrigin}parent-ecommerce/parent-store-2.html?fairId=${fairId}`;
		window.open(shopUrl);
		trackOnlineShopping(shopUrl);
	};

	return (
		<ShopMore>
			<SectionContainer>
				<StyledSchooolImage
					src={`${damAssetsOrigin}LiveHomepage/ShopOnline/img_school.png`}
					alt="School!"
				/>
				<StyledHeading>
					{beforeOfeStart &&
						`SHOP ONLINE DURING ${formattedStart} – ${formattedEnd} TO SUPPORT OUR SCHOOL!`}
					{!beforeOfeStart &&
						beforeOfeEnd &&
						"SHOP EVEN MORE TITLES ONLINE & CONTINUE TO SUPPORT OUR SCHOOL!"}
					{!beforeOfeEnd &&
						"Shop 1000s of titles online and support our school all year long"}
				</StyledHeading>
				<MobileHeading>
					{!beforeOfeStart
						? "SHOP ONLINE TO SUPPORT OUR SCHOOL!"
						: `SHOP ONLINE DURING OUR FAIR TO SUPPORT OUR SCHOOL!`}
				</MobileHeading>
				<StyledSubHeading>
					{beforeOfeStart &&
						"Bring on the Bookjoy—25% of every online purchase during the Fair directly benefits our school!"}
					{!beforeOfeStart &&
						beforeOfeEnd &&
						`Bring on the Bookjoy—25% of every online purchase from ${formattedStart} – ${formattedEnd} directly benefits our school!`}
					{!beforeOfeEnd &&
						"Bring on the Bookjoy—2% of every purchase directly benefits our school!"}
				</StyledSubHeading>
				<StyledRedButton
					handleClick={(e) => handleSubmit(e)}
					disabled={beforeOfeStart}
				>
					{!beforeOfeStart
						? "SHOP NOW"
						: `COME BACK ON ${formattedStart}`}
				</StyledRedButton>
				<StyledShopMoreImage
					src={`${damAssetsOrigin}LiveHomepage/ShopOnline/img_shop_online.png`}
					alt="Over 6000 Products!"
				/>
				<StyledTerms>
					Shop online for an expanded selection of exclusive books,
					new releases, value packs, and more. All orders ship home
					and book-only orders over $25 ship free. Buy One, Get One
					and 25% Off pricing is not applicable online.
				</StyledTerms>
			</SectionContainer>
		</ShopMore>
	);
};

const StyledRedButton = styled(RedButton)`
	margin-top: 30px;
	min-width: 150px;
	&:disabled {
		opacity: 1;
		min-width: 246px;
	}
	@media (max-width: 720px) {
		margin-top: 45px;
	}
	@media (max-width: 610px) {
		margin-top: 40px;
		width: 132px;
		&:disabled {
			opacity: 1;
			min-width: 250px;
		}
	}
`;

const ShopMore = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
	background: #d8f3f6;
	align-items: center;
`;

const SectionContainer = styled.div`
	padding: 32px 0 20px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 720px) {
		padding: 30px 0px 0 0px;
	}
	@media (max-width: 610px) {
		padding: 0 20px 0 20px;
	}
`;

const StyledShopMoreImage = styled.img`
	display: flex;
	margin-bottom: 9px;
	margin-top: 19px;
	width: 800px;
	@media (max-width: 950px) {
		max-width: 575px;
	}
	@media (max-width: 610px) {
		max-width: 335px;
	}
`;

const StyledSchooolImage = styled.img`
	display: flex;
	width: 126px;
	height: 101px;
	margin: 12px 0px 30px 0px;
	@media (max-width: 610px) {
		margin-bottom: 0px;
	}
`;

const StyledHeading = styled(Heading)`
	display: flex;
	text-transform: uppercase;
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: 35px;
	letter-spacing: 1px;
	text-align: center;
	max-width: 650px;
	color: #015e8c;
	margin-top: 20px;
	@media (max-width: 720px) {
		max-width: 467px;
		max-height: 105px;
		font-size: 32px;
		line-height: 35px;
	}
	@media (max-width: 610px) {
		display: none;
	}
`;

const MobileHeading = styled(StyledHeading)`
	display: none;
	@media (max-width: 610px) {
		display: block;
		max-width: 300px;
		margin-top: 30px;
		font-size: 28px;
		font-weight: medium;
		line-height: 32px;
		letter-spacing: 1.5px;
	}
`;

const StyledSubHeading = styled(BodyText)`
	display: flex;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 1px;
	text-align: center;
	max-width: 500px;
	color: #333333;
	@media (max-width: 720px) {
		font-size: 18px;
		line-height: 21px;
		max-width: 364px;
		max-height: 63px;
	}
	@media (max-width: 610px) {
		margin-top: 10px;
		max-width: 250px;
		font-size: 16px;
	}
`;

const StyledTerms = styled(BodyText)`
	display: flex;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0px;
	text-align: center;
	max-width: 600px;
	color: #333333;
	margin: 22px 0px 31px 0px;
	@media (max-width: 720px) {
		margin-top: 25px;
		margin-bottom: 25px;
		font-size: 16px;
		line-height: 19px;
		max-width: 417px;
	}
	@media (max-width: 610px) {
		max-width: 315px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
`;

export default ShopMoreComponent;
