import React from "react";
import styled from "styled-components/macro";

interface Props {
	className?: any;
	checked: boolean;
	onChange: (value: boolean) => void;
	label: string;
	value?: string;
	disabled?: boolean;
	alignItemsStart?: boolean;
	htmlFor?: string;
	radioButtonName: string;
}

const RadioButton = ({
	className,
	checked,
	onChange,
	label,
	value,
	disabled,
	alignItemsStart,
	radioButtonName,
}: Props) => {
	return (
		<div>
			<StyledContainer
				className={className}
				alignItemsStart={alignItemsStart}
			>
				<StyledRadioButton
					type="radio"
					checked={checked}
					onChange={() => onChange(!checked)}
					onKeyPress={() => onChange(!checked)}
					disabled={disabled}
					value={value}
					id={value}
					name={radioButtonName}
				/>
				<CustomRadioButton />
				<StyledLabel htmlFor={value}>{label}</StyledLabel>
			</StyledContainer>
		</div>
	);
};

interface StyledContainerProps {
	alignItemsStart?: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
	display: inline-flex;
	align-items: ${(props) =>
		props.alignItemsStart ? "flex-start" : "center"};
	position: relative;
`;

const CustomRadioButton = styled.label`
	&::before {
		content: "";
		height: 20px;
		width: 20px;
		display: block;
		position: relative;
		margin-right: 8px;
	}
`;

const StyledRadioButton = styled.input`
	z-index: 1;
	position: absolute;
	min-height: 20px;
	min-width: 20px;
	&:focus,
	:focus-visible + ${CustomRadioButton} {
		&::before {
			outline: 2px auto #116eee;
			outline-offset: 0px;
		}
	}
	&[data-error] + ${CustomRadioButton} {
		&::before {
			outline: 1px auto #e81111;
		}
	}
	&:checked + ${CustomRadioButton} {
		&::before {
		}
	}
`;

const StyledLabel = styled.label`
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
	color: #333333;
`;

export default RadioButton;
