import React from "react";
import styled from "styled-components";
import { AEMModalContainer } from "components/AEMModalContainer";
import { WhiteButton } from "components/Button";

interface Props {
	closeModal: () => void;
}

export const EmailSuccessModal = ({ closeModal }: Props) => {
	return (
		<AEMModalContainer closeModal={closeModal}>
			<StyledContainer>
				<StyledText>Thank you, your email has been sent.</StyledText>
				<StyledButtonContainer>
					<StyledWhiteButton
						whiteBackground={true}
						handleClick={closeModal}
					>
						Close
					</StyledWhiteButton>
				</StyledButtonContainer>
			</StyledContainer>
		</AEMModalContainer>
	);
};

const StyledContainer = styled.div`
	padding: 24px;
`;

const StyledText = styled.p`
	padding-top: 65px;
	padding-bottom: 48px;
	font-weight: 700;
	font-size: 18px;
	line-height: 21px;
	color: black;
`;

const StyledButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const StyledWhiteButton = styled(WhiteButton)`
	margin-right: 1px;
	margin-bottom: 1px;
	float: right;
`;
