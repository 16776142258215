import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import BodyText from "components/BodyText";
import Heading from "components/Heading";
import TextLink from "components/TextLink";
import { useReactToPrint } from "react-to-print";
import { PrintableComponent } from "components/PrintableComponent";
import { convertYMDToMDYYYY } from "utils/DateConversion";
import { FairData } from "hooks/SalesHistory/useSalesHistory";
import { trackCertificateOfAgreement, trackViewInvoice } from "DumbleData";
import SalesHistoryInvoice from "./SalesHistoryInvoice";

interface Props {
	fairData: FairData;
	schoolId: string;
}

export const SalesHistoryItem = ({ fairData, schoolId }: Props) => {
	/**
	 * Logic for the Printable Invoice PDF
	 */
	const componentRef = useRef<HTMLDivElement>(null);
	const [printClicked, setPrintClicked] = useState(false);

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});
	const handleSatelliteCall = () => {
		trackCertificateOfAgreement();
	};

	const handlePrint = () => {
		openPrintDialogue();
		setPrintClicked(false);
	};

	const openPrintDialogue = useReactToPrint({
		content: () => componentRef.current as HTMLElement,
	});

	const handleClickInvoice = () => {
		trackViewInvoice();
		setPrintClicked(true);
	};
	/////////////////////////////////////////////////////////
	return (
		<ItemContainer>
			<FairDate>{convertYMDToMDYYYY(fairData.startDate)}</FairDate>
			<FairInfo>
				<StyledText>Fair ID: {fairData.fairId}</StyledText>
				<StyledText>Host: {fairData.hostName}</StyledText>
			</FairInfo>

			<SalesSection>
				<SectionHeading>Sales</SectionHeading>
				<SectionRow>
					<SectionText>In-School Fair</SectionText>
					<SectionText>
						{formatter.format(fairData.sales.itemized.inSchoolFair)}
					</SectionText>
				</SectionRow>
				<SectionRow>
					<SectionText>Online Shopping</SectionText>
					<SectionText>
						{formatter.format(
							fairData.sales.itemized.onlineShopping
						)}
					</SectionText>
				</SectionRow>
				<SectionTotal>
					<SectionTotalText>Total Sales</SectionTotalText>
					<SectionTotalText>
						{formatter.format(fairData.sales.total)}
					</SectionTotalText>
				</SectionTotal>
			</SalesSection>

			<ProfitSection>
				<SectionHeading>Profit & Rewards</SectionHeading>
				<SectionRow>
					<SectionText>Profit Taken in Cash</SectionText>
					<SectionText>
						{formatter.format(
							fairData.profitAndRewards.itemized.takeInCash
						)}
					</SectionText>
				</SectionRow>
				<SectionRow>
					<SectionText>Scholastic Dollars Profit</SectionText>
					<SectionText>
						{formatter.format(
							fairData.profitAndRewards.itemized
								.scholasticDollarsProfit
						)}
					</SectionText>
				</SectionRow>
				<SectionRow>
					<SectionText>Online Fair Profit</SectionText>
					<SectionText>
						{formatter.format(
							fairData.profitAndRewards.itemized.onlineFairProfit
						)}
					</SectionText>
				</SectionRow>
				<SectionTotal>
					<SectionTotalText>Total Profit & Rewards</SectionTotalText>
					<SectionTotalText>
						{formatter.format(fairData.profitAndRewards.total)}
					</SectionTotalText>
				</SectionTotal>
			</ProfitSection>

			<PrintableSection>
				<PrintSectionRow>
					{fairData.availability.coa && (
						<StyledPrintableComponent
							emailedPage={false}
							buttonText={"certificate of agreement"}
							redTextButton={true}
							fairId={fairData.fairId}
							handleSatelliteCall={handleSatelliteCall}
							schoolId={schoolId}
							salesHistoryPage={true}
						/>
					)}
					{fairData.availability.invoice && (
						<PrintCTA tabIndex={1} handleClick={handleClickInvoice}>
							Invoice
						</PrintCTA>
					)}
				</PrintSectionRow>
				<InvoicePDF>
					<div ref={componentRef}>
						{printClicked && (
							<SalesHistoryInvoice
								handlePrint={handlePrint}
								printClicked={printClicked}
								fairIdSalesHistory={fairData.fairId}
								schoolId={schoolId}
							/>
						)}
					</div>
				</InvoicePDF>
			</PrintableSection>
		</ItemContainer>
	);
};

const ItemContainer = styled.div`
	display: inline-flex;
	padding: 48px 80px;
	flex-direction: column;
	align-items: flex-start;
	gap: 35px;
	border-radius: 16px;
	background: #f5f5f5;
	width: calc(100% - 160px);
	@media (max-width: 375px) {
		padding: 48px 25px;
		width: calc(100% - 50px);
	}
`;

const FairDate = styled(Heading)`
	font-size: 28px;
	font-style: normal;
	font-weight: 300;
	line-height: 32px;
`;

const FairInfo = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	@media (max-width: 500px) {
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
	}
`;
const StyledText = styled(BodyText)`
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
`;
const Section = styled.div`
	width: 100%;
`;

const SectionHeading = styled(Heading)`
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 19px;
	text-align: left;
`;

const SalesSection = styled(Section)``;
const ProfitSection = styled(Section)``;
const PrintableSection = styled(Section)``;

const SectionRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 12px;
	padding-bottom: 12px;
	border-top: 1px solid #d7d7d7;
	@media (max-width: 500px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const SectionText = styled(BodyText)`
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
	text-align: left;
`;
const SectionTotal = styled(SectionRow)`
	border-top: 1px solid #333;
	border-bottom: 1px solid #333;
`;

const PrintSectionRow = styled(SectionRow)`
	border-top: none;
	padding: 12px 0px 13px 0px;
	@media (max-width: 500px) {
		gap: 35px;
		padding: 0px 0px 13px 0px;
	}
`;

const SectionTotalText = styled(SectionText)`
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 19px;
`;
const PrintCTA = styled(TextLink)`
	color: var(--Text-Accent-Red, #e81111);
	font-size: 11px;
	font-style: normal;
	font-weight: 700;
	line-height: 12px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
`;

const StyledPrintableComponent = styled(PrintableComponent)`
	text-decoration: none;
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 12px;
	text-align: center;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #e81111;
	background: none;
	border: none;
	&:hover {
		cursor: pointer;
		color: #b90000;
		text-decoration: underline;
		text-underline-offset: 4px;
	}
	&:focus,
	:focus-visible {
		color: #b90000;
		outline: 2px solid #b90000;
		outline-offset: 2px;
		border-radius: 2px;
	}
	&:active {
		color: #660000;
		outline: none;
	}
	&:disabled {
		color: #333333;
		mix-blend-mode: normal;
		opacity: 0.5;
	}
`;

const InvoicePDF = styled.div`
	display: none;
`;
