import { useState, useCallback } from "react";

const useGoalPageData = () => {
	const [goalAmount, setGoalBooks] = useState<string>("");
	const [bookFairSalesAmount, setBookFairSalesAmount] = useState<string>("");
	const [bookFairGoalCkbox, setBookFairGoalCkbox] = useState<boolean>(true);
	const [goalPurpose, setGoalPurpose] = useState<string>(
		"Please help our school meet our goal and inspire a love of reading in our students. Scholastic will give back a portion of the goal as rewards to our school when you shop."
	);
	const [actualAmount, setActualAmount] = useState<string>("");
	const [actualSales, setActualSales] = useState<string>("");
	const [fairHistory, setFairHistory] = useState<
		{
			historyFairSales: string;
			historyFairDate: string;
			historyFairProductId: string;
		}[]
	>([]);

	// Set the initial data from the /get request
	const setGoalData = useCallback(
		(
			initGoalAmount: string,
			initGoalSales: string,
			initGoalCkbox: string,
			initGoalPurpose: string,
			initActualAmount: string,
			initActualSales: string,
			initFairHistory: {
				historyFairSales: string;
				historyFairDate: string;
				historyFairProductId: string;
			}[]
		) => {
			// Adding commas to each of these fields so they show up initially
			if (initGoalAmount) {
				const formattedGoalBooks = initGoalAmount.replace(
					/\B(?=(\d{3})+(?!\d))/g,
					","
				);
				setGoalBooks(formattedGoalBooks);
			}
			if (initGoalSales) {
				const formattedBookFairSales = initGoalSales.replace(
					/\B(?=(\d{3})+(?!\d))/g,
					","
				);
				setBookFairSalesAmount(formattedBookFairSales);
			}
			if (initGoalCkbox && initGoalCkbox === "N") {
				setBookFairGoalCkbox(false);
			}
			if (initGoalPurpose) {
				setGoalPurpose(initGoalPurpose);
			}
			if (initActualAmount) {
				const formattedActualAmount = initActualAmount.replace(
					/\B(?=(\d{3})+(?!\d))/g,
					","
				);
				setActualAmount(formattedActualAmount);
			}
			if (initActualSales) {
				const formattedActualSales = initActualSales.replace(
					/\B(?=(\d{3})+(?!\d))/g,
					","
				);
				setActualSales(formattedActualSales);
			}
			if (initFairHistory) {
				setFairHistory(initFairHistory);
			}
		},
		[]
	);

	const goalData = {
		goalAmount,
		setGoalBooks,
		bookFairSalesAmount,
		setBookFairSalesAmount,
		bookFairGoalCkbox,
		setBookFairGoalCkbox,
		goalPurpose,
		setGoalPurpose,
		actualAmount,
		setActualAmount,
		actualSales,
		setActualSales,
		fairHistory,
	};

	return { goalData, setGoalData };
};

export default useGoalPageData;
