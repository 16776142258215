import React from "react";
import styled from "styled-components";

interface Props {
	recipientFirstName: string;
	recipientLastName: string;
	teacherLastName: string;
	grade: string;
	balance: number;
}

const BottomRightCard = ({
	recipientFirstName,
	recipientLastName,
	teacherLastName,
	grade,
	balance,
}: Props) => {
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});
	return (
		<Container className="gridItem">
			<RecipientName>
				{recipientFirstName} {recipientLastName}
			</RecipientName>
			<ClassroomDetails>
				<div>{teacherLastName}</div>
				<div>|</div>
				<div>{grade}</div>
			</ClassroomDetails>
			<Balance>{formatter.format(balance)}</Balance>
			<BalanceContainer>
				<BalanceText>Balance</BalanceText>
				<BalanceBox></BalanceBox>
			</BalanceContainer>
		</Container>
	);
};

const Container = styled.div`
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 2;
	grid-row-end: 3;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	border-top: 2px dotted rgba(51, 51, 51, 0.5);
	margin-left: 1px;
`;

const RecipientName = styled.div`
	color: #333;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	margin: 23px 0px 9px;
`;

const ClassroomDetails = styled.div`
	color: #333;
	font-size: 9px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
	margin-bottom: 11px;
	display: flex;
	flex-direction: row;
	gap: 5px;
`;

const Balance = styled.div`
	color: #333;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	margin-bottom: 37px;
`;

const BalanceContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 202px;
	gap: 4px;
`;

const BalanceText = styled.div`
	color: #333;
	font-size: 9px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
`;

const BalanceBox = styled.div`
	width: 100%;
	height: 40px;
	border-radius: 4px;
	border: 1px solid #919191;
`;

export default BottomRightCard;
