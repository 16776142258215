import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";
import { RedButton } from "../../Button";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navigation";
import Heading from "components/Heading";
import Subheading from "components/Subheading";
import { PurchaseOrder } from "./PurchaseOrder/PurchaseOrder";
import { PurchaseOrderType } from "hooks/FinancialForm/useSalesData";
import { FinancialFormTotalsContent } from "../Sales/Totals/FinancialFormTotalsContent";
import {
	FinFormProgress,
	FinFormUrls,
	getFinFormProgress,
	setFinFormCurrentPageLocalStorage,
} from "utils/FinFormLocalStorageUtility";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import validateSalesDataTotals, {
	salesInfoErrors,
	salesInfoErrorsDefault,
} from "./Totals/FinancialFormSalesValidation";
import useSaveChangesFinForm from "hooks/FinancialForm/useSaveChangesFinForm";
import { customParseFloat } from "utils/HubUtility";
import {
	trackAddPurchaseOrder,
	trackDeletePurchaseOrder,
	trackEditPurchaseOrdersButton,
	trackSaveAndContinue,
	updateFinancialFormSalesData,
} from "DumbleData";
import FairSalesTotal from "../FairSalesTotal";
import { roundToPrecision } from "utils/RoundingUtility";

interface Props {
	purchaseOrders: PurchaseOrderType[];
	fairId: string;
	taxRate: string;
	SDRedeemed: string;
	SDTaxExemptSales: string;
	SDTaxCollected: string;
	cashChecks: string;
	creditCards: string;
	taxExemptSales: string;
	defaultTaxExemptSales: string;
	defaultSDTaxExemptSales: string;
	STFFCollected: string;
	STFFRedeemed: string;
	taxExemptSalesEdited: boolean;
	fairSalesTotal: number;
	SDTotal: number;
	SDCashEquivalent: number;
	GCPurchased: string;
	GCRedeemed: string;
	GCUnredeemed: string;
	netSalesDiscounts: string;
	SDTaxableSales: number;
	taxableSalesLessSalesTax: number;
	grossSalesTotal: number;
	taxTotal: number;
	taxableSales: number;
	setPurchaseOrders: any;
	setSDRedeemed: any;
	setSDTaxExemptSales: any;
	setSDTaxCollected: any;
	setCashChecks: any;
	setCreditCards: any;
	setTaxExemptSales: any;
	handleSetTaxExemptSalesDirectly: any;
	setSDTotal: any;
	setSDCashEquivalent: any;
	setSDTaxableSales: any;
	setTaxableSalesLessSalesTax: any;
	setGrossSalesTotal: any;
	setTaxTotal: any;
	setTaxableSales: any;
	setFairSalesTotal: any;
}

export const FinancialFormSalesContent = ({
	fairId,
	taxRate,
	purchaseOrders,
	SDRedeemed,
	SDTaxExemptSales,
	SDTaxCollected,
	cashChecks,
	creditCards,
	taxExemptSales,
	defaultTaxExemptSales,
	defaultSDTaxExemptSales,
	STFFCollected,
	STFFRedeemed,
	GCPurchased,
	GCRedeemed,
	taxExemptSalesEdited,
	fairSalesTotal,
	SDTotal,
	SDCashEquivalent,
	netSalesDiscounts,
	GCUnredeemed,
	SDTaxableSales,
	taxableSalesLessSalesTax,
	grossSalesTotal,
	taxTotal,
	taxableSales,
	setPurchaseOrders,
	setSDRedeemed,
	setSDTaxExemptSales,
	setSDTaxCollected,
	setCashChecks,
	setCreditCards,
	setTaxExemptSales,
	handleSetTaxExemptSalesDirectly,
	setFairSalesTotal,
	setSDTotal,
	setSDCashEquivalent,
	setSDTaxableSales,
	setTaxableSalesLessSalesTax,
	setGrossSalesTotal,
	setTaxTotal,
	setTaxableSales,
}: Props) => {
	const [triggerScrollDown, setTriggerScrollDown] = useState(false);
	const [triggerScrollUp, setTriggerScrollUp] = useState(false);

	const openPOScrollRef = useRef(null);
	const closePOScrollRef = useRef(null);

	const firstRender = useRef(true);

	const { bookfairsAPI } = useConfig();

	const [salesEditing, setSalesEditing] = useState(false);
	const [POEditing, setPOEditing] = useState(false);

	const [salesInfoErrors, setSalesInfoErrors] = useState<salesInfoErrors>(
		salesInfoErrorsDefault
	);

	const navigate = useNavigate();

	// Scrolls the viewport to the purchase order section when you click 'add purchase order' in the totals section
	const scrollToPurchaseOrders = async () => {
		setTimeout(() => {
			if (openPOScrollRef.current) {
				(openPOScrollRef.current as HTMLElement).scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}
		}, 200);
	};

	const scrollToTotals = async () => {
		setTimeout(() => {
			if (closePOScrollRef.current) {
				(closePOScrollRef.current as HTMLElement).scrollIntoView({
					behavior: "smooth",
					block: "end",
				});
			}
		}, 200);
	};

	// Posts sales data to the backend
	const saveSalesData = async () => {
		// Construct payload
		const salesData = {
			scholasticDollars: {
				totalRedeemed: customParseFloat(SDRedeemed),
				taxExemptSales: customParseFloat(SDTaxExemptSales),
			},
			tenderTotals: {
				cashAndChecks: customParseFloat(cashChecks),
				creditCards: customParseFloat(creditCards),
			},
			grossSales: {
				taxExemptSales: customParseFloat(taxExemptSales),
			},
		};
		try {
			// Make PUT request
			const response = await axios.put(
				`${bookfairsAPI}/user/fairs/${fairId}/financials/form/sales`,
				salesData,
				{ withCredentials: true }
			);

			if (response.status !== 200) {
				handleGenericSuccess(response);
				return;
			}
		} catch (err) {
			handleGenericErrors(err);
		}
	};

	// Prepare purchase orders for delivery and submit to backend
	const savePurchaseOrders = async () => {
		const newPurchaseOrders = [];
		// First, convert all amounts to floats before submitting to backend, and remove ids
		for (const purchaseOrder of purchaseOrders) {
			const newOrder = {
				...purchaseOrder,
				amount: customParseFloat(purchaseOrder.amount),
				_id: undefined,
				errors: undefined,
			};
			newPurchaseOrders.push(newOrder);
		}

		// Wrap purchase orders in an object
		const payload = {
			list: newPurchaseOrders,
		};

		try {
			// Submit PUT request
			const response = await axios.put(
				`${bookfairsAPI}/user/fairs/${fairId}/financials/form/purchase-orders`,
				payload,
				{ withCredentials: true }
			);

			// Handle generic success
			if (response.status !== 200) {
				handleGenericSuccess(response);
				return;
			}
		} catch (e) {
			handleGenericErrors(e);
		}
	};

	// Enter purchase orders editing mode
	const handleClickEdit = () => {
		trackEditPurchaseOrdersButton();
		setPOEditing(true);
	};

	const isGreaterThanZero = (input: string) => {
		const number = parseFloat(input);
		return !isNaN(number) && number > 0;
	};

	// Check for any errors with each purchase order. Return true if there are no errors in any purchase order
	const checkForPurchaseOrderErrors = () => {
		const numberCounts = new Map();
		for (const purchaseOrder of purchaseOrders) {
			const { number } = purchaseOrder;
			numberCounts.set(number, (numberCounts.get(number) || 0) + 1);
		}

		let errorsPresent = false;
		for (const purchaseOrder of purchaseOrders) {
			const {
				number,
				amount,
				contactName,
				agencyName,
				address,
				city,
				state,
				zipcode,
				errors,
			} = purchaseOrder;
			errors.number = number === "";
			errors.amount = amount === "";
			errors.greaterThanZero = !isGreaterThanZero(amount);
			errors.contactName = contactName === "";
			errors.agencyName = agencyName === "";
			errors.address = address === "";
			errors.city = city === "";
			errors.zipcode = zipcode.length < 5;
			errors.state = state === "";
			errors.numberNotUnique = numberCounts.get(number) > 1;
			errorsPresent =
				errorsPresent ||
				errors.number ||
				errors.amount ||
				errors.greaterThanZero ||
				errors.contactName ||
				errors.agencyName ||
				errors.address ||
				errors.city ||
				errors.zipcode ||
				errors.state ||
				errors.numberNotUnique;
		}
		// Update purchase orders to reflect any error changes
		updatePurchaseOrders();
		return !errorsPresent;
	};

	// format purchase order input field 'amount' value to currency
	const formatPurchaseOrderAmounts = () => {
		for (const purchaseOrder of purchaseOrders) {
			purchaseOrder.amount = formatInputToDefaultValue(
				purchaseOrder.amount
			);
		}
	};

	// Check for errors and exit out of purchase order editing mode
	const handleClickSavePurchaseOrders = () => {
		const valid = checkForPurchaseOrderErrors();
		// Only exit out of editing if there are no errors
		if (valid) {
			formatPurchaseOrderAmounts();
			setPOEditing(false);
			setTriggerScrollUp(true);
		}
	};

	// Enter totals editing mode
	const handleViewSalesDetails = () => {
		setSalesEditing(true);
	};

	// Calculate total of purchase orders
	let purchaseOrdersTotal = 0;
	for (const purchaseOrder of purchaseOrders) {
		purchaseOrdersTotal += customParseFloat(purchaseOrder.amount);
	}

	// Calculate sum of tenders
	const tenderTotals =
		customParseFloat(cashChecks) +
		customParseFloat(creditCards) +
		purchaseOrdersTotal;

	const floatSDRedeemed = customParseFloat(SDRedeemed);
	const floatTaxExemptSales = customParseFloat(taxExemptSales);
	const floatSTFFCollected = customParseFloat(STFFCollected);
	const floatSTFFRedeemed = customParseFloat(STFFRedeemed);
	const floatSDTaxCollected = customParseFloat(SDTaxCollected);
	const floatTaxRate = parseFloat(taxRate);
	const floatGCPurchased = customParseFloat(GCPurchased);
	const floatGCRedeemed = customParseFloat(GCRedeemed);

	const floatGCUnredeemed = customParseFloat(GCUnredeemed);
	const floatNetSalesDiscounts = customParseFloat(netSalesDiscounts);

	const maxTaxExemptSales =
		tenderTotals +
		floatSDRedeemed * 0.5 -
		(floatGCPurchased - floatGCRedeemed) -
		(floatSTFFCollected - floatSTFFRedeemed);

	// Check for totals errors
	const checkForTotalsErrors = () => {
		const newSalesInfo = {
			SDTaxExemptSales: customParseFloat(SDTaxExemptSales),
			SDRedeemed: customParseFloat(SDRedeemed),
			SDTaxCollected: customParseFloat(SDTaxCollected),
			cashChecks: customParseFloat(cashChecks),
			creditCards: customParseFloat(creditCards),
			purchaseOrdersTotal,
			taxExemptSales: customParseFloat(taxExemptSales),
			STFFCollected: customParseFloat(STFFCollected),
			maxTaxExemptSales: maxTaxExemptSales,
		};
		const newSalesErrors = validateSalesDataTotals(newSalesInfo);
		setSalesInfoErrors(newSalesErrors);
		return !newSalesErrors.generalError;
	};

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	const formatInputToDefaultValue = (input: string) => {
		return formatter.format(customParseFloat(input)).replace("$", "");
	};

	const formatDefaultValues = () => {
		setSDRedeemed(formatInputToDefaultValue(SDRedeemed));
		setSDTaxExemptSales(formatInputToDefaultValue(SDTaxExemptSales));
		setSDTaxCollected(formatInputToDefaultValue(SDTaxCollected));
		setCashChecks(formatInputToDefaultValue(cashChecks));
		setCreditCards(formatInputToDefaultValue(creditCards));
		setTaxExemptSales(formatInputToDefaultValue(taxExemptSales));
	};

	// Check for errors and exit out of totals editing mode
	const handleClickSaveTotals = () => {
		const valid = checkForTotalsErrors();
		if (valid) {
			formatDefaultValues();
			setSalesEditing(false);
		}
	};

	// Removes an order from the list with the matching id
	const handleDeletePurchaseOrder = (_id: string) => {
		trackDeletePurchaseOrder();
		const newPurchaseOrders = purchaseOrders.filter(
			(elem) => elem._id !== _id
		);
		setPurchaseOrders(newPurchaseOrders);
		// If there are no remaining purchase orders, exit out of editing mode
		if (newPurchaseOrders.length === 0) {
			setPOEditing(false);
		}
	};

	// Create an empty purchase order and add it to the list
	const addPurchaseOrder = () => {
		const newPurchaseOrder = {
			_id: uuidv4(),
			number: "",
			amount: "0",
			contactName: "",
			agencyName: "",
			address: "",
			city: "",
			state: "",
			zipcode: "",
			errors: {
				number: false,
				amount: false,
				contactName: false,
				agencyName: false,
				city: false,
				state: false,
				zipcode: false,
			},
		};
		setPurchaseOrders([...purchaseOrders, newPurchaseOrder]);
	};

	// Refreshes the list of purchase orders after one is updated
	const updatePurchaseOrders = () => {
		setPurchaseOrders([...purchaseOrders]);
	};

	// Call PUT API to save sales data and purchase orders
	const saveSalesAndPurchaseOrders = async () => {
		updateDumbleData();
		trackSaveAndContinue();
		if (allChangesSaved) {
			await saveSalesData();
		} else {
			await Promise.all([saveSalesData(), savePurchaseOrders()]);
		}
	};

	const updateDumbleData = () => {
		updateFinancialFormSalesData(
			grossSalesTotal.toString(),
			taxExemptSales,
			taxTotal.toString(),
			SDRedeemed,
			purchaseOrdersTotal.toString()
		);
	};

	// Check errors for both totals and purchase orders, and then save them if both are valid
	const checkAndSaveTotalsAndPurchaseOrders = async () => {
		trackSaveAndContinue();
		const totalsValid = checkForTotalsErrors();
		const purchaseOrdersValid = checkForPurchaseOrderErrors();
		if (totalsValid && purchaseOrdersValid) {
			await saveSalesAndPurchaseOrders();
			setAllChangesSaved(true);
			setAllowNavigation(true);
		}
	};

	// Cache the list of all changeable items, so all changes saved value is not updated on every render, but only if a value was actually changed
	const saveableData = useMemo(() => {
		return [
			purchaseOrders,
			SDRedeemed,
			SDTaxExemptSales,
			cashChecks,
			creditCards,
			taxExemptSales,
		];
	}, [
		purchaseOrders,
		SDRedeemed,
		SDTaxExemptSales,
		cashChecks,
		creditCards,
		taxExemptSales,
	]);
	const {
		generatePromptAndModal,
		saveAndContinue,
		setNavDestination,
		setAllowNavigation,
		setAllChangesSaved,
		allChangesSaved,
	} = useSaveChangesFinForm(
		saveableData,
		checkAndSaveTotalsAndPurchaseOrders,
		fairId,
		FinFormProgress.Sales,
		saveSalesAndPurchaseOrders,
		FinFormUrls.Spending
	);

	// Adds a new purchase order when clicking the button in the totals section
	const addPurchaseOrderFromTotals = () => {
		trackAddPurchaseOrder();
		setPOEditing(true);
		addPurchaseOrder();
		setTriggerScrollDown(true);
	};

	// Live Calculations
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		} else {
			// Calculate gross sales total
			const grossSalesTotal =
				tenderTotals +
				floatSDRedeemed * 0.5 -
				(floatSTFFCollected - floatSTFFRedeemed) -
				(floatGCPurchased - floatGCRedeemed);
			const SDCashEquivalent = floatSDRedeemed * 0.5;
			const SDTotal = tenderTotals + SDCashEquivalent;
			const SDTaxableSales =
				floatSDRedeemed -
				customParseFloat(SDTaxExemptSales) -
				customParseFloat(SDTaxCollected);
			const taxableSales = roundToPrecision(
				tenderTotals +
					floatSDRedeemed * 0.5 -
					floatTaxExemptSales -
					(floatSTFFCollected - floatSTFFRedeemed) -
					(floatGCPurchased - floatGCRedeemed),
				5
			);
			const taxableSalesLessSalesTax = roundToPrecision(
				taxableSales / (1 + floatTaxRate / 100),
				5
			);
			const taxTotal = taxableSales - taxableSalesLessSalesTax;
			const calculatedFairSalesTotal =
				floatTaxExemptSales +
				taxableSalesLessSalesTax -
				(floatSDRedeemed - floatSDTaxCollected) * 0.5;

			setGrossSalesTotal(grossSalesTotal);
			setSDCashEquivalent(SDCashEquivalent);
			setSDTotal(SDTotal);
			setSDTaxableSales(SDTaxableSales);
			setTaxableSales(taxableSales);
			setTaxableSalesLessSalesTax(taxableSalesLessSalesTax);
			setTaxTotal(taxTotal);
			setFairSalesTotal(calculatedFairSalesTotal);
		}
	}, [
		SDRedeemed,
		tenderTotals,
		SDCashEquivalent,
		floatSDRedeemed,
		floatTaxRate,
		taxableSales,
		floatSTFFCollected,
		floatSTFFRedeemed,
		floatGCPurchased,
		floatGCRedeemed,
		floatSDTaxCollected,
		floatTaxExemptSales,
		setSDTaxableSales,
		setFairSalesTotal,
		setTaxableSales,
		setSDCashEquivalent,
		setSDTotal,
		SDTaxExemptSales,
		setTaxableSalesLessSalesTax,
		setGrossSalesTotal,
		SDTaxCollected,
		setTaxTotal,
		taxExemptSales,
		setTaxExemptSales,
		fairSalesTotal,
	]);

	// scroll to last purchase order in list
	useEffect(() => {
		if (POEditing && triggerScrollDown) {
			scrollToPurchaseOrders();
			setTriggerScrollDown(false);
		}
		if (!POEditing && triggerScrollUp) {
			scrollToTotals();
			setTriggerScrollUp(false);
		}
	}, [POEditing, triggerScrollDown, triggerScrollUp]);

	// if user tries to directly access the /sales page without completing previous steps, redirect them to the first incomplete step
	useEffect(() => {
		const progress = getFinFormProgress(fairId);
		if (progress === FinFormProgress.Confirmation) {
			navigate(FinFormUrls.Confirmation);
		} else if (progress < FinFormProgress.Sales) {
			navigate(FinFormProgress.Intro);
		} else {
			setFinFormCurrentPageLocalStorage(fairId, FinFormUrls.Sales);
		}
	}, [navigate, fairId]);

	return (
		<>
			<Navigation
				activeLink={FinFormProgress.Sales}
				fairId={fairId}
				setDestination={setNavDestination}
			></Navigation>
			<FinancialPage>
				<OuterSalesWrapper>
					<StyledHeading>Sales</StyledHeading>
					<StyledSubHeading>
						Please review all totals, make any necessary changes,
						and save your updates.
					</StyledSubHeading>
					<InnerSalesWrapper>
						<FinancialFormTotalsContent
							purchaseOrdersTotal={purchaseOrdersTotal}
							SDRedeemed={SDRedeemed}
							SDTaxExemptSales={SDTaxExemptSales}
							SDTaxCollected={SDTaxCollected}
							cashChecks={cashChecks}
							creditCards={creditCards}
							taxExemptSales={taxExemptSales}
							defaultSDTaxExemptSales={defaultSDTaxExemptSales}
							defaultTaxExemptSales={defaultTaxExemptSales}
							taxExemptSalesEdited={taxExemptSalesEdited}
							salesInfoErrors={salesInfoErrors}
							salesEditing={salesEditing}
							floatTaxExemptSales={floatTaxExemptSales}
							floatSDRedeemed={floatSDRedeemed}
							floatSTFFCollected={floatSTFFCollected}
							floatSTFFRedeemed={floatSTFFRedeemed}
							taxableSales={taxableSales}
							grossSalesTotal={grossSalesTotal}
							taxTotal={taxTotal}
							disableAddPurchaseOrder={purchaseOrders.length > 11}
							SDTotal={SDTotal}
							SDCashEquivalent={SDCashEquivalent}
							GCPurchased={floatGCPurchased}
							GCRedeemed={floatGCRedeemed}
							GCUnredeemed={floatGCUnredeemed}
							netSalesDiscounts={floatNetSalesDiscounts}
							taxableSalesLessSalesTax={taxableSalesLessSalesTax}
							taxRate={floatTaxRate}
							SDTaxableSales={SDTaxableSales}
							setTaxExemptSales={setTaxExemptSales}
							setSDRedeemed={setSDRedeemed}
							setSDTaxExemptSales={setSDTaxExemptSales}
							setSDTaxCollected={setSDTaxCollected}
							setCashChecks={setCashChecks}
							setCreditCards={setCreditCards}
							setEditing={setSalesEditing}
							handleClickAddPurchaseOrder={
								addPurchaseOrderFromTotals
							}
							handleSetTaxExemptSalesDirectly={
								handleSetTaxExemptSalesDirectly
							}
							handleClickSave={handleClickSaveTotals}
							handleViewSalesDetails={handleViewSalesDetails}
						/>
						<FairSalesTotal fairSalesTotal={fairSalesTotal} />
						{purchaseOrders.length > 0 && (
							<PurchaseOrder
								orders={purchaseOrders}
								fairId={fairId}
								editing={POEditing}
								openScrollRef={openPOScrollRef}
								closeScrollRef={closePOScrollRef}
								disableAddPurchaseOrder={
									purchaseOrders.length > 11
								}
								handleClickEdit={handleClickEdit}
								handleDeletePurchaseOrder={
									handleDeletePurchaseOrder
								}
								savePurchaseOrders={savePurchaseOrders}
								updatePurchaseOrders={updatePurchaseOrders}
								handleClickSave={handleClickSavePurchaseOrders}
								handleAddPurchaseOrderClicked={addPurchaseOrder}
							/>
						)}
						<StyledRedButton
							disabled={POEditing || salesEditing}
							handleClick={saveAndContinue}
						>
							Save & Continue
						</StyledRedButton>
					</InnerSalesWrapper>
				</OuterSalesWrapper>
			</FinancialPage>
			{generatePromptAndModal()}
		</>
	);
};

const StyledHeading = styled(Heading)`
	display: flex;
	position: relative;
	font-size: 39px;
	font-style: normal;
	font-weight: 300;
	line-height: 42px;
	padding-bottom: 21px;
`;

const StyledSubHeading = styled(Subheading)`
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	padding-bottom: 11px;
`;

const FinancialPage = styled.div`
	max-width: 100%;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

const OuterSalesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	margin-top: 56px;
	@media (max-width: 700px) {
		width: calc(100% - 40px);
	}
`;

const InnerSalesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	margin-bottom: 56px;
	gap: 33px;
	@media (max-width: 700px) {
		width: 100%;
	}
`;

const StyledRedButton = styled(RedButton)`
	margin-top: 30px;
	height: 40px;
	padding: 9px 30px;
	align-items: center;
	align-self: flex-end;
`;
