import React from "react";
import Checkbox from "components/Checkbox";
import styled from "styled-components";

interface Props {
	label: string;
	onClick: Function;
	isSelectedOption: boolean;
	index: number;
}

const FilterItem = ({ label, onClick, isSelectedOption, index }: Props) => {
	return (
		<>
			{label && label.includes("Label:") ? (
				<>
					{index === 0 ? (
						<TopSectionLabel>{label.substring(6)}</TopSectionLabel>
					) : (
						<SectionLabel>{label.substring(6)}</SectionLabel>
					)}
				</>
			) : (
				<Container>
					<StyledCheckbox
						checked={isSelectedOption}
						label=""
						onChange={(e: any) => {
							onClick();
						}}
						tabIndex={1}
					/>
					<StyledCheckBoxLabel
						onClick={(e: any) => {
							onClick();
						}}
						key={index}
						isSelectedOption={isSelectedOption}
						className={"dropDownElements"}
						id={`dropDown${index}`}
						role="option"
					>
						{label}
					</StyledCheckBoxLabel>
				</Container>
			)}
		</>
	);
};

interface ListProps {
	isSelectedOption?: boolean;
}

const StyledCheckbox = styled(Checkbox)`
	margin: 4px 0px;
`;

const TopSectionLabel = styled.div`
	margin-bottom: 8px;
	font-weight: 400;
	font-size: 14px;
	line-height: 12px;
`;

const SectionLabel = styled.div`
	margin: 24px 0px 8px 0px;
	font-weight: 400;
	font-size: 14px;
	line-height: 12px;
`;

const StyledCheckBoxLabel = styled.div<ListProps>`
	list-style: none;
	width: 100px;
	font-weight: 400;
	font-size: 13px;
	line-height: 15px;
	color: #333333;
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export default FilterItem;
