import React from "react";
import styled from "styled-components/macro";
import LegalCopyToPrint from "./LegalCopy";
import { PrintableComponent } from "components/PrintableComponent";

const PrintCoaPage = () => {
	return (
		<>
			<PrintCoaPageContainer>
				<PrintableComponent emailedPage={true} />
				<LegalCopyToPrint emailedPage={true} />
			</PrintCoaPageContainer>
		</>
	);
};

const PrintCoaPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1in;
`;

export default PrintCoaPage;
