import React from "react";
import styled from "styled-components/macro";
import BodyText from "components/BodyText";

interface Props {
	className?: any;
	checked: boolean;
	onChange: (value: boolean) => void;
	label: string;
}

const ToggleButton = ({ className, checked, onChange, label }: Props) => {
	return (
		<CheckBoxWrapper>
			<InlineElement>
				<StyledLabel>{label}</StyledLabel>
			</InlineElement>
			<InlineElement>
				<Wrapper>
					<CheckBox
						checked={checked}
						onChange={() => onChange(!checked)}
						onKeyPress={() => onChange(!checked)}
						className={className}
						id="checkbox"
						type="checkbox"
					/>

					<CheckBoxToggle htmlFor="checkbox" />
					<StyledBodyText>{checked ? "ON" : "OFF"}</StyledBodyText>
				</Wrapper>
			</InlineElement>
		</CheckBoxWrapper>
	);
};
const CheckBoxWrapper = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
	height: 32px;
	@media (max-width: 530px) {
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const InlineElement = styled.div`
	@media (max-width: 530px) {
		position: relative;
		margin-bottom: 11px;
		max-width: 189px;
		text-align: center;
	}
`;

const StyledBodyText = styled(BodyText)`
	position: absolute;
	top: 40px;
`;
const CheckBoxToggle = styled.label`
	position: absolute;
	top: 0px;
	width: 58.67px;
	height: 32px;
	border-radius: 15px;
	background: #949494;
	cursor: pointer;
	&::after {
		content: "";
		display: block;
		border-radius: 50%;
		width: 26.67px;
		height: 26.67px;
		margin: 3px;
		background: #ffffff;
		box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
		transition: 0.2s;
	}
	@media (max-width: 530px) {
		top: 0;
		left: -1;
	}
`;
const CheckBox = styled.input`
	opacity: 0;
	z-index: 1;
	width: 0;
	height: 0;
	border-radius: 15px;
	margin-left: 47px;
	&:focus-visible + ${CheckBoxToggle} {
		outline: 2px solid #116eee;
		outline-offset: 2px;
	}
	&:checked + ${CheckBoxToggle} {
		background: #116eee;
		&::after {
			content: "";
			display: block;
			border-radius: 50%;
			width: 26.67px;
			height: 26.67px;
			margin-left: 29.33px;
			transition: 0.2s;
		}
	}
`;

const StyledLabel = styled.label`
	font-family: museo-sans;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
	color: #333333;
	font-style: normal;
	font-weight: 400;
	letter-spacing: 0px;
	padding-top: 40px;
	margin-right: 47px;
	@media (max-width: 530px) {
		height: 30px;
		width: 100%;
		padding-bottom: 11px;
		margin-right: 0;
	}
`;

export default ToggleButton;
