import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import LimitedTextArea from "components/LimitedTextArea";
import TextInput from "components/TextInput";
import { validateEmail } from "utils/InputValidations";
import { RedButton, WhiteButton } from "components/Button";
import { AEMModalContainer } from "components/AEMModalContainer";
import { useConfig } from "providers/ConfigContext";
import { handleGenericErrors } from "utils/ErrorUtility";
import { getFairIdFromUrl } from "utils/HubUtility";

interface Props {
	closeModal: () => void;
	openEmailSuccessModal: () => void;
	openEmailFailureModal: () => void;
}

export const EmailAgreementModal = ({
	closeModal,
	openEmailSuccessModal,
	openEmailFailureModal,
}: Props) => {
	const [message, setMessage] = useState("");
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState<string>();
	const { bookfairsAPI } = useConfig();

	const handleSend = async () => {
		const currentFair = getFairIdFromUrl();
		if (email === "") {
			setEmailError("This field cannot be blank.");
		} else if (!validateEmail(email)) {
			setEmailError("Please enter a valid email address.");
		} else {
			setEmailError(undefined);
			try {
				await axios.post(
					bookfairsAPI +
						"/user/fairs/" +
						currentFair +
						"/coa/pdf-links",
					{
						email: email,
						message: message,
					},
					{
						withCredentials: true,
					}
				);
				closeModal();
				window._satellite.track("email-fair-send", {
					"button-text": "email-fair-send",
				});
				openEmailSuccessModal();
			} catch (e) {
				handleGenericErrors(e);
				closeModal();
				openEmailFailureModal();
			}
		}
	};

	return (
		<AEMModalContainer closeModal={closeModal}>
			<StyledContent>
				<StyledHeadSec>
					<StyledHeading>Forward Details</StyledHeading>
				</StyledHeadSec>
				<StyledHeadText>
					If others need to confirm these details, add their email
					address below and a copy will be sent to them on your
					behalf.
				</StyledHeadText>
				<RecipientEmailSec>
					<TextInput
						label="Recipient Email"
						value={email}
						onChange={setEmail}
						error={emailError}
					/>
				</RecipientEmailSec>
				<AdditionalDetailsSec>
					<LimitedTextArea
						label="Your Message"
						value={message}
						onChange={setMessage}
						limit={200}
					/>
				</AdditionalDetailsSec>
				<ButtonContainer>
					<StyledWhiteButton handleClick={closeModal}>
						Cancel
					</StyledWhiteButton>
					<StyledRedButton handleClick={handleSend}>
						Send
					</StyledRedButton>
				</ButtonContainer>
			</StyledContent>
		</AEMModalContainer>
	);
};

const StyledHeading = styled.p`
	font-size: 16px;
	line-height: 19px;
	font-weight: 600;
	padding-top: 40px;
`;

const StyledHeadText = styled.p`
	font-size: 17px;
	line-height: 25px;
	font-weight: 300;
	margin-bottom: 30px;
`;

const StyledContent = styled.div`
	background-color: white;
	max-width: 600px;
	padding: 24px;
	box-sizing: border-box;
	@media (max-width: 719px) {
		width: 100%;
		height: 100%;
	}
`;

const StyledHeadSec = styled.div`
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 16px;
	@media (max-width: 719px) {
		margin-bottom: 0px;
	}
`;

const AdditionalDetailsSec = styled.div`
	margin-bottom: 50px;
`;

const RecipientEmailSec = styled.div`
	margin-bottom: 50px;
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 130px;
	height: 40px;
	border: 1px solid #e81111;
`;

const StyledRedButton = styled(RedButton)`
	width: 130px;
	height: 40px;
	border-radius: 100px;
`;

const ButtonContainer = styled.div`
	gap: 24px;
	display: flex;
	justify-content: flex-end;
	@media (max-width: 500px) {
		width: 100%;
	}
`;
