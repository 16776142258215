import { useState, useEffect } from "react";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import {
	updateFairData,
	updateEwalletData,
	updateOnlineShoppingData,
} from "DumbleData";
import {
	handleCOASuccess,
	handleGenericErrors,
	handleGenericSuccess,
} from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

export type FairInfo = {
	fairId: string;
	name: string;
	organizationUcn: string;
	startDate: string;
	endDate: string;
	deliveryDate: string;
	pickupDate: string;
	deliveryUnavailableDates: string;
	pickupUnavailableDates: string;
	fairType: string;
	fairUrl: string;
	bookfairAccountId: string;
	taxRate: number;
	taxStatus: string;
	productId: string;
};

export type EWallet = {
	sales: string;
	unspentFunds: string;
	startDate: string;
	endDate: string;
	enabled: boolean;
};

export type OrgStfBalance = {
	balance: string | null;
	availableAmount: string | null;
	reservedAmount: string | null;
};

export type OnlineFair = {
	sales: string;
	startDate: string;
	endDate: string;
	enabled: boolean;
};

export type Consultant = {
	firstName: string;
	lastName: string;
	email: string;
};

export type CoChair = {
	firstName: string;
	lastName: string;
	email: string;
	salesforceId: string;
};

const fairInfoDefault = {
	fairId: "",
	name: "",
	organizationUcn: "",
	startDate: "",
	endDate: "",
	deliveryDate: "",
	pickupDate: "",
	deliveryUnavailableDates: "",
	pickupUnavailableDates: "",
	fairType: "",
	fairUrl: "",
	bookfairAccountId: "",
	taxRate: 0,
	taxStatus: "",
	productId: "",
};

const eWalletDefault = {
	sales: "",
	unspentFunds: "",
	startDate: "",
	endDate: "",
	enabled: false,
};

const orgStfBalanceDefault = {
	balance: null,
	availableAmount: null,
	reservedAmount: null,
};

const onlineFairDefault = {
	sales: "",
	startDate: "",
	endDate: "",
	enabled: false,
};

const consultantDefault = {
	firstName: "",
	lastName: "",
	email: "",
};

const useFairDetailsPageData = () => {
	const { bookfairsAPI } = useConfig();

	const [fairInfo, setFairInfo] = useState<FairInfo>(fairInfoDefault);
	const [eWallet, setEWallet] = useState<EWallet>(eWalletDefault);
	const [onlineFair, setOnlineFair] = useState<OnlineFair>(onlineFairDefault);
	const [orgStfBalance, setOrgStfBalance] = useState(orgStfBalanceDefault);
	const [consultant, setConsultant] = useState<Consultant>(consultantDefault);
	const [loggedInEmail, setLoggedInEmail] = useState<string>("");
	const [iamUcn, setIamUcn] = useState<string>("");
	const [coChairs, setCoChairs] = useState<Array<CoChair>>();

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const getFairSettingsPageData = async () => {
			const currentFair = getFairIdFromUrl();
			if (checkSpsUd()) {
				try {
					await axios
						.get(
							`${bookfairsAPI}/private/fairs/${currentFair}/settings`,
							{
								withCredentials: true,
							}
						)
						.then((response) => {
							if (response.status === 200) {
								const {
									fairInfo,
									ewallet,
									orgStfBalance,
									onlineFair,
									loggedInEmail,
									consultant,
									iamUcn,
								} = response.data;

								if (fairInfo) {
									setFairInfo(fairInfo);
									updateFairData(
										fairInfo.fairId,
										fairInfo.fairType
									);
								}
								if (ewallet) {
									setEWallet(ewallet);
									updateEwalletData(
										ewallet.sales,
										ewallet.unspentFunds
									);
								}
								if (orgStfBalance) {
									setOrgStfBalance(orgStfBalance);
								}

								if (onlineFair) {
									setOnlineFair(onlineFair);
									updateOnlineShoppingData(onlineFair.sales);
								}
								if (consultant) {
									setConsultant(consultant);
								}
								if (loggedInEmail) {
									setLoggedInEmail(loggedInEmail);
								}
								if (iamUcn) {
									setIamUcn(iamUcn);
								}
								if (response.data["co-chairs"]) {
									setCoChairs(response.data["co-chairs"]);
								}
							} else {
								if (!handleCOASuccess(response)) {
									handleGenericSuccess(response);
								}
							}
						})
						.catch((err) => {
							setIsError(true);
							handleGenericErrors(err);
						});
				} catch (err: any) {
					setIsError(true);
					handleGenericErrors(err);
				} finally {
					setIsLoading(false);
				}
			}
		};
		getFairSettingsPageData();
	}, [
		bookfairsAPI,
		setFairInfo,
		setEWallet,
		setOnlineFair,
		setOrgStfBalance,
		setConsultant,
		setCoChairs,
		setLoggedInEmail,
		setIamUcn,
	]);

	return {
		fairInfo,
		eWallet,
		orgStfBalance,
		onlineFair,
		consultant,
		loggedInEmail,
		iamUcn,
		coChairs,
		isLoading,
		isError,
	};
};

export default useFairDetailsPageData;
