import React from "react";
import "react-calendar/dist/Calendar.css";
import "styles/calendar.css";
import BlackoutCalendarSingleMonth from "./BlackoutCalendarSingleMonth";

interface Props {
	dateRangePickup: Date[];
	dateRangeDelivery: Date[];
	calendarEnabled: boolean;
	handleSelectDate: (
		date: Date,
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => void;
	tileDisabled: ({ date, view }: { date: Date; view: string }) => boolean;
	getClassNames: ({
		date,
		view,
	}: {
		date: Date;
		view: string;
	}) => string[] | string | null;
	activeStartDate: Date;
	setActiveStartDate: React.Dispatch<React.SetStateAction<Date>>;
}

const BlackoutCalendarMultiMonth = ({
	dateRangePickup,
	dateRangeDelivery,
	calendarEnabled,
	handleSelectDate,
	tileDisabled,
	getClassNames,
	activeStartDate,
	setActiveStartDate,
}: Props) => {
	const viewNumber = calculateNumberOfMonths();

	const monthYearLabel = (date: Date) => {
		const months = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		return `${months[date.getMonth()]} ${date.getFullYear()}`;
	};

	function calculateNumberOfMonths() {
		const startDate = new Date(dateRangeDelivery[0]);
		const endDate = new Date(dateRangePickup[1]);

		const startYear = startDate.getFullYear();
		const endYear = endDate.getFullYear();
		const startMonth = startDate.getMonth();
		const endMonth = endDate.getMonth();

		return (endYear - startYear) * 12 + (endMonth - startMonth) + 1;
	}

	// Calender ranges if 1 month displayed
	// Calendar 1: first day of the delivery start month -> last day of the pickup end month

	// delivery start month = the month that dateRangeDelivery[0] is in
	// pickup end month = the month that dateRangePickup[1] is in

	// if 2 months displayed
	// Calendar 1: first day of the delivery start month -> last day of the delivery start month
	// Calendar 2: first day of the pickup end month -> last day of the pickup end month

	// if 3 months displayed:
	// Calendar 1: first day of the delivery start month -> last day of the delivery start month
	// Calendar 2: first day of the pickup start month -> last day of the pickup start month
	// Calendar 3: first day of the pickup end month -> last day of the pickup end month

	return (
		<div>
			<div className="react-calendar-sec">
				<div className="react-calendar-month">
					{monthYearLabel(dateRangeDelivery[0])}
				</div>
				{viewNumber === 1 && (
					<BlackoutCalendarSingleMonth
						calendarEnabled={calendarEnabled}
						value={dateRangePickup[0]}
						minDate={
							new Date(
								dateRangeDelivery[0].getFullYear(),
								dateRangeDelivery[0].getMonth(),
								1
							)
						}
						maxDate={
							new Date(
								dateRangePickup[1].getFullYear(),
								dateRangePickup[1].getMonth() + 1,
								0
							)
						}
						tileDisabled={tileDisabled}
						getClassNames={getClassNames}
						handleSelectDate={handleSelectDate}
						activeStartDate={activeStartDate}
						setActiveStartDate={setActiveStartDate}
						activeStartDateChange={
							new Date(
								dateRangeDelivery[0].getFullYear(),
								dateRangeDelivery[0].getMonth(),
								1
							)
						}
					/>
				)}
				{viewNumber === 2 && (
					<>
						<BlackoutCalendarSingleMonth
							calendarEnabled={calendarEnabled}
							value={dateRangeDelivery[0]}
							minDate={
								new Date(
									dateRangeDelivery[0].getFullYear(),
									dateRangeDelivery[0].getMonth(),
									1
								)
							}
							maxDate={
								new Date(
									dateRangeDelivery[0].getFullYear(),
									dateRangeDelivery[0].getMonth() + 1,
									0
								)
							}
							tileDisabled={tileDisabled}
							getClassNames={getClassNames}
							handleSelectDate={handleSelectDate}
							activeStartDate={activeStartDate}
							setActiveStartDate={setActiveStartDate}
							activeStartDateChange={
								new Date(
									dateRangeDelivery[0].getFullYear(),
									dateRangeDelivery[0].getMonth(),
									1
								)
							}
						/>
						<div className="react-calendar-month">
							{monthYearLabel(dateRangePickup[1])}
						</div>
						<BlackoutCalendarSingleMonth
							calendarEnabled={calendarEnabled}
							value={dateRangePickup[0]}
							minDate={
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth(),
									1
								)
							}
							maxDate={
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth() + 1,
									0
								)
							}
							tileDisabled={tileDisabled}
							getClassNames={getClassNames}
							handleSelectDate={handleSelectDate}
							activeStartDate={dateRangePickup[1]}
							setActiveStartDate={setActiveStartDate}
							activeStartDateChange={
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth(),
									1
								)
							}
						/>
					</>
				)}
				{viewNumber === 3 && (
					<>
						<BlackoutCalendarSingleMonth
							calendarEnabled={calendarEnabled}
							minDate={
								new Date(
									dateRangeDelivery[0].getFullYear(),
									dateRangeDelivery[0].getMonth(),
									1
								)
							}
							maxDate={
								new Date(
									dateRangeDelivery[0].getFullYear(),
									dateRangeDelivery[0].getMonth() + 1,
									0
								)
							}
							tileDisabled={tileDisabled}
							getClassNames={getClassNames}
							handleSelectDate={handleSelectDate}
							activeStartDate={activeStartDate}
							setActiveStartDate={setActiveStartDate}
							activeStartDateChange={
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth() - 1,
									0
								)
							}
						/>
						<div className="react-calendar-month">
							{monthYearLabel(
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth() - 1
								)
							)}
						</div>
						<BlackoutCalendarSingleMonth
							calendarEnabled={calendarEnabled}
							minDate={
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth() - 1,
									1
								)
							}
							maxDate={
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth(),
									0
								)
							}
							tileDisabled={tileDisabled}
							getClassNames={getClassNames}
							handleSelectDate={handleSelectDate}
							activeStartDate={
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth() - 1,
									1
								)
							}
							setActiveStartDate={setActiveStartDate}
							activeStartDateChange={
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth(),
									0
								)
							}
						/>
						<div className="react-calendar-month">
							{monthYearLabel(dateRangePickup[1])}
						</div>
						<BlackoutCalendarSingleMonth
							calendarEnabled={calendarEnabled}
							minDate={
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth(),
									1
								)
							}
							maxDate={
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth() + 1,
									0
								)
							}
							tileDisabled={tileDisabled}
							getClassNames={getClassNames}
							handleSelectDate={handleSelectDate}
							activeStartDate={dateRangePickup[1]}
							setActiveStartDate={setActiveStartDate}
							activeStartDateChange={
								new Date(
									dateRangePickup[1].getFullYear(),
									dateRangePickup[1].getMonth(),
									1
								)
							}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default BlackoutCalendarMultiMonth;
