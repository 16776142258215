import React from "react";
import styled from "styled-components/macro";

interface Props {
	children: React.ReactNode;
	className?: string;
}

const Heading = ({ children, className }: Props) => {
	return <StyledHeading className={className}>{children}</StyledHeading>;
};

const StyledHeading = styled.p`
	font-family: museo-sans;
	font-style: normal;
	font-weight: 300;
	font-size: 39px;
	line-height: 42px;
	color: #333333;
	display: block;
	padding-bottom: 12px;
	text-align: center;
	width: auto;
	@media (max-width: 719px) {
		font-size: 36px;
		line-height: 39px;
		padding-bottom: 14px;
	}
	@media (max-width: 374px) {
		font-size: 32px;
		line-height: 35px;
		padding-bottom: 10px;
	}
`;

export default Heading;
