import React, { useState } from "react";
import styled from "styled-components";
import { RedButton, WhiteButton } from "components/Button";
import { PurchaseOrderType } from "hooks/FinancialForm/useSalesData";
import { PurchaseOrderItem } from "./PurchaseOrderItem";
import { trackSavePurchaseOrderButton } from "DumbleData";

interface Props {
	orders: Array<PurchaseOrderType>;
	handleClickEdit: (e: any) => any;
	handleClickSave: (e: any) => any;
	handleDeletePurchaseOrder: Function;
	handleAddPurchaseOrderClicked: Function;
	updatePurchaseOrders: Function;
	editing: boolean;
	fairId: string;
	savePurchaseOrders: any;
	openScrollRef: any;
	closeScrollRef: any;
	disableAddPurchaseOrder: boolean;
}

export const PurchaseOrder = ({
	orders,
	handleClickEdit,
	handleClickSave,
	handleDeletePurchaseOrder,
	handleAddPurchaseOrderClicked,
	updatePurchaseOrders,
	editing,
	openScrollRef,
	closeScrollRef,
	disableAddPurchaseOrder,
}: Props) => {
	const lastOrderIndex = orders.length - 1;

	const [purchaseOrderCount, setPurchaseOrderCount] = useState(3);

	const moreThanThreePOs = orders.length > 3;

	const handleClickLoadMore = () => {
		setPurchaseOrderCount(purchaseOrderCount + 3);
	};

	return (
		<>
			<StyledBox>
				<HeadingContainer>
					<BoxHeading ref={closeScrollRef}>
						Purchase Orders
					</BoxHeading>
					{!editing && (
						<EditButton
							whiteBackground={true}
							handleClick={handleClickEdit}
						>
							Edit
						</EditButton>
					)}
				</HeadingContainer>
				{orders.map((order, index) => {
					return (
						<>
							<PurchaseOrderItem
								key={order._id}
								openScrollRef={
									index === lastOrderIndex
										? openScrollRef
										: null
								}
								editing={editing}
								order={order}
								updatePurchaseOrders={updatePurchaseOrders}
								handleDelete={handleDeletePurchaseOrder}
								handleAddPurchaseOrderClicked={
									handleAddPurchaseOrderClicked
								}
								lastPurchaseOrder={index === orders.length - 1}
								lastLoadedPurchaseOrder={
									index === purchaseOrderCount - 1
								}
								moreThanThreePOs={moreThanThreePOs}
								poWithinLoadMoreIndex={
									index < purchaseOrderCount
								}
								disableAddPurchaseOrder={
									disableAddPurchaseOrder
								}
							></PurchaseOrderItem>
						</>
					);
				})}
				{editing && (
					<SaveButtonContainer>
						<SaveButton
							handleClick={(e) => {
								trackSavePurchaseOrderButton();
								handleClickSave(e);
							}}
						>
							Save
						</SaveButton>
					</SaveButtonContainer>
				)}
				{!editing && orders.length > purchaseOrderCount && (
					<LoadMoreButtonContainer>
						<LoadMoreButton
							handleClick={handleClickLoadMore}
							whiteBackground={true}
						>
							Load More
						</LoadMoreButton>
					</LoadMoreButtonContainer>
				)}
			</StyledBox>
		</>
	);
};

const HeadingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 500px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
	}
`;

const SaveButton = styled(RedButton)`
	width: 120px;
`;

const SaveButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const LoadMoreButton = styled(WhiteButton)`
	width: 120px;
`;

const LoadMoreButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const EditButton = styled(WhiteButton)``;

const BoxHeading = styled.div`
	font-size: 28px;
	font-weight: 300;
	line-height: 32px;
	letter-spacing: 0px;
	text-align: left;
`;

const StyledBox = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	padding: 48px 80px;
	background-color: #f5f5f5;
	color: #333333;
	box-sizing: border-box;
	gap: 35px;
	@media (max-width: 700px) {
		padding: 48px 24px;
	}
`;
