import React from "react";
import styled from "styled-components/macro";
import { SalesHistoryItem } from "./SalesHistoryItem";
import Heading from "components/Heading";
import { SalesHistory } from "hooks/SalesHistory/useSalesHistory";
import { updateSalesHistoryData } from "DumbleData";

interface Props {
	salesHistoryData: SalesHistory;
	schoolId: string;
}

export const SalesHistoryContent = ({ salesHistoryData, schoolId }: Props) => {
	const updateDumbleData = () => {
		const tempArray: any = [];
		let lifetimeSales = 0;

		salesHistoryData.pastFairs.forEach((fair) => {
			tempArray.push([
				fair.fairId,
				fair.sales.itemized.inSchoolFair,
				fair.sales.itemized.onlineShopping,
				fair.profitAndRewards.itemized.takeInCash,
				fair.profitAndRewards.itemized.scholasticDollarsProfit,
				fair.sales.total,
				fair.profitAndRewards.total,
			]);
			lifetimeSales += fair.sales.total;
		});

		updateSalesHistoryData(
			lifetimeSales.toString(),
			salesHistoryData.pastFairs.length.toString(),
			tempArray
		);
	};

	updateDumbleData();

	return (
		<SalesHistoryPageContainer>
			<StyledHeading>Past Fairs</StyledHeading>
			<FairHistory>
				{salesHistoryData.pastFairs.map((fair, index) => {
					return (
						<>
							<SalesHistoryItem
								fairData={fair}
								key={index}
								schoolId={schoolId}
							/>
						</>
					);
				})}
			</FairHistory>
		</SalesHistoryPageContainer>
	);
};

const StyledHeading = styled(Heading)`
	font-size: 39px;
	font-style: normal;
	font-weight: 400;
	line-height: 42px;
	text-align: left;
	margin-bottom: 40px;
	width: 600px;
	@media (max-width: 700px) {
		width: calc(100% - 80px);
	}
	@media (max-width: 500px) {
		font-size: 32px;
		font-style: normal;
		font-weight: 300;
		line-height: 35px;
	}
`;

const SalesHistoryPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	border-radius: 16px;
	padding: 45px 340px;
	align-items: center;
	@media (max-width: 720px) {
		padding: 43px 64px;
		width: 100%;
	}
	@media (max-width: 500px) {
		padding: 70px 20px;
	}
`;

const FairHistory = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 100%;
	gap: 24px;
	align-items: center;
	@media (max-width: 700px) {
		width: calc(100% - 80px);
		gap: 28px;
	}
	@media (max-width: 375px) {
		width: calc(100% - 50px);
	}
`;
