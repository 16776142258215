// Sorting functions, sortedBy controls up/down arrow styling

import { EWallet } from "hooks/EWalletRoster/useEWalletRosterData";

const sortGradeAlphabetize = (sortedData: Array<EWallet>) => {
	const tempSort = sortedData;
	tempSort.sort((a, b) => {
		const x = a.attributes.grade.toUpperCase();
		const y = b.attributes.grade.toUpperCase();
		if (x > y) {
			return 1;
		}
		if (x < y) {
			return -1;
		}
		return 0;
	});
	return tempSort;
};

const sortGradeAscend = (sortedData: Array<EWallet>) => {
	let tempSort = sortedData;
	let letterGrades = tempSort.filter(
		(ewallet) => ewallet.attributes.grade.length === 3
	);
	let alphabeticalGrades = tempSort.filter(
		(ewallet) => ewallet.attributes.grade.length !== 3
	);
	letterGrades = sortGradeAlphabetize(letterGrades);
	alphabeticalGrades = sortGradeAlphabetize(alphabeticalGrades).reverse();
	tempSort = alphabeticalGrades.concat(letterGrades);
	return tempSort;
};

const sortGradeDescend = (sortedData: Array<EWallet>) => {
	let tempSort = sortedData;
	let letterGrades = tempSort.filter(
		(ewallet) => ewallet.attributes.grade.length === 3
	);
	let alphabeticalGrades = tempSort.filter(
		(ewallet) => ewallet.attributes.grade.length !== 3
	);
	letterGrades = sortGradeAlphabetize(letterGrades).reverse();
	alphabeticalGrades = sortGradeAlphabetize(alphabeticalGrades);
	tempSort = letterGrades.concat(alphabeticalGrades);
	return tempSort;
};

const sortClassroomAscend = (sortedData: Array<EWallet>) => {
	const tempSort = sortedData;
	tempSort.sort((a, b) => {
		const x = a.attributes.teacherLastName.toUpperCase();
		const y = b.attributes.teacherLastName.toUpperCase();
		if (x < y) {
			return -1;
		}
		if (x > y) {
			return 1;
		}
		return 0;
	});
	return tempSort;
};

const sortClassroomDescend = (sortedData: Array<EWallet>) => {
	const tempSort = sortedData;
	tempSort.sort((a, b) => {
		const x = a.attributes.teacherLastName.toUpperCase();
		const y = b.attributes.teacherLastName.toUpperCase();
		if (x > y) {
			return -1;
		}
		if (x < y) {
			return 1;
		}
		return 0;
	});
	return tempSort;
};

const sortRecipientAscend = (sortedData: Array<EWallet>) => {
	const tempSort = sortedData;
	tempSort.sort((a, b) => {
		const x =
			a.attributes.recipientLastName.toUpperCase() +
			a.attributes.recipientFirstName.toUpperCase();
		const y =
			b.attributes.recipientLastName.toUpperCase() +
			b.attributes.recipientFirstName.toUpperCase();
		if (x < y) {
			return -1;
		}
		if (x > y) {
			return 1;
		}
		return 0;
	});
	return tempSort;
};

const sortRecipientDescend = (sortedData: Array<EWallet>) => {
	const tempSort = sortedData;
	tempSort.sort((a, b) => {
		const x =
			a.attributes.recipientLastName.toUpperCase() +
			a.attributes.recipientFirstName.toUpperCase();
		const y =
			b.attributes.recipientLastName.toUpperCase() +
			b.attributes.recipientFirstName.toUpperCase();
		if (x > y) {
			return -1;
		}
		if (x < y) {
			return 1;
		}
		return 0;
	});
	return tempSort;
};

const sortTypeAscend = (sortedData: Array<EWallet>) => {
	const tempSort = sortedData;
	tempSort.sort((a, b) => {
		const x = a.recipientType.toUpperCase();
		const y = b.recipientType.toUpperCase();
		if (x < y) {
			return -1;
		}
		if (x > y) {
			return 1;
		}
		return 0;
	});
	return tempSort;
};

const sortTypeDescend = (sortedData: Array<EWallet>) => {
	const tempSort = sortedData;
	tempSort.sort((a, b) => {
		const x = a.recipientType.toUpperCase();
		const y = b.recipientType.toUpperCase();
		if (x > y) {
			return -1;
		}
		if (x < y) {
			return 1;
		}
		return 0;
	});
	return tempSort;
};

const sortBalanceAscend = (sortedData: Array<EWallet>) => {
	const tempSort = sortedData;
	tempSort.sort((a, b) => {
		const x = a.balance;
		const y = b.balance;
		if (x < y) {
			return 1;
		}
		if (x > y) {
			return -1;
		}
		return 0;
	});
	return tempSort;
};

const sortBalanceDescend = (sortedData: Array<EWallet>) => {
	const tempSort = sortedData;
	tempSort.sort((a, b) => {
		const x = a.balance;
		const y = b.balance;
		if (x > y) {
			return 1;
		}
		if (x < y) {
			return -1;
		}
		return 0;
	});
	return tempSort;
};

export {
	sortGradeAscend,
	sortGradeDescend,
	sortClassroomAscend,
	sortClassroomDescend,
	sortRecipientAscend,
	sortRecipientDescend,
	sortTypeAscend,
	sortTypeDescend,
	sortBalanceAscend,
	sortBalanceDescend,
};
