import IframeContainer from "components/IframeContainer";
import { FinancialFormReviewContent } from "./FinancialFormReviewContent";
import FairInfoBar from "components/FairInfoBar";
import useReviewPageData from "hooks/FinancialForm/useReviewPage";

export const FinancialFormReview = () => {
	const {
		fairId,
		grossSales,
		taxExemptSales,
		taxTotal,
		scholasticDollarsRedeemed,
		scholasticDollarBalance,
		appliedScholasticDollars,
		fairEarningsScholasticDollarsDue,
		fairSpendingScholasticDollarsDue,
		scholasticDollarsEarned,
		profitSelected,
		cashProfitSelected,
		earningsDollarFairLevel,
		fairSalesTotal,
		isLoading,
	} = useReviewPageData();

	return (
		<IframeContainer
			renderItems={() => (
				<>
					{!isLoading && (
						<>
							<FairInfoBar />
							<FinancialFormReviewContent
								fairId={fairId}
								grossSales={grossSales}
								taxExemptSales={taxExemptSales}
								taxTotal={taxTotal}
								scholasticDollarsRedeemed={
									scholasticDollarsRedeemed
								}
								scholasticDollarBalance={
									scholasticDollarBalance
								}
								appliedScholasticDollars={
									appliedScholasticDollars
								}
								fairEarningsScholasticDollarsDue={
									fairEarningsScholasticDollarsDue
								}
								fairSpendingScholasticDollarsDue={
									fairSpendingScholasticDollarsDue
								}
								scholasticDollarsEarned={
									scholasticDollarsEarned
								}
								profitSelected={profitSelected}
								cashProfitSelected={cashProfitSelected}
								earningsDollarFairLevel={
									earningsDollarFairLevel
								}
								fairSalesTotal={fairSalesTotal}
							/>
						</>
					)}
				</>
			)}
		/>
	);
};
