import styled from "styled-components/macro";

export const Grid = styled.div`
	display: grid;
	margin: 0 auto;
	grid-template-columns: repeat(23, 1fr);
	width: -webkit-fill-available;
	width: -moz-available;
	max-width: 1240px;

	/* IE friendly.....*/
	display: -ms-grid;
	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
		1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

	@media (max-width: 1599px) {
		.grid-layout {
			margin: 0px 180px;
		}
	}

	@media (max-width: 1279px) {
		.grid-layout {
			margin: 0px 140px;
		}
	}

	@media (max-width: 959px) {
		.grid-layout {
			margin: 0px 152px;
		}
	}

	@media (max-width: 719px) {
		.grid-layout {
			max-width: 480px;
			margin: 0 auto;
		}
	}

	@media (max-width: 519px) {
		.grid-layout {
			margin: 0px 16px;
		}
	}
`;

interface RowProps {
	start?: number;
	end?: number;
}

export const Row = styled.div<RowProps>`
	grid-column: ${(props) => props.start + "/" + props.end};
	display: inline-flex;
`;

interface ColProps {
	start?: number;
	end?: number;
}

export const Col = styled.div<ColProps>`
	grid-row: ${(props) => props.start + "/" + props.end};
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: -webkit-fill-available;
`;
