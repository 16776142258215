import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import SearchBar from "components/FairFinder/SearchBar";
import SearchResults from "components/FairFinder/SearchResults";
import { ReactComponent as BlueWave } from "images/BlueWave.svg";
import { useConfig } from "providers/ConfigContext";
import { trackFairFinderSearch } from "DumbleData";

export type FairInfo = {
	fairId: string;
	name: string;
	fairUrl: string;
	address: string;
	city: string;
	state: string;
	zipcode: string;
	fairStartDate: string;
	fairEndDate: string;
};

export const FairFinderContent = () => {
	const { fairFinderAPI } = useConfig();
	const [totalCount, setTotalCount] = useState<number>(-1);
	const [fairs, setFairs] = useState<FairInfo[]>([]);
	const [searchQuery, setSearchQuery] = useState<string>();

	const onSearchFormSubmit = function (searchQuery: string) {
		fetchFairs(searchQuery);
	};

	const fetchFairs = async (searchQuery: string) => {
		try {
			const response = await axios.get(
				`${fairFinderAPI}find?searchQuery=${searchQuery}&offset=0&size=20`
			);
			setTotalCount(response.data.totalCount);
			setFairs(response.data.fairs);
			setSearchQuery(searchQuery);
			trackFairFinderSearch(response.data.totalCount);
		} catch (err: any) {
			console.error(err);
		}
	};

	const loadMoreFairs = async (offset: number) => {
		try {
			const response = await axios.get(
				`${fairFinderAPI}find?searchQuery=${searchQuery}&offset=${offset}&size=20`
			);
			setFairs([...fairs, ...response.data.fairs]);
		} catch (err: any) {
			console.error(err);
		}
	};

	return (
		<>
			<LightBlueContainer>
				<SearchBar onSearchFormSubmit={onSearchFormSubmit} />
			</LightBlueContainer>
			<WaveContainer>
				<StyledWave />
			</WaveContainer>
			{totalCount > -1 && (
				<SearchResults
					totalCount={totalCount}
					fairs={fairs}
					loadMoreFairs={loadMoreFairs}
				/>
			)}
		</>
	);
};

const LightBlueContainer = styled.div`
	background-color: #d7f9fa;
`;

const StyledWave = styled(BlueWave)`
	width: 100%;
	padding-bottom: 28px;
	height: 100%;
	@media (max-width: 850px) {
		width: 200%;
	}
	@media (max-width: 500px) {
		width: 400%;
	}
`;

const WaveContainer = styled.div`
	width: 100%;
	overflow: hidden;
`;
