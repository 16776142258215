import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Heading from "components/Heading";
import { RedButton } from "components/Button";
import { IconList } from "components/HomepageBuilder/IconList";
import BodyText from "components/BodyText";
import { Page } from "components/HomepageBuilder/Page";
import IframeContainer from "components/IframeContainer";

export const LandingPage = () => {
	const navigate = useNavigate();
	return (
		<IframeContainer
			renderItems={() => (
				<StyledLandingPage>
					<StyledHeading>
						Welcome to our new Homepage Builder
					</StyledHeading>
					<StyledBodyText>
						We've updated our Hompage Builder that makes it easier
						for you to customize.
					</StyledBodyText>
					<IconList />
					<StyledRedButton
						handleClick={() =>
							navigate("/reactapp/customize/contact")
						}
					>
						Customize Homepage
					</StyledRedButton>
				</StyledLandingPage>
			)}
		/>
	);
};

const StyledHeading = styled(Heading)`
	margin-top: 46px;
	@media (max-width: 979px) {
		margin-top: 40px;
		max-width: 449px;
	}
	@media (max-width: 719px) {
		margin-top: 72px;
	}
`;
const StyledBodyText = styled(BodyText)`
	width: 268.64px;
	height: 32px;
`;
const StyledLandingPage = styled(Page)`
	padding-bottom: 106px;
	@media (max-width: 959px) {
		padding-top: 0px;
	}
	@media (max-width: 375px) {
		padding-left: 40px;
		padding-right: 40px;
	}
`;
const StyledRedButton = styled(RedButton)`
	width: 257px;
`;
