import { useState, useCallback } from "react";

const useEventsData = () => {
	const [schedule, setSchedule] = useState<
		{
			fairId: string;
			scheduleDate: string;
			createDate: Date;
			eventCategory: string;
			eventName: string;
			startTime: string;
			endTime: string;
			description: string;
			id: number;
		}[]
	>([]);

	// Set the initial data from the /get request
	const setEventsData = useCallback(
		(
			initSchedule: {
				fairId: string;
				scheduleDate: string;
				createDate: Date;
				eventCategory: string;
				eventName: string;
				startTime: string;
				endTime: string;
				description: string;
				id: number;
			}[]
		) => {
			if (initSchedule) {
				//Sorts by date and then by time.
				initSchedule.sort((a, b) => {
					if (a.scheduleDate > b.scheduleDate) {
						return 1;
					} else if (
						a.scheduleDate === b.scheduleDate &&
						a.startTime > b.startTime
					) {
						return 1;
					} else {
						return -1;
					}
				});

				setSchedule(initSchedule);
			}
		},
		[]
	);

	const eventsData = {
		schedule,
		setSchedule,
	};

	return { eventsData, setEventsData };
};

export default useEventsData;
