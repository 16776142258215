import React from "react";
import styled from "styled-components";
import FairCard from "./FairCard";
import { WhiteButton } from "components/Button";
import RedArrow from "images/RedArrow.svg";
import { useConfig } from "providers/ConfigContext";
import { FairInfo } from "./FairFinderContent";

interface Props {
	totalCount: number;
	fairs: Array<FairInfo>;
	loadMoreFairs: Function;
}

const SearchResults = ({ totalCount, fairs, loadMoreFairs }: Props) => {
	const { onlineShopOrigin } = useConfig();
	const shopUrl: string = `${onlineShopOrigin}parent-ecommerce/fair-2.html`;

	const handleShowMore = function () {
		loadMoreFairs(fairs.length);
	};
	return (
		<Container>
			{totalCount > 0 && fairs && (
				<Results>
					<StyledResultCount>
						{totalCount === 1
							? `1 Result`
							: `${totalCount} Results`}
					</StyledResultCount>
					{fairs.map((fairInfo) => (
						<FairCard key={fairInfo.fairId} {...fairInfo} />
					))}
					{/* If all results are shown, hide ShowMore Button */}
					{totalCount > fairs.length && (
						<StyledWhiteButton handleClick={handleShowMore}>
							Show More <DownArrow src={RedArrow} />
						</StyledWhiteButton>
					)}
				</Results>
			)}
			{totalCount === 0 && (
				<StyledResultCount>No Results Found</StyledResultCount>
			)}
			{totalCount >= 0 && (
				<Footer totalCount={totalCount}>
					<StyledText>
						<BoldText>Can’t find your school?</BoldText>{" "}
						<StyledLink href={shopUrl} target="_blank">
							Sign up to be notified
						</StyledLink>{" "}
						about your next Book Fair!
					</StyledText>
				</Footer>
			)}
		</Container>
	);
};

interface FairCountProps {
	totalCount?: number;
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const Results = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
	box-sizing: border-box;
	padding: 0px 40px;
	@media (max-width: 500px) {
		padding: 0px 20px;
	}
`;
const StyledResultCount = styled.div`
	font-family: museo-sans;
	font-style: normal;
	font-weight: 400;
	font-size: 39px;
	line-height: 43px;
	text-align: center;
	color: #333333;
	margin-bottom: 42px;
	@media (max-width: 720px) {
		font-size: 32px;
		line-height: 35px;
		margin-bottom: 32px;
	}
`;
const StyledWhiteButton = styled(WhiteButton)`
	padding: 9px 20px;
	height: 40px;
	background: #ffffff;
	border: 1px solid #e81111;
	border-radius: 40px;
	margin: 22px 0px 40px 0px;
	@media (max-width: 720px) {
		margin: 24px 0px 48px 0px;
	}
`;
const DownArrow = styled.img`
	position: relative;
	left: 5px;
`;

const Footer = styled.div<FairCountProps>`
	max-width: 760px;
	margin: ${(props) =>
		props.totalCount === 0 ? "20px 0px 80px" : "20px 0px 0px"};
	@media (max-width: 720px) {
		margin: ${(props) =>
			props.totalCount === 0 ? "9px 21px 37px" : "20px 21px 0px"};
	}
`;
const StyledText = styled.div`
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #333333;
`;
const StyledLink = styled.a`
	font-family: museo-sans;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #333333;
`;
const BoldText = styled(StyledText)`
	font-weight: 600;
	display: inline-block;
	white-space: nowrap;
`;
export default SearchResults;
