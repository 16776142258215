import Cookies from "js-cookie";

// Get either fairId or use current from window URL
export const getFairIdFromUrl = () => {
	let currentFair: string = "current";
	const urlParams = new URLSearchParams(window.parent.location.search);
	urlParams.forEach((value, key) => {
		if (key.toLowerCase() === "fairid") {
			currentFair = value;
		}
	});
	return currentFair;
};

// Get either schoolID or use current from window URL
export const getSchoolIdFromUrl = () => {
	let currentSchool: string = "selected";
	const urlParams = new URLSearchParams(window.parent.location.search);
	urlParams.forEach((value, key) => {
		if (key.toLowerCase() === "schoolid") {
			currentSchool = value;
		}
	});
	return currentSchool;
};

// get uuid id from url
export const getUUIDFromUrl = () => {
	let currentId;
	const urlParams = new URLSearchParams(window.parent.location.search);
	urlParams.forEach((value, key) => {
		if (key.toLowerCase() === "uuid") {
			currentId = value;
		}
	});
	return currentId;
};

//Check if sps_ud exists
export const checkSpsUd = (publicRoute?: boolean) => {
	const rerouteToLogin = publicRoute ? false : true;
	const sps_ud = Cookies.get("SPS_UD");
	//user is not logged in
	if (sps_ud === undefined) {
		if (rerouteToLogin) {
			window.open(`/content/fairs/hub/login.html`, "_top");
		}
		return false;
	} else {
		return true;
	}
};

//Convert String to Dollar ammount
export const convertStringToDollarAmount = (amount: string) => {
	return parseFloat(amount).toLocaleString("en-US", {
		style: "currency",
		currency: "USD",
	});
};

// Custom parse float function, will return 0 if the input is empty or just a decimal point
export const customParseFloat = (input: string) => {
	if (input === "" || input === "." || input === null) {
		return 0;
	} else {
		const parseCommas = input.replace(/,/g, "");
		return parseFloat(parseCommas);
	}
};

export const formatNumberWithCommas = (input: number) => {
	return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatStringWithCommas = (input: string) => {
	return input.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
