import IframeContainer from "components/IframeContainer";
import { FinancialFormSpendingContent } from "./FinancialFormSpendingContent";
import FairInfoBar from "components/FairInfoBar";
import useSpendingData from "hooks/FinancialForm/useSpendingData";

export const FinancialFormSpending = () => {
	const {
		fairId,
		totalRedeemed,
		existingBalance,
		appliedBalance,
		scholasticDollarsDue,
		isLoading,
	} = useSpendingData();

	return (
		<IframeContainer
			renderItems={() => (
				<>
					{!isLoading && (
						<>
							<FairInfoBar />
							<FinancialFormSpendingContent
								fairId={fairId}
								totalRedeemed={totalRedeemed}
								existingBalance={existingBalance}
								appliedBalance={appliedBalance}
								scholasticDollarsDue={scholasticDollarsDue}
							/>
						</>
					)}
				</>
			)}
		/>
	);
};
