import React from "react";
import styled from "styled-components/macro";

interface Props {
	src: string;
	alt: string;
	defaultImg?: string;
	className?: string;
}

const PageIcon = ({ src, alt, className, defaultImg }: Props) => {
	return (
		<StyledPageIcon
			src={src}
			className={className}
			alt={alt}
			defaultImg={defaultImg}
		/>
	);
};

interface ImgProps {
	defaultImg?: string;
}
const StyledPageIcon = styled.img<ImgProps>`
	width: 120px;
	height: 120px;
	padding-bottom: 14px;
	background-image: ${(props) => `url(${props.defaultImg})`};
	background-size: 119px 119px;
	background-repeat: no-repeat;
`;

export default PageIcon;
