import IframeContainer from "../IframeContainer";
import { BlackoutCalendar } from "components/BlackoutCalendar/BlackoutCalendar";

export const BlackoutCalendarStandAlone = () => {
	return (
		<IframeContainer
			renderItems={(fairId?: string | null) => (
				<>
					<BlackoutCalendar
						closeModal={() => true}
						standAlone={true}
						fairId={fairId}
					/>
				</>
			)}
		/>
	);
};

export default BlackoutCalendarStandAlone;
