import { useState, useCallback } from "react";

const useShareHomepageData = () => {
	const [customUrl, setCustomUrl] = useState<string>("");
	const [schoolId, setSchoolId] = useState<string>("");
	const [urlStatus, setUrlStatus] = useState<string>("");
	const [shareUrl, setShareUrl] = useState<boolean>(false);

	// Set the initial data from the /get request
	const setShareHomepageData = useCallback(
		(
			initWebUrl: string,
			initSchoolId: string,
			initUrlStatus: string,
			initFairFinderCkbok: string
		) => {
			if (initWebUrl) {
				setCustomUrl(initWebUrl);
			}
			if (initSchoolId) {
				setSchoolId(initSchoolId);
			}
			if (initUrlStatus) {
				setUrlStatus(initUrlStatus);
			}
			if (initFairFinderCkbok && initFairFinderCkbok === "Y") {
				setShareUrl(true);
			}
		},
		[]
	);

	const shareHomepageData = {
		customUrl,
		setCustomUrl,
		schoolId,
		setSchoolId,
		urlStatus,
		setUrlStatus,
		shareUrl,
		setShareUrl,
	};

	return { shareHomepageData, setShareHomepageData };
};

export default useShareHomepageData;
