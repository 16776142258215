import React, { useCallback, useEffect, useState } from "react";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components/macro";
import { convertYMDToMDY } from "utils/DateConversion";
import { useConfig } from "providers/ConfigContext";
import axios from "axios";
import RedTextButton from "../RedTextButton";
import { BlackoutCalendarModal } from "components/BlackoutCalendar/BlackoutCalendarModal";
import {
	handleCOASuccess,
	handleGenericErrors,
	handleGenericSuccess,
} from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

type Props = {
	iamUcn: string;
	fairType: string;
	showUnavailableDates: boolean;
};

const DeliveryPickupCard = ({
	iamUcn,
	fairType,
	showUnavailableDates,
}: Props) => {
	const { bookfairsAPI } = useConfig();

	const [blackoutCalendarModalOpen, setBlackoutCalendarModalOpen] =
		useState<boolean>(false);

	//Component text defaults to the text displayed for virtual fairs.
	const [deliveryText, setDeliveryText] = useState(
		"Not applicable to virtual fairs."
	);
	const [deliveryTitle, setDeliveryTitle] = useState("Delivery window:");
	const [pickupText, setPickupText] = useState(
		"Not applicable to virtual fairs."
	);
	const [pickupTitle, setPickupTitle] = useState("Pickup window:");

	//handler function to convert date string to the format we need
	const handleDate = (date: string) => {
		let temp = convertYMDToMDY(date);
		if (temp && temp[0] === "0") {
			temp = temp.substring(1, temp.length);
		}
		return temp;
	};

	const openBlackoutCalendarModal = () => {
		setBlackoutCalendarModalOpen(true);
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};
	const closeBlackoutCalendarModal = () => {
		getDeliveryDates();
		setBlackoutCalendarModalOpen(false);
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
	};

	const getDeliveryDates = useCallback(async () => {
		const currentFair = getFairIdFromUrl();
		if (checkSpsUd()) {
			try {
				await axios
					.get(
						`${bookfairsAPI}/user/fairs/${currentFair}/settings/dates`,
						{
							withCredentials: true,
						}
					)
					.then((response) => {
						if (response.status === 200) {
							//Checks for exact pickup date, if exact date is not returned, handles window
							if (response.data.pickupDate) {
								const tempDate = handleDate(
									response.data.pickupDate
								);
								setPickupText(tempDate ? tempDate : " ");
								setPickupTitle("Pickup date:");
							} else {
								setPickupText(`${response.data.pickupWindow}`);
							}

							//Checks for exact delivery date, if exact date is not returned, handles window
							if (response.data.deliveryDate) {
								const tempDate = handleDate(
									response.data.deliveryDate
								);
								setDeliveryText(tempDate ? tempDate : " ");
								setDeliveryTitle("Delivery date:");
							} else {
								setDeliveryText(
									`${response.data.deliveryWindow}`
								);
							}
						} else {
							if (!handleCOASuccess(response)) {
								handleGenericSuccess(response);
							}
						}
					})
					.catch((err) => {
						handleGenericErrors(err);
					});
			} catch (err) {
				handleGenericErrors(err);
			}
		}
	}, [bookfairsAPI]);

	useEffect(() => {
		//If the fairType is not virtual, use the api to get the delivery/pickup window/dates
		if (fairType !== "" && fairType !== "Virtual") {
			getDeliveryDates();
		}
	}, [fairType, getDeliveryDates]);

	return (
		<>
			{blackoutCalendarModalOpen && (
				<BlackoutCalendarModal
					closeModal={closeBlackoutCalendarModal}
				/>
			)}
			<FairDetailsCard title={"Fair Delivery / Pickup"}>
				<OuterContainer>
					<DeliveryPickupWindowContainer>
						<InnerContainer>
							<Body>{deliveryTitle}</Body>
							<Body>{deliveryText}</Body>
						</InnerContainer>
						<InnerContainer>
							<Body>{pickupTitle}</Body>
							<Body>{pickupText}</Body>
						</InnerContainer>
					</DeliveryPickupWindowContainer>
					{showUnavailableDates && (
						<UnavailableDatesContainer>
							<RedTextButton
								handleClick={openBlackoutCalendarModal}
							>
								Add unavailable dates
							</RedTextButton>
						</UnavailableDatesContainer>
					)}
					<SMSSignUpContainer>
						<Body>
							<RedTextLink
								href={`https://scholasticcrm.my.salesforce-sites.com/apex/smsoptinpage?cId=${iamUcn}&sc=27249`}
								target="_blank"
								rel="noopener"
								tabIndex={1}
								onClick={() => {
									window._satellite.track("sign-up-updates", {
										"button-text": "sign-up-updates",
									});
								}}
							>
								Sign up
							</RedTextLink>{" "}
							to receive SMS text alerts
						</Body>
					</SMSSignUpContainer>
				</OuterContainer>
			</FairDetailsCard>
		</>
	);
};

const Body = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
`;

const OuterContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const DeliveryPickupWindowContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	@media (max-width: 720px) {
		flex-direction: row;
		column-gap: 64px;
	}
	@media (max-width: 500px) {
		flex-direction: column;
	}
`;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const UnavailableDatesContainer = styled.div`
	display: flex;
	margin-top: 8px;
`;

const SMSSignUpContainer = styled.div`
	display: flex;
	margin-top: 8px;
`;

const RedTextLink = styled.a`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #e81111;
	display: inline;
	text-decoration: none;
	&:hover {
		font-style: unset;
		text-decoration: underline;
		cursor: pointer;
	}
	&:focus-visible {
		outline: 2px solid #e81111;
		color: #e81111;
		outline-offset: 1px;
		margin-top: 1px;
	}
`;
export default DeliveryPickupCard;
