import IframeContainer from "components/IframeContainer";
import { useScanSheetPageData } from "hooks/ScanSheet/useScanSheetData";
import ScanSheetDocument from "./ScanSheetComponent";

export const ScanSheetPage = () => {
	sessionStorage.setItem("bfDestinationLogin", window.location.href);
	const { isLoading, schoolName, scanSheet } = useScanSheetPageData();

	return (
		<IframeContainer
			renderItems={() => (
				<>
					{!isLoading && (
						<ScanSheetDocument
							schoolName={schoolName}
							scanSheet={scanSheet}
						/>
					)}
				</>
			)}
		/>
	);
};
