import IframeContainer from "components/IframeContainer";
import { FinancialFormSummaryContent } from "./FinancialFormSummaryContent";
import useSummaryPageData from "hooks/FinancialForm/useSummaryPageData";

export const FinancialFormSummary = () => {
	const { registerInfo, registerInfoTotals, isLoading } =
		useSummaryPageData();
	return (
		<IframeContainer
			renderItems={() => (
				<>
					{!isLoading && (
						<FinancialFormSummaryContent
							registerData={registerInfo}
							registerTotalsData={registerInfoTotals}
						/>
					)}
				</>
			)}
		/>
	);
};
