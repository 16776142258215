import React from "react";
import { AEMModalContainer } from "components/AEMModalContainer";
import { RedButton, WhiteButton } from "components/Button";
import styled from "styled-components/macro";

interface Props {
	closeModal: any;
	handleRemoveCoChair: any;
	disableRemove?: boolean;
}

export const RemoveCoChairModal = ({
	closeModal,
	handleRemoveCoChair,
	disableRemove,
}: Props) => {
	const handleSubmit = () => {
		handleRemoveCoChair();
	};
	return (
		<AEMModalContainer closeModal={closeModal}>
			<Container>
				<TextContainer>
					<Title>Are you sure?</Title>
					<StyledText>
						By removing this person they will no longer have access
						to any of the admin tools used by this fair.
					</StyledText>
				</TextContainer>
				<ButtonContainer>
					<StyledWhiteButton
						whiteBackground={true}
						handleClick={closeModal}
					>
						Cancel
					</StyledWhiteButton>
					<StyledRedButton
						handleClick={handleSubmit}
						disabled={disableRemove}
					>
						Remove
					</StyledRedButton>
				</ButtonContainer>
			</Container>
		</AEMModalContainer>
	);
};
const Container = styled.div`
	position: relative;
	@media (max-width: 719px) {
		height: 100vh;
		width: 100vw;
	}
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding-left: 41px;
	margin-top: 38px;
	@media (max-width: 950px) {
		margin-top: 0px;
		padding-top: 30px;
		padding-left: 20px;
		align-items: center;
		width: 90%;
	}
`;

const Title = styled.p`
	font-size: 16px;
	font-weight: 700;
	line-height: 19px;
	text-align: left;
	@media (max-width: 550px) {
		padding-left: 5px;
		align-items: center;
		width: 100%;
	}
`;

const StyledText = styled.div`
	font-weight: 300;
	font-size: 17px;
	line-height: 25px;
`;

const ButtonContainer = styled.div`
	padding-top: 68px;
	padding-right: 24.3px;
	padding-bottom: 17px;
	display: flex;
	flex-direction: row;
	gap: 16px;
	justify-content: flex-end;
	@media (max-width: 719px) {
		position: absolute;
		bottom: 0;
		padding-top: 13px;
		padding-bottom: 30px;
		box-shadow: 0px 0px 14px 0px #0000001a;
		width: 100vw;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 130px;
	margin-bottom: 15px;
`;
const StyledRedButton = styled(RedButton)`
	width: 130px;
	margin-bottom: 15px;
	@media (max-width: 719px) {
		margin-right: 24px;
	}
`;
