import React, { useEffect } from "react";
import styled from "styled-components/macro";
import TopLeftCard from "./ScansheetCards/TopLeftCard";
import TopRightCard from "./ScansheetCards/TopRightCard";
import BottomLeftCard from "./ScansheetCards/BottomLeftCard";
import BottomRightCard from "./ScansheetCards/BottomRightCard";

interface Props {
	schoolName: string;
	barcodeValue: string;
	recipientFirstName: string;
	recipientLastName: string;
	teacherLastName: string;
	grade: string;
	balance: number;
}

const IndividualScansheet = ({
	schoolName,
	barcodeValue,
	recipientFirstName,
	recipientLastName,
	teacherLastName,
	grade,
	balance,
}: Props) => {
	// open print dialogue on page load
	useEffect(() => {
		window.print();
	}, []);

	return (
		<Container>
			<TopLeftCard schoolName={schoolName} />
			<TopRightCard />
			<BottomLeftCard barcodeValue={barcodeValue} />
			<BottomRightCard
				recipientFirstName={recipientFirstName}
				recipientLastName={recipientLastName}
				teacherLastName={teacherLastName}
				grade={grade}
				balance={balance}
			/>
		</Container>
	);
};

const Container = styled.div`
	margin: 0 auto;
	width: 792px;
	height: 612px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	margin-inline: auto;
	margin-block: 3em;
	// grid lines
	overflow: hidden;
	@media print {
		transform: rotate(90deg);
	}
`;

export default IndividualScansheet;
