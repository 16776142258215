import { useState, useEffect } from "react";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import {
	handleCOASuccess,
	handleGenericErrors,
	handleGenericSuccess,
} from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

type FairDates = {
	fairStartDate: string;
	fairEndDate: string;
	deliveryStartDate: string;
	deliveryEndDate: string;
	pickupStartDate: string;
	pickupEndDate: string;
	deliveryWindow: string;
	pickupWindow: string;
	deliveryDate: string;
	pickupDate: string;
	remoteRoutingWindowFlag: boolean;
	deliveryBlackoutDates: Array<string> | undefined;
	pickupBlackoutDates: Array<string> | undefined;
	bookfairHolidays: Array<string> | undefined;
};

const fairDatesDefault = {
	fairStartDate: "",
	fairEndDate: "",
	deliveryStartDate: "",
	deliveryEndDate: "",
	pickupStartDate: "",
	pickupEndDate: "",
	deliveryWindow: "",
	pickupWindow: "",
	deliveryDate: "",
	pickupDate: "",
	remoteRoutingWindowFlag: false,
	deliveryBlackoutDates: undefined,
	pickupBlackoutDates: undefined,
	bookfairHolidays: undefined,
};

interface Props {
	fairId?: string | null;
}

const useFairDatesData = ({ fairId }: Props) => {
	const { bookfairsAPI } = useConfig();

	const [fairDates, setFairDates] = useState<FairDates>(fairDatesDefault);

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const getFairDatesData = async () => {
			const currentFair = getFairIdFromUrl();
			if (checkSpsUd()) {
				try {
					await axios
						.get(
							`${bookfairsAPI}/user/fairs/${currentFair}/settings/dates`,
							{
								withCredentials: true,
							}
						)
						.then((response) => {
							if (response.status === 200) {
								if (response.data) {
									setFairDates(response.data);
								}
							} else {
								if (!handleCOASuccess(response)) {
									handleGenericSuccess(response);
								}
							}
						})
						.catch((err) => {
							setIsError(true);
							handleGenericErrors(err);
						});
				} catch (err: any) {
					setIsError(true);
					handleGenericErrors(err);
				} finally {
					setIsLoading(false);
				}
			}
		};

		const updateFairId = async () => {
			try {
				// set fair id
				// TODO: Check logic below, make sure same with new endpoint
				await axios
					.get(
						bookfairsAPI +
							`/login/userAuthorization/fairs/${fairId}`,
						{
							withCredentials: true,
						}
					)
					.then(() => {
						getFairDatesData();
					});
			} catch (e) {
				console.error(e);
				window.open("/content/fairs/hub/error.html");
			}
		};
		if (fairId) {
			updateFairId();
		} else {
			getFairDatesData();
		}
	}, [bookfairsAPI, setFairDates, fairId]);

	return {
		fairDates,
		isLoading,
		isError,
	};
};

export default useFairDatesData;
