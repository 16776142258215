import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { validateEmail } from "utils/InputValidations";
import Card from "components/Homepage/Card";
import BodyText from "components/BodyText";
import TextInput from "components/TextInput";
import { RedButton } from "components/Button";
import TextLink from "components/TextLink";
import useCustomAlert from "hooks/HomepageBuilder/useCustomAlert";
import { trackEmailSignUp, trackPrivacyPolicy } from "DumbleData";

interface Props {
	fairId: number;
	schoolUCN: number;
}

const Notifications = ({ fairId, schoolUCN }: Props) => {
	const { bookfairsAPI, damAssetsOrigin } = useConfig();
	const { activateAlert, alert } = useCustomAlert(
		"Thank you for signing up."
	);

	const [emailAddress, setEmailAddress] = useState<string>("");
	const [emailError, setEmailError] = useState<string>();
	const [attemptedToSubmit, setAttemptedToSubmit] = useState<boolean>(false);

	// Checks if email is valid after submitting the form
	useEffect(() => {
		if (attemptedToSubmit) {
			if (validateEmail(emailAddress)) {
				setEmailError(undefined);
			} else {
				setEmailError("Please enter a valid email address.");
			}
		}
	}, [emailAddress, attemptedToSubmit]);

	// Attempts to POST to backend if email is valid
	const handleSubmit = async (event: any) => {
		event.preventDefault();
		if (validateEmail(emailAddress)) {
			//TODO: this endpoint is not documented
			try {
				await axios.post(
					`${bookfairsAPI}/parent-comm/homepage/${fairId}`,
					{
						fairId: fairId,
						parentEmail: emailAddress,
						schoolUcn: schoolUCN,
					}
				);
				activateAlert();
				trackEmailSignUp();
			} catch (error) {
				console.error(error);
			}
		} else {
			setAttemptedToSubmit(true);
		}
	};

	return (
		<Card>
			{alert}
			<NotificationsForm onSubmit={handleSubmit}>
				<Header>
					<StyledNotificationsIcon
						src={`${damAssetsOrigin}LiveHomepage/Icons/Section/Email.png`}
					/>
				</Header>
				<StyledBodyText>
					Sign up for notifications about your school's Book Fair and
					exclusive offers from Scholastic
				</StyledBodyText>
				<StyledTextInput
					label="Email Address"
					id="emailAddress"
					value={emailAddress}
					onChange={setEmailAddress}
					required
					error={emailError}
				/>
				<StyledTextLink
					href="https://www.scholastic.com/privacy.htm"
					target="_blank"
					handleClick={() => trackPrivacyPolicy()}
				>
					Privacy policy
				</StyledTextLink>
				<StyledRedButton type="submit">Sign Up</StyledRedButton>
			</NotificationsForm>
		</Card>
	);
};

const StyledTextLink = styled(TextLink)`
	margin-top: 18px;
	@media (max-width: 670px) {
		margin-top: 20px;
	}
`;
const StyledTextInput = styled(TextInput)`
	max-width: 280px;
	margin-top: 0px;
	margin-bottom: 0;
	@media (max-width: 720px) {
		max-width: 246px;
	}
	@media (max-width: 670px) {
		margin-top: 0;
	}
`;
const StyledNotificationsIcon = styled.img`
	width: 68px;
	margin-bottom: 19px;
	margin-top: 28px;
	@media (max-width: 720px) {
		margin-top: 12.6px;
	}
`;
const StyledRedButton = styled(RedButton)`
	margin-top: 20px;
	width: 136px;
	@media (max-width: 720px) {
		margin-top: 30px;
	}
	@media (max-width: 670px) {
		margin-top: 26.1px;
	}
`;
const StyledBodyText = styled(BodyText)`
	font-style: normal;
	font-weight: 600;
	font-size: 21px;
	line-height: 24px;
	text-align: center;
	color: #333333;
	max-width: 340px;
	margin-top: 20px;
	margin-bottom: 19px;
	@media (max-width: 720px) {
		max-width: 238px;
	}
	@media (max-width: 670px) {
		max-width: 287px;
		margin-top: 12px;
		margin-bottom: 18.95px;
	}
`;
const NotificationsForm = styled.form`
	max-height: 400px;
	max-width: 440px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const Header = styled.div`
	width: 392px;
	border-bottom: 1px solid #ffd7c7;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 850px) {
		width: 274.4px;
	}
`;
export default Notifications;
