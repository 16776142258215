import IframeContainer from "components/IframeContainer";
import { FinancialFormIntroContent } from "./FinancialFormIntroContent";
import FairInfoBar from "components/FairInfoBar";
import useIntroPageData from "hooks/FinancialForm/useIntroPageData";

export const FinancialFormIntro = () => {
	const { availabilityState, fairId, isLoading } = useIntroPageData();

	return (
		<IframeContainer
			renderItems={() => (
				<>
					{/* wait to render components until primary request (useIntroPageData) is fulfilled */}
					{!isLoading && (
						<>
							<FairInfoBar />
							<FinancialFormIntroContent
								availabilityState={availabilityState}
								fairId={fairId}
							/>
						</>
					)}
				</>
			)}
		/>
	);
};
