import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { useConfig } from "providers/ConfigContext";
import { Page } from "components/HomepageBuilder/Page";
import Heading from "components/Heading";
import PageIcon from "components/HomepageBuilder/PageIcon";
import VolunteerIcon from "images/Volunteers.svg";
import DownloadIcon from "images/Download.svg";
import BodyText from "components/BodyText";
import TextInput from "components/TextInput";
import { RedButton } from "components/Button";
import TextLink from "components/TextLink";
import { useVolunteerData } from "./Builder";
import axios from "axios";
import SaveChangesModal from "components/HomepageBuilder/SaveChangesModal";
import Prompt from "components/HomepageBuilder/Prompt";
import { trackDownloadPaperSignUpForm, trackSaveAndPublish } from "DumbleData";
import IframeContainer from "components/IframeContainer";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

export const VolunteerPage = () => {
	const { damAssetsOrigin, bookfairsAPI } = useConfig();
	const { volunteerData, nextLocation, setNextLocation } = useVolunteerData();
	const navigate = useNavigate();
	const [urlError, setUrlError] = useState(false);
	const [attemptedToSubmit, setAttemptedToSubmit] = useState(false);
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const [allowNavigation, setAllowNavigation] = useState(false);
	const [savedData] = useState(volunteerData.volunteerSignUpUrl);
	const firstRender = useRef(true);

	const dataValidate = useCallback(() => {
		let isAllValid = true;

		//validates url
		var pattern = new RegExp(
			"^(https?:\\/\\/)?" + // protocol
				"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
				"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
				"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
				"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
				"(\\#[-a-z\\d_]*)?$",
			"i"
		); // fragment locator
		//Url optional so if there is no url inputted, remove error and return true for all valid
		if (!volunteerData.volunteerSignUpUrl) {
			setUrlError(false);
			isAllValid = true;
		} else if (!pattern.test(volunteerData.volunteerSignUpUrl)) {
			setUrlError(true);
			isAllValid = false;
		} else {
			setUrlError(false);
		}
		return isAllValid;
	}, [volunteerData.volunteerSignUpUrl]);

	//calls validation function and then sends post request if all inputs are valid, otherwise notifies useEffect to check for errors
	const submitVolunteerData = async () => {
		const currentFair = getFairIdFromUrl();

		setAllChangesSaved(true);
		if (dataValidate()) {
			if (checkSpsUd()) {
				try {
					let processedUrl = volunteerData.volunteerSignUpUrl;
					// if url is not blank, and does not include http or https, pre-pend https
					if (
						processedUrl !== "" &&
						!(
							processedUrl.includes("http://") ||
							processedUrl.includes("https://")
						)
					) {
						processedUrl = "https://" + processedUrl;
					}
					await axios
						.put(
							`${bookfairsAPI}/user/fairs/${currentFair}/homepage`,
							{
								volunteerSignupUrl: processedUrl,
							},
							{ withCredentials: true }
						)
						.then((response) => {
							if (response.status === 200) {
								trackSaveAndPublish(
									"recruit-volunteers",
									"Recruit Volunteers"
								);
								setAllowNavigation(true);
							} else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							handleGenericErrors(err);
						});
				} catch (err) {
					handleGenericErrors(err);
				}
			}
		} else {
			setSaveChangesModalOpen(false);
			setAttemptedToSubmit(true);
		}
	};

	//If submitted at least once, checks for input errors at every change of input field
	useEffect(() => {
		if (attemptedToSubmit) {
			dataValidate();
		}
	}, [attemptedToSubmit, dataValidate, volunteerData.volunteerSignUpUrl]);

	//
	// Logic to handle navigation and rendering the SaveChangesModal
	//

	//Tracks changes made
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		} else {
			setAllChangesSaved(false);
		}
	}, [volunteerData.volunteerSignUpUrl]);

	//Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			navigate(nextLocation);
		}
	}, [
		allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		nextLocation,
	]);

	///////////////////////////

	return (
		<IframeContainer
			renderItems={() => (
				<>
					<StyledVolunteerPage>
						<PageIcon
							src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/Volunteers.svg`}
							alt="Volunteer Form Icon"
							defaultImg={VolunteerIcon}
						/>
						<Heading>Recruit volunteers</Heading>
						<Wrapper>
							<BodyText>
								Volunteers are critical to the success of your
								Book Fair. You’ll need extra hands for setup,
								promotions, staffing, and packing up. Enable a
								volunteer sign-up form to appear on your
								homepage or post a link to your own form in your
								welcome message.
							</BodyText>
							<Download
								target="_blank"
								href={`${damAssetsOrigin}HomepageBuilder/Volunteers/Signup_Form.pdf`}
								download
								onClick={() => trackDownloadPaperSignUpForm()}
							>
								<DownloadImage src={DownloadIcon} />
								Download a paper sign-up form
							</Download>
							<TextInput
								label="Enter a URL link to an external volunteer sign-up form (optional)"
								value={volunteerData.volunteerSignUpUrl}
								onChange={volunteerData.setVolunteerSignUpUrl}
								error={
									urlError
										? "Please enter a valid URL link."
										: undefined
								}
							/>
							<StyledSubText>
								*After entering the URL, please test the address
								in your own browser to confirm it’s valid and
								accurate.
							</StyledSubText>
						</Wrapper>
						<StyledRedButton
							handleClick={() => {
								setNextLocation("/reactapp/customize/share");
								submitVolunteerData();
							}}
						>
							save & publish
						</StyledRedButton>
						<StyledTextLink
							handleClick={() => {
								if (!allChangesSaved) {
									setNextLocation(
										"/reactapp/customize/share"
									);
									setSaveChangesModalOpen(
										!saveChangesModalOpen
									);
								} else {
									navigate("/reactapp/customize/share");
								}
							}}
						>
							Skip
						</StyledTextLink>
					</StyledVolunteerPage>
					<Prompt
						message={() => {
							setSaveChangesModalOpen(true);
						}}
						when={!allChangesSaved}
					/>
					<SaveChangesModal
						saveAndPublish={submitVolunteerData}
						discardChanges={() => {
							volunteerData.setVolunteerSignUpUrl(savedData);
							setAllChangesSaved(true);
							setAllowNavigation(true);
						}}
						modalIsOpen={saveChangesModalOpen}
						handleModal={() => {
							setSaveChangesModalOpen(!saveChangesModalOpen);
						}}
					/>
				</>
			)}
		/>
	);
};

/*
	Page Specific Styles
*/
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 392px;
`;
const Download = styled.a`
	display: inline-flex;
	flex-direction: row;
	font-size: 13px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0px;
	color: #e81111;
	margin-bottom: 49px;
	margin-top: 36px;
	text-decoration: none;
	&:hover {
		cursor: pointer;
		color: #b90000;
		border-bottom: 1px solid #b90000;
	}
	&:focus,
	:focus-visible {
		color: #b90000;
		border: 2px solid #b90000;
		box-sizing: border-box;
		border-radius: 4px;
		outline: none;
		padding: 1px;
		margin-right: 10px;
	}
	&:active {
		color: #660000;
		border-bottom: 1px solid #660000;
		padding-bottom: 3px;
	}
	@media (max-width: 374px) {
		margin-top: 45.5px;
	}
`;
const DownloadImage = styled.img`
	padding-right: 11.5px;
	margin-top: -1px;
`;
const SubText = styled.p`
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0px;
	text-align: left;
	margin-top: -11.5px;
	margin-bottom: 66.5px;
	@media (max-width: 374px) {
		margin-bottom: 50px;
	}
`;
const StyledRedButton = styled(RedButton)`
	width: 181px;
`;
const StyledTextLink = styled(TextLink)`
	margin-top: 23px;
`;
const StyledSubText = styled(SubText)`
	padding-top: 15px;
`;
const StyledVolunteerPage = styled(Page)`
	padding-bottom: 95px;
	@media (max-width: 719px) {
		padding-bottom: 103px;
	}
	@media (max-width: 375px) {
		padding-bottom: 74px;
		padding-left: 21px;
		padding-right: 20px;
	}
`;
