import React, { useCallback, useEffect, useRef, useState } from "react";
import { IconButtonWhite, RedButton, WhiteButton } from "components/Button";
import Email from "images/Email.svg";
import Checkbox from "components/Checkbox";
import Check from "images/Check.svg";
import styled from "styled-components/macro";
import {
	ChairpersonData,
	FairInfoData,
	OrganizationData,
} from "hooks/ConfirmYourFair/useCoaPageData";
import { convertYMDStringToDate, convertYMDToMDY } from "utils/DateConversion";
import { useConfig } from "providers/ConfigContext";
import axios from "axios";
import ServiceAgreements from "./ServiceAgreements";
import { PrintableComponent } from "components/PrintableComponent";
import { RejectionModal } from "./RejectionModal";
import { EmailAgreementModal } from "./EmailModals/EmailAgreementModal";
import { EmailSuccessModal } from "./EmailModals/EmailSuccessModal";
import { EmailFailureModal } from "./EmailModals/EmailFailureModal";
import RedTextButton from "components/RedTextButton";
import { BlackoutCalendarModal } from "components/BlackoutCalendar/BlackoutCalendarModal";
import {
	handleCOAErrors,
	handleCOASuccess,
	handleGenericErrors,
	handleGenericSuccess,
} from "utils/ErrorUtility";
import { Grid } from "components/Grid";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";
import useCurrentServiceAgreement from "hooks/ConfirmYourFair/ServiceAgreement/useCurrentServiceAgreement";

export type CoaPage = {
	header: string;
	shareDesc: string;
	schoolType: {
		header: string;
		private: string;
		public: string;
		privateNotForProfit: string;
		privateForProfit: string;
	};
	taxStatus: {
		header: string;
		notOnFile: {
			html: string;
		};
	};
	chairpersonInfo: {
		header: string;
	};
	checkboxDesc: string;
};

interface ModelProps {
	coaPage: CoaPage;
	fairInfoData: FairInfoData;
	chairpersonData: ChairpersonData;
	organizationData: OrganizationData;
}

interface ConfirmYourFairProps {
	fairInfoData: FairInfoData;
	chairpersonData: ChairpersonData;
	organizationData: OrganizationData;
}

export const ConfirmYourFairContent = ({
	fairInfoData,
	chairpersonData,
	organizationData,
}: ConfirmYourFairProps) => {
	// Get the Teams data from AEM using the useAllTeams
	const coaPage: CoaPage = {
		header: "Confirm Your Book Fair",
		shareDesc:
			"If someone else needs to confirm these details, click below for a version to print or email.",
		schoolType: {
			header: "School Type:",
			private:
				"Private School - Supported by a private organization or private individuals rather than by the government.",
			public: "Public School - Publicly funded for the education of children as part of the free public education system.",
			privateNotForProfit:
				"Private Not For Profit School - Usually denoted as such by the IRS and created to provide a public benefit. Proceeds may be tax deductible.",
			privateForProfit:
				"Private For Profit School - Provides services for payment that will benefit its owners. Usually, proceeds are not tax deductible.",
		},
		taxStatus: {
			header: "Tax Certificate Status:",
			notOnFile: {
				html: "<p>We don’t have tax-exemption documents from your school. To claim exemption from charging sales tax on purchases at the Fair, all schools must email this documentation to <a href = mailto: SBFtaxforms@scholasticbookfairs.com>SBFtaxforms@<wbr>scholasticbookfairs.com</a> before the Fair. Please note: Tax-exemption documentation is not required for schools in Delaware, Montana, New Hampshire, and Oregon.</p>\n",
			},
		},
		chairpersonInfo: { header: "Your Information" },
		checkboxDesc:
			"By clicking on ACCEPT, I confirm that I have authority to agree to the above terms  and conditions on behalf of the above named school.",
	};

	return (
		<ConfirmYourFairModel
			coaPage={coaPage}
			fairInfoData={fairInfoData}
			chairpersonData={chairpersonData}
			organizationData={organizationData}
		/>
	);
};

const ConfirmYourFairModel = ({
	coaPage,
	fairInfoData,
	chairpersonData,
	organizationData,
}: ModelProps) => {
	const {
		header,
		shareDesc,
		schoolType,
		taxStatus,
		chairpersonInfo,
		checkboxDesc,
	} = coaPage;

	const [acceptChecked, setAcceptChecked] = useState<boolean>(false);
	const [rejectionModalOpen, setRejectionModalOpen] =
		useState<boolean>(false);
	const [emailAgreementModalOpen, setEmailAgreementModalOpen] =
		useState<boolean>(false);
	const [emailSuccessModalOpen, setEmailSuccessModalOpen] =
		useState<boolean>(false);
	const [emailFailureModalOpen, setEmailFailureModalOpen] =
		useState<boolean>(false);
	const [blackoutCalendarModalOpen, setBlackoutCalendarModalOpen] =
		useState<boolean>(false);
	const handleAcceptCheckbox = () => {
		acceptChecked ? setAcceptChecked(false) : setAcceptChecked(true);
	};
	const [showUnavailableDates, setShowUnavailableDates] =
		useState<boolean>(false);

	const [acceptClicked, setAcceptClicked] = useState(false);
	//Delivery and pickup text defaults to the text displayed for virtual fairs.
	const [deliveryText, setDeliveryText] = useState(
		"Not applicable to virtual fairs."
	);
	const [deliveryTitle, setDeliveryTitle] = useState("Delivery window:");
	const [pickupText, setPickupText] = useState(
		"Not applicable to virtual fairs."
	);
	const [pickupTitle, setPickupTitle] = useState("Pickup window:");
	const firstRender = useRef(true);

	const { bookfairsAPI } = useConfig();

	const isCurrentServiceAgreement = useCurrentServiceAgreement({
		coaAcceptedDate: fairInfoData.coaAcceptedDate,
	});

	useEffect(() => {
		const currentDate = new Date();
		const fairStart = convertYMDStringToDate(fairInfoData.startDate);
		const oneMonthBeforeStart = new Date(fairStart);
		oneMonthBeforeStart.setMonth(fairStart.getMonth() - 1);

		if (
			fairInfoData.fairType !== "bogo case" &&
			fairInfoData.fairType !== "case"
		) {
			setShowUnavailableDates(false);
		} else if (currentDate < oneMonthBeforeStart) {
			setShowUnavailableDates(true);
		} else {
			setShowUnavailableDates(false);
		}
	}, [fairInfoData.fairType, fairInfoData.startDate]);

	const handleAcceptClicked = () => {
		if (!acceptClicked) {
			setAcceptClicked(true);
		}
	};
	const acceptRequest = useCallback(async () => {
		const currentFair = getFairIdFromUrl();
		window._satellite.track("accept-coa-click", {
			"button-text": "accept-coa-click",
		});
		if (checkSpsUd()) {
			axios
				.put(
					`${bookfairsAPI}/user/fairs/${currentFair}/coa/confirmation`,
					{ coaStatus: "Accepted" },
					{ withCredentials: true }
				)
				.then((coaResponse) => {
					if (coaResponse.status === 200) {
						window.open(
							"/content/fairs/hub/coa/confirm.html",
							"_top"
						);
					} else {
						handleGenericSuccess(coaResponse);
					}
				})
				.catch(function (error) {
					if (!handleCOAErrors(error)) {
						handleGenericErrors(error);
					}
				});
		}
	}, [bookfairsAPI]);

	useEffect(() => {
		if (acceptClicked) {
			acceptRequest();
		}
	}, [acceptClicked, acceptRequest]);

	let schoolTypeDescription;
	if (organizationData.schoolType === "Public School") {
		schoolTypeDescription = schoolType.public;
	}

	switch (organizationData.schoolType) {
		case "Private":
			schoolTypeDescription = schoolType.private;
			break;
		case "Public":
			schoolTypeDescription = schoolType.public;
			break;
		case "Private Not For Profit":
			schoolTypeDescription = schoolType.privateNotForProfit;
			break;
		case "Private For Profit":
			schoolTypeDescription = schoolType.privateForProfit;
			break;
	}

	function getServiceAgreement(fairType: string) {
		const fairTypeVar = fairType.replace(/\s+/g, "").toLowerCase();
		const {
			serviceBottomHtml,
			serviceChartCurrHtml,
			serviceChartPrevHtml,
			serviceTopCurrHtml,
			serviceTopPrevHtml,
		} = ServiceAgreements[fairTypeVar];
		return {
			serviceBottomHtml,
			serviceTopCurrHtml,
			serviceTopPrevHtml,
			serviceChartCurrHtml,
			serviceChartPrevHtml,
		};
	}

	const {
		serviceBottomHtml,
		serviceTopCurrHtml,
		serviceTopPrevHtml,
		serviceChartCurrHtml,
		serviceChartPrevHtml,
	} = getServiceAgreement(fairInfoData.fairType ?? "default");

	const openRejectModal = () => {
		window._satellite.track("request-change-coa-click", {
			"button-text": "request-change-coa-click",
		});
		setRejectionModalOpen(true);
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};
	const closeRejectModal = () => {
		setRejectionModalOpen(false);
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
	};

	const openEmailAgreementModal = () => {
		window._satellite.track("email-fair", {
			"button-text": "email-fair",
		});
		setEmailAgreementModalOpen(true);
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};
	const closeEmailAgreementModal = () => {
		setEmailAgreementModalOpen(false);
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
	};

	const openEmailSuccessModal = () => {
		setEmailSuccessModalOpen(true);
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};
	const closeEmailSuccessModal = () => {
		setEmailSuccessModalOpen(false);
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
	};

	const openEmailFailureModal = () => {
		setEmailFailureModalOpen(true);
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};
	const closeEmailFailureModal = () => {
		setEmailFailureModalOpen(false);
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
	};

	const openBlackoutCalendarModal = () => {
		setBlackoutCalendarModalOpen(true);
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};
	const closeBlackoutCalendarModal = () => {
		getDeliveryDates();
		setBlackoutCalendarModalOpen(false);
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
	};

	//handler function to convert date string to the format we need
	const handleDate = (date: string) => {
		let temp = convertYMDToMDY(date);
		if (temp && temp[0] === "0") {
			temp = temp.substring(1, temp.length);
		}
		return temp;
	};

	const getDeliveryDates = useCallback(async () => {
		const currentFair = getFairIdFromUrl();
		if (checkSpsUd()) {
			try {
				await axios
					.get(
						`${bookfairsAPI}/user/fairs/${currentFair}/settings/dates`,
						{
							withCredentials: true,
						}
					)
					.then((response) => {
						if (response.status === 200) {
							//Checks for exact pickup date, if exact date is not returned, handles window
							if (response.data.pickupDate) {
								const tempDate = handleDate(
									response.data.pickupDate
								);
								setPickupText(tempDate ? tempDate : " ");
								setPickupTitle("Pickup date:");
							} else {
								setPickupText(`${response.data.pickupWindow}`);
							}
							//Checks for exact delivery date, if exact date is not returned, handles window
							if (response.data.deliveryDate) {
								const tempDate = handleDate(
									response.data.deliveryDate
								);
								setDeliveryText(tempDate ? tempDate : " ");
								setDeliveryTitle("Delivery date:");
							} else {
								setDeliveryText(
									`${response.data.deliveryWindow}`
								);
							}
						} else {
							if (!handleCOASuccess(response)) {
								handleGenericSuccess(response);
							}
						}
					})
					.catch((err) => {
						handleGenericErrors(err);
					});
			} catch (err) {
				handleGenericErrors(err);
			}
		}
	}, [bookfairsAPI]);

	useEffect(() => {
		//If the fairType is not virtual, use the api to get the delivery/pickup window/dates
		if (
			fairInfoData.fairType !== "" &&
			fairInfoData.fairType !== "Virtual" &&
			firstRender.current
		) {
			firstRender.current = false;
			getDeliveryDates();
		}
	}, [fairInfoData.fairType, getDeliveryDates, fairInfoData.fairId]);

	const handleSubmit = async (
		additionalDetails: string,
		contactMethod: string,
		reasons: string[]
	) => {
		const currentFair = getFairIdFromUrl();
		try {
			await axios
				.post(
					`${bookfairsAPI}/user/fairs/${currentFair}/coa/rejection`,
					{
						additionalDetails: additionalDetails,
						contactMethod: contactMethod,
						reasons: reasons,
					},
					{
						withCredentials: true,
					}
				)
				.then((res) => {
					window.open(
						`/content/fairs/hub/coa/coa-changes.html`,
						"_top"
					);
				})
				.catch(function (error) {
					handleGenericErrors(error);
				});
		} catch (err) {
			handleGenericErrors(err);
		}
		closeRejectModal();
	};

	return (
		<>
			{rejectionModalOpen && (
				<RejectionModal
					closeModal={closeRejectModal}
					handleSubmit={handleSubmit}
				/>
			)}
			{emailAgreementModalOpen && (
				<EmailAgreementModal
					closeModal={closeEmailAgreementModal}
					openEmailSuccessModal={openEmailSuccessModal}
					openEmailFailureModal={openEmailFailureModal}
				/>
			)}
			{emailSuccessModalOpen && (
				<EmailSuccessModal closeModal={closeEmailSuccessModal} />
			)}
			{emailFailureModalOpen && (
				<EmailFailureModal
					openEmailAgreementModal={openEmailAgreementModal}
					closeModal={closeEmailFailureModal}
				/>
			)}
			{blackoutCalendarModalOpen && (
				<BlackoutCalendarModal
					closeModal={closeBlackoutCalendarModal}
				/>
			)}
			<Grid>
				<Heading>{header}</Heading>
				<Container>
					<PrintEmailContainer>
						<PrintEmailInnerContainer>
							<BodyText>{shareDesc}</BodyText>
							<ButtonContainer>
								<PrintableComponent
									emailedPage={false}
									buttonText={"Print"}
								/>
								<IconButtonWhite
									iconAlt="Email"
									iconSrc={Email}
									iconWidth={14}
									handleClick={openEmailAgreementModal}
								>
									EMAIL
								</IconButtonWhite>
							</ButtonContainer>
						</PrintEmailInnerContainer>
					</PrintEmailContainer>
					<SchoolDetailsContainer>
						<SchoolTitle>{organizationData.name}</SchoolTitle>
						<SchoolDetails>
							<div>
								{organizationData.addressLine1}{" "}
								{organizationData.addressLine2}{" "}
								{organizationData.city},{" "}
								{organizationData.state}{" "}
								{organizationData.zipcode}
							</div>
							<div>
								Account #: {organizationData.bookfairAccountId}
							</div>
							<div>Fair ID: {fairInfoData.fairId}</div>
							<div>
								{`Fair Dates: ${convertYMDToMDY(
									fairInfoData.startDate
								)} - 
								${convertYMDToMDY(fairInfoData.endDate)}`}
							</div>
						</SchoolDetails>
					</SchoolDetailsContainer>
					{showUnavailableDates && (
						<DeliveryPickupDatesContainer>
							<Subheading>Delivery & pickup dates</Subheading>
							<BodyText>
								Please review your delivery and pickup windows
								and add any unavailable dates below.
							</BodyText>
							{/* TODO: Add new delivery window values */}
							<BodyText>
								<BoldText>{deliveryTitle}</BoldText>{" "}
								{deliveryText}
							</BodyText>
							<BodyText>
								<BoldText>{pickupTitle}</BoldText> {pickupText}
							</BodyText>
							<RedTextButton
								handleClick={openBlackoutCalendarModal}
							>
								Add unavailable dates
							</RedTextButton>
						</DeliveryPickupDatesContainer>
					)}
					<HorizontalBreak />
					<SchoolTypeContainer>
						<Subheading>{schoolType.header}</Subheading>
						<BodyText>{schoolTypeDescription}</BodyText>
					</SchoolTypeContainer>
					<HorizontalBreak />
					<TaxStatusContainer>
						<Subheading>{taxStatus.header}</Subheading>
						{organizationData.taxStatus === "OF" && (
							<>
								<TaxStatusOnFile>
									<Icon alt={`Green Checkmark`} src={Check} />
									<div>Tax Certificate on File</div>
								</TaxStatusOnFile>
								<TaxRateValue>
									Tax Rate: {fairInfoData.taxRate}%
								</TaxRateValue>
							</>
						)}
						{organizationData.taxStatus === "NF" && (
							<>
								<TaxStatusNotOnFile>
									<div>No Tax Certificate on File</div>
								</TaxStatusNotOnFile>
								<TaxRateValue>
									Tax Rate: {fairInfoData.taxRate}%
								</TaxRateValue>
								<TaxStatusNotOnFileCopy
									dangerouslySetInnerHTML={{
										__html: taxStatus.notOnFile.html,
									}}
								></TaxStatusNotOnFileCopy>
							</>
						)}
					</TaxStatusContainer>
					<HorizontalBreak />
					<PersonalInfoContainer>
						<Subheading>{chairpersonInfo.header}</Subheading>
						<PILowerContainer>
							<PILeftContainer>
								<BodyText>Contact:</BodyText>
								<BodyText>Phone:</BodyText>
								<BodyText>Email:</BodyText>
							</PILeftContainer>
							<PIRightContainer>
								<ChairInfoText>
									{chairpersonData.firstName}{" "}
									{chairpersonData.lastName}
								</ChairInfoText>
								<ChairInfoText>
									{chairpersonData.phoneNumber}
								</ChairInfoText>
								<ChairInfoText>
									{chairpersonData.email}
								</ChairInfoText>
							</PIRightContainer>
						</PILowerContainer>
					</PersonalInfoContainer>
					<LegalCopyContainer>
						{isCurrentServiceAgreement ? (
							<>
								<BodyText
									dangerouslySetInnerHTML={{
										__html: serviceTopCurrHtml,
									}}
								></BodyText>
								{serviceChartCurrHtml !== "" && (
									<ChartOuterContainer>
										<ChartInnerContainer>
											<ChartScrollContainer>
												<BodyText
													dangerouslySetInnerHTML={{
														__html: serviceChartCurrHtml,
													}}
												></BodyText>
											</ChartScrollContainer>
										</ChartInnerContainer>
										{fairInfoData.fairType === "case" && (
											<DisclaimerContainer>
												<ItalicFinePrint>
													**If a school presents an
													official statement from
													another school book fair
													operator evidencing that
													they earned more money from
													a fair that they ran with
													such other operator within
													one (1) year prior to their
													Scholastic Book Fair than
													they earned from such
													Scholastic Book Fair,
													Scholastic will pay the
													school the difference in
													promotional Scholastic
													Dollars in an amount equal
													to one (1) Scholastic Dollar
													for every $0.50 earned from
													the other operator. Such
													promotional Scholastic
													Dollars must be used within
													6 months of receipt by the
													school. Guarantee applies to
													amounts earned from other
													book fairs in cash only, no
													other forms of payment
													(books or other products,
													services, etc.) will be
													considered.
													<ExtraSmallFinePrint>
														Offer not valid for
														Virtual, BOGO, Tabletop
														and Sponsored Fairs.
													</ExtraSmallFinePrint>
												</ItalicFinePrint>
											</DisclaimerContainer>
										)}
									</ChartOuterContainer>
								)}
							</>
						) : (
							<>
								<BodyText
									dangerouslySetInnerHTML={{
										__html: serviceTopPrevHtml,
									}}
								></BodyText>
								{serviceChartPrevHtml !== "" && (
									<ChartOuterContainer>
										<ChartInnerContainer>
											<ChartScrollContainer>
												<BodyText
													dangerouslySetInnerHTML={{
														__html: serviceChartPrevHtml,
													}}
												></BodyText>
											</ChartScrollContainer>
										</ChartInnerContainer>
									</ChartOuterContainer>
								)}
							</>
						)}
						<BottomText
							dangerouslySetInnerHTML={{
								__html: serviceBottomHtml,
							}}
						></BottomText>
					</LegalCopyContainer>
					<CheckboxContainer>
						<Checkbox
							checked={acceptChecked}
							onChange={() => handleAcceptCheckbox()}
							label={checkboxDesc}
							alignItemsStart={true}
						/>
					</CheckboxContainer>
					<AcceptButtonContainer>
						<StyledRedButton
							disabled={!acceptChecked || acceptClicked}
							handleClick={handleAcceptClicked}
						>
							Accept
						</StyledRedButton>
						<StyledWhiteButton
							handleClick={openRejectModal}
							whiteBackground={true}
						>
							Request Change
						</StyledWhiteButton>
					</AcceptButtonContainer>
				</Container>
			</Grid>
		</>
	);
};

const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

const FlexRow = styled.div`
	display: flex;
`;

// Container Styling

const AcceptButtonContainer = styled(FlexRow)`
	gap: 24px;
	flex-wrap: wrap;
	margin-bottom: 12px;
	@media (max-width: 500px) {
		width: 100%;
	}
`;

const ButtonContainer = styled(FlexRow)`
	gap: 21px;
	flex-wrap: wrap;
	@media (max-width: 500px) {
		gap: 14px;
	}
`;

const Container = styled(FlexColumn)`
	align-items: flex-start;
	width: 100%;
	gap: 40px;
	grid-column-start: 1;
	grid-column-end: 24;
`;

const PrintEmailContainer = styled.div`
	background: #fff8f8;
	border: 1px solid #ffb3b3;
	width: 100%;
`;

const PrintEmailInnerContainer = styled(FlexColumn)`
	align-items: flex-start;
	gap: 23px;
	padding: 24px 39px 35px;
	@media (max-width: 500px) {
		padding: 24px 12px;
	}
`;

const ChartOuterContainer = styled(FlexColumn)`
	align-items: center;
`;

const ChartInnerContainer = styled.div`
	width: 100%;
	table {
		border-collapse: collapse;
	}
	td {
		padding: 7px 50px 3px 34px;
	}
	td:last-child {
		text-align: right;
	}

	tr:nth-child(even) {
		background-color: #f2f2f2;
	}

	tr:first-child {
		font-weight: 700;
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: left;
	}
`;

const ChartScrollContainer = styled.div`
	height: 100%;
	overflow-x: scroll;
	white-space: nowrap;
	width: 100%;
`;

const SchoolDetailsContainer = styled.div``;

const DeliveryPickupDatesContainer = styled(FlexColumn)`
	gap: 8px;
`;

const BoldText = styled.div`
	font-weight: 700;
	display: inline;
`;

const SchoolTypeContainer = styled(FlexColumn)`
	gap: 8px;
`;

const TaxStatusContainer = styled(FlexColumn)`
	gap: 16px;
`;

const LegalCopyContainer = styled(FlexColumn)`
	width: 100%;
	gap: 32px;
`;

const PersonalInfoContainer = styled(FlexColumn)`
	gap: 16px;
`;

const PILowerContainer = styled(FlexRow)`
	gap: 23px;
`;

const PILeftContainer = styled(FlexColumn)`
	gap: 15px;
	align-items: flex-end;
	padding-left: 55px;
	@media (max-width: 500px) {
		padding-left: 30px;
	}
`;

const PIRightContainer = styled(PILeftContainer)`
	align-items: flex-start;
	padding: unset;
`;

const CheckboxContainer = styled(FlexRow)`
	max-width: 501px;
`;

// Component Styling

const Heading = styled.div`
	font-weight: 500;
	font-size: 28px;
	line-height: 32px;
	color: #333333;
	grid-column-start: 1;
	grid-column-end: 24;
	margin-top: 48px;
	margin-bottom: 16px;
	@media (max-width: 400px) {
		font-size: 24px;
		line-height: 28px;
	}
`;

const Subheading = styled.div`
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 21px;
`;

const BodyText = styled.div`
	font-family: "museo-sans";
	font-weight: 300;
	font-size: 17px;
	line-height: 25px;
	ul {
		margin-left: 20px;
		list-style-type: disc;
	}

	ul li p {
		margin-left: 0px !important;
	}
`;

const ChairInfoText = styled(BodyText)`
	word-break: break-word;
	min-height: 25px;
`;

const BottomText = styled(BodyText)`
	font-weight: 300;
	font-size: 17px;
	line-height: 25px;
`;

const SchoolTitle = styled.div`
	font-weight: 500;
	font-size: 21px;
	line-height: 24px;
`;

const SchoolDetails = styled.div`
	font-weight: 300;
	font-size: 17px;
	line-height: 25px;
	color: #333333;
`;

const HorizontalBreak = styled.hr`
	width: 100%;
	height: 1px;
	border: none;
	background: #ebebeb;
`;

const TaxStatusOnFile = styled.div`
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	color: #008018;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
`;

const TaxStatusNotOnFileCopy = styled(BodyText)`
	margin-top: 8px;
	@media (max-width: 400px) {
		width: 95%;
	}
`;

const TaxStatusNotOnFile = styled(TaxStatusOnFile)`
	color: #b90000;
	margin-top: 2px;
`;

const TaxRateValue = styled(BodyText)`
	font-size: 17px;
	font-weight: 250;
	line-height: 25px;
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 159px;
	height: 40px;
`;

const StyledRedButton = styled(RedButton)`
	width: 130px;
	height: 40px;
	border-radius: 100px;
`;

const Icon = styled.img`
	width: 14px;
`;

const ItalicFinePrint = styled.p`
	font-size: 17px;
	font-style: italic;
`;

const ExtraSmallFinePrint = styled.span`
	font-size: 15px;
`;

const DisclaimerContainer = styled.div`
	display: inline-block;
	margin-top: 5px;
	margin-bottom: 10px;
`;
