import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import FairInformationComponent from "components/Homepage/FairInformationComponent";
import BeforeTheFairComponent from "components/Homepage/BeforeTheFairComponent";
import ChairpersonContactComponent from "components/Homepage/ChairpersonContactComponent";
import { ReactComponent as Logo } from "images/Scholastic.svg";
import useHomepageData from "hooks/HomepageBuilder/useHomepageData";
import VolunteerSection from "components/Homepage/VolunteerSection";
import ShopMoreComponent from "components/Homepage/ShopMoreComponent";
import PreviewFlyerComponent from "components/Homepage/PreviewFlyerSection/PreviewFlyerComponent";
import { trackAccount, trackPageName } from "DumbleData";
import SocialShareIconsComponent from "components/Homepage/SocialShareIconsComponent";
import { useConfig } from "providers/ConfigContext";
import FooterComponent from "components/Homepage/FooterComponent";

export const Homepage = () => {
	const { schoolName } = useParams();
	const { damAssetsOrigin } = useConfig();
	const {
		goalData,
		fairInfoData,
		eventsData,
		contactData,
		isLoading,
		isError,
		fairId,
		schoolUCN,
		volunteerData,
		shareHomepageData,
		ofeData,
		fairType,
		isVirtual,
		eWalletFlag,
		eWalletStartDate,
		eWalletEndDate,
	} = useHomepageData({
		fairId: "",
		url: schoolName,
		isPublic: true,
	});

	const isAfter = fairType === "after";
	const displayOnlineShoppingStatuses = ["Active", "Submitted", "Complete"];

	useEffect(() => {
		trackAccount(`${fairId}`, schoolUCN);
		trackPageName(`${document.title}:${schoolUCN}:${fairId}`);
	}, [fairId, schoolUCN]);

	if (isLoading || isError) {
		return null;
	}

	return (
		<StyledHomepage>
			<RedNav>
				<StyledLogo />
			</RedNav>
			{isAfter ? (
				<StyledHeaderImage
					src={`${damAssetsOrigin}LiveHomepage/intro/image_after.png`}
					alt="Thank you for supporting our Book Fair!"
				></StyledHeaderImage>
			) : (
				<StyledHeaderImage
					src={`${damAssetsOrigin}LiveHomepage/intro/image_welcome.png`}
					alt="We're having a Book Fair!"
				></StyledHeaderImage>
			)}
			<FairInformationComponent
				fairInfoData={fairInfoData}
				goalData={goalData}
				afterFair={isAfter}
			/>
			<StyledFairInfoGraphic
				src={`${damAssetsOrigin}LiveHomepage/intro/img_hero.png`}
				alt=""
			></StyledFairInfoGraphic>
			<SocialShareIconsComponent
				customUrl={shareHomepageData.customUrl}
			/>
			{!isAfter && (
				<BeforeTheFairComponent
					fairId={fairId}
					isVirtual={isVirtual}
					schoolUCN={schoolUCN}
					startDate={fairInfoData.startDate}
					endDate={fairInfoData.endDate}
					eventsData={eventsData.schedule}
					url={shareHomepageData.customUrl}
					eWalletFlag={eWalletFlag}
					eWalletStartDate={eWalletStartDate}
					eWalletEndDate={eWalletEndDate}
				/>
			)}

			{!(isAfter || isVirtual) && (
				<PreviewFlyerComponent
					productId={fairInfoData.productId}
					k8Flag={fairInfoData.k8Flag}
				/>
			)}

			{!(isAfter || isVirtual) && volunteerData.volunteerSignUpUrl && (
				<VolunteerSection
					volunteerForm={volunteerData.volunteerSignUpUrl}
				/>
			)}
			{displayOnlineShoppingStatuses.includes(ofeData.ofeStatus) && (
				<>
					<ShopMoreComponent fairId={fairId} ofeData={ofeData} />
				</>
			)}

			<ChairpersonContactComponent
				contactData={contactData}
				fairInfoData={fairInfoData}
				afterFair={isAfter}
				fairId={fairId}
				isVirtual={isVirtual}
				eWalletFlag={eWalletFlag}
			/>
			<FooterComponent />
		</StyledHomepage>
	);
};

const StyledLogo = styled(Logo)`
	margin-top: 24px;
`;

const StyledHeaderImage = styled.img`
	width: 100%;
	max-width: 920px;
	margin-top: 8px;
`;

const StyledFairInfoGraphic = styled.img`
	width: 100%;
	max-width: 920px;
	margin: 19px 0 -1px 0;
`;

const StyledHomepage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	grid-column: 1/24;
	box-sizing: border-box;
	background-color: #77d7e0;
`;

const RedNav = styled.div`
	width: 100%;
	height: 70px;
	position: relative;
	top: 0;
	background: #e81111;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;
