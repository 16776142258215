import React from "react";
import styled from "styled-components/macro";

interface Props {
	className?: string;
	children?: any;
}
const Card = ({ children, className }: Props) => {
	return <CardContainer className={className}>{children}</CardContainer>;
};
const CardContainer = styled.div`
	padding: 0 24px 32px;
	height: 400px;
	box-sizing: border-box;
	max-width: 440px;
	border-radius: 0px;
	background: #ffffff;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
	position: relative;
	@media (max-width: 720px) {
		max-height: 407px;
		min-width: 308px;
		margin-left: auto;
		margin-right: auto;
	}
	@media (max-width: 375px) {
		width: 335px;
	}
`;
export default Card;
