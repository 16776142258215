import React, { useEffect, useState, useCallback, useRef } from "react";
import styled from "styled-components/macro";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { addDays, subDays } from "date-fns";
import { Page } from "components/HomepageBuilder/Page";
import Heading from "components/Heading";
import PageIcon from "components/HomepageBuilder/PageIcon";
import MessageIcon from "images/Message.svg";
import RedArrow from "images/RedArrow.svg";
import BodyText from "components/BodyText";
import TextInput from "components/TextInput";
import { RedButton } from "components/Button";
import TextLink from "components/TextLink";
import Checkbox from "components/Checkbox";
import LimitedTextArea from "components/LimitedTextArea";
import SelectInput from "components/DropDownSelect";
import { useConfig } from "providers/ConfigContext";
import { DatePickerComponent } from "components/DatePicker";
import { useFairInfoData } from "./Builder";
import { convertDateToString } from "utils/DateConversion";
import SaveChangesModal from "components/HomepageBuilder/SaveChangesModal";
import Prompt from "components/HomepageBuilder/Prompt";
import { trackSaveAndPublish } from "DumbleData";
import IframeContainer from "components/IframeContainer";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";
const UsaStates = require("usa-states").UsaStates;

export const InformationPage = () => {
	const {
		fairId,
		fairType,
		fairInfoData,
		isVirtual,
		nextLocation,
		setNextLocation,
	} = useFairInfoData();
	const {
		schoolName,
		defaultStartDate,
		defaultEndDate,
		endDate,
		startDate,
		location,
		address,
		city,
		state,
		zipcode,
		welcomeMessage,
		cash,
		checks,
		creditCard,
		thankYouMessage,
		payableTo,
	} = fairInfoData;
	const [isThankYouMessage, setIsThankYouMessage] = useState<boolean>(false);
	const [errorSchoolName, setErrorSchoolName] = useState<string>();
	const [errorEndDate, setErrorEndDate] = useState<string>();
	const [errorStartDate, setErrorStartDate] = useState<string>();
	const [errorAddress, setErrorAddress] = useState<string>();
	const [errorCity, setErrorCity] = useState<string>();
	const [errorState, setErrorState] = useState<string>();
	const [errorZipcode, setErrorZipcode] = useState<string>();
	const [errorLocation, setErrorLocation] = useState<string>();
	const [errorWelcomeMessage, setErrorWelcomeMessage] = useState<string>();
	const [errorThankYouMessage, setErrorThankYouMessage] = useState<string>();
	const [errorPayableTo, setErrorPayableTo] = useState<string>();
	const [attemptedToSubmit, setAttemptedToSubmit] = useState<boolean>(false);
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const { bookfairsAPI, damAssetsOrigin } = useConfig();
	const navigate = useNavigate();
	const usStates = new UsaStates();
	const StatesOptions: Object = usStates.states.map(
		(s: any) => s.abbreviation
	);
	const firstRender = useRef(true);
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const [allowNavigation, setAllowNavigation] = useState(false);
	const [savedData] = useState({ fairInfoData });
	const virtual = isVirtual ? "virtual" : "";
	const afterFair = fairType === "after";
	const nextPage =
		isVirtual || afterFair
			? "/reactapp/customize/share"
			: "/reactapp/customize/schedule";
	const [isStartCalendarOpen, setIsStartCalendarOpen] =
		useState<boolean>(false);
	const [isEndCalendarOpen, setIsEndCalendarOpen] = useState<boolean>(false);

	const openStartCalendar = () => {
		setIsStartCalendarOpen(true);
		setIsEndCalendarOpen(false);
	};
	const openEndCalendar = () => {
		setIsEndCalendarOpen(true);
		setIsStartCalendarOpen(false);
	};
	const resetCalendars = () => {
		setIsStartCalendarOpen(false);
		setIsEndCalendarOpen(false);
	};

	// Subtracts 5 days from default start date for End Date datepicker
	// If date has passed, returns current date
	const calcEndDateDefaultStart = () => {
		const fiveDaysEarlier = subDays(defaultEndDate, 5);
		const currentDate = new Date();
		if (currentDate > fiveDaysEarlier) {
			return currentDate;
		} else {
			return fiveDaysEarlier;
		}
	};
	// Checks if all required fields are supplied
	const dataValidate = useCallback(() => {
		let isAllValid = true;
		if (!schoolName) {
			setErrorSchoolName("Please enter the school name.");
			isAllValid = false;
		} else {
			setErrorSchoolName(undefined);
		}
		if (!endDate) {
			setErrorEndDate("Please enter an end date within range.");
			isAllValid = false;
		} else {
			setErrorEndDate(undefined);
		}
		if (!startDate) {
			setErrorStartDate("Please enter a start date within range.");
			isAllValid = false;
		} else {
			setErrorStartDate(undefined);
		}
		if (endDate < startDate) {
			setErrorEndDate(
				"Please enter an end date that follows the start date."
			);
			isAllValid = false;
		} else {
			setErrorEndDate(undefined);
		}
		if (!address) {
			setErrorAddress("Please enter a valid address.");
			isAllValid = false;
		} else {
			setErrorAddress(undefined);
		}
		if (location && location.length > 40) {
			setErrorLocation("Please do not exceed 40 characters.");
			isAllValid = false;
		} else {
			setErrorLocation(undefined);
		}
		if (!city) {
			setErrorCity("Please enter a valid city.");
			isAllValid = false;
		} else {
			setErrorCity(undefined);
		}
		if (!state) {
			setErrorState("Please enter a valid state.");
			isAllValid = false;
		} else {
			setErrorState(undefined);
		}
		// 5-digit zip code regular expression
		const pattern = /^[0-9]{5}$/;
		if (!pattern.test(zipcode)) {
			setErrorZipcode("Please enter a valid zip code.");
			isAllValid = false;
		} else {
			setErrorZipcode(undefined);
		}
		if (!welcomeMessage) {
			setErrorWelcomeMessage("Please enter a welcome message.");
			isAllValid = false;
		} else {
			setErrorWelcomeMessage(undefined);
		}
		// Opens up thank you message box if left blank
		if (!thankYouMessage) {
			setErrorThankYouMessage("Please enter a thank you message.");
			setIsThankYouMessage(true);
			isAllValid = false;
		} else {
			setErrorThankYouMessage(undefined);
		}
		if (checks && !payableTo) {
			setErrorPayableTo("Please enter where the checks are payable to.");
			isAllValid = false;
		} else {
			setErrorPayableTo(undefined);
		}
		return isAllValid;
	}, [
		schoolName,
		endDate,
		startDate,
		address,
		location,
		city,
		state,
		zipcode,
		welcomeMessage,
		thankYouMessage,
		checks,
		payableTo,
	]);

	// Validates input after every change once the user has tried to submit the form once
	useEffect(() => {
		if (attemptedToSubmit) {
			dataValidate();
		}
	}, [attemptedToSubmit, dataValidate]);

	// Attempt to save updates to backend
	const saveFairInfo = async () => {
		const currentFair = getFairIdFromUrl();
		setAllChangesSaved(true);
		if (checkSpsUd()) {
			try {
				if (dataValidate()) {
					await axios
						.put(
							`${bookfairsAPI}/user/fairs/${currentFair}/homepage`,
							{
								fairId: parseInt(fairId),
								schoolName: schoolName,
								fairStartDate: convertDateToString(startDate),
								fairEndDate: convertDateToString(endDate),
								fairLocation: location,
								schoolAddress1: address,
								schoolCity: city,
								schoolState: state,
								schoolPostalcode: zipcode,
								welcomeMessage: welcomeMessage,
								thankYouMessage: thankYouMessage,
								paymentCashCkbox: cash ? "Y" : "N",
								paymentCheckCkbox: checks ? "Y" : "N",
								paymentCcCkbox: creditCard ? "Y" : "N",
								checkDescription: payableTo,
							},
							{ withCredentials: true }
						)
						.then((response) => {
							if (response.status === 200) {
								trackSaveAndPublish(
									"fair-information",
									"Fair Information"
								);
								setAllowNavigation(true);
							} else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							handleGenericErrors(err);
						});
				} else {
					setAttemptedToSubmit(true);
					setSaveChangesModalOpen(false);
				}
			} catch (err) {
				handleGenericErrors(err);
			}
		}
	};

	//
	// Logic to handle navigation and rendering the SaveChangesModal
	//
	//Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			navigate(nextLocation);
		}
	}, [
		allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		nextLocation,
	]);

	//Tracks if there are changes made
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		} else {
			setAllChangesSaved(false);
		}
	}, [
		schoolName,
		defaultStartDate,
		defaultEndDate,
		endDate,
		startDate,
		location,
		address,
		city,
		state,
		zipcode,
		welcomeMessage,
		cash,
		checks,
		creditCard,
		thankYouMessage,
		payableTo,
	]);

	///////////////////////////////

	return (
		<IframeContainer
			renderItems={() => (
				<>
					<StyledInformationPage>
						<PageIcon
							src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/FairInformation.svg`}
							defaultImg={MessageIcon}
							alt="Information Form Icon"
						/>
						<StyledHeading>Fair information</StyledHeading>
						<StyledBodyTextSubheading>
							Welcome visitors to your Fair with a personal
							message. Then write a message to appear on your
							homepage after your Fair to thank shoppers for
							supporting your school.
						</StyledBodyTextSubheading>
						<Wrapper>
							<FlexRow onFocus={() => resetCalendars()}>
								<TextInput
									label="School Name"
									value={fairInfoData.schoolName}
									onChange={fairInfoData.setSchoolName}
									error={errorSchoolName}
								/>
							</FlexRow>
							<FlexRow>
								<InlineElement
									onFocus={() => openStartCalendar()}
								>
									<DatePickerComponent
										label="Start Date"
										value={fairInfoData.startDate}
										onChange={fairInfoData.setStartDate}
										defaultStart={subDays(
											defaultStartDate,
											5
										)}
										defaultEnd={addDays(
											defaultStartDate,
											5
										)}
										error={errorStartDate}
										isOpen={isStartCalendarOpen}
										onClick={() =>
											setIsStartCalendarOpen(true)
										}
										disabled={isVirtual || afterFair}
										className={virtual}
										afterFair={afterFair}
									/>
								</InlineElement>
								<InlineElement
									onFocus={() => openEndCalendar()}
								>
									<DatePickerComponent
										label="End Date"
										value={fairInfoData.endDate}
										onChange={fairInfoData.setEndDate}
										defaultStart={calcEndDateDefaultStart()}
										defaultEnd={addDays(defaultEndDate, 5)}
										error={errorEndDate}
										isOpen={isEndCalendarOpen}
										onClick={() =>
											setIsEndCalendarOpen(true)
										}
										disabled={isVirtual || afterFair}
										className={virtual}
										afterFair={afterFair}
									/>
								</InlineElement>
							</FlexRow>
							{!isVirtual && (
								<FlexRow onFocus={() => resetCalendars()}>
									<TextInput
										label="Book Fair location (Optional: e.g. gym, front lobby, etc.)"
										value={fairInfoData.location}
										onChange={fairInfoData.setLocation}
										error={errorLocation}
									/>
								</FlexRow>
							)}
							<FlexRow>
								<InlineElement>
									<TextInput
										label="Address"
										value={fairInfoData.address}
										onChange={fairInfoData.setAddress}
										error={errorAddress}
										className={virtual}
										tabIndex={isVirtual ? -1 : undefined}
									/>
								</InlineElement>
								<InlineElement>
									<TextInput
										label="City"
										value={fairInfoData.city}
										onChange={fairInfoData.setCity}
										error={errorCity}
										className={virtual}
										tabIndex={isVirtual ? -1 : undefined}
									/>
								</InlineElement>
							</FlexRow>
							<FlexRow>
								<InlineElement>
									<SelectInput
										label={"State"}
										optionChosen={fairInfoData.state}
										onChange={fairInfoData.setState}
										options={StatesOptions}
										disabled={false}
										defaultText="AL"
										error={errorState}
										className={virtual}
										tabIndex={isVirtual ? -1 : 0}
									/>
								</InlineElement>
								<InlineElement>
									<TextInput
										label="Zip Code"
										value={fairInfoData.zipcode}
										onChange={fairInfoData.setZipcode}
										error={errorZipcode}
										className={virtual}
										tabIndex={isVirtual ? -1 : 0}
									/>
								</InlineElement>
							</FlexRow>
							{!afterFair && (
								<>
									<FlexRow>
										<LimitedTextArea
											label="Welcome Message"
											value={fairInfoData.welcomeMessage}
											onChange={
												fairInfoData.setWelcomeMessage
											}
											error={errorWelcomeMessage}
											limit={175}
										/>
									</FlexRow>
									<StyledExpandTextArea>
										<StyledImg
											alt="arrow"
											isThankyoumessage={
												isThankYouMessage
											}
											src={RedArrow}
											onClick={(e) => {
												setIsThankYouMessage(
													!isThankYouMessage
												);
											}}
										/>
										<StyledButton
											onClick={(e) => {
												setIsThankYouMessage(
													!isThankYouMessage
												);
											}}
											style={{
												padding: "0 0 0 8px",
											}}
										>
											Customize your post Fair thank you
											message
										</StyledButton>
									</StyledExpandTextArea>
								</>
							)}
							{(isThankYouMessage || afterFair) && (
								<FlexRow afterFair={afterFair}>
									<StyledLimitedTextArea
										label={
											afterFair
												? "Thank You Message"
												: undefined
										}
										value={fairInfoData.thankYouMessage}
										onChange={
											fairInfoData.setThankYouMessage
										}
										error={errorThankYouMessage}
										limit={175}
									/>
								</FlexRow>
							)}
							{!isVirtual && (
								<LeftAlignWrapper>
									<StyledBodyTextPayment>
										In-School payment options
									</StyledBodyTextPayment>
									<StyledBodyTextPaymentCaption>
										These payment options will be displayed
										on your homepage. Uncheck any box to
										remove it as a form of payment.
									</StyledBodyTextPaymentCaption>
									<ChecksRow>
										<StyledCheckbox
											label="Checks made payable to:"
											checked={fairInfoData.checks}
											onChange={fairInfoData.setChecks}
										/>
										<OuterSpan>
											<StyledPayableToSchool
												value={fairInfoData.payableTo}
												onChange={
													fairInfoData.setPayableTo
												}
												error={errorPayableTo}
												label="School name"
												fontSize={"13px"}
											/>
										</OuterSpan>
									</ChecksRow>
									<StyledCheckboxOther
										label="Cash"
										checked={fairInfoData.cash}
										onChange={fairInfoData.setCash}
									/>
									<StyledCheckboxOther
										label="Credit Cards"
										checked={fairInfoData.creditCard}
										onChange={fairInfoData.setCreditCard}
									/>
								</LeftAlignWrapper>
							)}
						</Wrapper>
						<StyledRedButton
							handleClick={() => {
								setNextLocation(nextPage);
								saveFairInfo();
							}}
						>
							save & publish
						</StyledRedButton>
						<StyledTextLink
							handleClick={() => {
								if (!allChangesSaved) {
									setNextLocation(nextPage);
									setSaveChangesModalOpen(
										!saveChangesModalOpen
									);
								} else {
									navigate(nextPage);
								}
							}}
						>
							Skip
						</StyledTextLink>
					</StyledInformationPage>

					<Prompt
						message={() => {
							setSaveChangesModalOpen(true);
						}}
						when={!allChangesSaved}
					/>
					<SaveChangesModal
						saveAndPublish={saveFairInfo}
						discardChanges={() => {
							fairInfoData.setStartDate(
								savedData.fairInfoData.startDate
							);
							fairInfoData.setEndDate(
								savedData.fairInfoData.endDate
							);
							fairInfoData.setSchoolName(
								savedData.fairInfoData.schoolName
							);
							fairInfoData.setLocation(
								savedData.fairInfoData.location
							);
							fairInfoData.setAddress(
								savedData.fairInfoData.address
							);
							fairInfoData.setCity(savedData.fairInfoData.city);
							fairInfoData.setState(savedData.fairInfoData.state);
							fairInfoData.setZipcode(
								savedData.fairInfoData.zipcode
							);
							fairInfoData.setWelcomeMessage(
								savedData.fairInfoData.welcomeMessage
							);
							fairInfoData.setThankYouMessage(
								savedData.fairInfoData.thankYouMessage
							);
							fairInfoData.setCash(savedData.fairInfoData.cash);
							fairInfoData.setCreditCard(
								savedData.fairInfoData.creditCard
							);
							fairInfoData.setChecks(
								savedData.fairInfoData.checks
							);
							fairInfoData.setPayableTo(
								savedData.fairInfoData.payableTo
							);
							setAllChangesSaved(true);
							setAllowNavigation(true);
						}}
						modalIsOpen={saveChangesModalOpen}
						handleModal={() => {
							setSaveChangesModalOpen(!saveChangesModalOpen);
						}}
					/>
				</>
			)}
		/>
	);
};

/*
	Page Specific Styles
*/
const StyledHeading = styled(Heading)`
	padding-bottom: 14px;
`;
const StyledBodyTextSubheading = styled(BodyText)`
	padding-bottom: 31px;
	max-width: 346px;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 440px;
`;
const StyledRedButton = styled(RedButton)`
	width: 181px;
	margin-top: 71.5px;
`;
const StyledTextLink = styled(TextLink)`
	margin-top: 23px;
`;
const StyledInformationPage = styled(Page)`
	padding-bottom: 95px;
	@media (max-width: 719px) {
		padding-bottom: 103px;
	}
	@media (max-width: 375px) {
		padding-bottom: 74px;
		padding-left: 21px;
		padding-right: 20px;
	}
`;
const FlexRow = styled.div<{ afterFair?: boolean }>`
	margin-top: ${(props) => (props.afterFair ? "25px" : "unset")};
	display: inline-flex;
	width: 100%;
	gap: 40px;
	@media (max-width: 734px) {
		gap: 14px;
	}
`;

const ChecksRow = styled.div`
	display: flex;
	width: 100%;
	flex: row;
	flex-direction: row;
	position: relative;
	height: 78px;
	align-items: center;
`;

const OuterSpan = styled.span`
	position: absolute;
	right: 0px;
	top: 0;
`;
const InlineElement = styled.div`
	flex: 1 auto;
	width: calc(50% - 20px);
`;

const LeftAlignWrapper = styled.div`
	padding-top: 28px;
	border-top: 1px solid #e3e3e3;
	width: 100%;
	flex-direction: column;
`;

const StyledCheckbox = styled(Checkbox)`
	margin-top: 20px;
	@media (max-width: 450px) {
		width: 113px;
		margin-bottom: 22px;
	}
`;

const StyledCheckboxOther = styled(Checkbox)`
	margin-bottom: 20px;
	@media (max-width: 450px) {
		margin-bottom: 22px;
	}
`;

const StyledLimitedTextArea = styled(LimitedTextArea)`
	margin-top: -22px;
	margin-bottom: 22.5px;
`;

const StyledExpandTextArea = styled.div`
	background: none;
	border: none;
	color: #e81111;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	margin: 18px 0 28.5px 0;
	align-content: center;
	display: inline-flex;
	width: 100%;
`;

const StyledButton = styled.button`
	background: none;
	border: none;
	color: #e81111;
	&:hover {
		cursor: pointer;
		color: #b90000;
		border-bottom: 1px solid #b90000;
		padding-bottom: 3px;
		margin-bottom: -4px;
	}
	&:focus,
	:focus-visible {
		color: #b90000;
		border: 2px solid #b90000;
		box-sizing: border-box;
		border-radius: 4px;
		outline: none;
		padding: 1px;
	}
`;
const StyledBodyTextPaymentCaption = styled(BodyText)`
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	margin-bottom: 16px;
`;

const StyledBodyTextPayment = styled(BodyText)`
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	margin-bottom: 15px;
`;
const StyledPayableToSchool = styled(TextInput)`
	margin-bottom: 0;
	color: black;
	line-height: 19px;
	width: 160px;
	#textInputErrorLabel {
		bottom: -35px;
	}
	::placeholder {
		color: #333333;
	}
`;

interface ImgProps {
	isThankyoumessage?: boolean;
}

const StyledImg = styled.img<ImgProps>`
	transform: ${(props) =>
		props.isThankyoumessage ? "none" : "rotate(-90deg)"};
`;
