import React from "react";
import styled from "styled-components/macro";
import Heading from "components/Heading";
import { RedButton } from "components/Button";
import TextLink from "components/TextLink";

interface Props {
	saveAndPublish(): any;
	discardChanges(): any;
	modalIsOpen: boolean;
	handleModal(): any;
}

const SaveChangesModal = ({
	saveAndPublish,
	discardChanges,
	modalIsOpen,
}: Props) => {
	return (
		<>
			{modalIsOpen && (
				<StyledModal>
					<Container>
						<Heading>Would you like to save changes?</Heading>
						<StyledRedButton handleClick={saveAndPublish}>
							save & publish
						</StyledRedButton>
						<StyledTextLink handleClick={discardChanges}>
							discard changes
						</StyledTextLink>
					</Container>
				</StyledModal>
			)}
		</>
	);
};

/*
	Page Specific Styles
*/

const StyledRedButton = styled(RedButton)`
	margin-top: 86px;
	width: 181px;
	@media (max-width: 720px) {
		margin-top: 49px;
	}
	@media (max-width: 375px) {
		margin-top: 47px;
	}
`;
const StyledTextLink = styled(TextLink)`
	margin-top: 31px;
`;

const StyledModal = styled.div`
	top: 0;
	position: absolute;
	z-index: 2000;
	background: white;
	width: 100%;
	height: 100%;
`;

const Container = styled.div`
	top: 253px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 32px;
	padding-left: 47px;
	padding-right: 47px;
	grid-column: 1/24;
	box-sizing: border-box;
	@media (max-width: 720px) {
		top: 112px;
	}
	@media (max-width: 375px) {
		top: 92px;
	}
`;

export default SaveChangesModal;
