import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { convertYMDToMDY } from "utils/DateConversion";
import { FairSelectorModal } from "./FairSelector/FairSelectorModal";
import { getFairIdFromUrl } from "utils/HubUtility";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { trackChangeFair, updateFairBarData } from "DumbleData";

export interface SavedFairBasicInfo {
	type: string;
	name: string;
	startDate: string;
	endDate: string;
}

interface Props {
	StandAlone?: boolean;
}

export const FairInfoBar = ({ StandAlone }: Props) => {
	const { bookfairsAPI } = useConfig();
	const [fairSelectorModalOpen, setFairSelectorModalOpen] = useState(false);

	const [fairData, setFairData] = useState<SavedFairBasicInfo | null>(null);
	const [enableSwitch, setEnableSwitch] = useState<boolean>(true);

	const openFairSelectorModal = () => {
		setFairSelectorModalOpen(true);
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};

	const closeFairSelectorModal = () => {
		setFairSelectorModalOpen(false);
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
	};

	useEffect(() => {
		const fetchCurrentFair = async () => {
			// if fair id in url, returns fair id, otherwise returns 'current'
			const currentFair = getFairIdFromUrl();

			try {
				const response = await axios.get(
					`${bookfairsAPI}/user/fairs/${currentFair}?mode=DO_NOT_SELECT`,
					{ withCredentials: true }
				);
				if (response.status !== 200) {
					handleGenericSuccess(response);
					return;
				}

				// Check if the fair switcher should be enabled
				const responseEnableSwitch = response.data.enableSwitch;
				setEnableSwitch(responseEnableSwitch);

				const fairID = response.data.fair.id;
				const item = localStorage.getItem(`fairBasicInfo-${fairID}`);
				let tempfairBasicInfo = item
					? (JSON.parse(item) as SavedFairBasicInfo)
					: null;

				if (!tempfairBasicInfo) {
					const response = await axios.get(
						`${bookfairsAPI}/user/fairs/${fairID}/basic-info`,
						{ withCredentials: true }
					);
					if (response.status !== 200) {
						handleGenericSuccess(response);
						return;
					}

					tempfairBasicInfo = {
						type: response.data.fair.type,
						name: response.data.fair.name,
						startDate: response.data.fair.startDate,
						endDate: response.data.fair.endDate,
					};
					localStorage.setItem(
						`fairBasicInfo-${fairID}`,
						JSON.stringify(tempfairBasicInfo)
					);
				}
				if (!StandAlone) {
					updateFairBarData(
						tempfairBasicInfo.name,
						tempfairBasicInfo.startDate,
						tempfairBasicInfo.endDate
					);
				}
				setFairData(tempfairBasicInfo);
			} catch (e) {
				handleGenericErrors(e);
			}
		};
		fetchCurrentFair();
	}, [bookfairsAPI, StandAlone]);
	return (
		<>
			<FairInfoBarDiv>
				<Grid>
					{fairData && (
						<>
							<FairName>{fairData.name}</FairName>
							<FairDate addExtraPadding={!enableSwitch}>
								Fair Dates:{" "}
								{convertYMDToMDY(fairData.startDate)} -{" "}
								{convertYMDToMDY(fairData.endDate)}
							</FairDate>
							{enableSwitch && (
								<StyledTextLink
									onClick={() => {
										trackChangeFair();
										openFairSelectorModal();
									}}
									tabIndex={1}
									onMouseDown={(e) => e.preventDefault()}
								>
									<span>Change Fair</span>
								</StyledTextLink>
							)}
						</>
					)}
				</Grid>
			</FairInfoBarDiv>
			{fairSelectorModalOpen && (
				<FairSelectorModal
					closeFairSelectorModal={closeFairSelectorModal}
					StandAlone={StandAlone}
				/>
			)}
		</>
	);
};

const FairInfoBarDiv = styled.div`
	padding: 16px 0;
`;

const FairName = styled.div`
	color: var(--text-primary, #333);
	text-align: center;
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 35px;
	margin-top: 24px;

	@media (max-width: 375px) {
		font-size: 21px;
		line-height: 24px; /* 114.286% */
	}
`;

interface FairDateProps {
	addExtraPadding: boolean;
}
const FairDate = styled.div<FairDateProps>`
	margin-top: 8px;
	color: var(--text-secondary, #666);
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	margin-bottom: ${(props) => (props.addExtraPadding ? "24px" : "0px")};
`;

const Grid = styled.div`
	display: grid;
	margin: 0 auto;
	grid-template-columns: none;
	width: -webkit-fill-available;
	width: -moz-available;
	max-width: 1240px;

	/* IE friendly.....*/
	display: -ms-grid;
	-ms-grid-columns: none;
	border-bottom: 1px solid var(--grey-grey-6, #d7d7d7);

	@media (max-width: 1599px) {
		margin: 0px 180px;
	}

	@media (max-width: 1279px) {
		margin: 0px 140px;
	}

	@media (max-width: 959px) {
		margin: 0px 40px;
	}

	@media (max-width: 519px) {
		margin: 0px 20px;
	}
`;

const StyledTextLink = styled.button`
	margin-top: 8px;
	background: none;
	border: none;
	text-decoration: none;
	font-style: normal;
	color: var(--text-default-clifford-red, #e81111);
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	margin-bottom: 24px;
	&:hover {
		color: #000000;
		text-decoration: none;
		cursor: pointer;
	}
	&:active {
		color: #000000;
	}
	&:focus-within span {
		outline: 2px solid #e81111;
	}
	&:focus,
	:focus-visible {
		outline: none;
	}
`;

export default FairInfoBar;
