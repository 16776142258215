import { useConfig } from "providers/ConfigContext";
import { useRef, useEffect } from "react";
import styled from "styled-components/macro";

interface Props {
	renderItems: (fairId?: string | null) => React.ReactNode;
}

const IframeContainer = ({ renderItems }: Props) => {
	const pageRef = useRef<HTMLDivElement>(null);
	const resizeObserver = useRef<ResizeObserver>();
	const pageHeight = useRef<number>(150);
	const fairId = useRef<string | null>(null);
	const { bookfairsOrigin, StageBookfairsOrigin, BookfairsMessageOrigin } =
		useConfig();

	useEffect(() => {
		let trueBookfairsOrigin: URL;
		if (bookfairsOrigin) {
			trueBookfairsOrigin = new URL(bookfairsOrigin);
		}

		function messageHandler(event: MessageEvent<any>) {
			// Check if origin matches AEM domains
			if (
				event.origin === BookfairsMessageOrigin ||
				event.origin === trueBookfairsOrigin.origin ||
				(event.origin === StageBookfairsOrigin &&
					event.source === window.parent)
			) {
				if (event.data.id === "fairId") {
					fairId.current = event.data.fairID;
				}
				if (!resizeObserver.current) {
					// Initialize a new ResizeObserver to monitor the page
					resizeObserver.current = new ResizeObserver((entries) => {
						// Get the new height
						const newHeight = entries[0].borderBoxSize[0].blockSize;
						if (newHeight !== pageHeight.current) {
							pageHeight.current = newHeight;
							// Post a message to AEM with new height
							window.parent.postMessage(newHeight, event.origin);
						}
					});
					// Start observing
					if (pageRef.current) {
						resizeObserver.current.observe(pageRef.current);
						window.parent.postMessage(
							pageRef.current.clientHeight,
							event.origin
						);
					}
				}
			}
		}

		// Listen for messages from AEM
		window.addEventListener("message", messageHandler);

		// Cleanup, disconnect observer and remove event listener
		return function cleanup() {
			resizeObserver.current?.disconnect();
			window.removeEventListener("message", messageHandler);
		};
	}, [bookfairsOrigin, StageBookfairsOrigin, BookfairsMessageOrigin]);

	return (
		<StyledContainer ref={pageRef}>
			{renderItems(fairId.current)}
		</StyledContainer>
	);
};

const StyledContainer = styled.div`
	background-color: white;
`;

export default IframeContainer;
