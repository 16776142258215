import { useState, useCallback } from "react";

const useContactPageData = () => {
	const [firstName, setFirstName] = useState<string>("");
	const [lastName, setLastName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	// TODO: Convert boolean to "Y"/"N" when making POST request
	const [displayPhoneNumber, setDisplayPhoneNumber] =
		useState<boolean>(false);

	const contactData = {
		firstName,
		setFirstName,
		lastName,
		setLastName,
		email,
		setEmail,
		phoneNumber,
		setPhoneNumber,
		displayPhoneNumber,
		setDisplayPhoneNumber,
	};

	// Set the initial contact data from the GET request
	const setContactData = useCallback(
		(
			firstName: string,
			lastName: string,
			email: string,
			phoneNumber: string,
			displayPhoneNumber: string
		) => {
			if (firstName) {
				setFirstName(firstName);
			}
			if (lastName) {
				setLastName(lastName);
			}
			if (email) {
				setEmail(email);
			}
			if (phoneNumber) {
				setPhoneNumber(phoneNumber);
			}
			if (displayPhoneNumber && displayPhoneNumber === "Y") {
				setDisplayPhoneNumber(true);
			}
		},
		[]
	);

	return { contactData, setContactData };
};

export default useContactPageData;
