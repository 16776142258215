import { useState, useCallback } from "react";
import { convertStringToDate } from "utils/DateConversion";

const useOfeData = () => {
	const tempDate = new Date();
	// Determines whether or not to display the online shopping section on the live homepage
	const [ofeStatus, setOfeStatus] = useState<string>("");
	// Dates displayed in the online shopping section
	const [ofeStartDate, setOfeStartDate] = useState<Date>(tempDate);
	const [ofeEndDate, setOfeEndDate] = useState<Date>(tempDate);

	// Set the initial data from the /get request
	const setOfeData = useCallback(
		(
			initOfeStatus: string,
			initOfeStartDate: string,
			initOfeEndDate: string
		) => {
			if (initOfeStatus) {
				setOfeStatus(initOfeStatus);
			}
			if (initOfeStartDate) {
				setOfeStartDate(convertStringToDate(initOfeStartDate));
			}
			if (initOfeEndDate) {
				setOfeEndDate(convertStringToDate(initOfeEndDate));
			}
		},
		[]
	);

	const ofeData = {
		ofeStatus,
		ofeStartDate,
		ofeEndDate,
	};

	return { ofeData, setOfeData };
};

export default useOfeData;
