import React, { useEffect, useState } from "react";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components/macro";
import RedTextButton from "../RedTextButton";
import { useConfig } from "providers/ConfigContext";

type Props = {
	homepageUrl: string;

	fairId: string;
};

const BookFairHomepageCard = ({ homepageUrl, fairId }: Props) => {
	const { BookfairsHomepageOrigin } = useConfig();
	const [formattedUrl, setFormattedUrl] = useState<string>(homepageUrl);
	const [showCopied, setShowCopied] = useState(false);

	const handleCustomizeUrl = () => {
		window._satellite.track("customize-book-fair-homepage", {
			"button-text": "customize-book-fair-homepage",
		});
		window.open(
			`/content/fairs/hub/hmpg-builder.html?fairID=${fairId}`,
			"_blank"
		);
	};

	const handleCopyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(
				BookfairsHomepageOrigin + homepageUrl
			);
			// Displays the alert, then hides it after 2 seconds
			setShowCopied(true);
			setTimeout(() => {
				setShowCopied(false);
			}, 2000);
			window._satellite.track("copy-book-fair-url", {
				"button-text": "copy-book-fair-url",
			});
		} catch (err) {
			console.error(err);
		}
	};

	// trim url if longer than 10 characters (36 including https://scholastic.com/bf)
	useEffect(() => {
		if (homepageUrl) {
			let formattedUrl = homepageUrl;
			if (formattedUrl.length > 10) {
				formattedUrl = formattedUrl.substring(0, 11) + "...";
			}
			setFormattedUrl(formattedUrl);
		}
	}, [homepageUrl]);

	return (
		<FairDetailsCard
			title={"Book Fair Homepage"}
			linkText={"CUSTOMIZE"}
			handleLinkClicked={handleCustomizeUrl}
		>
			<OuterContainer>
				<LeftContainer>
					<StyledUrl>{`https://scholastic.com/bf/${formattedUrl}`}</StyledUrl>
					<RedTextButton handleClick={handleCopyToClipboard}>
						{showCopied ? "URL Copied!" : "Copy URL"}
					</RedTextButton>
				</LeftContainer>
				<RightContainer>
					<RedTextLink
						href={BookfairsHomepageOrigin + homepageUrl}
						target="_blank"
						rel="noopener"
						tabIndex={1}
						onClick={() => {
							window._satellite.track("view-homepage", {
								"button-text": "view-homepage",
							});
						}}
					>
						View Homepage
					</RedTextLink>
				</RightContainer>
			</OuterContainer>
		</FairDetailsCard>
	);
};

const OuterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	row-gap: 16px;
	flex-wrap: wrap;
	column-gap: 15px;
`;

const LeftContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const RightContainer = styled.div``;

const StyledUrl = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	margin-right: 5px;
`;

const RedTextLink = styled.a`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #e81111;
	display: inline;
	text-decoration: none;
	&:hover {
		font-style: unset;
		text-decoration: underline;
		cursor: pointer;
	}
	&:focus-visible {
		outline: 2px solid #e81111;
		color: #e81111;
		outline-offset: 1px;
		margin-top: 1px;
	}
`;

export default BookFairHomepageCard;
