import React from "react";
import styled from "styled-components/macro";
import scholasticLogo from "images/ScholasticLogoWhiteOutline.svg";
import { useConfig } from "providers/ConfigContext";

const FooterComponent = () => {
	const { DoNotSellOrigin } = useConfig();
	return (
		<StyledFooter>
			<StyledLogoLink
				href="http://www.scholastic.com/"
				target="_blank"
				rel="noreferrer"
			>
				<StyledLogo src={scholasticLogo} alt="Scholastic Logo" />
			</StyledLogoLink>
			<StyledPrivacyPolicyLinks>
				<StyledPrivacyPolicyLink
					href="https://www.scholastic.com/privacy.htm"
					target="_blank"
					rel="noreferrer"
				>
					<StyledPrivacyPolicyLinkUnderlined>
						Privacy Policy
					</StyledPrivacyPolicyLinkUnderlined>
				</StyledPrivacyPolicyLink>
				<StyledMiddle>
					<StyledFooterLink
						href="https://www.scholastic.com/terms.htm"
						target="_blank"
						rel="noreferrer"
					>
						Terms of Use
					</StyledFooterLink>
					<StyledFooterLink
						href={
							DoNotSellOrigin + "content/corp-home/donotsell.html"
						}
						rel="opener"
						referrerPolicy="no-referrer-when-downgrade"
						target="_blank"
					>
						Do Not Sell My Info
					</StyledFooterLink>
					<StyledFooterLink
						href={"https://www.scholastic.com/site/privacy.html#7"}
						rel="opener"
						target="_blank"
					>
						California Privacy Notice
					</StyledFooterLink>
				</StyledMiddle>
				<StyledFooterLink
					href="https://www.scholastic.com/aboutscholastic/"
					target="_blank"
					rel="noreferrer"
				>
					About Scholastic
				</StyledFooterLink>
			</StyledPrivacyPolicyLinks>
			<StyledDescription>
				TM ® & © {new Date().getFullYear()} Scholastic Inc. All Rights
				Reserved.
			</StyledDescription>
		</StyledFooter>
	);
};

const StyledFooter = styled.div`
	background-color: #333333;
	width: 100%;
	color: white;
	font-family: museo-sans;
	font-size: 13px;
	line-height: 1.23;
	padding: 40px 0px;
	min-height: 22px;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	gap: 40px;
	@media (max-width: 1175px) {
		flex-direction: column;
		align-items: center;
		row-gap: 20px;
	}
	@media (max-width: 750px) {
		margin-bottom: 66px;
	}
	@media (max-width: 350px) {
		margin-bottom: 85px;
	}
	@media (max-width: 330px) {
		margin-bottom: 95px;
	}
`;

const StyledPrivacyPolicyLinks = styled.div`
	margin-top: 5px;
	display: flex;
	flex-direction: row;
	gap: 40px;
	@media (max-width: 680px) {
		flex-direction: column;
		align-items: center;
		row-gap: 10px;
	}
`;

const StyledMiddle = styled.div`
	display: flex;
	gap: 40px;

	@media (max-width: 450px) {
		gap: 20px;
		flex-wrap: wrap;
		justify-content: center;
	}
`;

const StyledDescription = styled.div`
	margin-top: 7px;
	font-size: 11px;
	text-align: center;
`;

const StyledFooterLink = styled.a`
	padding-bottom: 2px;
	text-decoration: none;
	color: white;
	&:hover {
		cursor: pointer;
		border-bottom: solid 1.5px white;
	}
	&:focus-visible {
		outline: 2px solid white;
	}
`;

const StyledPrivacyPolicyLinkUnderlined = styled.span`
	transition: all 0.1s ease-in-out;
	&:hover {
		padding-bottom: 2px;
		border-bottom: solid 1.5px white;
		color: white;
	}
`;

const StyledPrivacyPolicyLink = styled(StyledFooterLink)`
	color: #70a8f4;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	font-size: 14.5px;
	font-weight: 700;
	line-height: 14.5px;
	&:hover {
		border-bottom: solid 0px;
	}
`;

const StyledLogo = styled.img`
	width: 174px;
	height: 22px;
	padding-left: 80px;
	@media (max-width: 1175px) {
		padding-left: 0px;
	}
`;

const StyledLogoLink = styled.a`
	&:focus-visible {
		outline: 2px solid white;
	}
`;

export default FooterComponent;
