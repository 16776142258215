import { Transaction } from "hooks/SDBalance/useSDBalanceData";
import React, { useState } from "react";
import styled from "styled-components";
import { convertYMDToMDYYYY } from "utils/DateConversion";

const DataTableRow = ({
	date,
	description,
	transactionAmount,
	balance,
}: Transaction) => {
	const [isExpanded, setExpanded] = useState(false);

	const handleExpandRow = () => {
		setExpanded(!isExpanded);
	};

	return (
		<>
			<Row>
				<td onClick={handleExpandRow}>{convertYMDToMDYYYY(date)}</td>
				<td onClick={handleExpandRow} className="description">
					{description}
				</td>
				<td onClick={handleExpandRow}>
					{transactionAmount.toLocaleString("en-US", {
						style: "currency",
						currency: "USD",
					})}
				</td>
				<td onClick={handleExpandRow}>
					{balance.toLocaleString("en-US", {
						style: "currency",
						currency: "USD",
					})}
				</td>
			</Row>
		</>
	);
};

const Row = styled.tr`
	height: 36px;
	color: #333333;
	td {
		border-bottom: 1px solid rgba(51, 51, 51, 0.25);
		padding: 8px;
	}

	td.description {
		min-width: 252px;
	}
`;

export default DataTableRow;
