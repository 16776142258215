import { AEMModalContainer } from "components/AEMModalContainer";
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { SchoolSelectorContent } from "./SchoolSelectorContent";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { getSchoolIdFromUrl } from "utils/HubUtility";
import { updateSchoolBarData, trackChangeSchoolLink } from "DumbleData";

interface School {
	id: string;
	name: string;
}
const schoolDefault = { id: "", name: "" };

interface Props {
	StandAlone?: boolean;
	handleSetSchoolId?: (schoolId: string) => void;
}

export const SchoolSelectorBar = ({ StandAlone, handleSetSchoolId }: Props) => {
	const { bookfairsAPI } = useConfig();
	const [savedSchoolData, setSavedSchoolData] = useState<School | null>(
		schoolDefault
	);
	const [enableSwitch, setEnableSwitch] = useState<boolean>(true);

	const [schoolSelectorModalOpen, setSchoolSelectorModalOpen] =
		useState(false);

	const openSchoolSelectorModal = () => {
		setSchoolSelectorModalOpen(true);
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};

	const closeSchoolSelectorModal = () => {
		setSchoolSelectorModalOpen(false);
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
	};

	const handleClickChangeSchool = () => {
		trackChangeSchoolLink();
		openSchoolSelectorModal();
	};

	useEffect(() => {
		const fetchCurrentSchool = async () => {
			const currentSchool = getSchoolIdFromUrl();

			try {
				const response = await axios.get(
					`${bookfairsAPI}/user/schools/${currentSchool}?mode=DO_NOT_SELECT`,
					{ withCredentials: true }
				);

				if (response.status !== 200) {
					handleGenericSuccess(response);
					return;
				}

				if (response.data.school.id === null) {
					window.open(
						`/content/fairs/hub/school-selector.html`,
						"_top"
					);
					return;
				}

				// Check if the school switcher should be enabled
				const responseEnableSwitch = response.data.enableSwitch;
				setEnableSwitch(responseEnableSwitch);

				const schoolID = response.data.school.id;

				// if on sales history page, set school ID for coa and invoice buttons
				handleSetSchoolId && handleSetSchoolId(schoolID);
				const item = localStorage.getItem(
					`SchoolBasicInfo-${schoolID}`
				);
				let schoolBasicInfo = item ? JSON.parse(item) : null;

				if (!schoolBasicInfo) {
					const response = await axios.get(
						`${bookfairsAPI}/user/schools/selected/basic-info`,
						{ withCredentials: true }
					);

					if (response.status !== 200) {
						handleGenericSuccess(response);
						return;
					}

					schoolBasicInfo = {
						id: response.data.school.id,
						name: response.data.school.name,
					};

					localStorage.setItem(
						`SchoolBasicInfo-${schoolID}`,
						JSON.stringify(schoolBasicInfo)
					);
				}
				if (!StandAlone) {
					updateSchoolBarData(
						schoolBasicInfo.name,
						schoolBasicInfo.id
					);
				}
				setSavedSchoolData(schoolBasicInfo);
			} catch (e) {
				handleGenericErrors(e);
			}
		};
		fetchCurrentSchool();
	}, [bookfairsAPI, StandAlone, handleSetSchoolId]);

	return (
		<>
			<SchoolSelectorBarDiv>
				<Grid>
					{true && (
						<>
							<SchoolName>{savedSchoolData?.name}</SchoolName>
							<SchoolAccount addExtraPadding={!enableSwitch}>
								Account #: {savedSchoolData?.id}
							</SchoolAccount>
							{enableSwitch && (
								<StyledTextLink
									onClick={handleClickChangeSchool}
									tabIndex={1}
									onMouseDown={(e) => e.preventDefault()}
								>
									<span>Change School</span>
								</StyledTextLink>
							)}
						</>
					)}
				</Grid>
			</SchoolSelectorBarDiv>
			{schoolSelectorModalOpen && (
				<AEMModalContainer closeModal={closeSchoolSelectorModal}>
					<SchoolSelectorContent isModal={true} />
				</AEMModalContainer>
			)}
		</>
	);
};

const SchoolSelectorBarDiv = styled.div`
	padding: 16px 0;
`;

const SchoolName = styled.div`
	color: var(--text-primary, #333);
	text-align: center;
	font-size: 32px;
	font-style: normal;
	font-weight: 500;
	line-height: 35px;
	margin-top: 24px;

	@media (max-width: 375px) {
		font-size: 21px;
		line-height: 24px; /* 114.286% */
	}
`;

interface SchoolAccountProps {
	addExtraPadding: boolean;
}
const SchoolAccount = styled.div<SchoolAccountProps>`
	margin-top: 8px;
	color: var(--text-secondary, #666);
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	margin-bottom: ${(props) => (props.addExtraPadding ? "47px" : "0px")};
`;

const Grid = styled.div`
	display: grid;
	margin: 0 auto;
	grid-template-columns: none;
	width: -webkit-fill-available;
	width: -moz-available;
	max-width: 1240px;

	/* IE friendly.....*/
	display: -ms-grid;
	-ms-grid-columns: none;
	border-bottom: 1px solid var(--grey-grey-6, #d7d7d7);

	@media (max-width: 1599px) {
		margin: 0px 180px;
	}

	@media (max-width: 1279px) {
		margin: 0px 140px;
	}

	@media (max-width: 959px) {
		margin: 0px 40px;
	}

	@media (max-width: 519px) {
		margin: 0px 20px;
	}
`;

const StyledTextLink = styled.button`
	margin-top: 8px;
	background: none;
	border: none;
	text-decoration: none;
	font-style: normal;
	color: var(--text-default-clifford-red, #e81111);
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	margin-bottom: 24px;
	&:hover {
		color: #000000;
		text-decoration: none;
		cursor: pointer;
	}
	&:active {
		color: #000000;
	}
	&:focus-within span {
		outline: 2px solid #e81111;
	}
	&:focus,
	:focus-visible {
		outline: none;
	}
`;

export default SchoolSelectorBar;
