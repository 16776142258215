import React from "react";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components/macro";

interface Props {
	firstName: string;
	lastName: string;
	email: string;
}

const MyContactsCard = ({ firstName, lastName, email }: Props) => {
	return (
		<FairDetailsCard title={"My Contacts"}>
			<Body>Consultant</Body>
			<Body>
				{firstName} {lastName}
			</Body>
			<RedTextLink
				href={`mailto:${email}`}
				onClick={() => {
					window._satellite.track("email-link", {
						"button-text": "email-link",
					});
				}}
			>
				{email}
			</RedTextLink>
		</FairDetailsCard>
	);
};

const Body = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
`;

const RedTextLink = styled.a`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #e81111;
	display: inline;
	text-decoration: none;
	&:hover {
		font-style: unset;
		text-decoration: underline;
		cursor: pointer;
	}
	&:focus-visible {
		outline: 2px solid #e81111;
		color: #e81111;
		outline-offset: 1px;
		margin-top: 1px;
	}
`;

export default MyContactsCard;
