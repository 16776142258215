import FinancialFormSaveChangesModal from "components/FinancialForm/FinancialFormSaveChangesModal";
import Prompt from "components/HomepageBuilder/Prompt";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
	getFinFormProgress,
	setFinFormUrlsLocalStorage,
} from "utils/FinFormLocalStorageUtility";

// Notify AEM that a modal is being opened
const openSaveChangesModal = () => {
	window.parent.postMessage("openModal", window.location.origin);
	document.documentElement.classList.add("bookfairs-modal--open");
};

/* Parameter descriptions
 * changeableValues: an array of all the fields that will be saved in the UI/backend, should be cached using useMemo to ensure there are no unecessary updates
 * validateAndSaveChanges: a function that will check for input errors and make a network request to save the values on the backend
 * fairId: self-explanatory
 * FinFormUrlsStep: an enum value that represents the current step of the fin form you are on, see utils/FinFormCookieUtility for proper values
 * saveData: function that saves all data to the backend, what you would normally run when clicking save & continue
 * nextDestination: string that determines where the user will be routed next after clicking save and continue
 */
const useSaveChangesFinForm = (
	changeableValues: any[],
	validateAndSaveChanges: () => void,
	fairId: string,
	FinFormUrlsStep: number,
	saveData: () => void,
	nextDestination: string
) => {
	// Value that determines if all changes have been saved on a page
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	// Keeps track of whether the modal is open or closed
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	// Determines whether the user can safely navigate away from the current page
	const [allowNavigation, setAllowNavigation] = useState(false);
	// Keeps track of where the user is trying to navigate to
	const [navDestination, setNavDestination] = useState("");
	// Keeps track of whether the page is on the initial render
	const firstRender = useRef(true);
	const navigate = useNavigate();

	// Prevents the save changes modal from activating when data is first initialized
	// Otherwise, every time a value is changed, trigger the save changes modal to appear
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		} else {
			setAllChangesSaved(false);
		}
	}, [changeableValues]);

	// Navigates the user to their intended destination after clicking save/discard changes
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			const savedFinFormProgress = getFinFormProgress(fairId);
			if (savedFinFormProgress === FinFormUrlsStep) {
				setFinFormUrlsLocalStorage(fairId, navDestination);
			}
			navigate(navDestination);
		}
	}, [
		allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		navDestination,
		fairId,
		FinFormUrlsStep,
	]);

	// Generate the prompt and save changes modal components that will appear on the page. Should be added at the very bottom of the UI. See FinancialFormSalesContent.tsx for an example
	const generatePromptAndModal = () => {
		return (
			<>
				<Prompt
					message={() => {
						// Open the modal and notify AEM
						setSaveChangesModalOpen(true);
						openSaveChangesModal();
					}}
					when={!allChangesSaved}
				/>
				<FinancialFormSaveChangesModal
					saveAndPublish={() => {
						// Validate and save data
						validateAndSaveChanges();
						// Close the modal
						setSaveChangesModalOpen(false);
					}}
					discardChanges={() => {
						setAllChangesSaved(true);
						setAllowNavigation(true);
						setSaveChangesModalOpen(false);
					}}
					modalIsOpen={saveChangesModalOpen}
					handleModal={() => {
						setSaveChangesModalOpen(!saveChangesModalOpen);
					}}
				/>
			</>
		);
	};

	// Function that saves all data, sets the user's next destination, and disables save changes modal from appearing
	// Use this function as the click handler for your save & continue button
	const saveAndContinue = async () => {
		await saveData();
		setNavDestination(nextDestination);
		setAllChangesSaved(true);
		setAllowNavigation(true);
	};

	return {
		generatePromptAndModal,
		saveAndContinue,
		setNavDestination,
		setAllowNavigation,
		setAllChangesSaved,
		allChangesSaved,
	};
};

export default useSaveChangesFinForm;
