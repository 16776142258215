export type salesInfoErrors = {
	taxExemptSDExceeds: boolean;
	taxCollectedSDExceeds: boolean;
	taxExemptSalesExceeds: boolean;
	tenderTotalExceedsSTF: boolean;
	tenderTotalZero: boolean;
	generalError: boolean;
};

export const salesInfoErrorsDefault = {
	taxExemptSDExceeds: false,
	taxCollectedSDExceeds: false,
	taxExemptSalesExceeds: false,
	tenderTotalExceedsSTF: false,
	tenderTotalZero: false,
	generalError: false,
};

type NewSalesInfo = {
	SDTaxExemptSales: number;
	SDRedeemed: number;
	SDTaxCollected: number;
	cashChecks: number;
	creditCards: number;
	purchaseOrdersTotal: number;
	taxExemptSales: number;
	STFFCollected: number;
	maxTaxExemptSales: number;
};

// Validate errors on the sales page in the totals section
const validateSalesDataTotals = ({
	SDTaxExemptSales,
	SDRedeemed,
	SDTaxCollected,
	cashChecks,
	creditCards,
	purchaseOrdersTotal,
	taxExemptSales,
	STFFCollected,
	maxTaxExemptSales,
}: NewSalesInfo) => {
	const tempSalesErrors = {
		taxExemptSDExceeds: false,
		taxCollectedSDExceeds: false,
		taxExemptSalesExceeds: false,
		tenderTotalExceedsSTF: false,
		tenderTotalZero: false,
		generalError: false,
	};

	// Tax Exempt SD error
	tempSalesErrors.taxExemptSDExceeds = SDTaxExemptSales > SDRedeemed;
	// Tax Collected SD error
	tempSalesErrors.taxCollectedSDExceeds = SDTaxCollected > SDRedeemed;

	const tenderTotals = cashChecks + creditCards + purchaseOrdersTotal;

	// Tax Exempt Sales error
	tempSalesErrors.taxExemptSalesExceeds = taxExemptSales > maxTaxExemptSales;
	// Tender total exceeds STF error
	tempSalesErrors.tenderTotalExceedsSTF = tenderTotals < STFFCollected;
	// Tender total zero error
	tempSalesErrors.tenderTotalZero = tenderTotals === 0;

	// General error
	tempSalesErrors.generalError =
		tempSalesErrors.taxExemptSDExceeds ||
		tempSalesErrors.taxCollectedSDExceeds ||
		tempSalesErrors.taxExemptSalesExceeds ||
		tempSalesErrors.tenderTotalExceedsSTF ||
		tempSalesErrors.tenderTotalZero;

	return tempSalesErrors;
};

export default validateSalesDataTotals;
