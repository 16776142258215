import { getFairIdFromUrl } from "./HubUtility";

export const handleCOASuccess = (msg: any) => {
	const currentFair = getFairIdFromUrl();
	if (
		msg.status === 204 &&
		msg.headers["sbf-jarvis-reason"] === "NEEDS_COA_CONFIRMATION"
	) {
		if (currentFair !== "current") {
			window.open(
				`/content/fairs/hub/coa.html?fairID=${currentFair}`,
				"_top"
			);
		} else {
			window.open(`/content/fairs/hub/coa.html`, "_top");
		}
		return true;
	}
	return false;
};

export const handleCOAErrors = (err: any) => {
	sessionStorage.setItem("errorCode", err.response?.status);
	sessionStorage.setItem(
		"bfSelectedFairId",
		err.response?.headers["sbf-jarvis-resource-id"]
	);
	if (err.response?.status === 404) {
		window.open(`/content/fairs/hub/db-error.html`, "_top");
		console.error(err);
		return true;
	}
	return false;
};

export const handleGenericSuccess = (msg: any) => {
	if (msg.status === 204) {
		if (msg.headers["sbf-jarvis-reason"] === "NO_ASSOCIATED_RESOURCES") {
			sessionStorage.setItem("errorCode", msg.status);
			sessionStorage.setItem(
				"bfSelectedFairId",
				msg.headers["sbf-jarvis-resource-id"]
			);
			//User is logged in, but they do not have access to the toolkit.
			window.open(`/content/fairs/hub/error-unauthorized.html`, "_top");
			return true;
		}
		if (msg.headers["sbf-jarvis-reason"] === "NO_SCHL") {
			//User is not logged in
			window.open(`/content/fairs/hub/login.html`, "_top");
			return true;
		}
		if (msg.headers["sbf-jarvis-reason"] === "SELECTION_REQUIRED") {
			//User needs to select a school
			window.open(`/content/fairs/hub/school-selector.html`, "_top");
			return true;
		}
	}
	return false;
};
export const handleGenericErrors = (err: any) => {
	sessionStorage.setItem("errorCode", err.response?.status);
	sessionStorage.setItem(
		"bfSelectedFairId",
		err.response?.headers["sbf-jarvis-resource-id"]
	);
	if (
		err.response?.status === 401 ||
		(err.response?.status === 403 &&
			err.response?.headers["sbf-jarvis-reason"] ===
				"CURRENT_FAIR_EXPIRED") ||
		err.response?.headers["sbf-jarvis-reason"] === "RESOURCE_ID_NOT_VALID"
	) {
		//User is trying to access a fair that they do not have on their account or this fair has expired.
		window.open(`/content/fairs/hub/error-unauthorized.html`, "_top");
		return true;
	} else {
		//Something went wrong.
		console.error(err);
		window.open(`/content/fairs/hub/error.html`, "_top");
	}
};
