import styled from "styled-components/macro";
import { useConfig } from "providers/ConfigContext";
import ContactIcon from "images/Contact.svg";
import ActivitiesIcon from "images/Activities.svg";
import InfoNotepadIcon from "images/InfoNotepad.svg";
import PencilIcon from "images/InfoPencil.svg";
import ShareIcon from "images/ViewAndShare.svg";
import GoalTargetIcon from "images/GoalTarget.svg";
import ArrowIcon from "images/GoalArrow.svg";
import VolunteersIcon from "images/Volunteers.svg";
import PageIcon from "components/HomepageBuilder/PageIcon";
import IconCaption from "components/IconCaption";

interface Props {
	virtualFair?: boolean;
	afterFair?: boolean;
}

export const IconList = ({ virtualFair, afterFair }: Props) => {
	const { damAssetsOrigin } = useConfig();
	return (
		<StyledIconList>
			<IconComponent>
				<StyledLink href="/reactapp/customize/contact">
					<PageIcon
						src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/Contact.svg`}
						alt="Contact Form Icon"
						defaultImg={ContactIcon}
					/>
					<IconCaption>Your contact information</IconCaption>
				</StyledLink>
			</IconComponent>
			<IconComponent>
				<StyledLink href="/reactapp/customize/goals">
					<StyledArrowIcon
						src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/GoalArrow.svg`}
						defaultImg={ArrowIcon}
					/>
					<PageIcon
						src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/GoalTarget.svg`}
						alt="Goal Icon"
						defaultImg={GoalTargetIcon}
					/>
					<IconCaption>Set a goal</IconCaption>
				</StyledLink>
			</IconComponent>
			<IconComponent>
				<StyledLink href="/reactapp/customize/information">
					<StyledPencilIcon
						src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/InfoPencil.svg`}
						defaultImg={PencilIcon}
					/>
					<PageIcon
						src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/InfoNotepad.svg`}
						alt="Fair Information Icon"
						defaultImg={InfoNotepadIcon}
					/>
					<IconCaption>Fair information</IconCaption>
				</StyledLink>
			</IconComponent>
			{!virtualFair && !afterFair && (
				<>
					<IconComponent>
						<StyledLink href="/reactapp/customize/schedule">
							<PageIcon
								src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/Activities.svg`}
								alt="Activities Icon"
								defaultImg={ActivitiesIcon}
							/>
							<IconCaption>Schedule activities</IconCaption>
						</StyledLink>
					</IconComponent>
					<IconComponent>
						<StyledLink href="/reactapp/customize/volunteer">
							<PageIcon
								src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/Volunteers.svg`}
								alt="Volunteers Icon"
								defaultImg={VolunteersIcon}
							/>
							<IconCaption>Recruit volunteers</IconCaption>
						</StyledLink>
					</IconComponent>
				</>
			)}
			<IconComponent>
				<StyledLink href="/reactapp/customize/share">
					<PageIcon
						src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/View%20&%20Share.svg`}
						alt="View and Share Icon"
						defaultImg={ShareIcon}
					/>
				</StyledLink>
				<IconCaption>Share your homepage</IconCaption>
			</IconComponent>
		</StyledIconList>
	);
};

const StyledArrowIcon = styled.img<{ defaultImg: string }>`
	position: absolute;
	padding-bottom: 100px;
	padding-left: 70px;
	background-image: ${(props) => `url(${props.defaultImg})`};
	background-repeat: no-repeat;
	background-position-x: 70px;
`;
const StyledPencilIcon = styled.img<{ defaultImg: string }>`
	position: absolute;
	padding-bottom: 95px;
	padding-left: 70px;
	background-image: ${(props) => `url(${props.defaultImg})`};
	background-repeat: no-repeat;
	background-position-x: 70px;
`;
const StyledLink = styled.a`
	text-decoration: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
const StyledIconList = styled.div`
	display: flex;
	flex-direction: row;
	padding-top: 75px;
	padding-bottom: 81px;
	grid-column: 1/24;
	flex-wrap: wrap;
	gap: 40px;
	position: relative;
	justify-content: center;
	@media (max-width: 719px) {
		max-width: 614px;
		align-content: flex-start;
		column-gap: 40px;
		row-gap: 65px;
	}
	@media (max-width: 397px) {
		column-gap: 30px;
		row-gap: 49px;
	}
	@media (max-width: 367px) {
		column-gap: 40px;
		row-gap: 49px;
	}
`;
const IconComponent = styled.div``;
