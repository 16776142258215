import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import EWalletCard from "../EWalletCard";
import {
	CoChair,
	Consultant,
	EWallet,
	FairInfo,
	OnlineFair,
	OrgStfBalance,
} from "hooks/FairDetails/useFairDetailsData";
import OnlineFairCard from "../OnlineFairCard";
import BookFairHomepageCard from "../BookFairHomepageCard";
import PaymentPortalCard from "../PaymentPortalCard";
import CoHostAccessCard from "../CoHostAccessCard";
import MyContactsCard from "../MyContactsCard";
import DeliveryPickupCard from "../DeliveryPickupCard";
import RestocksCard from "../RestocksCard";
import { convertYMDStringToDate } from "utils/DateConversion";
import ShareTheFairFundsCard from "../ShareTheFairFundsCard";
import FairTermsCard from "../FairTermsCard";

interface Props {
	fairInfo: FairInfo;
	eWallet: EWallet;
	orgStfBalance: OrgStfBalance;
	onlineFair: OnlineFair;
	consultant: Consultant;
	coChairs: CoChair[] | undefined;
	loggedInEmail: string;
	iamUcn: string;
	fairId: string;
}

const FairDetailsPageContent = ({
	fairInfo,
	eWallet,
	orgStfBalance,
	onlineFair,
	consultant,
	coChairs,
	loggedInEmail,
	iamUcn,
	fairId,
}: Props) => {
	const [showUnavailableDates, setShowUnavailableDates] =
		useState<boolean>(false);
	useEffect(() => {
		if (fairInfo.startDate) {
			const currentDate = new Date();
			const fairStart = convertYMDStringToDate(fairInfo.startDate);
			const oneMonthBeforeStart = new Date(fairStart);
			oneMonthBeforeStart.setMonth(fairStart.getMonth() - 1);

			if (
				fairInfo.fairType !== "bogo case" &&
				fairInfo.fairType !== "case"
			) {
				setShowUnavailableDates(false);
			} else if (currentDate < oneMonthBeforeStart) {
				setShowUnavailableDates(true);
			} else {
				setShowUnavailableDates(false);
			}
		}
	}, [fairInfo.startDate, fairInfo.fairType]);

	const [showRestockBeforeCopy, setShowRestockBeforeCopy] =
		useState<boolean>(false);
	const [restockEndDate, setRestockEndDate] = useState<Date>(new Date());
	const [showRestockButton, setShowRestockButton] = useState<boolean>(false);

	useEffect(() => {
		const currentDate = new Date();
		const deliveryDate = convertYMDStringToDate(fairInfo.deliveryDate);
		const endDate = new Date(
			convertYMDStringToDate(fairInfo.endDate).setHours(12)
		);

		const twoDaysBeforeEnd = new Date(
			endDate.setDate(endDate.getDate() - 2)
		);
		setRestockEndDate(twoDaysBeforeEnd);

		// module should be hidden if type virtual
		if (fairInfo.fairType.toLowerCase() !== "virtual") {
			// copy should change 2 days prior to the end of the fair (Fair end date)
			// restock button should only show during the fair
			if (currentDate < twoDaysBeforeEnd) {
				setShowRestockBeforeCopy(true);
				if (currentDate >= deliveryDate) {
					setShowRestockButton(true);
				}
			} else {
				setShowRestockBeforeCopy(false);
				setShowRestockButton(false);
			}
		} else {
			setShowRestockBeforeCopy(false);
			setShowRestockButton(false);
		}
	}, [fairInfo.deliveryDate, fairInfo.endDate, fairInfo.fairType]);

	return (
		<Grid>
			<FullWidth>
				<Heading>
					<HeadingTitle>Fair Details and Settings</HeadingTitle>
					<HeadingFairInfo>
						<HeadingFairInfoText>
							Account #: {fairInfo.bookfairAccountId}
						</HeadingFairInfoText>
						<HeadingFairInfoText>
							Fair ID: {fairInfo.fairId}
						</HeadingFairInfoText>
					</HeadingFairInfo>
				</Heading>
				<EWalletCard
					sales={eWallet.sales}
					unspentFunds={eWallet.unspentFunds}
					startDate={eWallet.startDate}
					endDate={eWallet.endDate}
					enabled={eWallet.enabled}
					fairId={fairInfo.fairId}
				/>
			</FullWidth>
			<LeftColumn>
				{(fairInfo.fairType.toLowerCase() === "tabletop" ||
					fairInfo.fairType.toLowerCase() === "bogo tabletop") && (
					<PaymentPortalCard
						startDate={fairInfo.startDate}
						endDate={fairInfo.endDate}
						fairId={fairInfo.fairId}
					/>
				)}
				<BookFairHomepageCard
					homepageUrl={fairInfo.fairUrl}
					fairId={fairId}
				/>
				<OnlineFairCard
					sales={onlineFair.sales}
					startDate={onlineFair.startDate}
					endDate={onlineFair.endDate}
					enabled={onlineFair.enabled}
				/>
				<CoHostAccessCard
					coChairData={coChairs}
					currentUserEmail={loggedInEmail}
					consultantEmail={consultant.email}
				/>
			</LeftColumn>
			<RightColumn>
				<DeliveryPickupCard
					iamUcn={iamUcn}
					fairType={fairInfo.fairType}
					showUnavailableDates={showUnavailableDates}
				/>
				<FairTermsCard
					taxRate={fairInfo.taxRate}
					taxStatus={fairInfo.taxStatus}
				/>
				{orgStfBalance.balance != null && (
					<ShareTheFairFundsCard balance={orgStfBalance.balance} />
				)}
				<RestocksCard
					fairId={fairInfo.fairId}
					schoolName={fairInfo.name}
					startDate={fairInfo.startDate}
					endDate={fairInfo.endDate}
					showRestockBeforeCopy={showRestockBeforeCopy}
					restockEndDate={restockEndDate}
					showRestockButton={showRestockButton}
					fairType={fairInfo.fairType}
					productId={fairInfo.productId}
				/>
				<MyContactsCard
					firstName={consultant.firstName}
					lastName={consultant.lastName}
					email={consultant.email}
				/>
			</RightColumn>
		</Grid>
	);
};
export const Heading = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	margin: 48px 0;
	@media (max-width: 720px) {
		flex-direction: column;
		justify-content: unset;
		gap: 16px;
	}
`;

export const HeadingTitle = styled.p`
	font-weight: 500;
	font-size: 39px;
	line-height: 42px;
	color: #333333;
	@media (max-width: 720px) {
		font-size: 36px;
		line-height: 39px;
	}
`;
const HeadingFairInfo = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 720px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const HeadingFairInfoText = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 15px;
	text-align: right;
	color: #333333;
`;

const FullWidth = styled.div`
	grid-column-start: 1;
	grid-column-end: 24;
`;
const LeftColumn = styled.div`
	grid-column-start: 1;
	grid-column-end: 16;
	@media (max-width: 720px) {
		grid-column-start: 1;
		grid-column-end: 24;
	}
`;
const RightColumn = styled.div`
	grid-column-start: 17;
	grid-column-end: 24;
	@media (max-width: 720px) {
		grid-column-start: 1;
		grid-column-end: 24;
	}
`;

export const Grid = styled.div`
	display: grid;
	margin: 0 auto 24px;
	grid-template-columns: repeat(23, 1fr);
	width: -webkit-fill-available;
	width: -moz-available;
	max-width: 1240px;

	/* IE friendly.....*/
	display: -ms-grid;
	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
		1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

	@media (max-width: 1599px) {
		margin: 0px 180px 24px;
	}

	@media (max-width: 1279px) {
		margin: 0px 140px 24px;
	}

	@media (max-width: 959px) {
		margin: 0px 40px 24px;
	}

	@media (max-width: 519px) {
		margin: 0px 20px 24px;
	}
`;
export default FairDetailsPageContent;
