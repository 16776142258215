import { useEffect, useState } from "react";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import useGoalPageData from "./useGoalPageData";
import useVolunteerPageData from "./useVolunteerPageData";
import useContactPageData from "./useContactPageData";
import useFairInfoData from "./useFairInfoData";
import useShareHomepageData from "./useShareHomepageData";
import useEventsData from "./useEventsData";
import useOfeData from "./useOfeData";
import {
	convertStringToDate,
	convertYMDStringToDate,
} from "utils/DateConversion";
import { handleCOASuccess, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

interface Props {
	fairId?: string;
	url?: string;
	isPublic: boolean;
}

enum FairType {
	before = "before",
	after = "after",
	during = "during",
}

const tempDate = new Date();

const useHomepageData = (props: Props) => {
	const { bookfairsAPI } = useConfig();

	const { contactData, setContactData } = useContactPageData();
	const { goalData, setGoalData } = useGoalPageData();
	const { fairInfoData, setFairInfoData } = useFairInfoData();
	const { volunteerData, setVolunteerData } = useVolunteerPageData();
	const { eventsData, setEventsData } = useEventsData();
	const { shareHomepageData, setShareHomepageData } = useShareHomepageData();
	const { ofeData, setOfeData } = useOfeData();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isVirtual, setIsVirtual] = useState<boolean>(false);
	const [fairType, setFairType] = useState<FairType>(FairType.during);
	const [isError, setIsError] = useState<boolean>(false);
	const [fairId, setFairId] = useState<number>(0);
	const [schoolUCN, setSchoolUCN] = useState<number>(0);
	const [nextLocation, setNextLocation] = useState("");
	const [eWalletFlag, setEWalletFlag] = useState<boolean>(false);
	const [eWalletStartDate, setEWalletStartDate] = useState<Date>(tempDate);
	const [eWalletEndDate, setEWalletEndDate] = useState<Date>(tempDate);

	useEffect(() => {
		const getHomepageData = async () => {
			//used to calculate before, after and virtual fairs
			const virtualFairs = ["OC", "OS", "OT", "VF"];
			const currentDate: Date = new Date();
			// Set time to 00:00:00 for proper date comparisons
			currentDate.setHours(0, 0, 0, 0);

			const currentFair = getFairIdFromUrl();
			if (props.isPublic || checkSpsUd(props.isPublic)) {
				try {
					await axios
						.get(
							props.isPublic
								? `${bookfairsAPI}/public/homepage?fairid=${props.fairId}&url=${props.url}`
								: `${bookfairsAPI}/user/fairs/${currentFair}/homepage`,
							{ withCredentials: true }
						)
						.then((response) => {
							if (response.status === 200) {
								const {
									online_homepage,
									goals,
									events,
									fair,
									school,
									ewalletStartDate,
									ewalletEndDate,
								} = response.data;
								const { onlineShopping } = online_homepage;
								setFairId(fair.id);
								setSchoolUCN(school.schoolUcn);
								setEWalletStartDate(
									convertYMDStringToDate(ewalletStartDate)
								);
								setEWalletEndDate(
									convertYMDStringToDate(ewalletEndDate)
								);
								setContactData(
									online_homepage.contactFirstName,
									online_homepage.contactLastName,
									online_homepage.contactEmail,
									online_homepage.contactPhoneNumber,
									online_homepage.contactPhoneNumberCkbox
								);
								setGoalData(
									goals.onlineHomepage.booksGoal,
									goals.onlineHomepage.dollarsGoal,
									goals.onlineHomepage.bookFairGoalCkbox,
									goals.onlineHomepage.goalPurpose,
									goals.onlineHomepage.booksSales,
									goals.onlineHomepage.dollarsSales,
									goals.historyDetails
								);
								setFairInfoData(
									online_homepage.schoolName,
									fair.productId,
									fair.deliveryDate,
									fair.pickupDate,
									online_homepage.fairEndDate,
									online_homepage.fairStartDate,
									fair.k8_Flag,
									online_homepage.fairLocation,
									online_homepage.schoolAddress1,
									online_homepage.schoolCity,
									online_homepage.schoolState,
									online_homepage.schoolPostalcode,
									online_homepage.welcomeMessage,
									online_homepage.paymentCashCkbox,
									online_homepage.paymentCheckCkbox,
									online_homepage.paymentCcCkbox,
									online_homepage.thankYouMessage,
									online_homepage.checkDescription,
									fairInfoData.taxRate,
									fairInfoData.taxStatus
								);
								setVolunteerData(
									online_homepage.volunteerSignupUrl
								);
								setEventsData(events);
								setShareHomepageData(
									online_homepage.homepageUrl.webUrl,
									online_homepage.homepageUrl.schoolId,
									online_homepage.homepageUrl.urlStatus,
									online_homepage.homepageUrl.fairFinderCkbok
								);
								setOfeData(
									onlineShopping.ofeStatus,
									onlineShopping.ofeStartDate,
									onlineShopping.ofeEndDate
								);
								setEWalletFlag(
									online_homepage.ewalletFlag === "N"
										? false
										: true
								);
								setIsVirtual(
									virtualFairs.includes(fair.productId)
								);
								if (
									currentDate >
									convertStringToDate(
										online_homepage.fairEndDate
									)
								) {
									setFairType(FairType.after);
								} else if (
									currentDate <
									convertStringToDate(
										online_homepage.fairStartDate
									)
								) {
									setFairType(FairType.before);
								}
							} else {
								if (!handleCOASuccess(response)) {
									handleGenericSuccess(response);
								}
							}
						})
						.catch((err) => {
							setIsError(true);
							window.open(
								`/content/fairs/hub/homepage-unavailable.html`,
								"_top"
							);
						});
				} catch (err: any) {
					setIsError(true);
					window.open(
						`/content/fairs/hub/homepage-unavailable.html`,
						"_top"
					);
				} finally {
					setIsLoading(false);
				}
			}
		};
		getHomepageData();
	}, [
		bookfairsAPI,
		props.fairId,
		props.isPublic,
		props.url,
		setContactData,
		setGoalData,
		setFairInfoData,
		setVolunteerData,
		setEventsData,
		setShareHomepageData,
		setOfeData,
		setEWalletStartDate,
		setEWalletEndDate,
		fairInfoData.taxRate,
		fairInfoData.taxStatus,
	]);

	return {
		fairId,
		schoolUCN,
		contactData,
		goalData,
		fairInfoData,
		volunteerData,
		eventsData,
		shareHomepageData,
		ofeData,
		isLoading,
		isVirtual,
		fairType,
		isError,
		nextLocation,
		setNextLocation,
		eWalletFlag,
		eWalletStartDate,
		eWalletEndDate,
	};
};

export default useHomepageData;
