import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import {
	handleCOASuccess,
	handleGenericErrors,
	handleGenericSuccess,
} from "utils/ErrorUtility";
import {
	FinFormUrls,
	setFinFormUrlsLocalStorage,
} from "utils/FinFormLocalStorageUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

export type IndividualRegisterInfo = {
	registerNumber: number;
	scholasticDollars: {
		totalRedeemed: number;
		taxExempt: number;
		taxCollected: number;
		cashEquivalent: number;
		taxable: number;
	};
	tenderTotals: {
		cash: {
			quantity: number;
			amount: number;
		};
		creditCard: {
			quantity: number;
			amount: number;
		};
		purchaseOrders: {
			quantity: number;
			amount: number;
		};
		scholasticDollarsCashEquivalent: {
			quantity: number;
			amount: number;
		};
		total: {
			quantity: number;
			amount: number;
		};
	};
	grossSales: {
		taxExemptSales: number;
		taxableSales: number;
		taxRate: number;
		grossSalesTotal: number;
		taxTotal: number;
		taxableSalesLessTax: number;
	};
	netSales: {
		discounts: number;
		giftCertificates: {
			purchased: number;
			redeemed: number;
			unredeemed: number;
		};
		shareTheFairFunds: {
			collected: number;
			redeemed: number;
			unRedeemed: number;
		};
		unredeemedTotal: number;
	};
	taxRate: number;
};

const defaultIndividualRegisterInfo: IndividualRegisterInfo = {
	registerNumber: 0,
	scholasticDollars: {
		totalRedeemed: 0,
		taxExempt: 0,
		taxCollected: 0,
		cashEquivalent: 0,
		taxable: 0,
	},
	tenderTotals: {
		cash: {
			quantity: 0,
			amount: 0,
		},
		creditCard: {
			quantity: 0,
			amount: 0,
		},
		purchaseOrders: {
			quantity: 0,
			amount: 0,
		},
		scholasticDollarsCashEquivalent: {
			quantity: 0,
			amount: 0,
		},
		total: {
			quantity: 0,
			amount: 0,
		},
	},
	grossSales: {
		taxExemptSales: 0,
		taxableSales: 0,
		taxRate: 0,
		grossSalesTotal: 0,
		taxTotal: 0,
		taxableSalesLessTax: 0,
	},
	netSales: {
		discounts: 0,
		giftCertificates: {
			purchased: 0,
			redeemed: 0,
			unredeemed: 0,
		},
		shareTheFairFunds: {
			collected: 0,
			redeemed: 0,
			unRedeemed: 0,
		},
		unredeemedTotal: 0,
	},
	taxRate: 0,
};

const useSummaryPageData = () => {
	const { bookfairsAPI } = useConfig();

	const [registerInfo, setRegisterInfo] = useState<
		Array<IndividualRegisterInfo>
	>([defaultIndividualRegisterInfo]);
	const [registerInfoTotals, setRegisterInfoTotals] =
		useState<IndividualRegisterInfo>(defaultIndividualRegisterInfo);

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetchSummaryInfo = async () => {
			const currentFair = getFairIdFromUrl();
			if (checkSpsUd()) {
				setIsLoading(true);
				try {
					const response = await axios.get(
						`${bookfairsAPI}/user/fairs/${currentFair}/financials/summary`,
						{
							withCredentials: true,
						}
					);
					if (response.status !== 200) {
						handleGenericSuccess(response);
						return;
					}
					setRegisterInfo(response.data["individualRegisters"]);
					setRegisterInfoTotals(response.data["registerTotals"]);

					const availabilityResponse = await axios.get(
						`${bookfairsAPI}/user/fairs/${currentFair}/financials/form/status`,
						{ withCredentials: true }
					);
					if (availabilityResponse.status !== 200) {
						if (!handleCOASuccess(response)) {
							handleGenericSuccess(response);
						}
						return;
					}
					if (availabilityResponse.data["type"] === "call") {
						setFinFormUrlsLocalStorage(
							availabilityResponse.headers[
								"sbf-jarvis-resource-id"
							],
							FinFormUrls.Intro
						);
						window.open(
							`/content/fairs/hub/financial-form.html`,
							"_top"
						);
					}
				} catch (err) {
					setIsError(true);
					handleGenericErrors(err);
				} finally {
					setIsLoading(false);
				}
			}
		};
		fetchSummaryInfo();
	}, [bookfairsAPI]);

	return {
		registerInfo,
		registerInfoTotals,
		isError,
		isLoading,
	};
};

export default useSummaryPageData;
