import styled from "styled-components/macro";
import { Grid } from "styles/_grid";

interface Props {
	children: React.ReactNode;
	className?: string;
}

export const Page = ({ className, children }: Props) => {
	return (
		<Grid>
			<StyledPage className={className}>{children}</StyledPage>
		</Grid>
	);
};

const StyledPage = styled.div`
	max-width: 100%;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding-top: 32px;
	padding-left: 47px;
	padding-right: 47px;
	grid-column: 1/24;
	box-sizing: border-box;
	@media (max-width: 1020px) {
		padding-top: 144px;
	}
	@media (max-width: 375px) {
		padding-top: 136px;
	}
`;
