import React from "react";
import useInvoicePageData from "hooks/FinancialForm/useInvoicePageData";
import InvoicePrintablePages from "./InvoicePrintablePages";

interface Props {
	printClicked: boolean;
	handlePrint: Function;
}
const RegisterInvoice = ({ printClicked, handlePrint }: Props) => {
	const {
		invoiceBasicInfo,
		invoiceSalesInfo,
		invoiceEarningsInfo,
		invoiceSpendingInfo,
		isLoading,
		fairId,
	} = useInvoicePageData();

	return (
		<InvoicePrintablePages
			printClicked={printClicked}
			handlePrint={handlePrint}
			isLoading={isLoading}
			invoiceBasicInfo={invoiceBasicInfo}
			invoiceSalesInfo={invoiceSalesInfo}
			invoiceEarningsInfo={invoiceEarningsInfo}
			invoiceSpendingInfo={invoiceSpendingInfo}
			fairId={fairId}
		/>
	);
};

export default RegisterInvoice;
