import React from "react";
import styled from "styled-components/macro";
import NavElement from "./NavElement";

interface Props {
	handleNextLocation(nextLocation: string): any;
	isVirtual: boolean;
	fairType: string;
}

const Navigation = ({ handleNextLocation, isVirtual, fairType }: Props) => {
	return (
		// prettier-ignore
		<Wrapper>
			<NavElement to="/reactapp/customize/contact" handleNextLocation={handleNextLocation}>Contact Information</NavElement>
			<NavElement to="/reactapp/customize/goals" handleNextLocation={handleNextLocation}>Set or update goal</NavElement>
			<NavElement to="/reactapp/customize/information" handleNextLocation={handleNextLocation}>Fair information</NavElement>
			{!isVirtual && fairType !== "after"
				&& 
				<>
				<NavElement to="/reactapp/customize/schedule" handleNextLocation={handleNextLocation}>Schedule activities</NavElement>
				<NavElement to="/reactapp/customize/volunteer" handleNextLocation={handleNextLocation}>Recruit volunteers</NavElement>
				</>
			}
			<NavElement to="/reactapp/customize/share" handleNextLocation={handleNextLocation}>View & share homepage</NavElement>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 19px;
	padding-top: 36px;
	padding-left: 47px;
	z-index: 1000;
	@media (max-width: 1020px) {
		display: inline-flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 60px 21.8px 0px;
		background-color: #ffffff;
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
		width: 100%;
		width: -moz-available; /* WebKit-based browsers */
		width: -webkit-fill-available; /* Mozilla-based browsers */
		width: fill-available;
		/* Hide scrollbar on mobile menu */
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}
		/* pseudo element for full-width bottom border */
		&::before {
			content: " ";
			position: absolute;
			display: inline-block;
			width: 100%;
			z-index: 1;
			left: 0px;
			right: 0px;
			bottom: 0px;
			border-bottom: 2px solid #e8e8e8;
		}
	}
	@media (max-width: 719px) {
		&::before {
			width: 838px;
		}
	}
`;

export default Navigation;
