import React from "react";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components";
import { convertStringToDollarAmount } from "utils/HubUtility";

interface Props {
	balance: string;
}
const ShareTheFairFundsCard = ({ balance }: Props) => {
	return (
		<FairDetailsCard title={"Share the Fair Funds"}>
			<Balance>{convertStringToDollarAmount(balance)}</Balance>
			<Body>Reflects real-time balance when registers are online.</Body>
		</FairDetailsCard>
	);
};

const Body = styled.div`
	font-size: 13px;
	font-weight: 500;
	line-height: 15px;
	margin-bottom: 24px;
	margin-top: 17px;
`;

const Balance = styled.p`
	font-size: 36px;
	font-weight: 900;
	line-height: 28px;
`;

export default ShareTheFairFundsCard;
