import React from "react";
import SDBalanceCard from "../FairDetailsPage/FairDetailsCard";
import styled from "styled-components";

interface Props {
	profitBalance: number;
	rewardsBalance: number;
}

const BalanceCard = ({ profitBalance, rewardsBalance }: Props) => {
	return (
		<SDBalanceCard title={"Balance"} FullHeight={true}>
			<InnerContainer>
				<BalanceContainer>
					<TitleText>Profit balance</TitleText>
					<Balance>
						{profitBalance.toLocaleString("en-US", {
							style: "currency",
							currency: "USD",
						})}
					</Balance>
				</BalanceContainer>
				<BalanceContainer>
					<TitleText>Reward Balance</TitleText>
					<Balance>
						{rewardsBalance.toLocaleString("en-US", {
							style: "currency",
							currency: "USD",
						})}
					</Balance>
					<DescriptionText>These will expire.</DescriptionText>
				</BalanceContainer>
			</InnerContainer>
		</SDBalanceCard>
	);
};

const BalanceContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	min-width: 50%;
`;

const TitleText = styled.p`
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 15px;
	color: #333333;
`;

const DescriptionText = styled.p`
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	color: #333333;
`;

const Balance = styled.p`
	font-size: 36px;
	font-weight: 900;
	line-height: 28px;
	padding: 4px 0;
`;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 6px;
	gap: 32px;
	align-self: stretch;

	@media (min-width: 582px) and (max-width: 720px) {
		flex-direction: row;
		justify-content: space-between;
	}

	@media (max-width: 520px) {
		gap: 16px;
	}
`;

export default BalanceCard;
