import React from "react";
import styled from "styled-components";
import TwitterIcon from "images/Twitter.svg";
import { trackShareBuilder, trackShareHomepage } from "DumbleData";

interface Props {
	customUrl: string;
	pageName?: string;
}

const TwitterShareButton = ({ customUrl, pageName }: Props) => {
	return (
		<TwitterShareButtonStyled
			href={`https://twitter.com/share?ref_src=twsrc%5Etfw&text=It's Book Fair time! Visit our school's homepage to get the latest Book Fair news, shop our Online Fair, and more! &url=${window.location.origin}/bf/${customUrl}`}
			target="_blank"
			className={pageName}
			onClick={
				pageName === "liveHomepage"
					? () => trackShareHomepage("twitter")
					: () => trackShareBuilder("twitter")
			}
		>
			<TwitterIconStyled
				src={TwitterIcon}
				className={pageName}
				alt="Share Live Homepage to Twitter"
			/>
		</TwitterShareButtonStyled>
	);
};

const TwitterShareButtonStyled = styled.a`
	background-color: #000000;
	border: none;
	border-radius: 56px;
	display: flex;
	align-items: center;
	width: 56px;
	&.liveHomepage {
		height: 40px;
		width: 40px;
	}
	&:focus {
		outline: 2px solid #333333;
		outline-offset: 2px;
	}
`;

const TwitterIconStyled = styled.img`
	&.liveHomepage {
		height: 40px;
		width: 20px;
		margin-left: 10px;
	}
	width: 100%;
	height: 25px;
`;

export default TwitterShareButton;
