import React from "react";
import styled from "styled-components/macro";

const VirtualFair = () => {
	return (
		<VirtualFairContainer>
			<OpeningParagraph>
				<b>Thank you for hosting a Scholastic Book Fair!</b> We look
				forward to working with you. Please read the following Services
				Agreement and guidelines, which have recently changed. As
				always, Scholastic will provide:
			</OpeningParagraph>
			<VirtualFairText>
				<VirtualFairList>
					<li>Books and educational products</li>
					<li>A dedicated support and service team</li>
					<li>Online planning resources and tools</li>
					<li>Setup Kit with promotional materials</li>
					<li>A Virtual Fair by way of The Scholastic Store</li>
				</VirtualFairList>
				<p>The school or organization listed agrees to:</p>
				<VirtualFairList>
					<li>
						Use Scholastic Book Fairs as the exclusive provider and
						retailer of all books, merchandise, and promotional
						materials during the Online Fair time frame, which is a
						total duration of 14 consecutive days.
					</li>
				</VirtualFairList>
				<p>
					Changes to this Services Agreement may be made solely at the
					discretion of Scholastic Book Fairs as permissible by law
					and/or as business conditions deem appropriate. We will
					notify you of any such changes by posting the revised
					Services Agreement in the Book Fair Host Hub. Under certain
					circumstances, we may also notify you of changes to this
					Services Agreement by additional means, including, but not
					limited to, posting a notice on the Scholastic Book Fairs
					homepage, sending you an email if we have your email
					address, or by contacting you through your Book Fair
					Consultant. At that time, you will have the opportunity to
					review and accept a new Services Agreement.
				</p>
			</VirtualFairText>
			<ProfitHeading>Profit</ProfitHeading>
			<VirtualFairText>
				Virtual Fair profit is 25% of the total sales and is
				automatically applied as Scholastic Dollars.
			</VirtualFairText>
		</VirtualFairContainer>
	);
};

const VirtualFairContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 6.5in;
`;

const OpeningParagraph = styled.p`
	margin-top: 20px;
	font-size: 10px;
`;

const VirtualFairText = styled.div`
	font-size: 9px;
	padding: 10px 0;
	p {
		padding: 10px 0;
	}
`;

const VirtualFairList = styled.ul`
	list-style-type: disc; /* Change the bullet point to a disc */
	margin: 0; /* Remove default margin */
	padding-left: 0; /* Remove default padding */
	li {
		margin-left: 0.25in;
	}
`;

const ProfitHeading = styled.div`
	font-size: 10px;
	font-weight: 700;
`;

export default VirtualFair;
