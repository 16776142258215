import { useState, useCallback } from "react";

const useVolunteerPageData = () => {
	const [volunteerSignUpUrl, setVolunteerSignUpUrl] = useState<string>("");

	// Set the initial data from the /get request
	const setVolunteerData = useCallback((initVolunteerSignUpUrl: string) => {
		if (initVolunteerSignUpUrl) {
			setVolunteerSignUpUrl(initVolunteerSignUpUrl);
		}
	}, []);

	const volunteerData = {
		volunteerSignUpUrl,
		setVolunteerSignUpUrl,
	};

	return { volunteerData, setVolunteerData };
};

export default useVolunteerPageData;
