import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { getFairIdFromUrl } from "utils/HubUtility";

export type earningsInfo = {
	sales: number;
	dollarFairLevel: number;
	cash: {
		selected: number | null;
		max: number;
	};
	scholasticDollars: {
		selected: number | null;
		earned: number;
		due: number;
		balance: number;
		max: number;
	};
};

const earningsInfoDefault = {
	sales: 0.0,
	dollarFairLevel: 0.0,
	cash: {
		selected: 0.0,
		max: 2000.0,
	},
	scholasticDollars: {
		selected: 4000.0,
		earned: 0.0,
		due: 0.0,
		balance: 0.0,
		max: 4000.0,
	},
};

const useEarningsData = () => {
	const [earningsInfo, setEarningsInfo] =
		useState<earningsInfo>(earningsInfoDefault);

	const { bookfairsAPI } = useConfig();

	const [fairId, setFairId] = useState("");

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		const fetchEarningsData = async () => {
			// if fair id in url, returns fair id, otherwise returns 'current'
			const currentFair = getFairIdFromUrl();
			try {
				const response = await axios.get(
					`${bookfairsAPI}/user/fairs/${currentFair}/financials/form/earnings`,
					{ withCredentials: true }
				);
				if (response.status !== 200) {
					handleGenericSuccess(response);
					return;
				}
				setEarningsInfo(response.data);
				setFairId(response.headers["sbf-jarvis-resource-id"]);
			} catch (err) {
				setIsError(true);
				handleGenericErrors(err);
			} finally {
				setIsLoading(false);
			}
		};
		fetchEarningsData();
	}, [bookfairsAPI]);

	return {
		earningsInfo,
		setEarningsInfo,
		fairId,
		isError,
		isLoading,
	};
};

export default useEarningsData;
