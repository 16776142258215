import React, { useCallback, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "styles/calendar.css";
import styled from "styled-components/macro";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import {
	convertDateToYMD,
	convertStringToDate,
	convertStringArrayToDateArray,
} from "utils/DateConversion";
import { RedButton, WhiteButton } from "../Button";
import useFairDatesData from "hooks/FairDetails/useFairDates";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";
import BlackoutCalendarMultiMonth from "./BlackoutCalendarMultiMonth/BlackoutCalendarMultiMonth";

interface Props {
	closeModal: any;
	standAlone?: boolean;
	fairId?: string | null;
}

export const BlackoutCalendar = ({ closeModal, standAlone, fairId }: Props) => {
	const { bookfairsAPI } = useConfig();

	//Gets data from api
	const { fairDates, isLoading } = useFairDatesData({ fairId });

	// If fair start date is less than one month away, disable standalone calendar
	const fairStartDate = convertStringToDate(fairDates.fairStartDate);
	const currentDate = new Date();
	const oneMonthBeforeStart = new Date(fairStartDate);
	oneMonthBeforeStart.setMonth(fairStartDate.getMonth() - 1);
	const calendarEnabled = !(standAlone && currentDate >= oneMonthBeforeStart);

	/** Creates instances of UseStates that will be updated once fairDates data is loaded */
	////////////////////////////////////////////////////////////////////////////////////////

	//list of delivery dates user has blacked out
	const [blackedOutDeliveryDatesList, setBlackedOutDeliveryDatesList] =
		useState<Date[]>([]);

	//list of pickup dates user has blacked out
	const [blackedOutPickupDatesList, setBlackedOutPickupDatesList] = useState<
		Date[]
	>([]);

	//list of scholastic holidays that we automatically blackout
	const [scholasticHolidayList, setScholasticHolidayList] = useState<Date[]>(
		[]
	);
	//date range of delivery gotten from api and then reset when user blacks out a date
	const [dateRangeDelivery, setDateRangeDelivery] = useState([
		new Date(),
		new Date(),
	]);

	//fairdates gotten from api
	const [dateRangeFair, setDateRangeFair] = useState([
		new Date(),
		new Date(),
	]);

	//date range of pickup gotten from api and then reset when user blacks out a date
	const [dateRangePickup, setDateRangePickup] = useState([
		new Date(),
		new Date(),
	]);

	//Required to stop calendar library from setting it by default
	const [activeStartDate, setActiveStartDate] = useState(new Date());
	////////////////////////////////////////////////////////////////////////////////////////

	//Show/hide for blackout dates error message
	const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

	// disable save before selection is made
	const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

	// after save is clicked once, disabled save button
	const [saveClicked, setSaveClicked] = useState<boolean>(false);
	const [datesUnselectedBeforeReload, setDatesUnselectedBeforeReload] =
		useState<Array<Date>>([]);

	//Sets all UseState values with the api data once it is loaded
	useEffect(() => {
		if (!isLoading) {
			setDateRangeDelivery([
				convertStringToDate(fairDates.deliveryStartDate),
				convertStringToDate(fairDates.deliveryEndDate),
			]);
			setDateRangePickup([
				convertStringToDate(fairDates.pickupStartDate),
				convertStringToDate(fairDates.pickupEndDate),
			]);
			setDateRangeFair([
				convertStringToDate(fairDates.fairStartDate),
				convertStringToDate(fairDates.fairEndDate),
			]);
			if (fairDates.pickupBlackoutDates) {
				setBlackedOutPickupDatesList(
					convertStringArrayToDateArray(fairDates.pickupBlackoutDates)
				);
			}
			if (fairDates.deliveryBlackoutDates) {
				setBlackedOutDeliveryDatesList(
					convertStringArrayToDateArray(
						fairDates.deliveryBlackoutDates
					)
				);
			}
			if (fairDates.bookfairHolidays) {
				setScholasticHolidayList(
					convertStringArrayToDateArray(fairDates.bookfairHolidays)
				);
			}
			setActiveStartDate(
				new Date(
					convertStringToDate(
						fairDates.deliveryStartDate
					).getFullYear(),
					convertStringToDate(fairDates.deliveryStartDate).getMonth(),
					1
				)
			);
		}
	}, [fairDates, isLoading]);

	// Check if the date falls outside of both date ranges or is a holiday
	function tileDisabled({ date, view }: { date: Date; view: string }) {
		return (
			(!(
				isWithinRange(
					date,
					dateRangeDelivery[0],
					dateRangeDelivery[1]
				) || isWithinRange(date, dateRangePickup[0], dateRangePickup[1])
			) ||
				isWeekendOrHoliday(date)) &&
			!(
				isInArray(blackedOutDeliveryDatesList, date) ||
				isInArray(blackedOutPickupDatesList, date) ||
				isInArray(datesUnselectedBeforeReload, date)
			)
		);
	}

	//checks that a given date is within a given range
	function isWithinRange(date: Date, startDate: Date, endDate: Date) {
		// Check if the date is within the specified range
		return date >= startDate && date <= endDate;
	}

	//Checks if a given date is a weekend date or a scholastic holiday
	function isWeekendOrHoliday(date: Date) {
		return (
			isInArray(scholasticHolidayList, date) ||
			date.getDay() === 6 ||
			date.getDay() === 0
		);
	}

	const handleSaveClicked = () => {
		if (!saveClicked) {
			setSaveClicked(true);
		}
	};

	const handleSaveBlackoutDates = useCallback(async () => {
		const currentFair = getFairIdFromUrl();

		const deliveryDates: string[] = [];
		const pickupDates: string[] = [];
		blackedOutDeliveryDatesList.forEach((date) => {
			deliveryDates.push(convertDateToYMD(date));
		});
		blackedOutPickupDatesList.forEach((date) => {
			pickupDates.push(convertDateToYMD(date));
		});
		if (checkSpsUd()) {
			try {
				await axios
					.put(
						`${bookfairsAPI}/user/fairs/${currentFair}/settings/dates/blackout-dates`,
						{
							blackoutDates: {
								deliveryBlackoutDates: deliveryDates,
								pickupBlackoutDates: pickupDates,
							},
						},
						{ withCredentials: true }
					)
					.then((response) => {
						if (response.status === 200) {
							if (standAlone) {
								window.location.reload();
							} else {
								closeModal();
							}
						} else {
							handleGenericSuccess(response);
						}
					})
					.catch((err) => {
						handleGenericErrors(err);
					});
			} catch (err) {
				handleGenericErrors(err);
			}
		}
	}, [
		bookfairsAPI,
		blackedOutDeliveryDatesList,
		blackedOutPickupDatesList,
		closeModal,
		standAlone,
	]);

	function isInArray(dateArray: Date[], date: Date) {
		return !!dateArray.find((item) => {
			return item.getTime() === date.getTime();
		});
	}

	//This function added the style for the three sets of days: delivery window, fair window, pickup window
	const getClassNames = ({ date, view }: { date: Date; view: string }) => {
		if (isInArray(scholasticHolidayList, date)) {
			return "holiday-date";
		}
		if (isInArray(blackedOutDeliveryDatesList, date)) {
			return "date-range-delivery selected-date";
		}
		if (isInArray(blackedOutPickupDatesList, date)) {
			return "date-range-pickup selected-date";
		}
		if (
			date < dateRangeFair[0] &&
			isInArray(datesUnselectedBeforeReload, date)
		) {
			return "date-range-delivery";
		}
		if (
			date > dateRangeFair[1] &&
			isInArray(datesUnselectedBeforeReload, date)
		) {
			return "date-range-pickup";
		}
		if (
			view === "month" &&
			((dateRangeDelivery[0] <= date && date <= dateRangeDelivery[1]) ||
				(dateRangeFair[0] <= date && date <= dateRangeFair[1]) ||
				(dateRangePickup[0] <= date && date <= dateRangePickup[1]))
		) {
			//styled for selected delivery window
			if (
				dateRangeDelivery[0] <= date &&
				date <= dateRangeDelivery[1] &&
				!isWeekendOrHoliday(date)
			) {
				return "date-range-delivery";

				//styled for fair window
			} else if (dateRangeFair[0] <= date && date <= dateRangeFair[1]) {
				return "date-range-fair";

				//styled for selected pickup window
			} else if (
				dateRangePickup[0] <= date &&
				date <= dateRangePickup[1] &&
				!isWeekendOrHoliday(date)
			) {
				return "date-range-pickup";
			}
		}
		return null;
	};

	//When a date is blacked out on either the delivery or pickup window, we extend the range by one to ensure there is a 5 day window
	const handleExtendDateWindow = (isDeliveryRange: boolean) => {
		//Deals with the delivery window
		if (isDeliveryRange) {
			const tempDate = dateRangeDelivery[0];
			let extendedDateNotFound = true;

			//finds nearest date available to add to beginnging of the delivery range
			while (extendedDateNotFound) {
				tempDate.setDate(tempDate.getDate() - 1);
				if (!isWeekendOrHoliday(tempDate)) {
					extendedDateNotFound = false;
					setActiveStartDate(tempDate);
					setDateRangeDelivery([tempDate, dateRangeDelivery[1]]);
				}
			}
			//Deals with the pickup window
		} else {
			const tempDate = dateRangePickup[1];
			let extendedDateNotFound = true;

			//finds nearest date available to add to end of the pickup range
			while (extendedDateNotFound) {
				tempDate.setDate(tempDate.getDate() + 1);
				if (!isWeekendOrHoliday(tempDate)) {
					extendedDateNotFound = false;
					setDateRangePickup([dateRangePickup[0], tempDate]);
				}
			}
		}
	};

	//When a blackout date on either the delivery or pickup window is unselected, we subtract the range by one to ensure there is a 5 day window
	const handleShortenDateWindow = (isDeliveryRange: boolean, date: Date) => {
		//deals with delivery window
		if (isDeliveryRange) {
			const tempDate = dateRangeDelivery[0];
			let nextDateNotFound = true;
			while (nextDateNotFound) {
				tempDate.setDate(tempDate.getDate() + 1);
				if (!isInArray(blackedOutDeliveryDatesList, tempDate)) {
					if (!isWeekendOrHoliday(tempDate)) {
						nextDateNotFound = false;
						setActiveStartDate(tempDate);
						//handles if on load, the last delivery day was select and then user unselects
						//(last day starts outside of delivery window until unselected)
						if (date >= dateRangeDelivery[1]) {
							setDateRangeDelivery([tempDate, date]);
						} else {
							setDateRangeDelivery([
								tempDate,
								dateRangeDelivery[1],
							]);
						}
						if (blackedOutDeliveryDatesList.length > 0) {
							setBlackedOutDeliveryDatesList(
								blackedOutDeliveryDatesList.filter(
									(currDate) =>
										currDate >= dateRangeDelivery[0]
								)
							);
						}
						if (datesUnselectedBeforeReload.length > 0) {
							setDatesUnselectedBeforeReload(
								datesUnselectedBeforeReload.filter(
									(currDate) =>
										currDate >= dateRangeDelivery[0]
								)
							);
						}
					}
				}
			}
			//deals with pickup window
		} else {
			const tempDate = dateRangePickup[1];
			let nextDateNotFound = true;
			while (nextDateNotFound) {
				tempDate.setDate(tempDate.getDate() - 1);
				if (!isInArray(blackedOutPickupDatesList, tempDate)) {
					if (!isWeekendOrHoliday(tempDate)) {
						nextDateNotFound = false;
						//handles if on load, the first pickup day was select and then user unselects
						//(first day starts outside of pickup window until unselected)
						if (date <= dateRangePickup[0]) {
							setDateRangePickup([date, tempDate]);
						} else {
							setDateRangePickup([dateRangePickup[0], tempDate]);
						}
						if (blackedOutPickupDatesList.length > 0) {
							setBlackedOutPickupDatesList(
								blackedOutPickupDatesList.filter(
									(currDate) => currDate <= dateRangePickup[1]
								)
							);
						}
						if (datesUnselectedBeforeReload.length > 0) {
							setDatesUnselectedBeforeReload(
								datesUnselectedBeforeReload.filter(
									(currDate) => currDate <= dateRangePickup[1]
								)
							);
						}
					}
				}
			}
		}
	};

	//Function that handles the logic when a date is selected
	const handleSelectDate = (
		date: Date,
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		//if date is selected then the blackout lists change, so we need to enable the save changes button
		setSaveDisabled(false);
		setShowErrorMessage(false);

		const unselectedDates = datesUnselectedBeforeReload;

		let target = event.target as Element;
		if (target.nodeName === "ABBR") {
			target = target.parentNode as Element;
		}

		//Assumes the date selected is in the pickup window
		let isDeliveryRange = false;
		let tempList = blackedOutPickupDatesList;
		let setListFunction = setBlackedOutPickupDatesList;

		//if the date is from the delivery window, set the values for delivery window
		if (target.classList.contains("date-range-delivery")) {
			isDeliveryRange = true;
			tempList = blackedOutDeliveryDatesList;
			setListFunction = setBlackedOutDeliveryDatesList;
		}

		//If the date is already selected for blackout, we unselect it
		if (target.classList.contains("selected-date")) {
			//Remove date from blacked out dates list
			const dateIndex = tempList.findIndex(
				(blackoutDate) =>
					JSON.stringify(blackoutDate) === JSON.stringify(date)
			);
			tempList.splice(dateIndex, 1);
			unselectedDates.push(date);
			setListFunction([...tempList]);
			setDatesUnselectedBeforeReload(unselectedDates);
			setShowErrorMessage(false);
			handleShortenDateWindow(isDeliveryRange, date);
			//handle styling
			target.classList.remove("selected-date");

			//if the date is not selected, we select the date to be blacked out
		} else {
			//Checks if max number of blackout dates has been selected
			if (
				(isDeliveryRange && blackedOutDeliveryDatesList.length < 15) ||
				(!isDeliveryRange && blackedOutPickupDatesList.length < 15)
			) {
				//handle styling
				target.classList.add("selected-date");
				//Add date to blacked out dates list
				setListFunction([...tempList, date]);
				handleExtendDateWindow(isDeliveryRange);
			} else {
				//If max number of dates has been selected, show error message
				setShowErrorMessage(true);
			}
		}
	};

	useEffect(() => {
		if (saveClicked) {
			handleSaveBlackoutDates();
		}
	}, [saveClicked, handleSaveBlackoutDates]);

	return (
		<div className="unavailable-dates-wrapper">
			<div className="react-calendar-wrapper">
				<p className="hd-calendar">Select your unavailable dates</p>
				<CalendarDesc marginBottom={showErrorMessage ? "13" : "37"}>
					Review your potential delivery and pickup dates. Select any
					dates where you are <span>NOT AVAILABLE</span> for delivery
					or pickup.
				</CalendarDesc>
				{showErrorMessage && (
					<p className="error-message-calendar">
						Your unavailable dates have exceeded the delivery and
						pick up window. Please contact your Fair consultant.
					</p>
				)}
				<BlackoutCalendarMultiMonth
					calendarEnabled={calendarEnabled}
					tileDisabled={tileDisabled}
					getClassNames={getClassNames}
					activeStartDate={activeStartDate}
					dateRangePickup={dateRangePickup}
					dateRangeDelivery={dateRangeDelivery}
					handleSelectDate={handleSelectDate}
					setActiveStartDate={setActiveStartDate}
				/>
				<StyledLegend>
					<StyledKey className="date-range-delivery">
						<abbr></abbr>
						<div className="hd-daterange">Delivery</div>
					</StyledKey>
					<StyledKey className="date-range-pickup">
						<abbr></abbr>
						<div className="hd-daterange">Pickup</div>
					</StyledKey>
					<StyledKey className="date-range-fair">
						<abbr></abbr>
						<div className="hd-daterange">Scheduled Book Fair</div>
					</StyledKey>
					<StyledKey className="selected-date">
						<abbr></abbr>
						<div className="hd-daterange">Unavailable</div>
					</StyledKey>
				</StyledLegend>
			</div>
			<div className="sec-buttons">
				{!standAlone && (
					<StyledWhiteButton
						className="btn-normal btn-red"
						whiteBackground={true}
						handleClick={() => closeModal()}
					>
						Cancel
					</StyledWhiteButton>
				)}
				{calendarEnabled && (
					<StyledRedButton
						className="btn-normal btn-red-solid"
						handleClick={handleSaveClicked}
						disabled={saveDisabled || saveClicked}
					>
						Save
					</StyledRedButton>
				)}
			</div>
		</div>
	);
};

interface DescProps {
	marginBottom: string;
}

const CalendarDesc = styled.p<DescProps>`
	width: 598px;
	text-align: center;
	font-size: 17px;
	line-height: 25px;
	font-weight: 300;
	margin: 0 auto 0px auto;
	margin-bottom: ${(props) =>
		props.marginBottom ? `${props.marginBottom}px` : "37px"};
	span {
		font-weight: 600;
	}
	@media (max-width: 865px) {
		width: unset;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 130px;
`;

const StyledRedButton = styled(RedButton)`
	width: 130px;
`;

const StyledKey = styled.div`
	display: flex;
	align-items: center;
	margin-top: 10px;
	gap: 10px;
	abbr {
		position: relative !important;
		height: 16px;
		width: 16px;
	}
	.hd-daterange {
		margin-bottom: 0px;
	}
`;

const StyledLegend = styled.div`
	display: flex;
	justify-content: center;
	gap: 16px;
	@media (max-width: 550px) {
		flex-direction: column;
		gap: 10px;
		margin-left: 70px;
	}
`;
