import React from "react";
import styled from "styled-components";

interface Props {
	children: React.ReactNode;
	className?: string;
	disabled?: boolean;
	handleClick?(e: any): any;
	whiteBackground?: boolean;
	type?: string;
	href?: string;
	target?: string;
	iconAlt?: string;
	iconSrc?: string;
	iconWidth?: number;
	buttonWidth?: number;
}

const RedButton = ({
	children,
	className,
	disabled,
	handleClick,
	type,
	href,
	target,
}: Props) => {
	const typeIsSubmit = type === "submit";
	let tag: any = undefined;
	if (typeIsSubmit) {
		tag = "input";
	} else if (href) {
		tag = "a";
	}
	return (
		<StyledButtonRed
			as={tag}
			className={className}
			onClick={handleClick}
			disabled={disabled}
			type={type}
			value={typeIsSubmit ? children : null}
			href={href}
			target={target}
		>
			{!typeIsSubmit ? children : null}
		</StyledButtonRed>
	);
};
const WhiteButton = ({
	children,
	className,
	disabled,
	handleClick,
	whiteBackground,
	type,
	href,
	target,
}: Props) => {
	const typeIsSubmit = type === "submit";
	let tag: any = undefined;
	if (typeIsSubmit) {
		tag = "input";
	} else if (href) {
		tag = "a";
	}
	return (
		<StyledButtonWhite
			as={tag}
			className={className}
			onClick={handleClick}
			disabled={disabled}
			whiteBackground={whiteBackground}
			value={typeIsSubmit ? children : null}
			href={href}
			target={target}
		>
			{!typeIsSubmit ? children : null}
		</StyledButtonWhite>
	);
};

const IconButtonWhite = ({
	children,
	className,
	disabled,
	handleClick,
	whiteBackground,
	type,
	href,
	target,
	iconAlt,
	iconSrc,
	iconWidth,
	buttonWidth,
}: Props) => {
	const typeIsSubmit = type === "submit";
	let tag: any = undefined;
	if (typeIsSubmit) {
		tag = "input";
	} else if (href) {
		tag = "a";
	}
	return (
		<StyledIconButtonWhite
			as={tag}
			className={className}
			onClick={handleClick}
			disabled={disabled}
			whiteBackground={whiteBackground}
			value={typeIsSubmit ? children : null}
			href={href}
			target={target}
			tabIndex={1}
			buttonWidth={buttonWidth}
		>
			<Icon alt={iconAlt} src={iconSrc} width={iconWidth} />
			{!typeIsSubmit ? children : null}
		</StyledIconButtonWhite>
	);
};

const StyledButton = styled.button`
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 12px;
	text-align: center;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	border: none;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content;
	min-width: 100px;
	padding: 14px;
	border-radius: 100px;
	text-decoration: none;
`;

const StyledButtonRed = styled(StyledButton)`
	color: white;
	background-color: #e81111;
	&:hover {
		cursor: pointer;
		background-color: #b90000;
	}
	&:focus-visible {
		outline: 2px solid #b90000;
		background-color: #b90000;
		outline-offset: 2px;
	}
	&:active {
		outline: none;
		background-color: #660000;
	}
	&:disabled {
		opacity: 0.5;
		background-color: #333333;
	}
`;

interface WhiteButtonProps {
	whiteBackground?: boolean;
}
const StyledButtonWhite = styled(StyledButton)<WhiteButtonProps>`
	background-color: white;
	color: #e81111;
	outline: ${(props) =>
		props.whiteBackground ? "1px solid #e81111" : "2px solid white"};
	&:hover {
		cursor: pointer;
		color: #b90000;
		background-color: white;
		outline: ${(props) =>
			props.whiteBackground ? "1px solid #b90000" : "2px solid white"};
	}
	&:focus-visible {
		outline: ${(props) =>
			props.whiteBackground ? "2px solid #e81111" : "2px solid white"};
		color: #e81111;
		outline-offset: 2px;
		background-color: white;
	}
	&:active {
		outline: none;
		color: #b90000;
	}
	&:disabled {
		color: #333333;
		opacity: 0.5;
		background: #b5b5b5;
	}
`;

interface StyledWhiteIconButtonProps {
	buttonWidth?: number;
}
const StyledWhiteIconButton = styled(StyledButton)<StyledWhiteIconButtonProps>`
	width: ${(props) =>
		props.buttonWidth ? `${props.buttonWidth}px` : "130px"};
	height: 40px;
	border: 1px solid #e81111;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 9px;
	background-color: white;
	color: #e81111;
	&:hover {
		cursor: pointer;
		color: #b90000;
		border-color: #b90000;
	}
	&:focus-visible {
		outline: 2px solid #e81111;
		outline-offset: 2px;
		color: #e81111;
		background-color: white;
	}
	&:active {
		border-color: #660000;
		outline: none;
		color: #b90000;
	}
	&:disabled {
		color: #333333;
		opacity: 0.5;
		background: #b5b5b5;
	}
`;

interface IconProps {
	iconWidth?: number;
}
const Icon = styled.img<IconProps>`
	width: ${(props) => (props.iconWidth ? `${props.iconWidth}px` : "20px")};
`;

const StyledIconButtonWhite = styled(StyledWhiteIconButton)`
	${Icon} {
		filter: invert(12%) sepia(65%) saturate(7216%) hue-rotate(356deg)
			brightness(103%) contrast(95%);
	}
	&:hover {
		${Icon} {
			filter: invert(7%) sepia(86%) saturate(6492%) hue-rotate(359deg)
				brightness(109%) contrast(108%);
		}
	}
	&:focus-visible {
		${Icon} {
			filter: invert(7%) sepia(86%) saturate(6492%) hue-rotate(359deg)
				brightness(109%) contrast(108%);
		}
	}
	&:active {
		${Icon} {
			filter: invert(9%) sepia(49%) saturate(5332%) hue-rotate(352deg)
				brightness(94%) contrast(115%);
		}
	}
`;

export { RedButton, WhiteButton, IconButtonWhite };
