import "react-calendar/dist/Calendar.css";
import "styles/calendar.css";
import { AEMModalContainer } from "components/AEMModalContainer";
import { BlackoutCalendar } from "./BlackoutCalendar";

interface Props {
	closeModal: any;
	standAlone?: boolean;
}

export const BlackoutCalendarModal = ({ closeModal, standAlone }: Props) => {
	return (
		<AEMModalContainer closeModal={closeModal}>
			<BlackoutCalendar closeModal={closeModal} standAlone={standAlone} />
		</AEMModalContainer>
	);
};
