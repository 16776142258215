import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

export type InvoiceBasicInfo = {
	number: string;
	host: {
		name: string;
		email: string;
	};
	school: {
		name: string;
		address: string;
		bookfairAccountId: string;
	};
	amountDetails: {
		totalCollected: number;
		digitalPaymentsCollected: number;
		purchaseOrders: number;
		cashProfit: number;
		amountDue: number;
	};
};

export type InvoiceSalesInfo = {
	scholasticDollars: {
		totalRedeemed: number;
		taxExemptSales: number;
		taxCollected: number;
		cashEquivalent: number;
	};
	tenderTotals: {
		cashAndChecks: number;
		creditCards: number;
		purchaseOrders: number;
	};
	grossSales: {
		taxExemptSales: number;
		taxableSales: number;
		total: number;
		taxTotal: number;
	};
	netSales: {
		shareTheFairFunds: {
			collected: number;
			redeemed: number;
		};
	};
	taxRate: number;
};

export type InvoiceSpendingInfo = {
	scholasticDollars: {
		totalRedeemed: number;
		existingBalance: number;
		appliedBalance: number;
		due: number;
	};
};

export type InvoiceEarningsInfo = {
	sales: number;
	scholasticDollars: {
		earned: number;
		due: number;
		balance: number;
		selected: number;
		max: number;
	};
	dollarFairLevel: number;
	cash: {
		selected: number;
		max: number;
	};
};

const defaultIndividualRegisterInfo: InvoiceBasicInfo = {
	number: "",
	host: {
		name: "",
		email: "",
	},
	school: {
		name: "",
		address: "",
		bookfairAccountId: "",
	},
	amountDetails: {
		totalCollected: 0,
		digitalPaymentsCollected: 0,
		purchaseOrders: 0,
		cashProfit: 0,
		amountDue: 0,
	},
};

const defaultInvoiceSalesInfo: InvoiceSalesInfo = {
	scholasticDollars: {
		totalRedeemed: 0,
		taxExemptSales: 0,
		taxCollected: 0,
		cashEquivalent: 0,
	},
	tenderTotals: {
		cashAndChecks: 0,
		creditCards: 0,
		purchaseOrders: 0,
	},
	grossSales: {
		taxExemptSales: 0,
		taxableSales: 0,
		total: 0,
		taxTotal: 0,
	},
	netSales: {
		shareTheFairFunds: {
			collected: 0,
			redeemed: 0,
		},
	},
	taxRate: 0,
};

const defaultInvoiceSpendingInfo: InvoiceSpendingInfo = {
	scholasticDollars: {
		totalRedeemed: 0,
		existingBalance: 0,
		appliedBalance: 0,
		due: 0,
	},
};

const defaultInvoiceEarningsInfo: InvoiceEarningsInfo = {
	sales: 0,
	scholasticDollars: {
		earned: 0,
		due: 0,
		balance: 0,
		selected: 0,
		max: 0,
	},
	dollarFairLevel: 0,
	cash: {
		selected: 0,
		max: 0,
	},
};

const useInvoicePageData = () => {
	const { bookfairsAPI } = useConfig();

	const [invoiceBasicInfo, setInvoiceBasicInfo] = useState<InvoiceBasicInfo>(
		defaultIndividualRegisterInfo
	);
	const [invoiceSalesInfo, setInvoiceSalesInfo] = useState<InvoiceSalesInfo>(
		defaultInvoiceSalesInfo
	);
	const [invoiceSpendingInfo, setInvoiceSpendingInfo] =
		useState<InvoiceSpendingInfo>(defaultInvoiceSpendingInfo);
	const [invoiceEarningsInfo, setInvoiceEarningsInfo] =
		useState<InvoiceEarningsInfo>(defaultInvoiceEarningsInfo);
	const [fairId, setFairId] = useState<string>("");

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetchInvoiceInfo = async () => {
			const currentFair = getFairIdFromUrl();
			if (checkSpsUd()) {
				try {
					const response = await axios.get(
						`${bookfairsAPI}/user/fairs/${currentFair}/financials/form`,
						{
							withCredentials: true,
						}
					);
					if (response.status !== 200) {
						handleGenericSuccess(response);
						return;
					}
					setInvoiceBasicInfo(response.data["invoice"]);
					setInvoiceSalesInfo(response.data["sales"]);
					setInvoiceSpendingInfo(response.data["spending"]);
					setInvoiceEarningsInfo(response.data["earnings"]);
					setFairId(response.headers["sbf-jarvis-resource-id"]);
				} catch (err) {
					setIsError(true);
					handleGenericErrors(err);
				} finally {
					setIsLoading(false);
				}
			}
		};
		fetchInvoiceInfo();
	}, [bookfairsAPI]);

	return {
		invoiceBasicInfo,
		invoiceSalesInfo,
		invoiceSpendingInfo,
		invoiceEarningsInfo,
		fairId,
		isError,
		isLoading,
	};
};

export default useInvoicePageData;
