import React from "react";
import styled from "styled-components";

interface Props {
	fairSalesTotal: number;
}

const FairSalesTotal = ({ fairSalesTotal }: Props) => {
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	return (
		<Container>
			<LeftContainer>
				<Header>Fair Sales Total</Header>
				<Description>
					(excluding Scholastic Dollars Redemptions, Sales Tax,
					Teacher Certificates, Unredeemed Share the Fair Contributions and Gift Certificate Purchases)
				</Description>
			</LeftContainer>
			<RightContainer>{formatter.format(fairSalesTotal)}</RightContainer>
		</Container>
	);
};

const Container = styled.div`
	display: inline-flex;
	padding: 48px 80px;
	flex-direction: row;
	align-items: flex-start;
	gap: 35px;
	border-radius: 16px;
	background: #f5f5f5;
	@media (max-width: 700px) {
		padding: 48px 25px;
	}
`;

const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 19px;
`;

const RightContainer = styled.div``;

const Header = styled.div`
	font-size: 28px;
	font-style: normal;
	font-weight: 300;
	line-height: 32px;
`;

const Description = styled.div`
	font-size: 13px;
	font-style: normal;
	font-weight: 300;
	line-height: 19px;
`;

export default FairSalesTotal;
