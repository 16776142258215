import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { getFairIdFromUrl } from "utils/HubUtility";

const useConfirmationPageData = () => {
	const { bookfairsAPI } = useConfig();

	const [fairId, setFairId] = useState("");
	const [amountDue, setAmountDue] = useState(0);
	const [paymentAvailable, setPaymentAvailable] = useState(false);

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetchConfirmationPageData = async () => {
			// if fair id in url, returns fair id, otherwise returns 'current'
			const currentFair = getFairIdFromUrl();
			try {
				const response = await axios.get(
					`${bookfairsAPI}/user/fairs/${currentFair}/financials/form/invoice`,
					{ withCredentials: true }
				);
				if (response.status !== 200) {
					handleGenericSuccess(response);
					return;
				}
				setAmountDue(response.data.amountDetails.amountDue.toFixed(2));
				setFairId(response.headers["sbf-jarvis-resource-id"]);
				setPaymentAvailable(response.data.paymentAvailable);
			} catch (err) {
				setIsError(true);
				handleGenericErrors(err);
			} finally {
				setIsLoading(false);
			}
		};
		fetchConfirmationPageData();
	}, [bookfairsAPI]);
	return {
		amountDue,
		fairId,
		paymentAvailable,
		isError,
		isLoading,
	};
};

export default useConfirmationPageData;
