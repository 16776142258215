import React, { useEffect } from "react";
import { AEMModalContainer } from "components/AEMModalContainer";
import { RedButton, WhiteButton } from "components/Button";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import styled from "styled-components/macro";
import { disableScrolling, enableScrolling } from "utils/ToggleScrolling";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

interface EnableProps {
	closeModal: any;
}

interface DisableProps {
	closeModal: any;
	setEnabled: any;
}

export const EnableEwalletModal = ({ closeModal }: EnableProps) => {
	const handleCloseEnable = () => {
		closeModal();
		enableScrolling();
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
	};

	useEffect(() => {
		disableScrolling();
	}, []);

	return (
		<AEMModalContainer closeModal={handleCloseEnable}>
			<StyledContainer>
				<Title> Are You Sure?</Title>
				<Text>
					{" "}
					eWallet is now enabled and available for parents on your
					Book Fair homepage.
				</Text>
				<ButtonContainer>
					<StyledRedButton handleClick={handleCloseEnable}>
						{" "}
						Close{" "}
					</StyledRedButton>
				</ButtonContainer>
			</StyledContainer>
		</AEMModalContainer>
	);
};

export const DisableEwalletModal = ({
	closeModal,
	setEnabled,
}: DisableProps) => {
	const { bookfairsAPI } = useConfig();

	const handleCloseDisable = () => {
		enableScrolling();
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
		closeModal();
	};

	useEffect(() => {
		disableScrolling();
	}, []);

	const handleDisable = async () => {
		const currentFair = getFairIdFromUrl();
		if (checkSpsUd()) {
			try {
				await axios
					.post(
						`${bookfairsAPI}/private/fairs/${currentFair}/ewallet/disable`,
						{ withCredentials: true }
					)
					.then((disableResponse) => {
						if (disableResponse.status === 200) {
							setEnabled();
							handleCloseDisable();
						} else {
							handleCloseDisable();
							handleGenericSuccess(disableResponse);
						}
					})
					.catch((err) => {
						handleCloseDisable();
						handleGenericErrors(err);
					});
			} catch (err) {
				handleCloseDisable();
				handleGenericErrors(err);
			}
		}
	};

	return (
		<AEMModalContainer closeModal={handleCloseDisable}>
			<StyledContainer>
				<Title> Are You Sure?</Title>
				<Text>
					{" "}
					Are you sure you want to disable eWallet? Access to setup an
					eWallet will be removed from your Book Fair homepage. Any
					existing eWallets can still be redeemed.
				</Text>
				<ButtonContainer>
					<StyledWhiteButton
						whiteBackground={true}
						handleClick={handleDisable}
					>
						{" "}
						Disable{" "}
					</StyledWhiteButton>
					<StyledRedButton handleClick={handleCloseDisable}>
						{" "}
						Keep Active{" "}
					</StyledRedButton>
				</ButtonContainer>
			</StyledContainer>
		</AEMModalContainer>
	);
};

const StyledContainer = styled.div`
	padding: 24px;
`;

const ButtonContainer = styled.div`
	padding-top: 32px;
	display: flex;
	flex-direction: row;
	gap: 16px;
	justify-content: flex-end;
`;
const Title = styled.p`
	font-size: 16px;
	font-weight: 700;
	line-height: 19px;
	letter-spacing: 0px;
	text-align: left;
	padding-top: 40px;
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 130px;
`;
const StyledRedButton = styled(RedButton)`
	width: 130px;
`;
const Text = styled.p`
	font-size: 17px;
	font-weight: 300;
	line-height: 25px;
	letter-spacing: 0px;
	text-align: left;
	padding-top: 16px;
	max-width: 538px;
`;
