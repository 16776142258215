import React, { useEffect } from "react";
import styled from "styled-components";
import ScanSheetExample from "images/ScanSheetExamplePNG.png";
import ScholasticLogoImg from "images/ScholasticLogoGrayOutline.svg";
import BookFairLogoImg from "images/BFLogoPNG.png";
import EWalletIconImg from "images/EWalletIcon.svg";
import { ScanSheetEntry } from "hooks/ScanSheet/useScanSheetData";
import GradeTeacherEntry from "./ScanSheetGradeTeacherEntry";

interface Props {
	schoolName: string;
	scanSheet: ScanSheetEntry[];
}

const ScanSheetDocument = ({ schoolName, scanSheet }: Props) => {
	// open print dialogue on page load
	useEffect(() => {
		window.print();
	}, []);
	return (
		<>
			<LetterPage>
				<TopLogoDiv>
					<ScholasticLogo
						src={ScholasticLogoImg}
						alt="Scholastic Logo"
					/>
					<BookFairLogo src={BookFairLogoImg} alt="Book Fair Logo" />
				</TopLogoDiv>
				<SchoolDiv>
					<EwalletIcon src={EWalletIconImg} alt="Ewallet Logo" />
					<InnerTextDiv>
						<ScanSheetText>Scan Sheet</ScanSheetText>
						<SchoolName>{schoolName}</SchoolName>
					</InnerTextDiv>
				</SchoolDiv>
				<IntroTextDiv>
					<IntroText>
						Keep this scan sheet at checkout. It's handy if an
						internet connection is unavailable or as an alternative
						to searching student names in the Payment System.
						Remember that families can add funds any time to a Book
						Fair eWallet, so be sure you're using the latest scan
						sheet. While you can use the scan sheet to track account
						balances, you'll have the most accurate eWallet balance
						information by using your register.
					</IntroText>
				</IntroTextDiv>
				<ExampleImage src={ScanSheetExample} alt="ScanSheetExample" />
				{scanSheet.map((entry, index) => (
					<GradeTeacherWrapper>
						<GradeTeacherEntry key={index} entry={entry} />
					</GradeTeacherWrapper>
				))}
			</LetterPage>
		</>
	);
};

const LetterPage = styled.div`
	box-sizing: border-box;
	padding: 20px 30px 0 30px;
	max-width: 2550px;
	margin: auto;
	@media print {
		@page {
			size: 2480px 3508px;
		}
	}
`;

const TopLogoDiv = styled.div`
	display: flex;
	flex-direction: row;
	gap: 30px;
	align-items: center;
`;

const ScholasticLogo = styled.img`
	width: 107px;
	height: 100%;
	@media print {
		width: 445px;
	}
	@media (min-width: 1100px) {
		width: 220px;
	}
	@media (min-width: 1400px) {
		width: 250px;
	}
	@media (min-width: 2050px) {
		width: 445px;
	}
`;

const BookFairLogo = styled.img`
	width: 90px;
	height: 100%;
	margin-top: -2px;
	@media print {
		width: 375px;
	}
	@media (min-width: 1100px) {
		width: 180px;
	}
	@media (min-width: 1400px) {
		width: 200px;
	}
	@media (min-width: 2050px) {
		width: 375px;
	}
`;

const SchoolDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 20px;
`;

const EwalletIcon = styled.img`
	width: 47px;
	height: 44px;
	@media print {
		width: 195px;
		height: 90%;
	}
	@media (min-width: 1100px) {
		width: 80px;
		height: 90%;
	}
	@media (min-width: 1400px) {
		width: 100px;
		height: 90%;
	}
	@media (min-width: 2050px) {
		width: 195px;
	}
`;

const InnerTextDiv = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 28px;
	gap: 5px;
	padding-top: 5px;
`;

const ScanSheetText = styled.p`
	color: #333;
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	@media print {
		font-size: 45px;
		line-height: 50px;
	}
	@media (min-width: 1100px) {
		font-size: 20px;
		line-height: 30px;
	}
	@media (min-width: 1400px) {
		font-size: 30px;
		line-height: 40px;
	}
	@media (min-width: 2050px) {
		font-size: 45px;
		line-height: 50px;
	}
`;

const SchoolName = styled.p`
	color: #333;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	@media print {
		font-size: 87px;
		line-height: 100px;
	}
	@media (min-width: 1100px) {
		font-size: 40px;
		line-height: 50px;
	}
	@media (min-width: 1400px) {
		font-size: 50px;
		line-height: 60px;
	}
	@media (min-width: 2050px) {
		font-size: 87px;
		line-height: 100px;
	}
`;

const IntroTextDiv = styled.div`
	margin: 22px 0px 6px 0px;
	min-height: 74px;
	@media print {
		min-height: 308px;
	}
	@media (min-width: 800px) {
		min-height: 50px;
	}
	@media (min-width: 2050px) {
		min-height: 308px;
	}
`;

const IntroText = styled.p`
	color: #333;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	@media print {
		font-size: 41.67px;
		line-height: 62.5px;
	}
	@media (min-width: 1000px) {
		font-size: 15px;
		line-height: 20px;
	}
	@media (min-width: 1100px) {
		font-size: 20px;
		line-height: 25px;
	}
	@media (min-width: 2050px) {
		font-size: 41.67px;
		line-height: 62.5px;
	}
`;

const ExampleImage = styled.img`
	width: 100%;
	height: auto;
	margin: 0 -2px 12px -2px;
`;

const GradeTeacherWrapper = styled.div`
	page-break-inside: avoid;
	&:not(:last-child) {
		margin-bottom: 16px;
	}
	@media print {
		page-break-inside: avoid;
	}
`;

export default ScanSheetDocument;
