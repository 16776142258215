import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import Slider from "react-slick";
import EventCard from "./EventCard";
import MoreDetailsModal from "components/Homepage/MoreDetailsModal";
import { convertDateToString } from "utils/DateConversion";
import { disableScrolling, enableScrolling } from "utils/ToggleScrolling";
import { generateCalendarFile } from "utils/GenerateCalendarFile";
import { trackMoreDetails } from "DumbleData";
import { trackAddToCalendar } from "DumbleData";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Props = {
	startDate: Date;
	endDate: Date;
	url: string;
	eventsData: {
		fairId: string;
		scheduleDate: string;
		createDate: Date;
		eventCategory: string;
		eventName: string;
		startTime: string;
		endTime: string;
		description: string;
		id: number;
		nextActive?: boolean;
	}[];
};

// Returns how many slides are shown on a page depending on the breakpoint.
// Used for controlling which cards are focusable
const getSlidesPerBreakpoint = (breakpoint: number) => {
	switch (breakpoint) {
		case 1600:
			return 5;
		case 960:
			return 4;
		case 764:
			return 3;
		case 670:
			return 100;
		default:
			return 5;
	}
};

const EventsCarousel = ({ startDate, endDate, eventsData, url }: Props) => {
	const [[activeBegin, activeEnd], setActiveSlides] = useState([0, 5]);
	const [openMoreDetails, setOpenMoreDetails] = useState(false);
	const [moreDetailsInfo, setMoreDetailsInfo] = useState<any>(null);

	const sliderRef = useRef<any>(null);
	const currentBreakpoint = useRef(null);

	useEffect(() => {
		sliderRef.current.innerSlider.list.parentNode.setAttribute(
			"tabindex",
			"-1"
		);
	}, []);

	const hasEventPassed = (scheduleDate: string, endTime = "23:59:59") => {
		const dateArray = scheduleDate.split("-");
		const timeArray = endTime.split(":");
		const formattedDate = new Date(
			parseInt(dateArray[0]),
			parseInt(dateArray[1]) - 1,
			parseInt(dateArray[2]),
			parseInt(timeArray[0]),
			parseInt(timeArray[1])
		);
		return formattedDate < new Date();
	};

	// Opens the more details modal
	const openMoreDetailsModal = (
		eventName: string,
		description: string,
		scheduleDate: string,
		startTime: string,
		endTime: string,
		eventCategory: string
	) => {
		disableScrolling();
		trackMoreDetails(eventCategory);
		setMoreDetailsInfo({
			eventName,
			description,
			scheduleDate,
			startTime,
			endTime,
			eventCategory,
		});
		setOpenMoreDetails(true);
	};

	// Closes the more details modal
	const closeMoreDetailsModal = () => {
		enableScrolling();
		setOpenMoreDetails(false);
	};

	// Generates a calendar file and tracks the click in dumble data
	const handleAddToCalendar = () => {
		generateCalendarFile(
			moreDetailsInfo.eventName,
			moreDetailsInfo.description,
			moreDetailsInfo.scheduleDate,
			moreDetailsInfo.startTime,
			moreDetailsInfo.endTime,
			false
		);
		trackAddToCalendar(moreDetailsInfo.eventCategory);
	};

	// Settings for the slick carousel
	const slickSettings = {
		arrows: false,
		dots: true,
		slidesToShow: 5,
		slidesToScroll: 5,
		variableWidth: true,
		infinite: false,
		beforeChange: (_oldIndex: number, newIndex: number) => {
			setActiveSlides([
				newIndex,
				newIndex +
					getSlidesPerBreakpoint(sliderRef.current.state.breakpoint),
			]);
		},
		onReInit: () => {
			if (
				sliderRef.current.state.breakpoint !== currentBreakpoint.current
			) {
				currentBreakpoint.current = sliderRef.current.state.breakpoint;
				sliderRef.current.slickGoTo(0);
			}
		},
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5,
				},
			},
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				},
			},
			{
				breakpoint: 764,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 670,
				settings: {
					slidesToShow: 100,
					slidesToScroll: 100,
				},
			},
		],
	};

	return (
		<CarouselWrapper>
			{openMoreDetails && (
				<MoreDetailsModal
					url={url || ""}
					eventName={moreDetailsInfo.eventName}
					date={moreDetailsInfo.scheduleDate}
					time={moreDetailsInfo.formattedTime}
					description={moreDetailsInfo.description}
					handleModal={closeMoreDetailsModal}
					eventCategory={moreDetailsInfo.eventCategory}
					handleAddToCalendar={handleAddToCalendar}
				/>
			)}
			<StyledSlider {...slickSettings} ref={sliderRef}>
				<EventCard
					scheduleDate={convertDateToString(startDate)}
					eventName="Book Fair Opens!"
					eventCategory="Opening Day"
					eventPassed={hasEventPassed(convertDateToString(startDate))}
					isActive={activeBegin <= 0 && 0 < activeEnd}
				/>
				{eventsData.map((event: any, index: number) => (
					<EventCard
						fairId={event.fairId}
						url={url}
						scheduleDate={event.scheduleDate}
						createDate={event.createDate}
						eventCategory={event.eventCategory}
						eventName={event.eventName}
						startTime={event.startTime}
						endTime={event.endTime}
						description={event.description}
						id={event.id}
						eventPassed={hasEventPassed(
							event.scheduleDate,
							event.endTime
						)}
						isActive={
							activeBegin <= index + 1 && index + 1 < activeEnd
						}
						handleMoreDetails={openMoreDetailsModal}
					/>
				))}
				<EventCard
					scheduleDate={convertDateToString(endDate)}
					eventName="Last Day of Book Fair"
					eventCategory="Closing Day"
					eventPassed={hasEventPassed(convertDateToString(endDate))}
					isActive={
						activeBegin <= eventsData.length + 1 &&
						eventsData.length + 1 < activeEnd
					}
				/>
			</StyledSlider>
		</CarouselWrapper>
	);
};

const CarouselWrapper = styled.div`
	position: relative;
	width: 100%;
`;

const StyledSlider = styled(Slider)`
	&.slick-slider.slick-dotted {
		margin-bottom: 48px;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-dots {
		margin-top: 27px;
		margin-bottom: 43px;
		position: absolute;
		bottom: 0;
	}
	.slick-dots li {
		margin: 0;
	}
	.slick-active .slick-dot-icon::before {
		color: white;
	}
	.slick-dots li button:focus-visible {
		outline: none;
	}
	.slick-dots li button::before {
		font-size: 12px;
	}
	.slick-dots li.slick-active button::before {
		color: white;
		opacity: 1;
	}
	.slick-slide {
		opacity: 0.5;
		pointer-events: none;
	}
	.slick-active {
		opacity: 1;
		pointer-events: auto;
	}
	.slick-list {
		width: 920px;
		margin-left: auto;
		margin-right: auto;
		overflow: visible;
	}
	.slick-track {
		width: 75000px !important;
		cursor: grab;
	}
	height: 400px;
	overflow-x: hidden;
	position: relative;
	padding: 0;
	width: 100%;
	text-align: center;
	box-sizing: border-box;
	white-space: nowrap;
	/* Hide scrollbar on mobile menu */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
	@media (max-width: 960px) {
		.slick-list {
			width: 733px;
		}
	}
	@media (max-width: 764px) {
		.slick-list {
			width: 546px;
		}
	}
	@media (max-width: 670px) {
		height: fit-content;
		padding: 0px 20px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		.slick-track {
			display: flex;
			flex-direction: column;
			width: 335px !important;
			cursor: auto;
		}
		.slick-list {
			width: 335px;
		}
		.slick-slide:last-child {
			margin-bottom: 75px;
		}
	}
`;

export default EventsCarousel;
