import React from "react";
import styled from "styled-components/macro";
import Heading from "components/Heading";
import BodyText from "components/BodyText";

interface Props {
	goalBooks: number;
	soldBooks: number;
	goalPurpose: string;
}
const GoalComponent = ({ goalBooks, soldBooks, goalPurpose }: Props) => {
	let tempPercent = Math.floor((soldBooks / goalBooks) * 100);
	if (tempPercent < 1) {
		tempPercent = 1;
	}
	if (tempPercent > 100) {
		tempPercent = 100;
	}
	const stringPercent = `${tempPercent}%`;
	return (
		<GoalComponentSection>
			<StyledHeading>School Goal</StyledHeading>
			<FlexRow>
				<InlineElement>
					<Container>
						<GoalTracker>
							<ProgressBar
								percent={stringPercent}
								complete={soldBooks >= goalBooks}
							/>
						</GoalTracker>
						<Row>
							<StyledBodyText className={"Sold"}>
								{soldBooks}
								{soldBooks === 1 ? " Book Sold" : ""}
								{soldBooks > 1 ? " Books Sold" : ""}
							</StyledBodyText>
							<StyledBodyText
								className={`Goal ${
									soldBooks > goalBooks ? "goalCompleted" : ""
								}`}
							>
								{goalBooks}{" "}
								{soldBooks > goalBooks
									? " Books Goal"
									: "Books "}
							</StyledBodyText>
						</Row>
					</Container>
				</InlineElement>
				<InlineElement>
					<Message>{goalPurpose}</Message>
				</InlineElement>
			</FlexRow>
		</GoalComponentSection>
	);
};

interface ProgressBarProps {
	percent: string;
	complete: boolean;
}

const StyledBodyText = styled(BodyText)`
	display: inline-flex;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px;
	letter-spacing: 0px;
	text-align: right;
	color: #e02c39;
	position: relative;
	&.Sold {
		left: 0;
	}
	&.Goal {
		right: 0;
		position: absolute;
	}
`;

const Container = styled.div`
	min-width: 308px;
	position: relative;
`;
const Row = styled.div`
	display: inline-flex;
	width: 100%;
	top: 48px;
	position: relative;
	overflow: hidden;
	white-space: nowrap;
`;
const ProgressBar = styled.div<ProgressBarProps>`
	position: absolute;
	width: ${(props) => props.percent};
	height: 40px;
	background: #e02c39;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	border-top-right-radius: ${(props) => (props.complete ? "8px" : "0")};
	border-bottom-right-radius: ${(props) => (props.complete ? "8px" : "0")};
`;
const GoalTracker = styled.div`
	position: absolute;
	width: 360px;
	height: 40px;
	background: #ffffff;
	border-radius: 8px;
	@media (max-width: 800px) {
		width: 308px;
	}
`;

const FlexRow = styled.div`
	display: inline-flex;
	width: 100%;
	gap: 40px;
	@media (max-width: 800px) {
		gap: 27.7px;
	}
	@media (max-width: 675px) {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 25px;
		align-items: center;
	}
`;

const InlineElement = styled.div`
	flex: 1 auto;
	width: calc(50% - 20px);

	@media (max-width: 675px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
`;

const Message = styled(BodyText)`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0px;
	text-align: left;
	max-width: 340px;
	@media (max-width: 675px) {
		max-width: 315px;
		margin-top: 40px;
	}
`;

const StyledHeading = styled(Heading)`
	font-size: 21px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: center;
	color: #015e8c;
`;

const GoalComponentSection = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 71px;
	min-height: 112px;
	@media (max-width: 720px) {
		padding-bottom: 51px;
	}
	@media (max-width: 675px) {
		padding-bottom: 20px;
	}
`;
export default GoalComponent;
