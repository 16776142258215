import { useState, useEffect } from "react";
import axios from "axios";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";
import { useConfig } from "providers/ConfigContext";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";

const useSpendingData = () => {
	const [totalRedeemed, setTotalRedeemed] = useState<number>(0.0);
	const [existingBalance, setExistingBalance] = useState<number>(0.0);
	const [appliedBalance, setAppliedBalance] = useState<number>(0.0);
	const [scholasticDollarsDue, setScholasticDollarsDue] =
		useState<number>(0.0);

	const { bookfairsAPI } = useConfig();

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [fairId, setFairId] = useState("");

	useEffect(() => {
		const getSpendingPageData = async () => {
			const currentFair = getFairIdFromUrl();
			if (checkSpsUd()) {
				try {
					await axios
						.get(
							`${bookfairsAPI}/user/fairs/${currentFair}/financials/form/spending`,
							{
								withCredentials: true,
							}
						)
						.then((response) => {
							if (response.status === 200) {
								if (response.data) {
									if (response.data.scholasticDollars) {
										setTotalRedeemed(
											response.data.scholasticDollars
												.totalRedeemed
										);
										setExistingBalance(
											response.data.scholasticDollars
												.existingBalance
										);
										setAppliedBalance(
											response.data.scholasticDollars
												.appliedBalance
										);
										setScholasticDollarsDue(
											response.data.scholasticDollars.due
										);
									}
									setFairId(
										response.headers[
											"sbf-jarvis-resource-id"
										]
									);
								}
							} else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							setIsError(true);
							handleGenericErrors(err);
						});
				} catch (err: any) {
					setIsError(true);
					handleGenericErrors(err);
				} finally {
					setIsLoading(false);
				}
			}
		};
		getSpendingPageData();
	}, [
		bookfairsAPI,
		setFairId,
		setIsError,
		setIsLoading,
		setTotalRedeemed,
		setExistingBalance,
		setAppliedBalance,
		setScholasticDollarsDue,
	]);

	return {
		fairId,
		totalRedeemed,
		existingBalance,
		appliedBalance,
		scholasticDollarsDue,
		isError,
		isLoading,
	};
};

export default useSpendingData;
