import React from "react";
import IframeContainer from "components/IframeContainer";
import { SDBalancePageContent } from "./SDBalancePageContent";
import SchoolSelectorBar from "components/SchoolSelector/SchoolSelectorBar";
import useSDBalancePageData from "hooks/SDBalance/useSDBalanceData";

export const SDBalancePage = () => {
	const { balance, transactions, isLoading } = useSDBalancePageData();

	return (
		<IframeContainer
			renderItems={() => (
				<>
					{!isLoading && (
						<>
							<SchoolSelectorBar />
							<SDBalancePageContent
								rewardsBalance={balance.rewards}
								profitBalance={balance.profit}
								transactions={transactions}
							/>
						</>
					)}
				</>
			)}
		/>
	);
};
