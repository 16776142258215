import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import DataTableRow from "./DataTableRow";
import TableHeaderCell from "./TableHeaderCell";
import { EWallet } from "hooks/EWalletRoster/useEWalletRosterData";
import DropDownFilter from "./DropDownFilter";
import {
	sortBalanceAscend,
	sortBalanceDescend,
	sortClassroomAscend,
	sortClassroomDescend,
	sortGradeAscend,
	sortGradeDescend,
	sortRecipientAscend,
	sortRecipientDescend,
	sortTypeAscend,
	sortTypeDescend,
} from "./SortingFunctions";

interface Props {
	ewallets: Array<EWallet>;
}

// API values are on left, display values are on right
// used to convert user selected filters to response specific values
const apiMapping = {
	"Pre-kindergarten": "Pre-K",
	Kindergarten: "K",
	"1st": "1st",
	"2nd": "2nd",
	"3rd": "3rd",
	"4th": "4th",
	"5th": "5th",
	"6th": "6th",
	"7th": "7th",
	"8th": "8th",
	STUDENT: "Student",
	TEACHER: "Teacher",
};

// Display in filter dropdown
// "Label:" prefix is parsed to render label
const options = [
	"Label:Grade",
	"Pre-K",
	"K",
	"1st",
	"2nd",
	"3rd",
	"4th",
	"5th",
	"6th",
	"7th",
	"8th",
	"Label:Type",
	"Student",
	"Teacher",
];

export const EWalletDataTable = ({ ewallets }: Props) => {
	// sorted and/or filtered data
	const [sortedData, setSortedData] = useState(ewallets);

	// sets sorting arrow styles
	const [sortedBy, setSortedBy] = useState("");

	// array of all filters currently applied to dataset
	const [filters, setFilters] = useState<Array<string>>([]);

	const handleAddFilter = (filter: string) => {
		setFilters((prevState) => [...prevState, filter]);
	};

	const handleRemoveFilter = (filter: string) => {
		const tempFilters = [...filters];
		tempFilters.splice(tempFilters.indexOf(filter), 1);
		setFilters(tempFilters);
	};

	// Sorting functions, sortedBy controls up/down arrow styling
	const sortGrade = () => {
		if (sortedBy === "gradeDescend") {
			setSortedBy("gradeAscend");
			setSortedData(sortGradeAscend(sortedData));
		} else {
			setSortedBy("gradeDescend");
			setSortedData(sortGradeDescend(sortedData));
		}
	};

	const sortClassroom = () => {
		if (sortedBy === "classroomDescend") {
			setSortedBy("classroomAscend");
			setSortedData(sortClassroomAscend(sortedData));
		} else {
			setSortedBy("classroomDescend");
			setSortedData(sortClassroomDescend(sortedData));
		}
	};

	const sortRecipient = () => {
		if (sortedBy === "recipientDescend") {
			setSortedBy("recipientAscend");
			setSortedData(sortRecipientAscend(sortedData));
		} else {
			setSortedBy("recipientDescend");
			setSortedData(sortRecipientDescend(sortedData));
		}
	};

	const sortType = () => {
		if (sortedBy === "typeDescend") {
			setSortedBy("typeAscend");
			setSortedData(sortTypeAscend(sortedData));
		} else {
			setSortedBy("typeDescend");
			setSortedData(sortTypeDescend(sortedData));
		}
	};

	const sortBalance = () => {
		if (sortedBy === "balanceDescend") {
			setSortedBy("balanceAscend");
			setSortedData(sortBalanceAscend(sortedData));
		} else {
			setSortedBy("balanceDescend");
			setSortedData(sortBalanceDescend(sortedData));
		}
	};

	useEffect(() => {
		setSortedData(ewallets);
	}, [ewallets]);

	const filterEWallets = useCallback(
		(ewallet: EWallet) => {
			setSortedBy("");
			const teacher = filters.includes("Teacher");
			const student = filters.includes("Student");
			if (filters.length === 2 && teacher && student) {
				// only both recipient types selected
				return true;
			} else if (teacher || student) {
				// one to many recipient types and 1 to many grades selected
				if (filters.length > 1) {
					if (
						filters.includes(
							apiMapping[
								ewallet.attributes
									.grade as keyof typeof apiMapping
							]
						) &&
						filters.includes(
							apiMapping[
								ewallet.recipientType as keyof typeof apiMapping
							]
						)
					) {
						return true;
					}
				} else if (filters.length === 1) {
					// one recipient type filter
					if (
						filters.includes(
							apiMapping[
								ewallet.recipientType as keyof typeof apiMapping
							]
						)
					) {
						return true;
					}
				}
			} else {
				if (
					// one to many grade types
					filters.includes(
						apiMapping[
							ewallet.attributes.grade as keyof typeof apiMapping
						]
					)
				) {
					return true;
				}
			}
			return false;
		},
		[filters]
	);

	// filter data when user selects a filter
	// restore full data set when all filters are unselected
	useEffect(() => {
		if (filters.length > 0) {
			setSortedData(ewallets.filter(filterEWallets));
		} else {
			setSortedData(ewallets);
		}
	}, [filters, ewallets, filterEWallets]);

	return (
		<>
			<FilterContainer>
				<StyledEWalletCount>
					{sortedData.length} eWallets
				</StyledEWalletCount>
				<DropDownFilter
					options={options}
					addFilter={handleAddFilter}
					removeFilter={handleRemoveFilter}
					optionsChosen={filters}
					defaultText={"Filter Results"}
				/>
			</FilterContainer>
			<Container>
				<table className="min-w-full text-center">
					<thead>
						<tr>
							<TableHeaderCell
								label={"Grade"}
								onClick={sortGrade}
								sortedBy={sortedBy}
								upArrow={"gradeAscend"}
								downArrow={"gradeDescend"}
							/>
							<TableHeaderCell
								label={"Classroom"}
								onClick={sortClassroom}
								sortedBy={sortedBy}
								upArrow={"classroomAscend"}
								downArrow={"classroomDescend"}
							/>
							<TableHeaderCell
								label={"Recipient"}
								onClick={sortRecipient}
								sortedBy={sortedBy}
								upArrow={"recipientAscend"}
								downArrow={"recipientDescend"}
							/>
							<TableHeaderCell
								label={"Type"}
								onClick={sortType}
								sortedBy={sortedBy}
								upArrow={"typeAscend"}
								downArrow={"typeDescend"}
							/>
							<TableHeaderCell
								label={"Balance"}
								onClick={sortBalance}
								sortedBy={sortedBy}
								upArrow={"balanceDescend"}
								downArrow={"balanceAscend"}
							/>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{sortedData.map((row) => (
							<DataTableRow
								key={row.id}
								grade={row.attributes.grade}
								classroom={row.attributes.teacherLastName}
								recipient={`${row.attributes.recipientLastName}, ${row.attributes.recipientFirstName}`}
								type={row.recipientType}
								balance={row.balance}
								transactions={row.transactions}
								id={row.id}
								apiMapping={apiMapping}
							/>
						))}
					</tbody>
				</table>
				{sortedData.length === 0 && (
					<NoResultsContainer>
						<StyledNoResultsText>
							No Results Found.
						</StyledNoResultsText>
					</NoResultsContainer>
				)}
			</Container>
		</>
	);
};

const Container = styled.div`
	width: 100%;
	overflow-x: scroll;
	min-height: 480px;
	table {
		width: 100%;
		border-collapse: collapse;
		text-align: left;
		margin-bottom: 20px;
	}
	th {
		background-color: #f4f4f4;
		height: 36px;
		text-align: left;
		border-bottom: 1px solid rgba(51, 51, 51, 0.25);
		min-width: 145px;
		max-width: 145px;
		padding-left: 8px;
	}
`;

const FilterContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 25px 0px;
	@media (max-width: 470px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const StyledEWalletCount = styled.div`
	font-weight: 400;
	font-size: 24px;
	line-height: 28px;
	height: 28px;
	color: #333333;
`;

const NoResultsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
`;

const StyledNoResultsText = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	margin-top: 20px;
`;

export default EWalletDataTable;
