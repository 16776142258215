import "./App.css";
import { ProvideConfig } from "providers/ConfigContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Builder } from "./components/HomepageBuilder/pages/Builder";
import { LandingPage } from "./components/HomepageBuilder/pages/LandingPage";
import { CustomizePage } from "./components/HomepageBuilder/pages/CustomizePage";
import { Homepage } from "components/HomepageBuilder/pages/Homepage";
import { ContactPage } from "components/HomepageBuilder/pages/ContactPage";
import { VolunteerPage } from "components/HomepageBuilder/pages/VolunteerPage";
import { InformationPage } from "components/HomepageBuilder/pages/InformationPage";
import { ShareHomepagePage } from "components/HomepageBuilder/pages/ShareHomepagePage";
import { ScheduleActivitiesPage } from "components/HomepageBuilder/pages/ScheduleActivitiesPage";
import { GoalPage } from "components/HomepageBuilder/pages/GoalPage";
import { ExitPage } from "components/HomepageBuilder/pages/ExitPage";
import { FairFinder } from "components/HomepageBuilder/pages/FairFinder";
import { FairSelector } from "components/FairSelector/FairSelector";
import { ConfirmYourFair } from "components/ConfirmYourFair/ConfirmYourFair";
import FairDetailsPage from "components/FairDetailsPage/pages/FairDetailsPage";
import PrintCoaPage from "components/ConfirmYourFair/LegalCopy/PrintCoaPage";
import FairInfoBarStandAlone from "components/FairInfoBarStandAlone";
import BlackoutCalendarStandAlone from "components/BlackoutCalendar/BlackoutCalendarStandAlone";
import { FinancialFormConfirmation } from "components/FinancialForm/Confirmation/FinancialFormConfirmation";
import { FinancialFormSummary } from "components/FinancialForm/Summary/FinancialFormSummary";
import { FinancialFormIntro } from "components/FinancialForm/Intro/FinancialFormIntro";
import { FinancialFormSales } from "components/FinancialForm/Sales/FinancialFormSales";
import { FinancialFormReview } from "components/FinancialForm/Review/FinancialFormReview";
import { FinancialFormSpending } from "components/FinancialForm/Spending/FinancialFormSpending";
import { FinancialFormEarnings } from "components/FinancialForm/Earnings/FinancialFormEarnings";
import ErrorCode from "components/ErrorCode";
import EWalletRosterPage from "components/EWalletRosterPage/EWalletRosterPage";
import { SchoolSelector } from "components/SchoolSelector/SchoolSelector";
import SchoolInfoBarStandAlone from "components/SchoolSelector/SchoolInfoBarStandAlone";
import { FinFormBuilder } from "components/FinancialForm/FinFormBuilder";
import { SalesHistory } from "components/SalesHistory/SalesHistory";
import { SDBalancePage } from "components/SDBalance/pages/SDBalancePage";
import { IndividualScansheetPage } from "components/EWalletScanSheet/IndividualScanSheet/IndividualScansheetPage";
import { ScanSheetPage } from "components/EWalletScanSheet/ScanSheetPage/ScanSheetPage";
// import ConsultantDashboardPage from "components/ConsultantDashboard/ConsultantDashboardPage";

function App() {
	return (
		<ProvideConfig>
			<div className="App">
				<Router>
					<Routes>
						<Route path="/bf/:schoolName" element={<Homepage />} />
						<Route
							path="/reactapp/customize"
							element={<CustomizePage />}
						/>
						<Route
							path="/reactapp/landing"
							element={<LandingPage />}
						/>
						<Route path="/reactapp/" element={<Builder />}>
							<Route
								path="customize/contact"
								element={<ContactPage />}
							/>
							<Route
								path="customize/goals"
								element={<GoalPage />}
							/>
							<Route
								path="customize/volunteer"
								element={<VolunteerPage />}
							/>
							<Route
								path="customize/schedule"
								element={<ScheduleActivitiesPage />}
							/>
							<Route
								path="customize/information"
								element={<InformationPage />}
							/>
							<Route
								path="customize/share"
								element={<ShareHomepagePage />}
							/>
						</Route>
						<Route path="/reactapp/" element={<FinFormBuilder />}>
							<Route
								path="financial-form"
								element={<FinancialFormIntro />}
							></Route>
							<Route
								path="financial-form/confirmation"
								element={<FinancialFormConfirmation />}
							></Route>
							<Route
								path="financial-form/summary"
								element={<FinancialFormSummary />}
							></Route>
							<Route
								path="financial-form/review"
								element={<FinancialFormReview />}
							></Route>
							<Route
								path="financial-form/sales"
								element={<FinancialFormSales />}
							></Route>
							<Route
								path="financial-form/spending"
								element={<FinancialFormSpending />}
							></Route>
							<Route
								path="financial-form/earnings"
								element={<FinancialFormEarnings />}
							></Route>
						</Route>
						<Route
							path="/reactapp/exit"
							element={<ExitPage />}
						></Route>
						<Route
							path="/reactapp/fairfinder"
							element={<FairFinder />}
						></Route>
						<Route
							path="/reactapp/fair-selector"
							element={<FairSelector />}
						></Route>
						<Route
							path="/reactapp/school-switcher"
							element={<SchoolInfoBarStandAlone />}
						></Route>
						<Route
							path="/reactapp/confirm-fair"
							element={<ConfirmYourFair />}
						></Route>
						<Route
							path="/reactapp/fair-details"
							element={<FairDetailsPage />}
						></Route>
						<Route
							path="/reactapp/ewallet-roster"
							element={<EWalletRosterPage />}
						></Route>
						<Route
							path="/reactapp/individual-scansheet"
							element={<IndividualScansheetPage />}
						></Route>
						<Route
							path="/reactapp/print-coa.html"
							element={<PrintCoaPage />}
						></Route>
						<Route
							path="/reactapp/fair-switcher"
							element={<FairInfoBarStandAlone />}
						></Route>
						<Route
							path="/reactapp/blackout-calendar"
							element={<BlackoutCalendarStandAlone />}
						></Route>
						<Route
							path="/reactapp/error-code"
							element={<ErrorCode />}
						></Route>
						<Route
							path="/print-coa.html"
							element={<PrintCoaPage />}
						></Route>
						<Route
							path="/reactapp/select-school"
							element={<SchoolSelector />}
						></Route>
						<Route
							path="/reactapp/sales-history"
							element={<SalesHistory />}
						></Route>
						<Route
							path="/reactapp/sd-balance"
							element={<SDBalancePage />}
						></Route>
						<Route
							path="/reactapp/ewallet-roster-scansheet"
							element={<ScanSheetPage />}
						></Route>
						{/* <Route
							path="/reactapp/consultant-dashboard"
							element={<ConsultantDashboardPage />}
						></Route> */}
					</Routes>
				</Router>
			</div>
		</ProvideConfig>
	);
}

export default App;
