import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { RedButton } from "components/Button";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd } from "utils/HubUtility";
import { trackSelectSchoolButton } from "DumbleData";

interface School {
	name: string;
	ucn: string;
	bookfairAccountId: string;
}

interface Props {
	isModal?: boolean;
}

export const SchoolSelectorContent = ({ isModal }: Props) => {
	const [schoolList, setSchoolList] = useState<School[]>([]);
	const { bookfairsAPI } = useConfig();

	let destinationLink = "/content/fairs/hub/sdc.html";
	if (isModal) {
		// If using the modal, parent page will reload when selecting a school
		destinationLink = window.parent.location.href;
	} else {
		destinationLink =
			sessionStorage.getItem("bfDestinationLogin") || destinationLink;
	}

	//Get schools from api
	useEffect(() => {
		const fetchSchools = async () => {
			if (checkSpsUd()) {
				try {
					const response = await axios.get(
						bookfairsAPI + "/user/schools",
						{
							withCredentials: true,
						}
					);
					if (response.status === 200) {
						setSchoolList(response.data.schools);
					} else {
						handleGenericSuccess(response);
					}
				} catch (e: any) {
					handleGenericErrors(e);
				}
			}
		};
		fetchSchools();
	}, [bookfairsAPI]);

	async function handleClickSelectSchool(
		schoolId: string,
		schoolName: string
	) {
		//add api request to select school here

		if (checkSpsUd()) {
			try {
				const response = await axios.get(
					bookfairsAPI + `/user/schools/${schoolId}?mode=SELECT`,
					{
						withCredentials: true,
					}
				);
				if (response.status === 200) {
					trackSelectSchoolButton(schoolName);
					window.open(destinationLink, "_top");
				} else {
					handleGenericSuccess(response);
				}
			} catch (e: any) {
				handleGenericErrors(e);
			}
		}
	}

	return (
		<>
			{schoolList.length > 0 && (
				<Container>
					<StyledHeading>Please Select a School</StyledHeading>
					<StyledSchoolList>
						{schoolList.map((school) => {
							return (
								<StyledSchoolContainer key={school.ucn}>
									<StyledName>{school.name}</StyledName>
									<StyledRedButton
										handleClick={() => {
											handleClickSelectSchool(
												school.bookfairAccountId,
												school.name
											);
										}}
									>
										Select School
									</StyledRedButton>
								</StyledSchoolContainer>
							);
						})}
					</StyledSchoolList>
				</Container>
			)}
		</>
	);
};

const StyledRedButton = styled(RedButton)`
	display: flex;
	padding: 17.5px 20px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

interface ContainerProps {
	isModal?: boolean;
}
const Container = styled.div<ContainerProps>`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-left: 72px;
	padding-right: 72px;
	padding-top: 24px;
	/* Hide Scrollbar */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
	::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
	@media (max-width: 719px) {
		padding: 24px 24px 0px 24px;
	}
`;

const StyledHeading = styled.h1`
	padding-top: 17px;
	font-size: 28px;
	line-height: 32px;
	font-weight: 500;
	color: #002d70;
	align-self: flex-start;
	@media (max-width: 500px) {
		width: 272px;
		text-align: center;
		align-self: center;
	}
`;

const StyledSchoolList = styled.div`
	width: 100%;
	padding-top: 48px;
	padding-bottom: "0px";
`;

const StyledSchoolContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid var(--Grey-Grey-6, #d7d7d7);
	padding-top: 16px;
	margin-bottom: 16px;
	gap: 20px;
	@media (max-width: 500px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
	}
`;

const StyledName = styled.h2`
	color: #002d70;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 21px;
	word-wrap: break-word;
`;
