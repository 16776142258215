import React from "react";
import styled from "styled-components/macro";
import {
	NumberCaption,
	StyledNumber,
} from "../FairDetailsPage/FairDetailsCard";
import { Grid } from "components/Grid";
import {
	Heading,
	HeadingTitle,
} from "../FairDetailsPage/pages/FairDetailsPageContent";
import EWalletDataTable from "./EWalletDataTable";
import { useConfig } from "providers/ConfigContext";
import { IconButtonWhite } from "components/Button";
import Print from "images/Print.svg";
import { EWalletRoster } from "hooks/EWalletRoster/useEWalletRosterData";

interface Props {
	eWalletRosterData: EWalletRoster;
}

const EWalletRosterPageContent = ({ eWalletRosterData }: Props) => {
	const { bookfairsOrigin } = useConfig();

	const handleOpenScanSheet = () => {
		window._satellite.track("print-scan-sheet", {
			"button-text": "print-scan-sheet",
		});
		window.open(
			`${bookfairsOrigin}content/fairs/hub/scansheet/classroom.html?fairid=${eWalletRosterData.fairId}`,
			"_blank"
		);
	};

	return (
		<Grid>
			<FullWidth>
				<Heading>
					<HeadingTitle>Book Fair eWallet</HeadingTitle>
					<HeadingPrintButton>
						<IconButtonWhite
							whiteBackground={true}
							handleClick={handleOpenScanSheet}
							iconSrc={Print}
							iconAlt={"Print"}
							iconWidth={14}
							buttonWidth={194}
						>
							PRINT SCAN SHEET
						</IconButtonWhite>
					</HeadingPrintButton>
				</Heading>
				<HeadingInfoText>
					All eWallets created for your book fair are listed below.
					Sort by grade, teacher, or student and click on individual
					names to view transaction history.
				</HeadingInfoText>
				<HeadingInfoText>
					If internet connection is unavailable: Print out a{" "}
					<LinkStyle
						href={`${bookfairsOrigin}content/fairs/hub/scansheet/classroom.html?fairid=${eWalletRosterData.fairId}`}
						target="_blank"
						rel="noopener noreferrer"
						tabIndex={1}
					>
						SCAN SHEET
					</LinkStyle>{" "}
					for the cashier station; each student will have a unique
					barcode. Because eWallets are created in real time, make
					sure to print out an updated scan sheet each day.{" "}
					<LinkStyle
						href={`https://scholasticcrm.my.site.com/scholasticfaqs/s/topic/0TO2M000001lCfxWAE/ewallet`}
						target="_blank"
						rel="noopener noreferrer"
						tabIndex={1}
					>
						View FAQs
					</LinkStyle>
					.
				</HeadingInfoText>
				<EwalletNumbers>
					<EwalletSales>
						<NumberCaption>Sales to date</NumberCaption>
						<StyledNumber>${eWalletRosterData.sales}</StyledNumber>
					</EwalletSales>
					<EwalletUnspent>
						<NumberCaption>Unspent funds</NumberCaption>
						<StyledNumber>
							${eWalletRosterData.unspentFunds}
						</StyledNumber>
					</EwalletUnspent>
					<EwalletNote>
						<EwalletUnspentNote>
							Remind student they have unspent funds
						</EwalletUnspentNote>
					</EwalletNote>
				</EwalletNumbers>
				<EWalletDataTable ewallets={eWalletRosterData.ewallets} />
			</FullWidth>
		</Grid>
	);
};

const HeadingPrintButton = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 720px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const HeadingInfoText = styled.p`
	font-style: normal;
	font-weight: 300;
	font-size: 17px;
	line-height: 25px;
	margin-bottom: 10px;
	color: #000000;
`;

const LinkStyle = styled.a`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #e81111;
	text-decoration: none;
	&:hover {
		font-style: unset;
		text-decoration: underline;
		cursor: pointer;
	}
	&:focus-visible {
		outline-color: #e81111;
		outline-offset: 5px;
	}
`;

const FullWidth = styled.div`
	grid-column-start: 1;
	grid-column-end: 24;
`;

const EwalletNumbers = styled.div`
	display: flex;
	padding-top: 30px;
	@media (max-width: 720px) {
		display: block;
	}
`;

const EwalletSales = styled.div`
	@media (max-width: 720px) {
		margin-bottom: 32px;
	}
`;

const EwalletUnspent = styled.div`
	padding-left: 145px;
	@media (max-width: 720px) {
		padding-left: 0px;
	}
`;

const EwalletNote = styled.div`
	padding-left: 12px;
	padding-top: 18px;
	width: 144px;
	@media (max-width: 720px) {
		padding-left: 0;
	}
`;

const EwalletUnspentNote = styled.div`
	font-weight: 400;
	font-size: 12px;
	line-height: 17px;
`;

export default EWalletRosterPageContent;
