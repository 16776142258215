import styled from "styled-components/macro";
import FacebookIcon from "images/Facebook.svg";
import { trackShareBuilder, trackShareHomepage } from "DumbleData";

interface Props {
	homepageURL: string;
	pageName?: string;
}

const FacebookShareButton = ({ homepageURL, pageName }: Props) => {
	const openFacebookDialog = () => {
		// @ts-ignore
		FB.ui(
			{
				method: "share",
				href: homepageURL,
			},
			function (response: any) {}
		);
		pageName === "liveHomepage"
			? trackShareHomepage("facebook")
			: trackShareBuilder("facebook");
	};

	return (
		<StyledButton onClick={openFacebookDialog} className={pageName}>
			<StyledFacebookIcon
				src={FacebookIcon}
				alt="Share Live Homepage to Facebook"
				className={pageName}
			></StyledFacebookIcon>
		</StyledButton>
	);
};

const StyledButton = styled.button`
	background: none;
	border: none;
	border-radius: 56px;
	display: flex;
	&:focus {
		outline: 2px solid #3c5a9b;
		outline-offset: 2px;
	}
	&.liveHomepage {
		height: 40px;
		width: 40px;
	}
`;

const StyledFacebookIcon = styled.img`
	cursor: pointer;
	&.liveHomepage {
		height: 40px;
	}
`;

export default FacebookShareButton;
