import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { useConfig } from "providers/ConfigContext";
import { Page } from "components/HomepageBuilder/Page";
import ShareIcon from "images/ViewAndShare.svg";
import Heading from "components/Heading";
import PageIcon from "components/HomepageBuilder/PageIcon";
import BodyText from "components/BodyText";
import TextInput from "components/TextInput";
import { RedButton, WhiteButton } from "components/Button";
import TextLink from "components/TextLink";
import ToggleButton from "components/ToggleButton";
import { useShareHomepageData } from "./Builder";
import useCustomAlert from "hooks/HomepageBuilder/useCustomAlert";
import FacebookShareButton from "components/HomepageBuilder/FacebookShareButton";
import TwitterShareButton from "components/HomepageBuilder/TwitterShareButton";
import SaveChangesModal from "components/HomepageBuilder/SaveChangesModal";
import Prompt from "components/HomepageBuilder/Prompt";
import { validateURL } from "utils/InputValidations";
import {
	trackShareBuilder,
	trackCustomizeUrl,
	trackFindYourFairToggleOn,
} from "DumbleData";
import IframeContainer from "components/IframeContainer";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

export const ShareHomepagePage = () => {
	const { env, bookfairsAPI, damAssetsOrigin } = useConfig();
	const { fairId, shareHomepageData, nextLocation, setNextLocation } =
		useShareHomepageData();
	const { activateAlert, alert } = useCustomAlert(
		"Homepage URL is copied to your clipboard"
	);
	const navigate = useNavigate();
	const [customUrlError, setCustomUrlError] = useState<string>("");
	const [cachedUrl, setCachedUrl] = useState<string>(
		shareHomepageData.customUrl
	);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const [allowNavigation, setAllowNavigation] = useState(false);
	const firstRender = useRef(true);
	const fromCancel = useRef(false);

	const currentFair = getFairIdFromUrl();

	const handleOpenCustomizeWindow = () => {
		setIsOpen(true);
		setCachedUrl(shareHomepageData.customUrl);
	};

	const cancel = () => {
		fromCancel.current = true;
		shareHomepageData.setCustomUrl(cachedUrl);
		setCustomUrlError("");
		setIsOpen(false);
		setAllowNavigation(false);
		setAllChangesSaved(true);
	};

	const handleInput = (e: string) => {
		shareHomepageData.setCustomUrl(e);
		setAllChangesSaved(false);
	};

	const handleCopyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(
				`https://www.scholastic.com/bf/${cachedUrl}`
			);
			activateAlert();
			trackShareBuilder("copy-url");
		} catch (err) {
			console.error(err);
		}
	};

	const saveUrl = () => {
		if (!shareHomepageData.customUrl.length) {
			setCustomUrlError("Please enter a web address.");
			setSaveChangesModalOpen(false);
		} else if (!validateURL(shareHomepageData.customUrl)) {
			setCustomUrlError("Only alphanumeric characters are allowed.");
			setSaveChangesModalOpen(false);
		} else {
			setIsOpen(false);
			submitCustomUrl();
		}
	};

	const submitCustomUrl = async () => {
		if (shareHomepageData.customUrl !== cachedUrl) {
			if (checkSpsUd()) {
				try {
					await axios
						.put(
							`${bookfairsAPI}/user/fairs/${currentFair}/homepage`,
							{
								homepageUrl: {
									fairId: fairId,
									schoolId: shareHomepageData.schoolId,
									webUrl: shareHomepageData.customUrl,
									urlStatus: shareHomepageData.urlStatus,
								},
							},
							{ withCredentials: true }
						)
						.then((response) => {
							if (response.status === 200) {
								setCachedUrl(shareHomepageData.customUrl);
								if (nextLocation.includes("/bf/")) {
									setNextLocation(
										`/bf/${shareHomepageData.customUrl}`
									);
								}
							} else {
								handleGenericSuccess(response);
							}
						})
						.then(() => {
							setAllChangesSaved(true);
						});
					trackCustomizeUrl(shareHomepageData.customUrl);
				} catch (err: any) {
					if (err.response?.status === 400) {
						setCustomUrlError("Please enter a unique web address.");
						// close save changes modal if address is not unique
						setSaveChangesModalOpen(false);
						setIsOpen(true);
					} else {
						console.error(err);
						// if request fails, redirect to existing nextLocation();
						setAllChangesSaved(true);
						handleGenericErrors(err);
					}
				}
			}
		}
	};

	useEffect(() => {
		const submitToggleData = async () => {
			if (checkSpsUd()) {
				try {
					await axios
						.put(
							`${bookfairsAPI}/user/fairs/${currentFair}/homepage`,
							{
								homepageUrl: {
									fairFinderCkbok: shareHomepageData.shareUrl
										? "Y"
										: "N",
								},
							},
							{ withCredentials: true }
						)
						.then((response) => {
							if (response.status === 200) {
								shareHomepageData.shareUrl &&
									trackFindYourFairToggleOn();
							} else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							handleGenericErrors(err);
						});
				} catch (err) {
					handleGenericErrors(err);
				}
			}
		};
		submitToggleData();
	}, [shareHomepageData.shareUrl, bookfairsAPI, fairId, currentFair]);

	//
	// Logic to handle navigation and rendering the SaveChangesModal
	//

	//Tracks changes made
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		} else if (fromCancel.current) {
			fromCancel.current = false;
		} else {
			setAllChangesSaved(false);
		}
	}, [shareHomepageData.customUrl]);

	//Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			if (nextLocation.includes("/bf/")) {
				if (env === "prod") {
					window.open(
						"https://bookfairs.scholastic.com" + nextLocation
					);
				} else {
					window.open(nextLocation);
				}
				window.location.reload();
			} else {
				navigate(nextLocation);
			}
		}
	}, [allChangesSaved, allowNavigation, navigate, nextLocation, env]);

	///////////////////////////

	return (
		<IframeContainer
			renderItems={() => (
				<>
					<StyledInformationPage>
						{alert}
						<PageIcon
							src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/View%20&%20Share.svg`}
							defaultImg={ShareIcon}
							alt="Share Icon"
						/>
						<StyledHeading>Share your homepage</StyledHeading>
						<StyledBodyTextSubheading>
							Now that you've customized your Book Fair homepage,
							it's ready to share! Don't forget to copy your
							homepage link to paste into all your Book Fair
							communications.
						</StyledBodyTextSubheading>
						<Wrapper>
							<FlexRow>
								<InlineElement>
									<FacebookShareButton
										homepageURL={`${window.location.origin}/bf/${cachedUrl}`}
									/>
								</InlineElement>
								<InlineElement>
									<TwitterShareButton customUrl={cachedUrl} />
								</InlineElement>
							</FlexRow>
							<FlexRow className="buttons">
								<InlineElement>
									<StyledWhiteButton
										whiteBackground={true}
										handleClick={handleCopyToClipboard}
									>
										copy homepage url
									</StyledWhiteButton>
								</InlineElement>
								<InlineElement>
									<StyledRedButton
										handleClick={() => {
											// remove google chrome save changes modal
											window.onbeforeunload = null;
											setNextLocation(`/bf/${cachedUrl}`);
											if (!allChangesSaved) {
												setSaveChangesModalOpen(
													!saveChangesModalOpen
												);
											} else {
												setAllowNavigation(true);
											}
										}}
									>
										view live homepage
									</StyledRedButton>
								</InlineElement>
							</FlexRow>
							<FlexRow className="customUrl">
								<InlineElement>
									<URLText>
										https://www.scholastic.com/bf/
										{!isOpen && shareHomepageData.customUrl}
									</URLText>
								</InlineElement>
								{isOpen && (
									<InlineElement>
										<StyledTextInput
											label=""
											value={shareHomepageData.customUrl}
											onChange={handleInput}
											fontSize={"13px"}
											error={
												customUrlError.length
													? customUrlError
													: undefined
											}
										/>
									</InlineElement>
								)}
							</FlexRow>
							{!isOpen && (
								<StyledTextLink
									className="customizeLink"
									handleClick={handleOpenCustomizeWindow}
								>
									customize URL
								</StyledTextLink>
							)}
							{isOpen && (
								<FlexRow>
									<InlineElement>
										<StyledTextLink handleClick={saveUrl}>
											save
										</StyledTextLink>
									</InlineElement>
									<InlineElement>
										<StyledTextLink handleClick={cancel}>
											cancel
										</StyledTextLink>
									</InlineElement>
								</FlexRow>
							)}
							<ToggleWrapper>
								<ToggleButton
									label="Show your homepage in the Find Your Fair search results"
									checked={shareHomepageData.shareUrl}
									onChange={shareHomepageData.setShareUrl}
								/>
							</ToggleWrapper>
						</Wrapper>
					</StyledInformationPage>

					<Prompt
						message={() => {
							setSaveChangesModalOpen(true);
						}}
						when={!allChangesSaved}
					/>
					<SaveChangesModal
						saveAndPublish={() => {
							saveUrl();
							setAllowNavigation(true);
						}}
						discardChanges={() => {
							setAllChangesSaved(true);
							setAllowNavigation(true);
						}}
						modalIsOpen={saveChangesModalOpen}
						handleModal={() => {
							setSaveChangesModalOpen(!saveChangesModalOpen);
						}}
					/>
				</>
			)}
		/>
	);
};

/*
	Page Specific Styles
*/

const StyledHeading = styled(Heading)`
	padding-bottom: 14px;
`;
const StyledBodyTextSubheading = styled(BodyText)`
	margin-bottom: 40px;
	max-width: 311px;
`;
const URLText = styled(BodyText)`
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0px;
`;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 440px;
`;
const StyledRedButton = styled(RedButton)`
	width: 188px;
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 188px;
	border: 1px solid #e81111;
	border-radius: 100px;
`;

const StyledInformationPage = styled(Page)`
	padding-bottom: 95px;
	@media (max-width: 719px) {
		padding-bottom: 103px;
	}
	@media (max-width: 530px) {
		padding-bottom: 74px;
	}
`;
const FlexRow = styled.div`
	display: inline-flex;
	gap: 40px;
	&.buttons {
		margin: 48px 0 44px 0;
		@media (max-width: 530px) {
			display: flex;
			flex-direction: column;
			width: 100%;
			gap: 25px;
			align-items: center;
		}
	}
	&.customUrl {
		gap: 3px;
		align-items: center;
		margin: 10px 0px 25px;
	}
`;

const InlineElement = styled.div`
	display: inline-flex;
	gap: 40px;
`;

const ToggleWrapper = styled.div`
	padding-top: 32px;
	position: relative;
	height: 32px;
	border-top: 1px solid #e3e3e3;
	width: 100%;
	margin-bottom: 20px;
	@media (max-width: 530px) {
		align-content: center;
		margin-bottom: 71px;
	}
`;
const StyledTextLink = styled(TextLink)`
	margin: 7px 0 38px 0;
	&.customizeLink {
		margin: 12px 0 54px 0;
		&:hover {
			margin-bottom: 50px;
		}
		&:focus {
			margin-bottom: 48px;
		}
	}
	&:hover {
		margin-bottom: 34px;
	}
	&:focus {
		margin-bottom: 32px;
	}
`;

const StyledTextInput = styled(TextInput)`
	font-family: museo-sans;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	margin-bottom: 8px;
	& #textInputErrorLabel {
		position: absolute;
		top: 40px;
	}
`;
