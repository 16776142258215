import React from "react";
import styled from "styled-components/macro";
import CloseIcon from "images/WhiteCloseIcon.svg";

type Props = {
	show?: boolean;
	onClose?: any;
	children: React.ReactNode;
};

const CustomAlert = ({ show, onClose, children }: Props) => {
	return (
		<StyledAlert show={show}>
			{children}
			<StyledCloseButton onClick={onClose} tabIndex={-1}>
				<img src={CloseIcon} alt="Exit the alert" />
			</StyledCloseButton>
		</StyledAlert>
	);
};

const StyledAlert = styled.div<Props>`
	position: fixed;
	display: flex;
	top: ${(props) => (props.show ? "65px" : "-65px")};
	width: fit-content;
	left: 0;
	right: 0;
	margin: 0 auto;
	transition: top 0.3s;
	align-items: center;
	gap: 35px;
	color: #ffffff;
	background: #116eee;
	font-family: museo-sans;
	font-weight: 600;
	font-size: 13px;
	line-height: 15px;
	padding: 20px;
	border-radius: 5px;
	z-index: 1000;
	@media (max-width: 719px) {
		top: ${(props) => (props.show ? "45px" : "-65px")};
	}
`;

const StyledCloseButton = styled.button`
	border: none;
	background: none;
	&:hover {
		cursor: pointer;
	}
`;

export default CustomAlert;
