import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

type PurchaseOrder = {
	number: string;
	amount: number;
	contactName: string;
	agencyName: string;
	address: string;
	city: string;
	state: string;
	zipcode: string;
};

export type ReviewPageData = {
	status: {
		type: string;
	};
	invoice: null;
	sales: {
		scholasticDollars: {
			totalRedeemed: number;
			taxExemptSales: number;
			taxCollected: number;
		};
		tenderTotals: {
			cashAndChecks: number;
			creditCards: number;
			purchaseOrders: number;
		};
		grossSales: {
			taxExemptSales: number;
			taxableSales: number;
			total: number;
			taxTotal: number;
		};
		netSales: {
			shareTheFairFunds: {
				collected: number;
				redeemed: number;
			};
		};
		taxRate: number;
	};
	purchaseOrders: {
		list: PurchaseOrder[];
	};
	spending: {
		scholasticDollars: {
			totalRedeemed: number;
			existingBalance: number;
			appliedBalance: number;
			due: number;
		};
	};
	earnings: {
		sales: number;
		scholasticDollars: {
			earned: number;
			due: number;
			balance: number;
			selected: number;
			max: number;
		};
		dollarFairLevel: string;
		cash: {
			selected: number;
			max: number;
		};
	};
};

const defaultReviewData: ReviewPageData = {
	status: {
		type: "notReady",
	},
	invoice: null,
	sales: {
		scholasticDollars: {
			totalRedeemed: 0.0,
			taxExemptSales: 0.0,
			taxCollected: 0.0,
		},
		tenderTotals: {
			cashAndChecks: 0.0,
			creditCards: 0.0,
			purchaseOrders: 0.0,
		},
		grossSales: {
			taxExemptSales: 0.0,
			taxableSales: 0.0,
			total: 0.0,
			taxTotal: 0.0,
		},
		netSales: {
			shareTheFairFunds: {
				collected: 0.0,
				redeemed: 0.0,
			},
		},
		taxRate: 0.0,
	},
	purchaseOrders: {
		list: [],
	},
	spending: {
		scholasticDollars: {
			totalRedeemed: 0.0,
			existingBalance: 0.0,
			appliedBalance: 0.0,
			due: 0.0,
		},
	},
	earnings: {
		sales: 0.0,
		scholasticDollars: {
			earned: 0.0,
			due: 0.0,
			balance: 0.0,
			selected: 0.0,
			max: 0.0,
		},
		dollarFairLevel: "30",
		cash: {
			selected: 0.0,
			max: 0.0,
		},
	},
};

const useReviewPageData = () => {
	const { bookfairsAPI } = useConfig();

	const [grossSales, setGrossSales] = useState<number>(
		defaultReviewData.sales.grossSales.total
	);
	const [taxExemptSales, setTaxExemptSales] = useState<number>(
		defaultReviewData.sales.grossSales.taxExemptSales
	);
	const [taxTotal, setTaxTotal] = useState<number>(
		defaultReviewData.sales.grossSales.taxTotal
	);
	const [scholasticDollarsRedeemed, setScholasticDollarsRedeemed] =
		useState<number>(
			defaultReviewData.sales.scholasticDollars.totalRedeemed
		);
	const [scholasticDollarBalance, setScholasticDollarBalance] =
		useState<number>(
			defaultReviewData.spending.scholasticDollars.existingBalance
		);
	const [appliedScholasticDollars, setAppliedScholasticDollars] =
		useState<number>(
			defaultReviewData.spending.scholasticDollars.appliedBalance
		);
	const [
		fairEarningsScholasticDollarsDue,
		setFairEarningsScholasticDollarsDue,
	] = useState<number>(defaultReviewData.earnings.scholasticDollars.due);
	const [
		fairSpendingScholasticDollarsDue,
		setFairSpendingScholasticDollarsDue,
	] = useState<number>(defaultReviewData.spending.scholasticDollars.due);
	const [scholasticDollarsEarned, setScholasticDollarsEarned] =
		useState<number>(defaultReviewData.earnings.scholasticDollars.earned);
	const [profitSelected, setProfitSelected] = useState<number>(
		defaultReviewData.earnings.scholasticDollars.selected
	);
	const [cashProfitSelected, setCashProfitSelected] = useState<number>(
		defaultReviewData.earnings.cash.selected
	);
	const [earningsDollarFairLevel, setEarningsDollarFairLevel] =
		useState<string>(defaultReviewData.earnings.dollarFairLevel);

	const [fairSalesTotal, setFairSalesTotal] = useState<number>(
		defaultReviewData.earnings.sales
	);

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [fairId, setFairId] = useState("");

	useEffect(() => {
		const fetchSalesData = async () => {
			const currentFair = getFairIdFromUrl();
			if (checkSpsUd()) {
				setIsLoading(true);
				try {
					// get review page data
					const response = await axios.get(
						`${bookfairsAPI}/user/fairs/${currentFair}/financials/form`,
						{
							withCredentials: true,
						}
					);
					// If not a 200, handle generic success logic (i.e. 204, NO_SCHL)
					if (response.status !== 200) {
						handleGenericSuccess(response);
						return;
					}
					setGrossSales(response.data.sales.grossSales.total);
					setTaxExemptSales(
						response.data.sales.grossSales.taxExemptSales
					);
					setTaxTotal(response.data.sales.grossSales.taxTotal);
					setScholasticDollarsRedeemed(
						response.data.sales.scholasticDollars.totalRedeemed
					);
					setScholasticDollarBalance(
						response.data.spending.scholasticDollars.existingBalance
					);
					setAppliedScholasticDollars(
						response.data.spending.scholasticDollars.appliedBalance
					);
					setFairSpendingScholasticDollarsDue(
						Math.abs(response.data.spending.scholasticDollars.due)
					);
					setFairEarningsScholasticDollarsDue(
						response.data.earnings.scholasticDollars.due
					);
					setScholasticDollarsEarned(
						response.data.earnings.scholasticDollars.earned
					);
					setProfitSelected(
						response.data.earnings.scholasticDollars.selected
					);
					setCashProfitSelected(response.data.earnings.cash.selected);
					setFairSalesTotal(response.data.earnings.sales);
					setFairId(response.headers["sbf-jarvis-resource-id"]);
					setEarningsDollarFairLevel(
						response.data.earnings.dollarFairLevel
					);
				} catch (err) {
					setIsError(true);
					handleGenericErrors(err);
				} finally {
					setIsLoading(false);
				}
			}
		};
		fetchSalesData();
	}, [
		bookfairsAPI,
		setGrossSales,
		setTaxExemptSales,
		setTaxTotal,
		setScholasticDollarsRedeemed,
		setScholasticDollarBalance,
		setAppliedScholasticDollars,
		setFairEarningsScholasticDollarsDue,
		setFairSpendingScholasticDollarsDue,
		setScholasticDollarsEarned,
		setProfitSelected,
		setCashProfitSelected,
	]);

	return {
		isError,
		isLoading,
		grossSales,
		taxExemptSales,
		taxTotal,
		scholasticDollarsRedeemed,
		scholasticDollarBalance,
		appliedScholasticDollars,
		fairEarningsScholasticDollarsDue,
		fairSpendingScholasticDollarsDue,
		scholasticDollarsEarned,
		profitSelected,
		cashProfitSelected,
		earningsDollarFairLevel,
		fairId,
		fairSalesTotal,
	};
};

export default useReviewPageData;
