import React from "react";
import styled from "styled-components/macro";

interface Props {
	className?: any;
	value: any;
	onChange: (value: string) => void;
	label?: string;
	type?: any;
	disabled?: boolean;
	error?: string;
	fontSize?: string;
	placeholder?: string;
	id?: string;
	required?: boolean;
	tabIndex?: number;
	height?: string;
	marginBottom?: string;
	onBlur?: any;
}

const TextInput = ({
	className,
	value,
	onChange,
	label,
	type,
	disabled,
	error,
	fontSize,
	placeholder,
	id,
	required,
	tabIndex,
	height,
	marginBottom,
	onBlur,
}: Props) => {
	return (
		<StyledContainer
			data-disabled={disabled}
			className={className}
			marginBottom={marginBottom}
		>
			{label && (
				<StyledLabel htmlFor={id} id="inputLabel" className={className}>
					{label}
				</StyledLabel>
			)}
			<StyledInput
				type={type}
				id={id}
				value={value}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				onBlur={onBlur}
				disabled={disabled}
				data-error={error}
				fontSize={fontSize}
				placeholder={placeholder}
				required={required}
				className={className}
				tabIndex={tabIndex}
				height={height}
			></StyledInput>
			{error && <ErrorLabel id="textInputErrorLabel">{error}</ErrorLabel>}
		</StyledContainer>
	);
};

interface FontProps {
	fontSize?: string;
	height?: string;
}
interface ContainerProps {
	marginBottom?: string;
}
const StyledContainer = styled.div<ContainerProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: ${(props) =>
		props.marginBottom ? props.marginBottom : "17px"};
	&[data-disabled] > #inputLabel {
		opacity: 0.2;
	}
`;

const StyledInput = styled.input<FontProps>`
	height: ${(props) => (props.height ? props.height : "40px")};
	background: #ffffff;
	border: 1px solid #919191;
	box-sizing: border-box;
	border-radius: 5px;
	font-family: museo-sans;
	font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
	line-height: 19px;
	color: #333333;
	padding: 0px 16px;
	&:focus {
		outline: 2px auto #116eee;
	}
	&:disabled {
		opacity: 0.2;
		border: 1px solid #333333;
	}
	&[data-error] {
		border: 1px solid #e81111;
	}
	&.virtual {
		border: none;
		pointer-events: none;
		padding: 0;
	}
`;

const StyledLabel = styled.label`
	display: block;
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
	color: #333333;
	padding-bottom: 8px;
	&.virtual {
		padding: 0px;
	}
`;

const ErrorLabel = styled(StyledLabel)`
	margin-top: 4px;
	color: #e81111;
	padding-bottom: 0;
	bottom: -16px;
	position: absolute;
`;

export default TextInput;
