import BodyText from "components/BodyText";
import Heading from "components/Heading";
import Subheading from "components/Subheading";
import React from "react";
import styled from "styled-components/macro";

interface LineItemProps {
	ItemTitleText: string;
	ItemSubTitleText?: string;
	ItemAmountText: string;
	LastItem?: boolean;
	OneLine?: boolean;
	ContinuedItem?: boolean;
}
interface SectionItemProps {
	children: React.ReactNode;
	SectionTitle?: string;
}

/**
 * Individual line item for the invoice
 */
export const InvoiceLineItem = ({
	ItemTitleText,
	ItemSubTitleText,
	ItemAmountText,
	LastItem,
	OneLine,
	ContinuedItem,
}: LineItemProps) => {
	return (
		<InvoiceLineItemContainer
			noBorder={LastItem}
			continuedItem={ContinuedItem}
		>
			<LeftSideContent oneLine={OneLine}>
				<ItemTitle>{ItemTitleText}</ItemTitle>
				{ItemSubTitleText && (
					<ItemSubtitle>{ItemSubTitleText}</ItemSubtitle>
				)}
			</LeftSideContent>
			<ItemAmount>{ItemAmountText}</ItemAmount>
		</InvoiceLineItemContainer>
	);
};

/**
 * Group of line items for the invoice
 */
export const InvoiceSectionItem = ({
	children,
	SectionTitle,
}: SectionItemProps) => {
	return (
		<InvoiceSectionItemContainer>
			{SectionTitle && <SectionHeader>{SectionTitle}</SectionHeader>}
			{children}
		</InvoiceSectionItemContainer>
	);
};

const InvoiceLineItemContainer = styled.div<{
	noBorder?: boolean;
	continuedItem?: boolean;
}>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 15px 0;
	margin-top: ${(props) => (props.continuedItem ? "40px;" : "0px")};
	border-bottom: ${(props) =>
		props.noBorder ? "none" : "1px solid #D7D7D7"};
`;
const LeftSideContent = styled.div<{ oneLine?: boolean }>`
	display: flex;
	flex-direction: ${(props) => (props.oneLine ? "row" : "column")};
	gap: 5px;
`;
const ItemTitle = styled(Heading)`
	font-size: 15px;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0px;
	text-align: left;
	padding-bottom: 0px;
`;
const ItemSubtitle = styled(Subheading)`
	font-size: 13px;
	font-weight: 300;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
`;
const ItemAmount = styled(BodyText)`
	font-size: 13px;
	font-weight: 300;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: right;
`;
const InvoiceSectionItemContainer = styled.div`
	border-top: 2px solid #666666;
`;

const SectionHeader = styled(Heading)`
	font-size: 20px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0px;
	text-align: left;
	padding: 10.92px 0 10.92px 0;
	border-bottom: 1px solid #d7d7d7;
`;
