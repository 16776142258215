import React, { useState } from "react";
import styled from "styled-components/macro";
import Trashcan from "images/Trashcan.svg";
import NavExpand from "images/NavExpand.svg";
import {
	ContentName,
	DollarInput,
	ErrorText,
	ErrorHeading,
} from "../Totals/FinancialFormTotalsContent";
import { PurchaseOrderType } from "hooks/FinancialForm/useSalesData";
import { PurchaseOrderDeleteConfirmation } from "./PurchaseOrderDeleteConfirmation";
import { trackAddAnotherPurchaseOrder } from "DumbleData";
import { customParseFloat, formatNumberWithCommas } from "utils/HubUtility";
const UsaStates = require("usa-states").UsaStates;

interface Props {
	editing: boolean;
	order: PurchaseOrderType;
	handleDelete: Function;
	handleAddPurchaseOrderClicked: Function;
	updatePurchaseOrders: Function;
	lastPurchaseOrder: boolean;
	openScrollRef?: any;
	scrollRef?: any;
	moreThanThreePOs: boolean;
	poWithinLoadMoreIndex: boolean;
	disableAddPurchaseOrder: boolean;
	lastLoadedPurchaseOrder: boolean;
}

export const PurchaseOrderItem = ({
	editing,
	order,
	handleDelete,
	handleAddPurchaseOrderClicked,
	updatePurchaseOrders,
	lastPurchaseOrder,
	openScrollRef,
	moreThanThreePOs,
	poWithinLoadMoreIndex,
	disableAddPurchaseOrder,
	lastLoadedPurchaseOrder,
}: Props) => {
	const usStates = new UsaStates();
	const options = usStates.arrayOf("abbreviations");
	options.unshift("");
	const {
		_id,
		number,
		amount,
		contactName,
		agencyName,
		address,
		city,
		state,
		zipcode,
		errors,
	} = order;

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const openModalAEM = () => {
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	// Updates a purchase order's number and refreshes the list
	const updateNumber = (newNumber: string) => {
		order.number = newNumber;
		updatePurchaseOrders();
	};

	// Updates a purchase order's amount and refreshes the list
	const updateAmount = (newAmount: string) => {
		order.amount = newAmount;
		updatePurchaseOrders();
	};

	// Updates a purchase order's contact name and refreshes the list
	const updateContactName = (newContactName: string) => {
		order.contactName = newContactName;
		updatePurchaseOrders();
	};

	// Updates a purchase order's agency name and refreshes the list
	const updateAgencyName = (newAgencyName: string) => {
		order.agencyName = newAgencyName;
		updatePurchaseOrders();
	};

	// Updates a purchase order's address and refreshes the list
	const updateAddress = (newAddress: string) => {
		order.address = newAddress;
		updatePurchaseOrders();
	};

	// Updates a purchase order's city and refreshes the list
	const updateCity = (newCity: string) => {
		order.city = newCity;
		updatePurchaseOrders();
	};

	// Updates a purchase order's zipcode and refreshes the list
	const updateZipcode = (newZipcode: string) => {
		order.zipcode = newZipcode;
		updatePurchaseOrders();
	};

	// Updates a purchase order's state and refreshes the list
	const updateState = (newState: string) => {
		order.state = newState;
		updatePurchaseOrders();
	};

	const handleDollarChange = (e: any, setter: any) => {
		const inputValue = e.target.value.replace(/[^0-9.]/g, "");
		const regexTest = /^\d{0,6}(\.\d{0,2})?$/;
		if (regexTest.test(inputValue)) {
			const formattedValue = formatNumberWithCommas(inputValue);
			setter(formattedValue);
		}
	};

	const handleAlphanumericChange = (e: any, setter: any, maxChar: number) => {
		const inputValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
		const regexTest = new RegExp(`^[a-zA-Z0-9 ]{0,${maxChar}}$`);
		if (regexTest.test(inputValue)) {
			setter(inputValue);
		}
	};

	const handleZipChange = (e: any, setter: any) => {
		const inputValue = e.target.value.replace(/[^0-9]/g, "");
		const regexTest = /^\d{0,5}?$/;
		if (regexTest.test(inputValue)) {
			setter(inputValue);
		}
	};

	const floatAmount = customParseFloat(amount);

	return (
		<>
			{/* Show PO if less than 3 POs, OR More than 3 POs AND current PO index is < Current Index set via Load More button,
			OR if editing */}
			{(!moreThanThreePOs ||
				(moreThanThreePOs && poWithinLoadMoreIndex) ||
				editing) && (
				<Column>
					{editing ? (
						<>
							<BoxLine ref={openScrollRef}>
								<ErrorHeading>
									Purchase Order Number
								</ErrorHeading>
								<ContentName>
									<PONumberInputBox
										errorState={
											errors.number ||
											errors.numberNotUnique
										}
										value={number}
										onChange={(e) =>
											handleAlphanumericChange(
												e,
												updateNumber,
												15
											)
										}
									/>
									{errors.number && (
										<POErrorText>
											This field cannot be blank.
										</POErrorText>
									)}
									{errors.numberNotUnique && (
										<POErrorText>
											Please ensure all Purchase Order
											Numbers are unique.
										</POErrorText>
									)}
								</ContentName>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<ErrorHeading>Amount</ErrorHeading>
								<ContentName>
									<POInputBox
										errorState={
											errors.amount ||
											errors.greaterThanZero
										}
										value={"$" + amount}
										onChange={(e) =>
											handleDollarChange(e, updateAmount)
										}
									/>
									{errors.greaterThanZero && (
										<POErrorText>
											Please enter an Amount greater than
											$0.
										</POErrorText>
									)}
									{errors.amount && (
										<POErrorText>
											This field cannot be blank.
										</POErrorText>
									)}
								</ContentName>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<ErrorHeading>
									Billing to Contact Name
								</ErrorHeading>
								<ContentName>
									<POInputBox
										errorState={errors.contactName}
										value={contactName}
										onChange={(e) =>
											handleAlphanumericChange(
												e,
												updateContactName,
												30
											)
										}
									/>
									{errors.contactName && (
										<POErrorText>
											This field cannot be blank.
										</POErrorText>
									)}
								</ContentName>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<ErrorHeading>
									District/Agency Name
								</ErrorHeading>
								<ContentName>
									<POInputBox
										errorState={errors.agencyName}
										value={agencyName}
										onChange={(e) =>
											handleAlphanumericChange(
												e,
												updateAgencyName,
												30
											)
										}
									/>
									{errors.agencyName && (
										<POErrorText>
											This field cannot be blank.
										</POErrorText>
									)}
								</ContentName>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<ErrorHeading>Address</ErrorHeading>
								<ContentName>
									<POInputBox
										errorState={errors.address}
										value={address}
										onChange={(e) =>
											handleAlphanumericChange(
												e,
												updateAddress,
												30
											)
										}
									/>
									{errors.address && (
										<POErrorText>
											This field cannot be blank.
										</POErrorText>
									)}
								</ContentName>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<ErrorHeading>City</ErrorHeading>
								<ContentName>
									<POInputBox
										errorState={errors.city}
										value={city}
										onChange={(e) =>
											handleAlphanumericChange(
												e,
												updateCity,
												20
											)
										}
									/>
									{errors.city && (
										<POErrorText>
											This field cannot be blank.
										</POErrorText>
									)}
								</ContentName>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<ErrorHeading>State</ErrorHeading>
								<ContentName>
									<PODropdown
										errorState={errors.state}
										onChange={(e) =>
											updateState(e.target.value)
										}
										defaultValue={state}
									>
										{options.map(
											(option: any, idx: any) => (
												<option key={idx}>
													{option}
												</option>
											)
										)}
									</PODropdown>
									{errors.state && (
										<POErrorText>
											This field cannot be blank.
										</POErrorText>
									)}
								</ContentName>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<ErrorHeading>Zip Code</ErrorHeading>
								<ContentName>
									<POInputBox
										errorState={errors.zipcode}
										value={zipcode}
										onChange={(e) =>
											handleZipChange(e, updateZipcode)
										}
									/>
									{errors.zipcode && (
										<POErrorText>
											This field cannot be blank.
										</POErrorText>
									)}
								</ContentName>
							</BoxLine>
							<HorizontalLineBreak />
						</>
					) : (
						<>
							<BoxLine>
								<BoldText>Purchase Order Number</BoldText>
								<BoldText>{number}</BoldText>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<div>Amount</div>
								<div>{formatter.format(floatAmount)}</div>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<div>Billing to Contact Name</div>
								<div>{contactName}</div>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<div>District/Agency Name</div>
								<div>{agencyName}</div>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<div>Address</div>
								<div>{address}</div>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<div>City</div>
								<div>{city}</div>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<div>State</div>
								<div>{state}</div>
							</BoxLine>
							<HorizontalLineBreak />
							<BoxLine>
								<div>Zip Code</div>
								<div>{zipcode}</div>
							</BoxLine>
						</>
					)}
					{editing && !lastPurchaseOrder && (
						<>
							<DeleteButton
								onClick={() => {
									setShowDeleteConfirmation(true);
									openModalAEM();
								}}
							>
								<Icon src={Trashcan} alt="" />
								<div>DELETE</div>
							</DeleteButton>
						</>
					)}
					{editing && lastPurchaseOrder && (
						<>
							<PurchaseOrderButtonContainer>
								<DeleteButton
									onClick={() => {
										setShowDeleteConfirmation(true);
										openModalAEM();
									}}
								>
									<Icon src={Trashcan} alt="" />
									<div>DELETE</div>
								</DeleteButton>
								<AddPurchaseOrderButton
									onClick={() => {
										trackAddAnotherPurchaseOrder();
										handleAddPurchaseOrderClicked();
									}}
									disabled={disableAddPurchaseOrder}
								>
									<Icon src={NavExpand} alt="" />
									<div>ADD ANOTHER PURCHASE ORDER</div>
								</AddPurchaseOrderButton>
							</PurchaseOrderButtonContainer>
						</>
					)}
					{editing && !lastPurchaseOrder && (
						<DarkHorizontalLineBreak />
					)}
					{!editing &&
						!lastLoadedPurchaseOrder &&
						!lastPurchaseOrder && <DarkHorizontalLineBreak />}
				</Column>
			)}
			{showDeleteConfirmation && (
				<PurchaseOrderDeleteConfirmation
					handleDelete={() => {
						handleDelete(_id);
					}}
					id={_id}
					closeModal={() => setShowDeleteConfirmation(false)}
				/>
			)}
		</>
	);
};

const PurchaseOrderButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 500px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 5px;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	max-width: 600px;
	gap: 12px;
`;

const BoldText = styled.div`
	font-weight: 600;
`;

const BoxLine = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	@media (max-width: 500px) {
		flex-direction: column;
		gap: 4px;
	}
`;

const HorizontalLineBreak = styled.hr`
	height: 1px;
	background: #d7d7d7;
	width: 100%;
	border: none;
`;

const DarkHorizontalLineBreak = styled(HorizontalLineBreak)`
	margin-top: 20px;
	background: #333;
`;

const Icon = styled.img``;

const AddPurchaseOrderButton = styled.button`
	display: flex;
	align-items: center;
	color: #e81111;
	outline-color: #e81111;
	outline-offset: 3px;
	background: none;
	border: none;
	gap: 5px;
	width: 240px;
	height: 40px;
	color: #e81111;
	font-family: museo-sans;
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	${Icon} {
		filter: invert(20%) sepia(32%) saturate(7450%) hue-rotate(348deg)
			brightness(80%) contrast(114%);
	}
	&:hover {
		cursor: pointer;
		color: #b90000;
		${Icon} {
			filter: invert(7%) sepia(86%) saturate(6492%) hue-rotate(359deg)
				brightness(109%) contrast(108%);
		}
	}
	&:focus-visible {
		outline: 2px solid #e81111;
		color: #b90000;
		outline-offset: 2px;
		${Icon} {
			filter: invert(7%) sepia(86%) saturate(6492%) hue-rotate(359deg)
				brightness(109%) contrast(108%);
		}
	}
	&:active {
		outline: none;
		color: #660000;
		${Icon} {
			filter: invert(9%) sepia(49%) saturate(5332%) hue-rotate(352deg)
				brightness(94%) contrast(115%);
		}
	}
	&:disabled {
		filter: grayscale(100%);
		cursor: unset;
		&:hover {
			color: #e81111;
		}
	}
`;

const DeleteButton = styled.button`
	display: flex;
	align-items: center;
	color: #e81111;
	outline-color: #e81111;
	outline-offset: 3px;
	background: none;
	border: none;
	gap: 5px;
	width: 70px;
	height: 40px;
	color: #e81111;
	font-family: museo-sans;
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	${Icon} {
		filter: invert(12%) sepia(65%) saturate(7216%) hue-rotate(356deg)
			brightness(103%) contrast(95%);
	}
	&:hover {
		cursor: pointer;
		color: #b90000;
		${Icon} {
			filter: invert(7%) sepia(86%) saturate(6492%) hue-rotate(359deg)
				brightness(109%) contrast(108%);
		}
	}
	&:focus-visible {
		outline: 2px solid #e81111;
		color: #b90000;
		outline-offset: 2px;
		${Icon} {
			filter: invert(7%) sepia(86%) saturate(6492%) hue-rotate(359deg)
				brightness(109%) contrast(108%);
		}
	}
	&:active {
		outline: none;
		color: #660000;
		${Icon} {
			filter: invert(9%) sepia(49%) saturate(5332%) hue-rotate(352deg)
				brightness(94%) contrast(115%);
		}
	}
`;

const POInputBox = styled(DollarInput)`
	width: 200px;
	@media (max-width: 500px) {
		margin-top: 4px;
	}
`;

const PONumberInputBox = styled(POInputBox)`
	font-weight: 700;
`;

const PODropdown = styled.select<{ errorState?: boolean }>`
	border-color: ${(props) => (props.errorState ? "#e81111" : "#919191")};
	width: 200px;
	height: 40px;
	padding-left: 16px;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
	-webkit-appearance: none;
	background-color: #ffffff;
	@media (max-width: 500px) {
		margin-top: 4px;
	}
`;

const POErrorText = styled(ErrorText)`
	max-width: 200px;
`;
