enum FinFormProgress {
	Intro = 0,
	Sales = 1,
	Spending = 2,
	Earnings = 3,
	Review = 4,
	Confirmation = 5,
}

enum FinFormUrls {
	Intro = `/reactapp/financial-form/`,
	Sales = `/reactapp/financial-form/sales`,
	Spending = `/reactapp/financial-form/spending`,
	Earnings = `/reactapp/financial-form/earnings`,
	Review = `/reactapp/financial-form/review`,
	Confirmation = `/reactapp/financial-form/confirmation`,
}

// Progress localStorage

const setFinFormUrlsLocalStorage = (fairId: string, url: string) => {
	let pageOrder;

	switch (url) {
		case FinFormUrls.Sales:
			pageOrder = FinFormProgress.Sales;
			break;
		case FinFormUrls.Spending:
			pageOrder = FinFormProgress.Spending;
			break;
		case FinFormUrls.Earnings:
			pageOrder = FinFormProgress.Earnings;
			break;
		case FinFormUrls.Review:
			pageOrder = FinFormProgress.Review;
			break;
		case FinFormUrls.Confirmation:
			pageOrder = FinFormProgress.Confirmation;
			break;
		default:
			pageOrder = FinFormProgress.Intro;
	}
	const cookieValue = JSON.stringify({ url, order: pageOrder });
	localStorage.setItem(`fin-form-progress-${fairId}`, cookieValue);
};

const checkFinFormUrlsLocalStorage = (fairId: string) => {
	const cookieValue = localStorage.getItem(`fin-form-progress-${fairId}`);
	if (cookieValue) {
		const { url: destination } = JSON.parse(cookieValue);
		if (destination && destination !== window.location.pathname) {
			return destination;
		}
	} else {
		if (fairId !== "" && window.location.pathname !== FinFormUrls.Intro) {
			return FinFormUrls.Intro;
		} else {
			return null;
		}
	}
};

const getFinFormProgress = (fairId: string) => {
	const cookieValue = localStorage.getItem(`fin-form-progress-${fairId}`);
	if (cookieValue) {
		const { order: progress } = JSON.parse(cookieValue);
		return progress;
	} else {
		return FinFormProgress.Intro;
	}
};

// Current Page Cookies

const setFinFormCurrentPageLocalStorage = (fairId: string, url: string) => {
	const cookieValue = JSON.stringify({ url });
	localStorage.setItem(`fin-form-current-page-${fairId}`, cookieValue);
};

const checkFinFormCurrentPageLocalStorage = (fairId: string) => {
	const cookieValue = localStorage.getItem(`fin-form-current-page-${fairId}`);
	if (cookieValue) {
		const { url: destination } = JSON.parse(cookieValue);
		if (destination && destination !== window.location.pathname) {
			return destination;
		}
	} else {
		if (fairId !== "" && window.location.pathname !== FinFormUrls.Intro) {
			return FinFormUrls.Intro;
		} else {
			return null;
		}
	}
};

export {
	checkFinFormUrlsLocalStorage,
	setFinFormUrlsLocalStorage,
	getFinFormProgress,
	setFinFormCurrentPageLocalStorage,
	checkFinFormCurrentPageLocalStorage,
	FinFormUrls,
	FinFormProgress,
};
