import IframeContainer from "./IframeContainer";
import FairInfoBar from "./FairInfoBar";

export const FairInfoBarStandAlone = () => {
	return (
		<IframeContainer
			renderItems={() => (
				<>
					<FairInfoBar StandAlone={true} />
				</>
			)}
		/>
	);
};

export default FairInfoBarStandAlone;
