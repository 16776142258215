import { useContext, createContext } from "react";

export interface ConfigType {
	env: string | undefined;
	appOrigin: string | undefined;
	bookfairsAPI: string | undefined;
	bookfairsOrigin: string | undefined;
	damAssetsOrigin: string | undefined;
	onlineShopOrigin: string | undefined;
	AEMAuthorOrigin: string | undefined;
	AEMPublishOrigin: string;
	StageAuthorOrigin: string | undefined;
	StagePublishOrigin: string | undefined;
	StageBookfairsOrigin: string | undefined;
	fairFinderAPI: string | undefined;
	fairFinderResult: string | undefined;
	BookfairsHomepageOrigin: string | undefined;
	PaymentPortalOrigin: string | undefined;
	EWalletRosterPrintOrigin: string | undefined;
	restockOrigin: string;
	DoNotSellOrigin: string | undefined;
	BookfairsMessageOrigin: string | undefined;
	bookfairsLiveChatAPI: string | undefined;
	bookfairsBootstrapURL: string | undefined;
}

const defaultConfig: ConfigType = {
	env: process.env.REACT_APP_ENV?.trimEnd() || "",
	appOrigin: process.env.REACT_APP_ORIGIN?.trimEnd() || "",
	bookfairsAPI: process.env.REACT_APP_BOOKFAIRS_API?.trimEnd() || "",
	bookfairsOrigin: process.env.REACT_APP_BOOKFAIRS_ORIGIN?.trimEnd() || "",
	damAssetsOrigin: process.env.REACT_APP_DAMASSETS_ORIGIN?.trimEnd() || "",
	onlineShopOrigin: process.env.REACT_APP_SHOP_ORIGIN?.trimEnd() || "",
	AEMAuthorOrigin: process.env.REACT_APP_AEM_AUTHOR_ORIGIN?.trimEnd() || "",
	AEMPublishOrigin:
		process.env.REACT_APP_AEM_PUBLISH_ORIGIN?.trimEnd() ||
		"https://www.scholastic.com",
	StageAuthorOrigin:
		process.env.REACT_APP_STAGE_AUTHOR_ORIGIN?.trimEnd() || "",
	StagePublishOrigin:
		process.env.REACT_APP_STAGE_PUBLISH_ORIGIN?.trimEnd() || "",
	StageBookfairsOrigin:
		process.env.REACT_APP_STAGE_BOOKFAIRS_ORIGIN?.trimEnd() || "",
	fairFinderAPI: process.env.REACT_APP_FAIR_FINDER_API?.trimEnd() || "",
	fairFinderResult: process.env.REACT_APP_FAIR_FINDER_RESULT?.trimEnd() || "",
	BookfairsHomepageOrigin:
		process.env.REACT_APP_BOOKFAIRS_HOMEPAGE_ORIGIN?.trimEnd() || "",
	PaymentPortalOrigin:
		process.env.REACT_APP_BOOKFAIRS_PAYMENT_PORTAL?.trimEnd() || "",
	EWalletRosterPrintOrigin:
		process.env.REACT_APP_EWALLET_ROSTER_PRINT_ORIGIN?.trimEnd() || "",
	restockOrigin: process.env.REACT_APP_RESTOCK_ORIGIN?.trimEnd() || "",
	DoNotSellOrigin: process.env.REACT_APP_DO_NOT_SELL_ORIGIN?.trimEnd() || "",
	BookfairsMessageOrigin:
		process.env.REACT_APP_BOOKFAIRS_MESSAGE_ORIGIN?.trimEnd() || "",
	bookfairsLiveChatAPI:
		process.env.REACT_APP_BOOKFAIRS_LIVECHAT_API?.trimEnd() || "",
	bookfairsBootstrapURL:
		process.env.REACT_APP_BOOKFAIRS_BOOTSTRAP_URL?.trimEnd() || "",
};

const configContext = createContext(defaultConfig);

const useConfig = () => {
	return useContext(configContext);
};

const ProvideConfig: React.FC = ({ children }) => {
	return (
		<configContext.Provider value={defaultConfig}>
			{children}
		</configContext.Provider>
	);
};

export { ProvideConfig, useConfig };
