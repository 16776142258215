import React from "react";
import styled from "styled-components";
import {
	convertDateToMonthDay,
	convertYMDStringToDate,
} from "utils/DateConversion";
import { trackFairFinderVisitFair } from "DumbleData";
import { useConfig } from "providers/ConfigContext";
const UsaStates = require("usa-states").UsaStates;

interface Props {
	name: string;
	fairUrl: string;
	address: string;
	city: string;
	state: string;
	zipcode: string;
	fairStartDate: string;
	fairEndDate: string;
}

const FairCard = ({
	name,
	fairUrl,
	address,
	city,
	state,
	zipcode,
	fairStartDate,
	fairEndDate,
}: Props) => {
	const fairStart = convertDateToMonthDay(
		convertYMDStringToDate(fairStartDate)
	);
	const fairEnd = convertDateToMonthDay(convertYMDStringToDate(fairEndDate));
	const { fairFinderResult } = useConfig();

	const lowerCaseName = name.toLowerCase();
	const lowerCaseAddress = address.toLowerCase();
	const lowerCaseCity = city.toLowerCase();

	// Create State Name Reference Object {CA: California}
	const usStates = new UsaStates();
	const stateNames: any = Object.assign(
		{},
		...usStates.states.map((s: any) => ({ [s.abbreviation]: s.name }))
	);
	stateNames.OTHER = "Other";

	return (
		<Container>
			<StyledBorder>
				<StyledCard>
					<LeftContainer>
						<StyledSchoolNameLink
							href={`${fairFinderResult}${fairUrl}`}
							target="_blank"
							rel="noopener"
							onClick={() => trackFairFinderVisitFair()}
						>
							{lowerCaseName}
						</StyledSchoolNameLink>
						<StyledAddress>
							<div>{lowerCaseAddress}</div>
							<div>
								{lowerCaseCity}, {stateNames[state]} {zipcode}
							</div>
						</StyledAddress>
						<StyledAddressMobile>
							<div>{lowerCaseAddress}</div>
							<div>
								{lowerCaseCity}, {state} {zipcode}
							</div>
						</StyledAddressMobile>
					</LeftContainer>
					<RightContainer>
						<StyledDate>
							{fairStart} - {fairEnd}
						</StyledDate>
					</RightContainer>
				</StyledCard>
			</StyledBorder>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 22px;
	max-width: 1520px;
	@media (max-width: 720px) {
		margin-bottom: 12px;
	}
`;

const StyledBorder = styled.div`
	background: #ffffff;
	border: 1px solid #bdbdbd;
	box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	width: 100%;
`;

const StyledCard = styled.div`
	width: 100%;
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;
	column-gap: 30px;
	row-gap: 10px;
	margin: 19px 0px;
	@media (max-width: 500px) {
		margin: 12px 0px;
	}
`;

const StyledSchoolNameLink = styled.a`
	color: #e81111;
	font-family: museo-sans;
	font-style: normal;
	font-weight: 400;
	font-size: 21px;
	line-height: 24px;
	text-decoration: none;
	text-transform: capitalize;
	:hover {
		color: #b90000;
		text-decoration: underline;
	}
	:focus {
		color: #660000;
		text-decoration: underline;
	}
	:focus-visible {
		color: #b90000;
		text-decoration: none;
		outline: 2px solid #b90000;
		border-radius: 2px;
		outline-offset: 4px;
	}
`;

const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 14px;
	margin: 0px 22px;
	@media (max-width: 500px) {
		margin: 0px 14px;
	}
`;

const RightContainer = styled.div`
	margin: 0px 22px;
	@media (max-width: 500px) {
		margin: 0px 14px;
	}
`;

const StyledAddress = styled.div`
	display: inline-flex;
	text-align: left;
	flex-direction: column;
	font-family: museo-sans;
	font-style: normal;
	font-weight: 400;
	font-size: 21px;
	line-height: 24px;
	color: #666666;
	text-transform: capitalize;
	@media (max-width: 600px) {
		display: none;
	}
`;

const StyledAddressMobile = styled(StyledAddress)`
	display: none;
	text-transform: capitalize;
	@media (max-width: 600px) {
		display: inline-flex;
	}
`;

const StyledDate = styled.div`
	color: #006578;
	font-family: museo-sans;
	font-style: normal;
	font-weight: 900;
	font-size: 21px;
	line-height: 24px;
`;

export default FairCard;
