import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useConfig } from "providers/ConfigContext";
import styled from "styled-components/macro";

export const FinFormLiveChat = () => {
	const { bookfairsLiveChatAPI, bookfairsBootstrapURL } = useConfig();
	const [chatAvailable, setChatAvailable] = useState(false);

	useEffect(() => {
		const loadScript = (src: any, callback: any) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = callback;
			document.body.appendChild(script);
		};

		const initEmbeddedService = (chatController: any) => {
			try {
				fetch(chatController)
					.then(function (response) {
						return response.json();
					})
					.then(function (data) {
						if (data.chatAvailability) {
							window.embeddedservice_bootstrap.settings.language =
								"en_US";
							window.embeddedservice_bootstrap.init(
								data.sfComponentId,
								"Customer_Service_Chat_Singed_In",
								data.signedInChatUrl,
								{
									scrt2URL: data.sfScrt2Url,
								}
							);
							window.embeddedservice_bootstrap.settings.hideChatButtonOnLoad =
								true;
							setChatAvailable(true);
						}
					})
					.catch((error) => {
						console.error(
							"There has been a problem with your fetch operation:",
							error
						);
					});
			} catch (err) {
				console.error("Error loading Embedded Messaging: ", err);
			}
		};
		if (bookfairsLiveChatAPI && bookfairsBootstrapURL) {
			loadScript(bookfairsBootstrapURL, () =>
				initEmbeddedService(bookfairsLiveChatAPI)
			);
		}
	}, [bookfairsLiveChatAPI, bookfairsBootstrapURL]);

	useEffect(() => {
		const onEmbeddedMessagingReady = () => {
			const iamUserId = (Cookies.get("SPS_UD") || "").split("|")[0];
			if (
				window.embeddedservice_bootstrap &&
				window.embeddedservice_bootstrap.prechatAPI
			) {
				window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(
					{
						Business_Unit: "Book Fairs",
						IAM_ID: iamUserId,
						Language: "English",
						USOR: "No",
					}
				);
			} else {
				console.error("prechatAPI is not available in EVENT LISTENER");
			}
		};

		window.addEventListener(
			"onEmbeddedMessagingReady",
			onEmbeddedMessagingReady
		);
		return () => {
			window.removeEventListener(
				"onEmbeddedMessagingReady",
				onEmbeddedMessagingReady
			);
		};
	}, []);

	const launchChatWindow = () => {
		if (window.embeddedservice_bootstrap) {
			window.embeddedservice_bootstrap.utilAPI
				.launchChat()
				.then(() => {
					// set size
					const element = document.getElementById(
						"embeddedMessagingFrame"
					);
					if (element) {
						const style = document.createElement("style");
						style.innerHTML = `
							#embeddedMessagingFrame {
								width: 600px !important;
							}
						`;
						document.head.appendChild(style);
					}
				})
				.catch((err: any) => {
					console.error(
						"There was an issue launching live chat.",
						err
					);
					// debugger;
				})
				.finally(() => {
					// Finally handler used to perform any clean-up actions regardless of whether the chat client launches successfully or not.
					// No cleanup actions required by docs
				});
		}
	};

	return (
		<>
			{chatAvailable && (
				<LiveChat onClick={launchChatWindow}>
					Live Chat
					<ScreenReader>Will open a new window</ScreenReader>
				</LiveChat>
			)}
		</>
	);
};

const LiveChat = styled.button`
	display: block;
	position: fixed;
	color: #fff;
	border: 1px solid #ccc;
	font-size: 15px;
	font-family: Arial;
	line-height: 15px;
	opacity: 1;
	z-index: 999995;
	cursor: pointer;
	text-decoration: none;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform: rotate(-90deg);
	-ms-transform: rotate(-90deg) scale(1.02);
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	background-color: black;
	right: -8px;
	top: 15%;
	padding: 5px 14px 14px;
	border-bottom: 0;
	color: #fff;
	border-radius: 9px 9px 0 0;
	-moz-border-radius: 9px 9px 0 0;
	-webkit-border-radius: 9px 9px 0 0;
	transform-origin: 100% 100% 0;
	-webkit-transform-origin: 100% 100% 0;
	-ms-transform-origin: 100% 100% 0;
	&:focus-visible,
	&:active,
	&:hover {
		-ms-transform: rotate(-90deg) scale(1.02) translateY(-5px);
		-webkit-transform: rotate(-90deg) translateY(-5px);
		-moz-transform: rotate(-90deg) translateY(-5px);
	}
`;

const ScreenReader = styled.span`
	position: absolute;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0;
	border: 0;
	height: 1px;
	width: 1px;
	overflow: hidden;
`;
