import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { IconButtonWhite } from "components/Button";
import Print from "images/Print.svg";
import styled from "styled-components/macro";
import LegalCopyToPrint from "./ConfirmYourFair/LegalCopy/LegalCopy";
import RedTextButton from "./RedTextButton";
import SalesHistoryLegalCopy from "./SalesHistory/SalesHistoryLegalCopy";

interface Props {
	buttonText?: string;
	buttonWidth?: number;
	redTextButton?: boolean;
	emailedPage: boolean;
	className?: string;
	fairId?: string;
	handleSatelliteCall?: Function;
	schoolId?: string;
	salesHistoryPage?: boolean;
}

export const PrintableComponent = ({
	buttonText,
	buttonWidth,
	redTextButton,
	emailedPage,
	className,
	fairId,
	handleSatelliteCall,
	schoolId,
	salesHistoryPage,
}: Props) => {
	const componentRef = useRef<HTMLDivElement>(null);

	const [printClicked, setPrintClicked] = useState(false);

	const handlePrint = () => {
		handleSatelliteCall !== undefined && handleSatelliteCall();
		openPrintDialogue();
		setPrintClicked(false);
	};

	const openPrintDialogue = useReactToPrint({
		content: () => componentRef.current as HTMLElement,
	});

	const handleClick = () => {
		setPrintClicked(true);
	};

	return (
		<div>
			<PdfInfo>
				<div ref={componentRef}>
					{/* render legal copy after print button is clicked */}
					{printClicked && (
						<>
							{salesHistoryPage === true ? (
								<SalesHistoryLegalCopy
									emailedPage={emailedPage}
									handlePrint={handlePrint}
									printClicked={printClicked}
									fairId={fairId}
									schoolId={schoolId}
								/>
							) : (
								<LegalCopyToPrint
									emailedPage={emailedPage}
									handlePrint={handlePrint}
									printClicked={printClicked}
									fairId={fairId}
									schoolId={schoolId}
								/>
							)}
						</>
					)}
				</div>
			</PdfInfo>
			{!redTextButton && (
				<IconButtonWhite
					whiteBackground={true}
					handleClick={() => {
						window._satellite.track("print-services-agreement", {
							"button-text": "print-services-agreement",
						});
						handleClick();
					}}
					iconSrc={Print}
					iconAlt={"Print"}
					iconWidth={14}
					buttonWidth={buttonWidth}
				>
					{buttonText}
				</IconButtonWhite>
			)}
			{redTextButton && (
				<RedTextButton
					handleClick={handleClick}
					className={className ? className : "header"}
				>
					{buttonText}
				</RedTextButton>
			)}
		</div>
	);
};
const PdfInfo = styled.div`
	display: none;
`;
