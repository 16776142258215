import React from "react";
import CopyLinkIcon from "images/CopyLink.svg";
import styled from "styled-components";
import useCustomAlert from "hooks/HomepageBuilder/useCustomAlert";
import { trackShareHomepage } from "DumbleData";

interface Props {
	customUrl: string;
}

const CopyLinkButton = ({ customUrl }: Props) => {
	const { activateAlert, alert } = useCustomAlert(
		"Homepage URL is copied to your clipboard"
	);

	const handleCopyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(document.location.href);
			activateAlert();
			trackShareHomepage("copy-url");
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<>
			{alert}
			<StyledCopyLinkButton onClick={handleCopyToClipboard}>
				<StyledCopyLinkIcon
					src={CopyLinkIcon}
					alt="Copy Homepage URL to your clipboard"
				/>
			</StyledCopyLinkButton>
		</>
	);
};

const StyledCopyLinkButton = styled.button`
	background: none;
	border: none;
	border-radius: 56px;
	display: flex;
	width: 40px;
	height: 40px;
	&:focus {
		outline: 2px solid #002d70;
		outline-offset: 2px;
	}
`;

const StyledCopyLinkIcon = styled.img`
	cursor: pointer;
	width: 40px;
`;

export default CopyLinkButton;
