import React from "react";
import FairDetailsCard from "./FairDetailsCard";
import RedTextButton from "../RedTextButton";
import { useConfig } from "providers/ConfigContext";
import styled from "styled-components";
import { convertYMDStringToDate } from "utils/DateConversion";

interface Props {
	fairId: string;
	schoolName: string;
	startDate: string;
	endDate: string;
	showRestockBeforeCopy: boolean;
	restockEndDate: Date;
	showRestockButton: boolean;
	fairType: string;
	productId: string;
}
const RestocksCard = ({
	fairId,
	schoolName,
	startDate,
	endDate,
	showRestockBeforeCopy,
	restockEndDate,
	showRestockButton,
	fairType,
	productId,
}: Props) => {
	const { restockOrigin } = useConfig();

	const handleRestockClick = () => {
		window.open(
			`${restockOrigin}?fairid=${fairId}&school=${schoolName}&sdate=${startDate}&edate=${endDate}&fairtype=${fairType}&pkId=${productId}`,
			"_blank"
		);
	};

	const formattedStartDate = convertYMDStringToDate(startDate).toLocaleString(
		"en-US",
		{
			month: "numeric",
			day: "numeric",
			year: "numeric",
		}
	);
	const formattedEndDate = restockEndDate.toLocaleString("en-US", {
		month: "numeric",
		day: "numeric",
		year: "numeric",
	});

	return (
		<FairDetailsCard title={"Restocks"}>
			{showRestockBeforeCopy ? (
				<Body>
					Place a restock request between: <br /> {formattedStartDate}{" "}
					and {formattedEndDate} by noon.
				</Body>
			) : (
				<Body>
					Restocks not available after noon on {formattedEndDate}
				</Body>
			)}
			{showRestockButton && (
				<RedTextButton handleClick={handleRestockClick}>
					Start a restock request
				</RedTextButton>
			)}
		</FairDetailsCard>
	);
};

const Body = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	margin-bottom: 16px;
`;

export default RestocksCard;
