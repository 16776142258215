import React from "react";
import styled from "styled-components";
import { ScanSheetEntry } from "hooks/ScanSheet/useScanSheetData";
import Barcode from "react-barcode";

interface Props {
	entry: ScanSheetEntry;
	key: number;
}

const GradeTeacherEntry = ({ key, entry }: Props) => (
	<div key={key}>
		{entry.grade !== "Kindergarten" &&
		entry.grade !== "Pre-kindergarten" ? (
			<GradeText>
				{`${entry.grade} Grade  |   ${entry.teacherLastName}`}
			</GradeText>
		) : (
			<GradeText>
				{`${entry.grade}  |   ${entry.teacherLastName}`}
			</GradeText>
		)}
		<Labels>
			<StudentLabel>Recipient Name</StudentLabel>
			<BalanceLabel>Balance</BalanceLabel>
			<BarcodeLabel />
			<StudentRemainingBalance>
				<RemainingBalanceLabel>Remaining Balance</RemainingBalanceLabel>
			</StudentRemainingBalance>
		</Labels>
		<StudentWrapper>
			{entry.students.map((student, studentIndex) => (
				<StudentContainer key={studentIndex}>
					<StudentName>{`${student.recipientLastName}, ${student.recipientFirstName}`}</StudentName>
					<StudentBalance>{`$${student.balance.toFixed(
						2
					)}`}</StudentBalance>
					<BarcodeDiv>
						<StyledBarcode
							value={student.barcode}
							format={"CODE128"}
						/>
					</BarcodeDiv>
					<StudentRemainingBalance>
						<BlankField />
					</StudentRemainingBalance>
				</StudentContainer>
			))}
		</StudentWrapper>
	</div>
);

const GradeText = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	padding-bottom: 8px;
	border-bottom: 1px solid #000000;
	@media print {
		font-size: 66px;
		line-height: 100px;
		padding-bottom: 33px;
	}
	@media (min-width: 1000px) {
		font-size: 18px;
		padding-bottom: 10px;
	}
	@media (min-width: 1200px) {
		font-size: 20px;
		padding-bottom: 15px;
	}
	@media (min-width: 1700px) {
		font-size: 30px;
		padding-bottom: 20px;
	}
	@media (min-width: 2050px) {
		font-size: 66px;
		line-height: 100px;
		padding-bottom: 33px;
	}
`;

const Labels = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const LabelText = styled.p`
	color: #333;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 15px;
	padding: 8px;
	@media print {
		font-size: 41px;
		padding: 33px;
	}
	@media (min-width: 1000px) {
		font-size: 15px;
		padding: 10px;
	}
	@media (min-width: 1200px) {
		font-size: 20px;
		padding: 15px;
	}
	@media (min-width: 1700px) {
		font-size: 30px;
		padding: 20px;
	}
	@media (min-width: 2050px) {
		font-size: 41px;
		padding: 33px;
	}
`;

const StudentLabel = styled(LabelText)`
	width: 15%;
`;

const BalanceLabel = styled(LabelText)`
	width: 10%;
`;

const BarcodeLabel = styled.div`
	width: 50%;
	padding: 0px;
`;

const StudentText = styled.p`
	color: #333;
	font-size: 10px;
	font-style: normal;
	font-weight: 300;
	line-height: 15px;
	padding: 8px;
	@media print {
		font-size: 41px;
		line-height: 62px;
		padding: 33px;
	}
	@media (min-width: 1000px) {
		font-size: 15px;
		padding: 10px;
	}
	@media (min-width: 1200px) {
		font-size: 20px;
		padding: 15px;
	}
	@media (min-width: 1700px) {
		font-size: 30px;
		padding: 20px;
	}
	@media (min-width: 2050px) {
		font-size: 41px;
		line-height: 62px;
		padding: 33px;
	}
`;

const StudentName = styled(StudentText)`
	width: 15%;
`;

const StudentBalance = styled(StudentText)`
	width: 10%;
`;

const RemainingBalanceLabel = styled.p`
	color: #333;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	margin: 8px 0 8px 20%;
	@media print {
		font-size: 41px;
		margin: 33px 0px 33px 20%;
	}
	@media (min-width: 1000px) {
		font-size: 15px;
		padding: 10px 0px;
	}
	@media (min-width: 1200px) {
		font-size: 20px;
		padding: 15px 0px;
	}
	@media (min-width: 1700px) {
		font-size: 30px;
		margin: 33px 0px 33px 20%;
	}
	@media (min-width: 2050px) {
		font-size: 41px;
		margin: 33px 0px 33px 20%;
	}
`;

const StudentWrapper = styled.div`
	gap: 8px;
`;

const StudentContainer = styled.div`
	display: flex;
	flex-direction: row;
	border-top: 1px solid #d7d7d7;
	page-break-inside: avoid;
`;

const BarcodeDiv = styled.div`
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledBarcode = styled(Barcode)`
	font-size: 16px;
	font-weight: 400;
	width: 110px;
	margin-top: 1px;
	transform: scale(1.25, 1) !important;
	@media print {
		margin-top: 20px;
		width: 1164px;
		height: 380px;
	}
	@media (min-width: 700px) {
		width: 80%;
		height: unset;
		margin-top: 8px;
	}
	@media (min-width: 1200px) {
		margin-top: 10px;
	}
	@media (min-width: 1500px) {
		margin-top: 15px;
	}
	@media (min-width: 2200px) {
		margin-top: 20px;
	}
`;

const StudentRemainingBalance = styled.div`
	width: 20%;
	color: #333;
`;

const BlankField = styled.div`
	width: 71%;
	height: 30px;
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #919191;
	margin: 8px 10px 13px 20%;
	@media print {
		height: 120px;
		border: 4px solid #919191;
		border-radius: 16px;
		margin-top: 20px;
	}
	@media (min-width: 1000px) {
		height: 60px;
		border: 2px solid #919191;
		border-radius: 10px;
		margin-top: 10px;
	}
	@media (min-width: 1200px) {
		height: 80px;
		border: 2px solid #919191;
		border-radius: 16px;
		margin-top: 20px;
	}
	@media (min-width: 1700px) {
		height: 80px;
		border: 3px solid #919191;
		border-radius: 16px;
		margin-top: 20px;
	}
	@media (min-width: 2050px) {
		height: 120px;
		border: 4px solid #919191;
		border-radius: 16px;
		margin-top: 20px;
	}
`;

export default GradeTeacherEntry;
