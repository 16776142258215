import React, { useEffect, useState } from "react";
import FairDetailsCard, { StyledDisableButton } from "./FairDetailsCard";
import styled from "styled-components/macro";
import { IconButtonWhite } from "components/Button";
import { EnableEwalletModal, DisableEwalletModal } from "./EWalletModals";
import { useConfig } from "providers/ConfigContext";
import Print from "images/Print.svg";
import {
	convertDateToMonthDayYear,
	convertStringToDate,
} from "utils/DateConversion";
import axios from "axios";
import { handleGenericSuccess, handleGenericErrors } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

type Props = {
	sales: string;
	unspentFunds: string;
	startDate: string;
	endDate: string;
	enabled: boolean;

	fairId: string;
};

const EWalletCard = ({
	sales,
	unspentFunds,
	startDate,
	endDate,
	enabled,

	fairId,
}: Props) => {
	const [isEnabled, setIsEnabled] = useState<Boolean>(enabled);
	const [enableModalOpen, setEnableModalOpen] = useState<Boolean>(false);
	const [disableModalOpen, setDisableModalOpen] = useState<Boolean>(false);

	useEffect(() => {
		setIsEnabled(enabled);
	}, [enabled]);

	const handleViewRoster = () => {
		window._satellite.track("view-roster", {
			"button-text": "view-roster",
		});
		window.open(
			`/content/fairs/hub/roster.html?fairID=${fairId}`,
			"_blank"
		);
	};

	const toggleEnabled = () => {
		setIsEnabled(!isEnabled);
	};

	const handleDisabledEWalletModal = () => {
		setDisableModalOpen(true);
		window._satellite.track("ewallet-enablement", {
			buttonStatus: "Disabled",
			"button-text": "ewallet-enablement",
		});
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};

	const { bookfairsAPI, bookfairsOrigin } = useConfig();

	const handleOpenScanSheet = () => {
		window._satellite.track("print-scan-sheet", {
			"button-text": "print-scan-sheet",
		});
		window.open(
			`${bookfairsOrigin}content/fairs/hub/scansheet/classroom.html?fairid=${fairId}`,
			"_blank"
		);
	};

	const handleEnabledEWalletModal = async () => {
		const currentFair = getFairIdFromUrl();
		//TODO: restructure
		if (checkSpsUd()) {
			try {
				await axios
					.put(
						`${bookfairsAPI}/private/fairs/${currentFair}/ewallet/enable`,
						{ withCredentials: true }
					)
					.then((response) => {
						if (response.status === 200) {
							setEnableModalOpen(true);
							toggleEnabled();
							window._satellite.track("ewallet-enablement", {
								buttonStatus: "Enabled",
								"button-text": "ewallet-enablement",
							});
							window.parent.postMessage(
								"openModal",
								window.location.origin
							);
							document.documentElement.classList.add(
								"bookfairs-modal--open"
							);
						} else {
							handleGenericSuccess(response);
						}
					})
					.catch((err) => {
						handleGenericErrors(err);
					});
			} catch (err: any) {
				handleGenericErrors(err);
			}
		}
	};

	return (
		<>
			{enableModalOpen && (
				<EnableEwalletModal
					closeModal={() => {
						setEnableModalOpen(false);
					}}
				/>
			)}
			{disableModalOpen && (
				<DisableEwalletModal
					closeModal={() => {
						setDisableModalOpen(false);
					}}
					setEnabled={toggleEnabled}
				/>
			)}
			<FairDetailsCard
				title={"eWallet"}
				linkText={"VIEW ROSTER"}
				handleLinkClicked={handleViewRoster}
			>
				<TopContainer>
					<LeftContainer>
						<StyledText>eWallet sales</StyledText>
						<LargeText>${sales}</LargeText>
					</LeftContainer>
					<RightContainer>
						<StyledText>Unspent Funds</StyledText>
						<LargeText>${unspentFunds}</LargeText>
					</RightContainer>
				</TopContainer>
				<MiddleContainer>
					<DescriptionText>
						Shoppers can set up and manage funds from:
					</DescriptionText>
					<StyledDate>
						{convertDateToMonthDayYear(
							convertStringToDate(startDate)
						)}{" "}
						→{" "}
						{convertDateToMonthDayYear(
							convertStringToDate(endDate)
						)}
					</StyledDate>
				</MiddleContainer>
				<BottomContainer>
					{isEnabled ? (
						<StyledDisableButton
							onClick={handleDisabledEWalletModal}
						>
							DISABLE
						</StyledDisableButton>
					) : (
						<StyledDisableButton
							onClick={handleEnabledEWalletModal}
						>
							ENABLE
						</StyledDisableButton>
					)}
					<StyledIconButtonWhite
						buttonWidth={194}
						iconSrc={Print}
						iconAlt={"Print"}
						iconWidth={20}
						handleClick={handleOpenScanSheet}
					>
						PRINT SCAN SHEET
					</StyledIconButtonWhite>
				</BottomContainer>
			</FairDetailsCard>
		</>
	);
};

const TopContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: 145px;
	row-gap: 16px;
	margin-bottom: 24px;
`;

const MiddleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const BottomContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: flex-end;
	margin-top: 20px;
	row-gap: 64px;
	column-gap: 200px;
`;

const StyledDate = styled.div`
	font-weight: 500;
	font-size: 14px;
	line-height: 14px;
	color: #919191;
`;

const LargeText = styled.div`
	font-weight: 900;
	font-size: 36px;
	line-height: 36px;
`;

const StyledText = styled.div`
	font-weight: 700;
	font-size: 13px;
	line-height: 15px;
`;

const DescriptionText = styled.div`
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
`;

const LeftContainer = styled.div`
	display: flex;
	gap: 4px;
	flex-direction: column;
	justify-content: left;
`;

const RightContainer = styled(LeftContainer)`
	display: flex;
	gap: 4px;
	flex-direction: column;
	justify-content: left;
`;

const StyledIconButtonWhite = styled(IconButtonWhite)`
	margin-top: -40px;
`;

export default EWalletCard;
