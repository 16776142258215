import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import useFairBasicInfo from "hooks/FinancialForm/useFairBasicInfo";
import { checkSpsUd, getFairIdFromUrl, getUUIDFromUrl } from "utils/HubUtility";

export type Student = {
	recipientFirstName: string;
	recipientLastName: string;
	balance: number;
	barcode: string;
};

export type ScanSheetEntry = {
	grade: string;
	teacherLastName: string;
	students: Student[];
};

/* ----TODO: SUBJECT TO CHANGE---- */

const useScanSheetPageData = () => {
	const { bookfairsAPI } = useConfig();

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [apiResponse, setApiResponse] = useState<any>();
	const [scanSheet, setScanSheet] = useState<ScanSheetEntry[]>([]);

	const [schoolName, setSchoolName] = useState<string>("");
	const currentFair = getFairIdFromUrl();
	const basicInfoResponse = useFairBasicInfo({ fairId: currentFair });

	useEffect(() => {
		// Convert the API response to the format needed for the scan sheet (Group by grade/teacherLastName)
		const convertToScanSheetEntries = () => {
			const newScanSheet: ScanSheetEntry[] = [];
			apiResponse.ewallets.forEach((ewallet: any) => {
				const { grade, teacherLastName } = ewallet.attributes;
				const student: Student = {
					recipientFirstName: ewallet.attributes.recipientFirstName,
					recipientLastName: ewallet.attributes.recipientLastName,
					balance: ewallet.balance,
					barcode: ewallet.attributes.barcode,
				};

				// See if there is already an entry for the grade/teacherLastName
				let entry = newScanSheet.find(
					(entry) =>
						entry.grade === grade &&
						entry.teacherLastName === teacherLastName
				);

				if (!entry) {
					// If entry doesn't exist, create a new one
					entry = {
						grade: grade,
						teacherLastName: teacherLastName,
						students: [],
					};
					newScanSheet.push(entry);
				}

				// Add the student to the entry's students array
				entry.students.push(student);
			});
			setScanSheet(newScanSheet);
			setIsLoading(false);
		};
		if (apiResponse !== undefined) {
			convertToScanSheetEntries();
		}
	}, [apiResponse]);

	/* ---- TODO: Sort Data ---- */

	useEffect(() => {
		const fetchScanSheetInfo = async () => {
			const currentFair = getFairIdFromUrl();
			if (checkSpsUd()) {
				setIsLoading(true);
				try {
					const response = await axios.get(
						/* ---- Might need to change this ---- */
						`${bookfairsAPI}/user/fairs/${currentFair}/ewallets`,
						{
							withCredentials: true,
						}
					);
					if (response.status !== 200) {
						handleGenericSuccess(response);
						return;
					}
					setApiResponse(response.data);

					if (
						!basicInfoResponse.isLoading &&
						basicInfoResponse.fairBasicInfo !== null
					) {
						setSchoolName(basicInfoResponse.fairBasicInfo.name);
					}
				} catch (err) {
					setIsError(true);
					handleGenericErrors(err);
				}
			}
		};
		fetchScanSheetInfo();
	}, [
		bookfairsAPI,
		basicInfoResponse.isLoading,
		basicInfoResponse.fairBasicInfo,
		currentFair,
	]);

	return {
		schoolName,
		scanSheet,
		isError,
		isLoading,
	};
};

export type IndividualScanSheet = {
	schoolName: string;
	wallet: {
		id: string;
		balance: number;
		attributes: {
			recipientFirstName: string;
			recipientLastName: string;
			teacherLastName: string;
			grade: string;
			barcode: string;
		};
	};
};

const useIndividualScansheetPageData = () => {
	const { bookfairsAPI } = useConfig();

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [schoolName, setSchoolName] = useState<string>("");
	const [id, setId] = useState<string>("");
	const [balance, setBalance] = useState<number>(0);
	const [recipientFirstName, setRecipientFirstName] = useState<string>("");
	const [recipientLastName, setRecipientLastName] = useState<string>("");
	const [teacherLastName, setTeacherLastName] = useState<string>("");
	const [grade, setGrade] = useState<string>("");
	const [barcode, setBarcode] = useState<string>("");

	useEffect(() => {
		const fetchIndividualScanSheetInfo = async () => {
			const uuid = getUUIDFromUrl();
			setIsLoading(true);
			try {
				const response = await axios.get(
					`${bookfairsAPI}/public/ewallets/${uuid}`,
					{
						withCredentials: true,
					}
				);
				if (response.status !== 200) {
					handleGenericSuccess(response);
					return;
				}
				if (response.data.wallet.id !== undefined) {
					setId(response.data.wallet.id);
				}
				if (response.data.wallet.balance !== undefined) {
					setBalance(response.data.wallet.balance);
				}
				if (
					response.data.wallet.attributes.recipientFirstName !==
					undefined
				) {
					setRecipientFirstName(
						response.data.wallet.attributes.recipientFirstName
					);
				}
				if (
					response.data.wallet.attributes.recipientLastName !==
					undefined
				) {
					setRecipientLastName(
						response.data.wallet.attributes.recipientLastName
					);
				}
				if (
					response.data.wallet.attributes.teacherLastName !==
					undefined
				) {
					setTeacherLastName(
						response.data.wallet.attributes.teacherLastName
					);
				}
				if (response.data.wallet.attributes.grade !== undefined) {
					setGrade(response.data.wallet.attributes.grade);
				}
				if (response.data.wallet.attributes.barcode !== undefined) {
					setBarcode(response.data.wallet.attributes.barcode);
				}
				if (response.data.schoolName !== undefined) {
					setSchoolName(response.data.schoolName);
				}
			} catch (err) {
				setIsError(true);
				handleGenericErrors(err);
			} finally {
				setIsLoading(false);
			}
		};
		fetchIndividualScanSheetInfo();
	}, [bookfairsAPI]);

	return {
		id,
		balance,
		recipientFirstName,
		recipientLastName,
		teacherLastName,
		grade,
		barcode,
		schoolName,
		isError,
		isLoading,
	};
};

export { useScanSheetPageData, useIndividualScansheetPageData };
