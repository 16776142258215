import React, { useRef } from "react";
import styled from "styled-components/macro";
import Close from "images/Close.svg";

interface Props {
	closeModal: any;
	children: React.ReactNode;
}

export const AEMModalContainer = ({ children, closeModal }: Props) => {
	const modalRef = useRef<HTMLDivElement>(null);
	return (
		<Wrapper>
			<Overlay />
			<Container>
				<StyledModal ref={modalRef}>
					<CloseButton onClick={closeModal}>
						<CloseImage src={Close} alt="Close Modal" />
					</CloseButton>
					<StyledChildren>{children}</StyledChildren>
				</StyledModal>
			</Container>
		</Wrapper>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const CloseButton = styled.button`
	border: none;
	z-index: 1;
	background-color: transparent;
	cursor: pointer;
	position: absolute;
	top: 27px;
	right: 27px;
`;

const CloseImage = styled.img`
	width: 18px;
	height: 18px;
`;

const StyledModal = styled.div`
	z-index: 1;
	display: grid;
	background-color: white;
	position: relative;
	grid-column-start: 4;
	grid-column-end: 21;
	top: 0px;
	max-height: 100%;
	box-sizing: border-box;
	overflow: auto;
	@media (max-width: 719px) {
		height: 100vh;
	}
`;

const Overlay = styled.div`
	z-index: 1;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.7);
	@media (max-width: 719px) {
		background-color: white;
	}
`;

const Wrapper = styled.div`
	z-index: 1;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	@media (max-width: 719px) {
		height: 100vh;
		top: 0;
	}
`;

const StyledChildren = styled.div`
	overflow-x: hidden;
`;
