import IframeContainer from "components/IframeContainer";
import { SalesHistoryContent } from "./SalesHistoryContent";
import { useSalesHistory } from "hooks/SalesHistory/useSalesHistory";
import SchoolSelectorBar from "components/SchoolSelector/SchoolSelectorBar";
import { useState } from "react";

export const SalesHistory = () => {
	const { salesHistory, isLoading } = useSalesHistory();

	const [schoolId, setSchoolId] = useState("");

	return (
		<IframeContainer
			renderItems={() => (
				<>
					{!isLoading && (
						<>
							<SchoolSelectorBar
								handleSetSchoolId={setSchoolId}
							/>
							<SalesHistoryContent
								salesHistoryData={salesHistory}
								schoolId={schoolId}
							/>
						</>
					)}
				</>
			)}
		/>
	);
};
