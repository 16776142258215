/**
 * @source https://github.com/remix-run/react-router/issues/8139
 */

import type { Blocker, History, Transition } from "history";
import { ContextType, useCallback, useContext, useEffect } from "react";
import {
	Navigator as BaseNavigator,
	UNSAFE_NavigationContext as NavigationContext,
} from "react-router-dom";

interface Navigator extends BaseNavigator {
	block: History["block"];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
	navigator: Navigator;
};

/**
 * @source https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 */
export function useBlocker(blocker: Blocker, when: boolean) {
	const { navigator } = useContext(
		NavigationContext
	) as NavigationContextWithBlock;

	useEffect(() => {
		if (!when) {
			return;
		}

		const unblock = navigator.block((tx: Transition) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					unblock();
					tx.retry();
				},
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
}

/**
 * @source https://github.com/remix-run/react-router/issues/8139#issuecomment-1021457943
 */
export function usePrompt(
	message:
		| any
		| ((
				location: Transition["location"],
				action: Transition["action"]
		  ) => null),
	when: boolean
) {
	const blocker = useCallback(
		(tx: Transition) => {
			message.message();
		},
		[message]
	);
	return useBlocker(blocker, message.when);
}
