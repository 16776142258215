import React, { useState } from "react";
import styled from "styled-components/macro";
import Checkbox from "components/Checkbox";
import RadioButton from "components/RadioButton";
import LimitedTextArea from "components/LimitedTextArea";
import { RedButton, WhiteButton } from "components/Button";
import { AEMModalContainer } from "components/AEMModalContainer";

interface Props {
	closeModal: any;
	handleSubmit: any;
}

interface CheckboxItem {
	label: string;
	value: string;
	checked: boolean;
}

interface RadioButtonItem {
	label: string;
	value: string;
	radioButtonName: string;
	checked: boolean;
}

const checkboxes: CheckboxItem[] = [
	{ label: "School Information", value: "schoolInfo", checked: false },
	{ label: "School Type", value: "schoolType", checked: false },
	{ label: "Tax Certificate Status", value: "taxCertStatus", checked: false },
	{ label: "Your Information", value: "yourInformatin", checked: false },
	{
		label: "Delivery/Pickup Days",
		value: "deliveryPickupDays",
		checked: false,
	},
];

const radioButtons: RadioButtonItem[] = [
	{
		label: "Email",
		value: "Email",
		radioButtonName: "contactMethod",
		checked: true,
	},
	{
		label: "Phone",
		value: "Phone",
		radioButtonName: "contactMethod",
		checked: false,
	},
];

export const RejectionModal = ({ closeModal, handleSubmit }: Props) => {
	const [checkboxesState, setCheckboxesState] = useState(checkboxes);
	const [radioButtonsState, setRadioButtonsState] = useState(radioButtons);

	const [message, setMessage] = useState("");

	const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);

	const handleMakeChangesCheckbox = (
		value: string,
		inputs: NodeListOf<HTMLInputElement>,
		errorLabel: HTMLElement
	) => {
		const updatedCheckboxes = checkboxesState.map((checkbox) => {
			if (checkbox.value === value) {
				inputs.forEach((input) => {
					input.classList.remove("error");
				});
				errorLabel.classList.add("hide");
				return {
					...checkbox,
					checked: !checkbox.checked,
				};
			}
			return checkbox;
		});
		setCheckboxesState(updatedCheckboxes);
		// Check if at least one checkbox is checked
		setIsAnyCheckboxChecked(
			updatedCheckboxes.some((checkbox) => checkbox.checked)
		);
	};

	const handleSend = (
		inputs: NodeListOf<HTMLInputElement>,
		errorLabel: HTMLElement
	) => {
		if (isAnyCheckboxChecked) {
			inputs.forEach((input) => {
				input.classList.remove("error");
			});
			errorLabel.classList.add("hide");
			const contactMethod = radioButtonsState
				.filter((item) => item.checked)
				.map((item) => item.value);
			const reasons = checkboxesState
				.filter((item) => item.checked)
				.map((item) => item.value);
			handleSubmit(message, contactMethod[0], reasons);
		} else {
			inputs.forEach((input) => {
				input.classList.add("error");
			});
			errorLabel.classList.remove("hide");
		}
	};

	const handleContactMethodCheckbox = () => {
		const updatedRadioButtons = radioButtonsState.map((radioButton) => {
			return {
				...radioButton,
				checked: !radioButton.checked,
			};
		});
		setRadioButtonsState(updatedRadioButtons);
	};

	return (
		<AEMModalContainer closeModal={closeModal}>
			<StyledContent>
				<StyledHeadSec>
					<StyledHeading>
						To make changes or request more info
					</StyledHeading>
				</StyledHeadSec>
				<StyledHeadText>
					Let us know where you'd like to make changes or get more
					information. A member of our team will reach out soon.
				</StyledHeadText>
				<CheckboxContainerSec id="CheckboxContainerSec">
					{checkboxesState.map(({ label, value, checked }) => (
						<CheckboxContainer key={value}>
							<Checkbox
								checked={checked}
								onChange={() =>
									handleMakeChangesCheckbox(
										value,
										document.querySelectorAll(
											"#CheckboxContainerSec input[type='checkbox']"
										)!,
										document.querySelector(
											"#CheckboxErrorLabel"
										)!
									)
								}
								label={label}
								value={value}
							/>
						</CheckboxContainer>
					))}
					<ErrorLabel id="CheckboxErrorLabel" className="hide">
						Please select at least one category.
					</ErrorLabel>
				</CheckboxContainerSec>
				<AdditionalDetailsSec>
					<LimitedTextArea
						label="Add additional details"
						value={message}
						onChange={setMessage}
					/>
				</AdditionalDetailsSec>
				<ContactMethodSec>
					<ContactMethodHeading>
						Preferred Contact Method
					</ContactMethodHeading>
					<RadioButtonsContainerSec>
						{radioButtonsState.map(
							({ label, value, radioButtonName, checked }) => (
								<RadioButton
									checked={checked}
									onChange={() =>
										handleContactMethodCheckbox()
									}
									label={label}
									value={value}
									radioButtonName={radioButtonName}
								/>
							)
						)}
					</RadioButtonsContainerSec>
				</ContactMethodSec>
				<ButtonContainer>
					<StyledWhiteButton handleClick={closeModal}>
						Cancel
					</StyledWhiteButton>
					<StyledRedButton
						handleClick={() =>
							handleSend(
								document.querySelectorAll(
									"#CheckboxContainerSec input[type='checkbox']"
								)!,
								document.querySelector("#CheckboxErrorLabel")!
							)
						}
					>
						Send
					</StyledRedButton>
				</ButtonContainer>
			</StyledContent>
		</AEMModalContainer>
	);
};

const StyledHeading = styled.p`
	font-size: 16px;
	line-height: 19px;
	font-weight: 600;
	padding-top: 40px;
`;

const StyledHeadText = styled.p`
	font-size: 17px;
	line-height: 25px;
	font-weight: 300;
	margin-bottom: 30px;
`;

const StyledContent = styled.div`
	background-color: white;
	padding: 24px;
	max-width: 552px;
	box-sizing: border-box;
	@media (max-width: 719px) {
		width: 100%;
		height: 100%;
	}
`;

const StyledHeadSec = styled.div`
	box-sizing: border-box;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 16px;
	@media (max-width: 719px) {
		margin-bottom: 0px;
	}
`;

const CheckboxContainerSec = styled.div`
	margin-bottom: 45px;
`;

const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 4px;
`;

const AdditionalDetailsSec = styled.div`
	margin-bottom: 50px;
`;

const ContactMethodSec = styled.div`
	margin-bottom: 45px;
`;

const ContactMethodHeading = styled.p`
	padding-bottom: 8px;
	font-size: 13px;
	line-height: 15px;
	font-weight: 400;
`;

const RadioButtonsContainerSec = styled.div`
	display: flex;
	gap: 35px;
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 130px;
	height: 40px;
	border: 1px solid #e81111;
`;

const StyledRedButton = styled(RedButton)`
	width: 130px;
	height: 40px;
	border-radius: 100px;
`;

const ButtonContainer = styled.div`
	gap: 24px;
	display: flex;
	justify-content: flex-end;
	@media (max-width: 500px) {
		width: 100%;
	}
`;

const ErrorLabel = styled.p`
	opacity: 1;
	font-weight: 400;
	font-size: 13px;
	line-height: 15px;
	color: #e81111;
	&.hide {
		opacity: 0;
		display: none;
	}
`;
