import React from "react";
import FairDetailsCard from "./FairDetailsCard";
import styled from "styled-components/macro";
import { PrintableComponent } from "components/PrintableComponent";
import Check from "images/Check.svg";

interface Props {
	taxRate: number;
	taxStatus: string;
}

const MyContactsCard = ({ taxRate, taxStatus }: Props) => {
	return (
		<FairDetailsCard title={"Fair Terms"}>
			<StyledContainer>
				<TaxStatusContainer>
					<Body>Sales Tax Rate: {taxRate}%</Body>
					{taxStatus === "OF" && (
						<>
							<TaxStatusOnFile>
								<Icon alt={`Green Checkmark`} src={Check} />
								<div>Tax Certificate on File</div>
							</TaxStatusOnFile>
						</>
					)}
					{taxStatus === "NF" && (
						<>
							<TaxStatusNotOnFile>
								<div>No Tax Certificate on File</div>
							</TaxStatusNotOnFile>
						</>
					)}
				</TaxStatusContainer>
				<PrintableComponent
					emailedPage={false}
					buttonText={"View Services Agreement"}
					redTextButton={true}
					className="fairTermsCard"
				/>
			</StyledContainer>
		</FairDetailsCard>
	);
};

const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const TaxStatusOnFile = styled.div`
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #008018;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
`;

const TaxStatusContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 9px;
	margin-bottom: 28px;
`;

const TaxStatusNotOnFile = styled(TaxStatusOnFile)`
	color: #b90000;
	margin-bottom: 8px;
	margin-top: 2px;
`;

const Body = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
`;

const Icon = styled.img`
	width: 14px;
`;

export default MyContactsCard;
