import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { getSchoolIdFromUrl } from "utils/HubUtility";

export type SalesHistory = {
	pastFairs: Array<FairData>;
};

export type FairData = {
	startDate: string;
	fairId: string;
	hostName: string;
	sales: {
		total: number;
		itemized: {
			inSchoolFair: number;
			onlineShopping: number;
		};
	};
	profitAndRewards: {
		total: number;
		itemized: {
			takeInCash: number;
			scholasticDollarsProfit: number;
			onlineFairProfit: number;
		};
	};
	availability: {
		coa: boolean;
		invoice: boolean;
	};
};

const fairDataDefault = {
	startDate: "",
	fairId: "",
	hostName: "",
	sales: {
		total: 0,
		itemized: {
			inSchoolFair: 0,
			onlineShopping: 0,
		},
	},
	profitAndRewards: {
		total: 0,
		itemized: {
			takeInCash: 0,
			scholasticDollarsProfit: 0,
			onlineFairProfit: 0,
		},
	},
	availability: {
		coa: false,
		invoice: false,
	},
};

const salesHistoryDefault = {
	pastFairs: [fairDataDefault],
};

const useSalesHistory = () => {
	const [salesHistory, setSalesHistory] =
		useState<SalesHistory>(salesHistoryDefault);
	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { bookfairsAPI } = useConfig();

	useEffect(() => {
		const getSalesHistoryPageData = async () => {
			const schoolIdOrSelected = getSchoolIdFromUrl();
			try {
				await axios
					.get(
						`${bookfairsAPI}/user/schools/${schoolIdOrSelected}/sales-history`,
						{
							withCredentials: true,
						}
					)
					.then((response) => {
						if (response.status === 200) {
							if (response.data) {
								setSalesHistory(response.data);
							}
						} else {
							handleGenericSuccess(response);
						}
					})
					.catch((err) => {
						setIsError(true);
						handleGenericErrors(err);
					});
			} catch (err: any) {
				setIsError(true);
				handleGenericErrors(err);
			} finally {
				setIsLoading(false);
			}
		};
		getSalesHistoryPageData();
	}, [bookfairsAPI]);

	return {
		salesHistory,
		isLoading,
		isError,
	};
};

export { useSalesHistory };
