import React from "react";
import SDBalanceCard from "../FairDetailsPage/FairDetailsCard";
import styled from "styled-components";
import { trackShopSDCOnline } from "DumbleData";

const RedeemCard = () => {
	const handleClickShopOnline = () => {
		trackShopSDCOnline();
		window.open("/content/fairs/hub/sdc.html", "_top");
	};
	return (
		<SDBalanceCard title={"How to redeem"}>
			<Body>
				<RedeemHeading>
					There are several ways to redeem your Scholastic Dollars
					balance:
				</RedeemHeading>
				<StyledUl>
					<BulletText>
						Take product from a future Scholastic Book Fair.
					</BulletText>
					<BulletText>
						Shop the{" "}
						<LinkText onClick={handleClickShopOnline}>
							Scholastic Dollars Catalog online.
						</LinkText>
					</BulletText>
					<BulletText>
						Call 800-799-7323 to place a Catalog order.
					</BulletText>
				</StyledUl>
			</Body>
		</SDBalanceCard>
	);
};

const Body = styled.div`
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
`;

const RedeemHeading = styled.p`
	margin-bottom: 24px;
`;

const StyledUl = styled.ul`
	list-style-type: disc;
	padding-left: 24px;
`;

const BulletText = styled.li`
	margin-bottom: 24px;
	&:last-child {
		margin-bottom: 36px;
	}
`;

const LinkText = styled.div`
	display: inline;
	text-decoration: underline;
	color: #333333;
	:hover {
		cursor: pointer;
	}
`;

export default RedeemCard;
