import styled from "styled-components/macro";
import { RedButton, WhiteButton } from "components/Button";
import CloseIcon from "images/Close.svg";
import Heading from "components/Heading";
import LimitedTextArea from "components/LimitedTextArea";
import TextInput from "components/TextInput";
import { useState } from "react";
import { validateEmail, validatePhoneNumber } from "utils/InputValidations";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { convertDateToMDY } from "utils/DateConversion";
import { trackContactChairperson } from "DumbleData";

interface Props {
	handleModal(): any;
	contactData: {
		email: string;
	};
	fairInfoData: {
		schoolName: string;
		startDate: Date;
		endDate: Date;
	};
	fairId: number;
}

const ContactChairpersonForm = ({
	handleModal,
	fairId,
	contactData,
	fairInfoData,
}: Props) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [firstNameError, setFirstNameError] = useState<string>();
	const [lastNameError, setLastNameError] = useState<string>();
	const [emailError, setEmailError] = useState<string>();
	const [phoneNumberError, setPhoneNumberError] = useState<string>();
	const [messageError, setMessageError] = useState<string>();
	const { bookfairsAPI } = useConfig();

	const dataValidate = () => {
		let valid = true;
		if (!firstName) {
			setFirstNameError("Please enter a first name.");
			valid = false;
		} else {
			setFirstNameError(undefined);
		}
		if (!lastName) {
			setLastNameError("Please enter a last name.");
			valid = false;
		} else {
			setLastNameError(undefined);
		}
		if (!validateEmail(email)) {
			setEmailError("Please enter a valid email address.");
			valid = false;
		} else {
			setEmailError(undefined);
		}
		if (phone.length) {
			if (!validatePhoneNumber(phone)) {
				setPhoneNumberError("Please enter a valid phone number.");
				valid = false;
			} else {
				setPhoneNumberError(undefined);
			}
		}
		if (!message) {
			setMessageError("Please enter a message");
			valid = false;
		} else {
			setMessageError(undefined);
		}
		return valid;
	};

	const formattedStartDate = convertDateToMDY(fairInfoData.startDate);
	const formattedEndDate = convertDateToMDY(fairInfoData.endDate);
	const sendMessage = async () => {
		if (dataValidate()) {
			try {
				//TODO: check payload, differs in swagger
				await axios.post(
					`${bookfairsAPI}/public/email/chairperson/homepage/parent`,
					{
						context: {
							email: email,
							message: message,
							name: {
								first: firstName,
								last: lastName,
							},
							phone: phone,
							schoolName: fairInfoData.schoolName,
							fairStartDate: formattedStartDate,
							fairEndDate: formattedEndDate,
							fairId: fairId,
						},
						email: contactData.email,
					}
				);
				trackContactChairperson();
			} catch (err) {
				console.error(err);
			}
			handleModal();
		}
	};

	return (
		<StyledOverlay>
			<StyledModal>
				<StyledCloseButton onClick={handleModal}>
					<img
						alt="Close contact chairperson modal"
						src={CloseIcon}
					/>
				</StyledCloseButton>

				<Content>
					<StyledHeading>Contact Chairperson</StyledHeading>

					<StyledHeadingMobile>
						Contact <br /> Your Book Fair Chairperson
					</StyledHeadingMobile>

					<InputContainer>
						<FlexRow>
							<InlineElement>
								<TextInput
									label="First name"
									value={firstName}
									onChange={setFirstName}
									error={firstNameError}
								/>
							</InlineElement>
							<InlineElement>
								<TextInput
									label="Last Name"
									value={lastName}
									onChange={setLastName}
									error={lastNameError}
								/>
							</InlineElement>
						</FlexRow>
						<FlexRow>
							<InlineElement>
								<TextInput
									label="Email Address"
									value={email}
									onChange={setEmail}
									error={emailError}
								/>
							</InlineElement>
							<InlineElement>
								<TextInput
									label="Phone Number (optional)"
									value={phone}
									onChange={setPhone}
									error={phoneNumberError}
								/>
							</InlineElement>
						</FlexRow>
						<FlexRow>
							<LimitedTextArea
								label="Your Message"
								value={message}
								onChange={setMessage}
								error={messageError}
								limit={250}
							/>
						</FlexRow>
					</InputContainer>
					<ButtonsRow>
						<StyledRedButton handleClick={sendMessage}>
							Send Message
						</StyledRedButton>
						<StyledWhiteButton handleClick={handleModal}>
							Cancel
						</StyledWhiteButton>
					</ButtonsRow>
				</Content>
			</StyledModal>
		</StyledOverlay>
	);
};

const InputContainer = styled.div`
	@media (max-width: 519px) {
		max-width: 315px;
	}
`;
const StyledRedButton = styled(RedButton)`
	width: 166px;
	margin-right: 16px;
	@media (max-width: 519px) {
		width: 315px;
		margin-right: unset;
	}
`;
const StyledWhiteButton = styled(WhiteButton)`
	width: 120px;
	border: 1px solid red;
	@media (max-width: 519px) {
		width: 315px;
		margin-bottom: 60px;
	}
`;
const ButtonsRow = styled.div`
	margin-top: 29px;
	@media (max-width: 519px) {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;
	}
`;

const Content = styled.div`
	padding: 66.4px;
	@media (max-width: 519px) {
		padding: 30px;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;
const StyledHeading = styled(Heading)`
	font-size: 32px;
	line-height: 35px;
	letter-spacing: 1px;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 48px;
	display: block;
	@media (max-width: 519px) {
		display: none;
	}
`;
const StyledHeadingMobile = styled(Heading)`
	display: none;
	width: 255px;
	padding: 32px 60px 32px 60px;
	text-transform: uppercase;
	text-align: center;
	font-size: 28px;
	font-weight: 500;
	line-height: 32px;
	letter-spacing: 1.5px;
	@media (max-width: 519px) {
		display: block;
	}
`;

const StyledOverlay = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledModal = styled.div`
	background: #ffffff;
	position: relative;
	width: 640px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow-y: scroll;
	overflow-x: hidden;
	@media (max-width: 519px) {
		width: 100%;
		height: 100%;
	}
`;

const FlexRow = styled.div`
	display: inline-flex;
	width: 100%;
	gap: 40px;
	@media (max-width: 519px) {
		flex-direction: column;
		gap: 7px;
	}
`;

const InlineElement = styled.div`
	flex: 1 auto;
	width: calc(50% - 20px);
	@media (max-width: 519px) {
		width: 100%;
	}
`;

const StyledCloseButton = styled.button`
	position: absolute;
	right: 20px;
	top: 22px;
	background: none;
	border: none;
	cursor: pointer;
	height: 20px;
	z-index: 1;
	@media (max-width: 519px) {
		right: 18px;
		top: 18px;
	}
`;

export default ContactChairpersonForm;
