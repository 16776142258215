import React from "react";
import styled from "styled-components";
import FoldHereFirstImage from "images/FoldHereFirst.svg";

const TopRightCard = () => {
	return (
		<Container className="gridItem">
			<FoldHereFirst src={FoldHereFirstImage} />
		</Container>
	);
};

const Container = styled.div`
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 1;
	grid-row-end: 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	width: 100%;
`;

const FoldHereFirst = styled.img`
	margin-bottom: 25px;
	height: 75px;
	width: 311px;
`;

export default TopRightCard;
