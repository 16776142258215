import BodyText from "components/BodyText";
import { RedButton, WhiteButton } from "components/Button";
import Heading from "components/Heading";
import Subheading from "components/Subheading";
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navigation";
import {
	FinFormProgress,
	FinFormUrls,
	checkFinFormUrlsLocalStorage,
	getFinFormProgress,
	setFinFormCurrentPageLocalStorage,
	setFinFormUrlsLocalStorage,
} from "utils/FinFormLocalStorageUtility";
import useFairBasicInfo from "hooks/FinancialForm/useFairBasicInfo";
import {
	updateFinancialFormSpendingData,
	trackSpendingBackButton,
	trackSpendingSaveAndContinue,
} from "DumbleData";

interface Props {
	fairId: string;
	totalRedeemed: number;
	existingBalance: number;
	appliedBalance: number;
	scholasticDollarsDue: number;
}

export const FinancialFormSpendingContent = ({
	fairId,
	totalRedeemed,
	existingBalance,
	appliedBalance,
	scholasticDollarsDue,
}: Props) => {
	const navigate = useNavigate();
	const { supressEarnings } = useFairBasicInfo({ fairId });

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	const clickBackButton = () => {
		trackSpendingBackButton();
		navigate(FinFormUrls.Sales);
	};

	const clickSaveAndContinue = () => {
		updateDumbleData();
		trackSpendingSaveAndContinue();
		const savedFinFormProgress = getFinFormProgress(fairId);
		if (supressEarnings) {
			if (
				savedFinFormProgress === FinFormProgress.Spending ||
				savedFinFormProgress === FinFormProgress.Earnings
			) {
				setFinFormUrlsLocalStorage(fairId, FinFormUrls.Review);
			}
			navigate(FinFormUrls.Review);
			return;
		}
		if (savedFinFormProgress === FinFormProgress.Spending) {
			setFinFormUrlsLocalStorage(fairId, FinFormUrls.Earnings);
		}
		navigate(FinFormUrls.Earnings);
	};

	const updateDumbleData = () => {
		updateFinancialFormSpendingData(
			totalRedeemed.toString(),
			appliedBalance.toString(),
			scholasticDollarsDue.toString()
		);
	};

	// if user tries to directly access the /spending page without completing previous steps, redirect them to the first incomplete step
	useEffect(() => {
		const progress = getFinFormProgress(fairId);
		if (progress === FinFormProgress.Confirmation) {
			navigate(FinFormUrls.Confirmation);
		} else if (progress < FinFormProgress.Spending) {
			const savedFinFormLocation = checkFinFormUrlsLocalStorage(fairId);
			if (savedFinFormLocation) {
				navigate(savedFinFormLocation);
			}
		} else {
			setFinFormCurrentPageLocalStorage(fairId, FinFormUrls.Spending);
		}
	}, [navigate, fairId]);

	return (
		<>
			<Navigation
				activeLink={FinFormProgress.Spending}
				fairId={fairId}
			></Navigation>
			<FinancialPage>
				<SpendingWrapper>
					<StyledHeading>Fair Spending</StyledHeading>
					<StyledSubHeading>
						Let's see how much you spent.
					</StyledSubHeading>
					<FinancialSpendingCard>
						<StyledContents>
							<ContentRow>
								<ContentName>
									<ContentHeading>
										Total Scholastic Dollars Redeemed
									</ContentHeading>
								</ContentName>
								<ContentValue>
									{formatter.format(Math.abs(totalRedeemed))}
								</ContentValue>
							</ContentRow>
							<ContentSeperator />
							<ContentRow>
								<ContentHeading>
									Apply Existing Scholastic Dollar Balance
									<br></br>(
									{formatter.format(existingBalance)})
								</ContentHeading>
								<ContentValue>
									{formatter.format(
										-Math.abs(appliedBalance)
									)}
								</ContentValue>
							</ContentRow>
							{/* If scholastic dollars due are null or 0, do not show scholastic dollars due */}
							{scholasticDollarsDue > 0 && (
								<>
									<ContentSeperator />
									<ContentRow>
										<ContentHeading>
											Scholastic Dollars Due
										</ContentHeading>
										<ContentValue>
											{formatter.format(
												Math.abs(scholasticDollarsDue)
											)}
										</ContentValue>
									</ContentRow>
								</>
							)}
						</StyledContents>
					</FinancialSpendingCard>
					<ButtonContainer>
						<StyledWhiteButton
							handleClick={clickBackButton}
							whiteBackground={true}
						>
							Back
						</StyledWhiteButton>
						<StyledRedButton handleClick={clickSaveAndContinue}>
							Save & Continue
						</StyledRedButton>
					</ButtonContainer>
				</SpendingWrapper>
			</FinancialPage>
		</>
	);
};

const FinancialPage = styled.div`
	max-width: 100%;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

const SpendingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	margin-top: 56px;
	margin-bottom: 56px;
	@media (max-width: 700px) {
		width: calc(100% - 40px);
	}
`;

const FinancialSpendingCard = styled.div`
	display: inline-flex;
	padding: 48px 80px;
	flex-direction: column;
	align-items: flex-start;
	gap: 35px;
	border-radius: 16px;
	background: #f5f5f5;
	margin-top: 33px;
	@media (max-width: 700px) {
		padding: 48px 25px;
	}
`;

const StyledHeading = styled(Heading)`
	display: flex;
	position: relative;
	font-size: 39px;
	font-style: normal;
	font-weight: 300;
	line-height: 42px;
	padding-bottom: 21px;
`;

const StyledSubHeading = styled(Subheading)`
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	padding-bottom: 11px;
`;

const StyledContents = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	width: 100%;
`;

const ContentSeperator = styled.div`
	width: 100%;
	height: 1px;
	background: #d7d7d7;
`;

const ContentRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	@media (max-width: 420px) {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 4px;
	}
`;

const ContentName = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 4px;
`;

const ContentHeading = styled(BodyText)`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	text-align: left;
`;

const ContentValue = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
`;

const StyledWhiteButton = styled(WhiteButton)`
	height: 40px;
	padding: 9px 30px;
`;

const StyledRedButton = styled(RedButton)`
	height: 40px;
	padding: 9px 30px;
`;
