import React from "react";
import styled from "styled-components/macro";
import Heading from "components/Heading";
import EWallet from "components/Homepage/eWallet";
import Notifications from "components/Homepage/Notifications";
import EventsCarousel from "./EventsCarousel";

interface Props {
	fairId: number;
	schoolUCN: number;
	startDate: Date;
	endDate: Date;
	url: string;
	isVirtual: boolean;
	eWalletFlag: boolean;
	eventsData: {
		fairId: string;
		scheduleDate: string;
		createDate: Date;
		eventCategory: string;
		eventName: string;
		startTime: string;
		endTime: string;
		description: string;
		id: number;
	}[];
	eWalletStartDate: Date;
	eWalletEndDate: Date;
}

const BeforeTheFairComponent = ({
	fairId,
	schoolUCN,
	startDate,
	endDate,
	eventsData,
	isVirtual,
	url,
	eWalletFlag,
	eWalletStartDate,
	eWalletEndDate,
}: Props) => {
	return (
		<BeforeTheFair>
			<SectionContainer>
				<StyledHeading>Before the Fair</StyledHeading>
				<FlexRow>
					{eWalletFlag && (
						<InlineElement>
							<EWallet
								fairId={fairId}
								endDate={endDate}
								eWalletStartDate={eWalletStartDate}
								eWalletEndDate={eWalletEndDate}
							/>
						</InlineElement>
					)}
					<InlineElement>
						<Notifications fairId={fairId} schoolUCN={schoolUCN} />
					</InlineElement>
				</FlexRow>
			</SectionContainer>
			{!isVirtual && (
				<>
					<StyledHeading>At The Fair</StyledHeading>
					<EventsCarousel
						eventsData={eventsData}
						startDate={startDate}
						endDate={endDate}
						url={url}
					/>
				</>
			)}
		</BeforeTheFair>
	);
};

const SectionContainer = styled.div`
	width: 100%;
	padding: 32px 0px 64px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 720px) {
		padding: 32px 0px 32px;
	}
	@media (max-width: 375px) {
		padding: 0 0 20px 0;
	}
`;
const FlexRow = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 40px;
	@media (max-width: 720px) {
		gap: 28px;
	}
	@media (max-width: 670px) {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 25px;
		align-items: center;
	}
`;

const InlineElement = styled.div`
	height: 100%;
	@media (max-width: 670px) {
		width: 100%;
	}
`;

const BeforeTheFair = styled.div`
	width: 100%;
	position: relative;
	background-color: #1226aa;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledHeading = styled(Heading)`
	text-transform: uppercase;
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: 35px;
	letter-spacing: 1px;
	text-align: center;
	max-width: 760px;
	color: #ffffff;
	margin-top: 32px;
	padding-bottom: 24px;
	@media (max-width: 670px) {
		margin-top: 54px;
	}
`;

export default BeforeTheFairComponent;
