import IframeContainer from "components/IframeContainer";
import { FinancialFormSalesContent } from "./FinancialFormSalesContent";
import useSalesData from "hooks/FinancialForm/useSalesData";
import FairInfoBar from "components/FairInfoBar";

export const FinancialFormSales = () => {
	const {
		purchaseOrders,
		SDRedeemed,
		SDTaxExemptSales,
		SDTaxCollected,
		cashChecks,
		creditCards,
		taxExemptSales,
		STFFCollected,
		STFFRedeemed,
		GCPurchased,
		GCRedeemed,
		defaultTaxExemptSales,
		defaultSDTaxExemptSales,
		taxExemptSalesEdited,
		fairId,
		isLoading,
		taxRate,
		fairSalesTotal,
		SDTotal,
		SDCashEquivalent,
		GCUnredeemed,
		netSalesDiscounts,
		SDTaxableSales,
		taxableSalesLessSalesTax,
		grossSalesTotal,
		taxTotal,
		taxableSales,
		setTaxableSales,
		setFairSalesTotal,
		setSDRedeemed,
		setSDTaxExemptSales,
		setSDTaxCollected,
		setCashChecks,
		setCreditCards,
		setTaxExemptSales,
		handleSetTaxExemptSalesDirectly,
		setTaxableSalesLessSalesTax,
		setGrossSalesTotal,
		setSDTaxableSales,
		setSDCashEquivalent,
		setSDTotal,
		setTaxTotal,
		setPurchaseOrders,
	} = useSalesData();

	return (
		<IframeContainer
			renderItems={() => (
				<>
					{!isLoading && (
						<>
							<FairInfoBar />
							<FinancialFormSalesContent
								purchaseOrders={purchaseOrders}
								fairId={fairId}
								taxRate={taxRate}
								SDRedeemed={SDRedeemed}
								SDTaxExemptSales={SDTaxExemptSales}
								SDTaxCollected={SDTaxCollected}
								cashChecks={cashChecks}
								creditCards={creditCards}
								taxExemptSales={taxExemptSales}
								defaultTaxExemptSales={defaultTaxExemptSales}
								defaultSDTaxExemptSales={
									defaultSDTaxExemptSales
								}
								STFFCollected={STFFCollected}
								STFFRedeemed={STFFRedeemed}
								GCPurchased={GCPurchased}
								GCRedeemed={GCRedeemed}
								taxExemptSalesEdited={taxExemptSalesEdited}
								fairSalesTotal={fairSalesTotal}
								SDTotal={SDTotal}
								SDCashEquivalent={SDCashEquivalent}
								netSalesDiscounts={netSalesDiscounts}
								GCUnredeemed={GCUnredeemed}
								SDTaxableSales={SDTaxableSales}
								taxableSalesLessSalesTax={
									taxableSalesLessSalesTax
								}
								grossSalesTotal={grossSalesTotal}
								taxTotal={taxTotal}
								taxableSales={taxableSales}
								setPurchaseOrders={setPurchaseOrders}
								setSDRedeemed={setSDRedeemed}
								setSDTaxExemptSales={setSDTaxExemptSales}
								setSDTaxCollected={setSDTaxCollected}
								setCashChecks={setCashChecks}
								setCreditCards={setCreditCards}
								setTaxExemptSales={setTaxExemptSales}
								handleSetTaxExemptSalesDirectly={
									handleSetTaxExemptSalesDirectly
								}
								setSDTotal={setSDTotal}
								setSDCashEquivalent={setSDCashEquivalent}
								setSDTaxableSales={setSDTaxableSales}
								setTaxableSalesLessSalesTax={
									setTaxableSalesLessSalesTax
								}
								setGrossSalesTotal={setGrossSalesTotal}
								setTaxTotal={setTaxTotal}
								setTaxableSales={setTaxableSales}
								setFairSalesTotal={setFairSalesTotal}
							/>
						</>
					)}
				</>
			)}
		/>
	);
};
