import React from "react";
import styled from "styled-components";
import { convertDateToMDY } from "utils/DateConversion";
import Barcode from "react-barcode";

interface Props {
	barcodeValue: string;
}

const BottomLeftCard = ({ barcodeValue }: Props) => {
	const currentDate = new Date();
	const formattedDate = convertDateToMDY(currentDate);
	// remove seconds from the string
	const formattedTime = currentDate
		.toLocaleTimeString()
		.replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");

	return (
		<Container className="gridItem">
			<Heading>Cashier</Heading>
			<Description>
				When checking out on the Register, select eWALLET as the tender
				type and tap PAY. Scan the barcode below or tap FIND eWALLET to
				apply as payment.
			</Description>
			<StyledBarcode value={barcodeValue} format={"CODE128"} />
			<DateContainer>
				Generated {formattedDate} | {formattedTime}
			</DateContainer>
			<Note>Note: Change will not be issued.</Note>
		</Container>
	);
};

const Container = styled.div`
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 2;
	grid-row-end: 3;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	border-right: 2px dotted rgba(51, 51, 51, 0.5);
	border-top: 2px dotted rgba(51, 51, 51, 0.5);
`;

const Heading = styled.div`
	color: #333;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	margin: 23px 10px;
`;

const Description = styled.div`
	color: #333;
	font-size: 9px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
	width: 179px;
	margin-bottom: 29px;
`;

const StyledBarcode = styled(Barcode)`
	font-size: 16px;
	font-weight: 400;
	width: 180px;
	height: 54px;
	margin-bottom: 37px;
	transform: scale(1.75, 1.75) !important; ;
`;

const DateContainer = styled.div`
	color: #333;
	font-size: 9px;
	font-style: normal;
	font-weight: 600;
	line-height: 12px;
	margin-bottom: 11px;
`;

const Note = styled.div`
	color: #333;
	font-size: 9px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
`;

export default BottomLeftCard;
