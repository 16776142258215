import { useState, useCallback } from "react";
import { convertStringToDate } from "utils/DateConversion";

const tempDate = new Date();

const useFairInfoData = () => {
	const [schoolName, setSchoolName] = useState<string>("");
	const [productId, setProductId] = useState<string>("");
	const [defaultStartDate, setDefaultStartDate] = useState<Date>(tempDate);
	const [defaultEndDate, setDefaultEndDate] = useState<Date>(tempDate);
	const [endDate, setEndDate] = useState<Date>(tempDate);
	const [startDate, setStartDate] = useState<Date>(tempDate);
	const [k8Flag, setK8Flag] = useState<string>("");
	const [location, setLocation] = useState<string>("");
	const [address, setAddress] = useState<string>("");
	const [city, setCity] = useState<string>("");
	const [state, setState] = useState<string>("AL");
	const [zipcode, setZipcode] = useState<string>("");
	const [welcomeMessage, setWelcomeMessage] = useState<string>(
		"All purchases through the Book Fair benefit our school. Thanks for supporting our kids! Happy reading!"
	);
	const [cash, setCash] = useState<boolean>(true);
	const [checks, setChecks] = useState<boolean>(true);
	const [creditCard, setCreditCard] = useState<boolean>(true);
	const [thankYouMessage, setThankYouMessage] = useState<string>(
		"Thank you so much for supporting our school at the Book Fair! You'll find more great books at our online Fair, and all your purchases will benefit our school."
	);
	const [payableTo, setPayableTo] = useState<string>("(School Name)");
	const [taxRate, setTaxRate] = useState<number>(0);
	const [taxStatus, setTaxStatus] = useState<string>("");

	// Set the initial data from the /get request
	const setFairInfoData = useCallback(
		(
			initSchoolName: string,
			initProductId: string,
			initDeliveryDate: string,
			initPickupDate: string,
			initEndDate: string,
			initStartDate: string,
			initK8Flag: string,
			initLocation: string,
			initAddress: string,
			initCity: string,
			initState: string,
			initZipcode: string,
			initWelcomeMessage: string,
			initCash: string,
			initChecks: string,
			initCreditCard: string,
			initThankYouMessage: string,
			initPayableTo: string,
			initTaxRate: number,
			initTaxStatus: string
		) => {
			if (initSchoolName) {
				setSchoolName(initSchoolName);
			}
			if (initProductId) {
				setProductId(initProductId);
			}
			if (initDeliveryDate) {
				setDefaultStartDate(convertStringToDate(initDeliveryDate));
			}
			if (initPickupDate) {
				setDefaultEndDate(convertStringToDate(initPickupDate));
			}
			if (initEndDate) {
				setEndDate(convertStringToDate(initEndDate));
			}
			if (initStartDate) {
				setStartDate(convertStringToDate(initStartDate));
			}
			if (initK8Flag) {
				setK8Flag(initK8Flag);
			}
			if (initLocation) {
				setLocation(initLocation);
			}
			if (initAddress) {
				setAddress(initAddress);
			}
			if (initCity) {
				setCity(initCity);
			}
			if (initState) {
				setState(initState);
			}
			if (initZipcode) {
				setZipcode(initZipcode);
			}
			if (initWelcomeMessage) {
				setWelcomeMessage(initWelcomeMessage);
			}
			if (initCash && initCash === "N") {
				setCash(false);
			}
			if (initChecks && initChecks === "N") {
				setChecks(false);
			}
			if (initCreditCard && initCreditCard === "N") {
				setCreditCard(false);
			}
			if (initThankYouMessage) {
				setThankYouMessage(initThankYouMessage);
			}
			if (initPayableTo) {
				setPayableTo(initPayableTo);
			}
			if (initTaxRate) {
				setTaxRate(initTaxRate);
			}
			if (initTaxStatus) {
				setTaxStatus(initTaxStatus);
			}
		},
		[]
	);

	const fairInfoData = {
		schoolName,
		setSchoolName,
		productId,
		defaultStartDate,
		defaultEndDate,
		endDate,
		setEndDate,
		startDate,
		setStartDate,
		k8Flag,
		location,
		setLocation,
		address,
		setAddress,
		city,
		setCity,
		state,
		setState,
		zipcode,
		setZipcode,
		welcomeMessage,
		setWelcomeMessage,
		cash,
		setCash,
		checks,
		setChecks,
		creditCard,
		setCreditCard,
		thankYouMessage,
		setThankYouMessage,
		payableTo,
		setPayableTo,
		taxRate,
		setTaxRate,
		taxStatus,
		setTaxStatus,
	};

	return { fairInfoData, setFairInfoData };
};

export default useFairInfoData;
