import React from "react";
import styled from "styled-components";
import BalanceCard from "../BalanceCard";
import RedeemCard from "../RedeemCard";
import { Grid } from "components/FairDetailsPage/pages/FairDetailsPageContent";
import TransactionTable from "../TransactionTable/TransactionTable";
import { Transaction } from "hooks/SDBalance/useSDBalanceData";
import { updateSDBalanceData } from "DumbleData";

interface Props {
	profitBalance: number;
	rewardsBalance: number;
	transactions: Transaction[];
}

export const SDBalancePageContent = ({
	profitBalance,
	rewardsBalance,
	transactions,
}: Props) => {
	updateSDBalanceData(
		profitBalance.toString(),
		rewardsBalance.toString(),
		transactions.length.toString()
	);

	return (
		<Grid>
			<FullWidth>
				<Heading>
					<HeadingTitle>Scholastic Dollars balance</HeadingTitle>
				</Heading>
			</FullWidth>
			<LeftColumn>
				<BalanceCard
					profitBalance={profitBalance}
					rewardsBalance={rewardsBalance}
				/>
			</LeftColumn>
			<RightColumn>
				<RedeemCard />
			</RightColumn>
			<FullWidth>
				<TransactionTable transactions={transactions} />
			</FullWidth>
		</Grid>
	);
};

const FullWidth = styled.div`
	grid-column-start: 1;
	grid-column-end: 24;
`;

const LeftColumn = styled.div`
	grid-column-start: 1;
	grid-column-end: 16;
	@media (max-width: 720px) {
		grid-column-start: 1;
		grid-column-end: 24;
	}
`;
const RightColumn = styled.div`
	grid-column-start: 17;
	grid-column-end: 24;
	@media (max-width: 720px) {
		grid-column-start: 1;
		grid-column-end: 24;
	}
`;

const Heading = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	margin-top: 29px;
	@media (max-width: 720px) {
		flex-direction: column;
		justify-content: unset;
		gap: 16px;
	}
`;

const HeadingTitle = styled.p`
	color: #333333;
	font-size: 39px;
	font-style: normal;
	font-weight: 300;
	line-height: 42px;
	@media (max-width: 375px) {
		font-size: 32px;
		line-height: 35px;
	}
`;
