import Cookies from "js-cookie";
import React from "react";
import IframeContainer from "./IframeContainer";
import styled from "styled-components";

const ErrorCode = () => {
	const spsUd = Cookies.get("SPS_UD");
	const fairId = sessionStorage.getItem("bfSelectedFairId");
	const errorCode = sessionStorage.getItem("errorCode");

	const formatSpsUd = (spsUd: string | undefined) => {
		if (spsUd) {
			const spsUdArr = spsUd.split("|");
			return spsUdArr[0];
		} else {
			return "0";
		}
	};

	const formattedSpsUd = formatSpsUd(spsUd);

	return (
		<IframeContainer
			renderItems={() => (
				<ErrorCodeContainer>
					<div>Error code:</div>
					<CodeContainer>
						{formattedSpsUd}-{errorCode || 0}-{fairId || 0}
					</CodeContainer>
				</ErrorCodeContainer>
			)}
		/>
	);
};

const ErrorCodeContainer = styled.div`
	font-size: 18px;
	line-height: 22px;
	font-weight: 300;
	color: #333333;
	display: flex;
	flex-direction: row;
	gap: 5px;
`;

const CodeContainer = styled.span`
	display: flex;
	flex-direction: row;
	font-weight: 500;
`;

export default ErrorCode;
