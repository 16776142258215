import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import FairDetailsCard from "./FairDetailsCard";
import { useConfig } from "providers/ConfigContext";
import { convertYMDStringToDate } from "utils/DateConversion";

type Props = {
	startDate: string;
	endDate: string;
	fairId: string;
};

const PaymentPortalCard = ({ startDate, endDate, fairId }: Props) => {
	const [supressed, setSuppressed] = useState(false);
	const { PaymentPortalOrigin } = useConfig();

	const handleLinkClicked = () => {
		window._satellite.track("launch-payment-portal", {
			"button-text": "launch-payment-portal",
		});
		window.open(`${PaymentPortalOrigin}?fairId=${fairId}`, "_blank");
	};

	const checkLaunchWindow = useCallback(() => {
		const delivery = convertYMDStringToDate(startDate);
		const pickup = convertYMDStringToDate(endDate);

		const currentDate = new Date();

		// midnight 5 days before the delivery date
		const beforeDelivery = new Date(
			delivery.setDate(delivery.getDate() - 5)
		);
		// midnight 11 days after the fair pickup date
		const afterPickup = new Date(pickup.setDate(pickup.getDate() + 11));

		// if current date is earlier than 5 days before delivery date
		// or if current date is later than 10 days after the pickup date, supress launch cta
		if (currentDate <= beforeDelivery || currentDate >= afterPickup) {
			setSuppressed(true);
		}
	}, [startDate, endDate]);

	useEffect(() => {
		checkLaunchWindow();
	}, [checkLaunchWindow]);

	const bodyText =
		"Process credit cards and eWallets up to 5 days before the Fair starts to when the Fair ends.";

	return (
		<>
			{supressed ? (
				<FairDetailsCard title={"Payment Portal"}>
					<Body>{bodyText}</Body>
				</FairDetailsCard>
			) : (
				<FairDetailsCard
					title={"Payment Portal"}
					linkText={"LAUNCH"}
					handleLinkClicked={handleLinkClicked}
				>
					<Body>{bodyText}</Body>
				</FairDetailsCard>
			)}
		</>
	);
};

const Body = styled.div`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #333333;
	overflow: hidden;
`;

export default PaymentPortalCard;
