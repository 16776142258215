import React from "react";
import IframeContainer from "components/IframeContainer";
import { ConfirmYourFairContent } from "./ConfirmYourFairContent";
import FairInfoBar from "components/FairInfoBar";
import { useCoaPageData } from "hooks/ConfirmYourFair/useCoaPageData";

export const ConfirmYourFair = () => {
	const { fairInfoData, chairpersonData, organizationData } = useCoaPageData({
		withToken: false,
	});

	return (
		<IframeContainer
			renderItems={() => (
				<>
					{/* wait to render components until primary request (useCoaPageData) is fulfilled */}
					{fairInfoData.fairId !== 0 && (
						<>
							<FairInfoBar />
							<ConfirmYourFairContent
								fairInfoData={fairInfoData}
								chairpersonData={chairpersonData}
								organizationData={organizationData}
							/>
						</>
					)}
				</>
			)}
		/>
	);
};
