import React from "react";
import { useCoaPageData } from "hooks/ConfirmYourFair/useCoaPageData";
import LegalCopyContent from "./LegalCopyContent";

interface Props {
	emailedPage: boolean;
	handlePrint?: Function;
	printClicked?: boolean;
	fairId?: string;
	schoolId?: string;
}

const LegalCopyToPrint = ({
	emailedPage,
	handlePrint,
	printClicked,
	fairId,
}: Props) => {
	const {
		fairInfoData,
		chairpersonData,
		consultantData,
		organizationData,
		isLoading,
	} = useCoaPageData({
		withToken: emailedPage,
		fairId: fairId,
	});

	return (
		<LegalCopyContent
			handlePrint={handlePrint}
			printClicked={printClicked}
			emailedPage={emailedPage}
			fairInfoData={fairInfoData}
			chairpersonData={chairpersonData}
			consultantData={consultantData}
			organizationData={organizationData}
			isLoading={isLoading}
		></LegalCopyContent>
	);
};
export default LegalCopyToPrint;
