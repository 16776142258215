import React from "react";
import styled from "styled-components";
import { AEMModalContainer } from "components/AEMModalContainer";
import { IconButtonWhite } from "components/Button";
import RedArrowLeft from "images/RedArrowLeft.svg";

interface Props {
	closeModal: () => void;
	openEmailAgreementModal: () => void;
}

export const EmailFailureModal = ({
	closeModal,
	openEmailAgreementModal,
}: Props) => {
	const goBack = () => {
		closeModal();
		openEmailAgreementModal();
	};

	return (
		<AEMModalContainer closeModal={closeModal}>
			<StyledContainer>
				<StyledText>
					Something went wrong... please try again.
				</StyledText>
				<StyledButtonContainer>
					<StyledWhiteButton
						iconSrc={RedArrowLeft}
						whiteBackground={true}
						handleClick={goBack}
					>
						Go Back
					</StyledWhiteButton>
				</StyledButtonContainer>
			</StyledContainer>
		</AEMModalContainer>
	);
};

const StyledContainer = styled.div`
	padding: 24px;
`;

const StyledText = styled.p`
	padding-top: 65px;
	padding-bottom: 48px;
	font-weight: 700;
	font-size: 18px;
	line-height: 21px;
	color: black;
`;

const StyledButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const StyledWhiteButton = styled(IconButtonWhite)`
	margin-right: 1px;
	margin-bottom: 1px;
	float: right;
`;
