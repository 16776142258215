import React from "react";
import styled from "styled-components/macro";
import { RedButton, WhiteButton } from "../../../Button";
import Heading from "components/Heading";
import BodyText from "components/BodyText";
import NavExpand from "images/NavExpand.svg";
import union from "images/Union.svg";
import {
	customParseFloat,
	formatNumberWithCommas,
	formatStringWithCommas,
} from "utils/HubUtility";
import { salesInfoErrors } from "./FinancialFormSalesValidation";
import { trackSaveSalesDetailsButton, trackViewSalesDetails } from "DumbleData";
import { roundToPrecision } from "utils/RoundingUtility";

interface Props {
	handleClickAddPurchaseOrder: Function;
	setEditing: any;
	purchaseOrdersTotal: number;
	SDRedeemed: string;
	SDTaxExemptSales: string;
	SDTaxCollected: string;
	cashChecks: string;
	creditCards: string;
	taxExemptSales: string;
	setTaxExemptSales: any;
	defaultTaxExemptSales: string;
	defaultSDTaxExemptSales: string;
	taxExemptSalesEdited: boolean;
	setSDRedeemed: any;
	setSDTaxExemptSales: any;
	setSDTaxCollected: any;
	setCashChecks: any;
	setCreditCards: any;
	handleSetTaxExemptSalesDirectly: any;
	handleClickSave: any;
	salesInfoErrors: salesInfoErrors;
	salesEditing: boolean;
	handleViewSalesDetails: () => void;
	grossSalesTotal: number;
	taxTotal: number;
	floatTaxExemptSales: number;
	floatSDRedeemed: number;
	floatSTFFCollected: number;
	floatSTFFRedeemed: number;
	taxableSales: number;
	disableAddPurchaseOrder: boolean;
	SDTotal: number;
	SDCashEquivalent: number;
	netSalesDiscounts: number;
	GCPurchased: number;
	GCRedeemed: number;
	GCUnredeemed: number;
	taxableSalesLessSalesTax: number;
	taxRate: number;
	SDTaxableSales: number;
}

export const FinancialFormTotalsContent = ({
	handleClickAddPurchaseOrder,
	salesEditing,
	purchaseOrdersTotal,
	SDRedeemed,
	SDTaxExemptSales,
	SDTaxCollected,
	cashChecks,
	creditCards,
	taxExemptSales,
	setTaxExemptSales,
	taxExemptSalesEdited,
	defaultTaxExemptSales,
	defaultSDTaxExemptSales,
	setSDRedeemed,
	setSDTaxExemptSales,
	setSDTaxCollected,
	setCashChecks,
	setCreditCards,
	handleSetTaxExemptSalesDirectly,
	handleClickSave,
	salesInfoErrors,
	handleViewSalesDetails,
	grossSalesTotal,
	taxTotal,
	floatTaxExemptSales,
	floatSDRedeemed,
	floatSTFFCollected,
	floatSTFFRedeemed,
	taxableSales,
	disableAddPurchaseOrder,
	SDTotal,
	SDCashEquivalent,
	GCPurchased,
	GCRedeemed,
	netSalesDiscounts,
	GCUnredeemed,
	taxableSalesLessSalesTax,
	taxRate,
	SDTaxableSales,
}: Props) => {
	// When entering in numerical inputs, ensure that only digits and a single decimal point are allowed
	const handleChange = (e: any, setter: any) => {
		const inputValue = e.target.value.replace(/[^0-9.]/g, "");
		const regexTest = /^\d{0,6}(\.\d{0,2})?$/;
		if (regexTest.test(inputValue)) {
			const formattedValue = formatNumberWithCommas(inputValue);
			setter(formattedValue);
		}
	};

	const handleChangeSDTaxExemptSales = (e: any) => {
		const inputValue = e.target.value.replace(/[^0-9.]/g, "");
		const regexTest = /^\d{0,6}(\.\d{0,2})?$/;
		if (regexTest.test(inputValue)) {
			const formattedValue = formatNumberWithCommas(inputValue);
			if (!taxExemptSalesEdited) {
				setSDTaxExemptSales(formattedValue);
				// Tax-Exempt Sales = (taxExemptSales - SDTaxExemptSales from API) + (user inputed SDTaxExemptSales *.5)
				const tempTaxExemptSales =
					customParseFloat(defaultTaxExemptSales) -
					customParseFloat(defaultSDTaxExemptSales) +
					customParseFloat(formattedValue) * 0.5;
				setTaxExemptSales(
					roundToPrecision(tempTaxExemptSales, 5).toString()
				);
			} else {
				setSDTaxExemptSales(formattedValue);
			}
		}
	};

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	return (
		<>
			{!salesEditing && (
				<FinancialSalesCard>
					<HeaderBar>
						<StyledHeaderHeading>Totals</StyledHeaderHeading>
						<WhiteButton
							whiteBackground={true}
							handleClick={() => {
								trackViewSalesDetails();
								handleViewSalesDetails();
							}}
						>
							{" "}
							View Sales Details
						</WhiteButton>
					</HeaderBar>
					<StyledContents>
						<CollapsedContentRow>
							<div>
								<ContentHeading>
									Gross Sales Total <br />
								</ContentHeading>
								<ContentSubHeading>
									(Total of all tenders excluding unredeemed
									Share the Fair contributions and Gift
									Certificate purchases)
								</ContentSubHeading>
							</div>
							<ContentValue>
								{formatter.format(grossSalesTotal)}
							</ContentValue>
						</CollapsedContentRow>
						<ContentSeparator />
						<CollapsedContentRow>
							<ContentHeading>Tax-Exempt Sales</ContentHeading>
							<ContentValue>
								{formatter.format(floatTaxExemptSales)}
							</ContentValue>
						</CollapsedContentRow>
						<ContentSeparator />
						<CollapsedContentRow>
							<ContentHeading>Tax Total</ContentHeading>
							<ContentValue>
								{formatter.format(taxTotal)}
							</ContentValue>
						</CollapsedContentRow>
						<ContentSeparator />
						<CollapsedContentRow>
							<ContentHeading>
								Total Scholastic Dollars Redeemed
							</ContentHeading>
							<ContentValue>
								{formatter.format(floatSDRedeemed)}
							</ContentValue>
						</CollapsedContentRow>
					</StyledContents>
				</FinancialSalesCard>
			)}
			{salesEditing && (
				<>
					<FinancialSalesCard>
						<HeaderBar>
							<StyledHeaderHeading>Totals</StyledHeaderHeading>
						</HeaderBar>
						<StyledContents>
							<ContentRow>
								<ContentHeading>
									<b>Scholastic Dollars</b>
								</ContentHeading>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Total Scholastic Dollars Redeemed
								</ContentHeading>
								<DollarInput
									type="text"
									value={
										"$" + formatStringWithCommas(SDRedeemed)
									}
									onChange={(e) => {
										handleChange(e, setSDRedeemed);
									}}
									placeholder="$0.00"
								/>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ErrorHeading>
									Tax-Exempt Scholastic Dollar Sales
								</ErrorHeading>
								<ContentName>
									<DollarInput
										errorState={
											salesInfoErrors.taxExemptSDExceeds
										}
										type="text"
										value={
											"$" +
											formatStringWithCommas(
												SDTaxExemptSales
											)
										}
										onChange={(e) =>
											handleChangeSDTaxExemptSales(e)
										}
										placeholder="$0.00"
									/>
									{salesInfoErrors.taxExemptSDExceeds && (
										<ErrorText>
											This total cannot exceed the total
											amount of Scholastic Dollars
											redeemed.
										</ErrorText>
									)}
								</ContentName>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Taxable Scholastic Dollars Sales
								</ContentHeading>
								<ContentName>
									{formatter.format(SDTaxableSales)}
								</ContentName>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Tax Collected in Scholastic Dollars
								</ContentHeading>
								<ContentName>
									${formatStringWithCommas(SDTaxCollected)}
								</ContentName>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Cash Equivalent (Redeemed x 50%)
								</ContentHeading>
								<ContentName>
									{formatter.format(SDCashEquivalent)}
								</ContentName>
							</ContentRow>
							<ContentSeparator />
						</StyledContents>
						<StyledContents>
							<ContentRow>
								<ContentHeading>
									<b>Tender Totals</b>
								</ContentHeading>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ErrorHeading>Cash & Checks</ErrorHeading>
								<ContentName>
									<DollarInput
										errorState={
											salesInfoErrors.tenderTotalZero ||
											salesInfoErrors.tenderTotalExceedsSTF
										}
										type="text"
										value={
											"$" +
											formatStringWithCommas(cashChecks)
										}
										onChange={(e) =>
											handleChange(e, setCashChecks)
										}
										placeholder="$0.00"
									/>
									{salesInfoErrors.tenderTotalExceedsSTF && (
										<ErrorText>
											Totals of Cash & Checks + Credit
											Cards + Purchase Orders must exceed
											the Share the Fair Collected total.
										</ErrorText>
									)}
								</ContentName>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<DoubleErrorHeading>
									Credit Cards <br />
									<ContentHeadingFine>
										(Including eWallet & eGift
										Card/Campaign)
									</ContentHeadingFine>
								</DoubleErrorHeading>
								<ContentName>
									<DollarInput
										errorState={
											salesInfoErrors.tenderTotalZero ||
											salesInfoErrors.tenderTotalExceedsSTF
										}
										type="text"
										value={
											"$" +
											formatStringWithCommas(creditCards)
										}
										onChange={(e) =>
											handleChange(e, setCreditCards)
										}
										placeholder="$0.00"
									/>
									{salesInfoErrors.tenderTotalExceedsSTF && (
										<ErrorText>
											Totals of Cash & Checks + Credit
											Cards + Purchase Orders must exceed
											the Share the Fair Collected total.
										</ErrorText>
									)}
								</ContentName>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Purchase Orders <br />
									<ContentHeadingFine>
										(If you add a new Purchase Order and add
										amount this will reflect the total of
										all the Purchase Order inputted)
									</ContentHeadingFine>
								</ContentHeading>
								<ContentValue>
									{formatter.format(purchaseOrdersTotal)}
								</ContentValue>
							</ContentRow>
							<StyledCTAText>
								<AddPurchaseOrderButton
									onClick={() => {
										handleClickAddPurchaseOrder();
									}}
									disabled={disableAddPurchaseOrder}
								>
									<Icon src={NavExpand} alt="" />
									<div>ADD PURCHASE ORDER</div>
								</AddPurchaseOrderButton>
							</StyledCTAText>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Scholastic Dollars <br />
									<ContentHeadingFine>
										(Cash equivalent)
									</ContentHeadingFine>
								</ContentHeading>
								<ContentValue>
									{formatter.format(SDCashEquivalent)}
								</ContentValue>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Total <br />
									<ContentHeadingFine>
										(Includes Unredeemed Total)
									</ContentHeadingFine>
								</ContentHeading>
								<ContentValue>
									{formatter.format(SDTotal)}
								</ContentValue>
							</ContentRow>
						</StyledContents>
						<StyledContents>
							<ContentRow>
								<ContentHeading>
									<b>Gross Sales</b>
								</ContentHeading>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ErrorHeading>Tax-Exempt Sales</ErrorHeading>
								<ContentName>
									<DollarInput
										errorState={
											salesInfoErrors.taxExemptSalesExceeds
										}
										type="text"
										value={
											"$" +
											formatStringWithCommas(
												taxExemptSales
											)
										}
										onChange={(e) =>
											handleChange(
												e,
												handleSetTaxExemptSalesDirectly
											)
										}
										placeholder="$0.00"
									/>
									{salesInfoErrors.taxExemptSalesExceeds && (
										<ErrorText>
											This total cannot exceed the Tender
											Total amount.
										</ErrorText>
									)}
								</ContentName>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>Taxable Sales</ContentHeading>
								<ContentValue>
									{formatter.format(taxableSales)}
								</ContentValue>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Gross Sales Total
									<ContentHeadingFine>
										(Excludes Unredeemed Total)
									</ContentHeadingFine>
								</ContentHeading>
								<ContentValue>
									{formatter.format(grossSalesTotal)}
								</ContentValue>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Tax Total ({taxRate}%)
								</ContentHeading>
								<ContentValue>
									{formatter.format(taxTotal)}
								</ContentValue>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Taxable Sales
									<ContentHeadingFine>
										(Less Sales Tax)
									</ContentHeadingFine>
								</ContentHeading>
								<ContentValue>
									{formatter.format(taxableSalesLessSalesTax)}
								</ContentValue>
							</ContentRow>
						</StyledContents>
						<StyledContents>
							<ContentRow>
								<ContentHeading>
									<b>Net Sales</b>
								</ContentHeading>
							</ContentRow>
							<ContentRow>
								<ContentHeading>Discounts</ContentHeading>
								<ContentValue>
									{formatter.format(netSalesDiscounts)}
								</ContentValue>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Gift Certificates Purchased
								</ContentHeading>
								<ContentValue>
									{formatter.format(GCPurchased)}
								</ContentValue>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Gift Certificates Redeemed
									<ContentHeadingFine>
										(Included in Gross)
									</ContentHeadingFine>
								</ContentHeading>
								<ContentValue>
									{formatter.format(GCRedeemed)}
								</ContentValue>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Gift Certificates Unredeemed
								</ContentHeading>
								<ContentValue>
									{formatter.format(GCUnredeemed)}
								</ContentValue>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Share the Fair Funds Collected
									<FairFundsCollectedTooltip>
										Funds collected online and through your
										Payment Systems to benefit Share the
										Fair.
									</FairFundsCollectedTooltip>
									<StyledUnionButton
										src={union}
										alt="Info Button"
									/>
									<br />
									<ContentHeadingFine>
										(Included in Tender Totals)
									</ContentHeadingFine>
								</ContentHeading>
								<ContentValue>
									{formatter.format(floatSTFFCollected)}
								</ContentValue>
							</ContentRow>
							<ContentSeparator />
							<ContentRow>
								<ContentHeading>
									Share the Fair Funds Redeemed
									<FairFundsRedeemedTooltip>
										Share the Fair funds redeemed to
										complete transactions during the Fair.{" "}
									</FairFundsRedeemedTooltip>
									<StyledUnionButton
										src={union}
										alt="Info Button"
									/>
									<br />
									<ContentHeadingFine>
										(Included in Gross Sales)
									</ContentHeadingFine>
								</ContentHeading>
								<ContentValue>
									{formatter.format(floatSTFFRedeemed)}
								</ContentValue>
							</ContentRow>
							<ContentSeparator />
						</StyledContents>
						{salesInfoErrors.tenderTotalZero && (
							<GeneralErrorText>
								Please check all tender total fields and enter
								at least one value.
								<br />
								The total amount cannot be $0.00.
							</GeneralErrorText>
						)}
						{salesInfoErrors.generalError &&
							!salesInfoErrors.tenderTotalZero && (
								<GeneralErrorText>
									Correct the error above.
								</GeneralErrorText>
							)}
						<StyledSaveButton
							handleClick={() => {
								trackSaveSalesDetailsButton();
								handleClickSave();
							}}
						>
							Save
						</StyledSaveButton>
					</FinancialSalesCard>
				</>
			)}
		</>
	);
};

const FinancialSalesCard = styled.div`
	display: inline-flex;
	padding: 48px 80px;
	flex-direction: column;
	align-items: flex-start;
	gap: 35px;
	border-radius: 16px;
	background: #f5f5f5;
	margin-top: 33px;
	@media (max-width: 700px) {
		padding: 48px 25px;
	}
`;

const HeaderBar = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	@media (max-width: 500px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
	}
`;

const StyledTooltip = styled.div`
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s;
	font-family: museo-sans;
	position: absolute;
	background: #333333;
	color: #ffffff;
	border-radius: 4px;
	padding: 5px;
	text-align: center;
	font-size: 13px;
	line-height: 15px;
	font-family: "museo-sans";
	weight: 400;
	box-shadow: 0 0 6px #d7d7d7;
	top: 25px;
	z-index: 1000;
	width: 240px;
	left: 26%;
	@media (min-width: 700px) {
		::after {
			// CSS for triangle on top of tooltip
			content: " ";
			position: absolute;
			top: -15%; /* Place at the top of the tooltip */
			rotate: 180deg;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: #333333 transparent transparent transparent;
		}
	}
	@media (max-width: 700px) {
		left: 0%;
	}
`;

const FairFundsCollectedTooltip = styled(StyledTooltip)``;

const FairFundsRedeemedTooltip = styled(StyledTooltip)`
	left: 28%;
	@media (max-width: 700px) {
		left: 0%;
	}
`;

const StyledContents = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	width: 100%;
`;

const ContentSeparator = styled.div`
	width: 100%;
	height: 1px;
	background: #d7d7d7;
`;

const StyledHeaderHeading = styled(Heading)`
	font-size: 28px;
	font-style: normal;
	font-weight: 300;
	line-height: 32px;
	padding: 0px;
`;

const CollapsedContentRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	@media (max-width: 500px) {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 4px;
	}
`;

const ContentRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	position: relative;
	&:hover {
		${StyledTooltip} {
			visibility: visible;
			opacity: 1;
		}
	}
	@media (max-width: 500px) {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 8px;
	}
`;

export const ContentName = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
`;

export const ContentHeading = styled(BodyText)`
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
	text-align: left;
`;

const ContentSubHeading = styled.p`
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
	max-width: 250px;
`;

const ContentValue = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
`;

const ContentHeadingFine = styled(BodyText)`
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
	text-align: left;
	max-width: 260px;
`;

export const DollarInput = styled.input<{ errorState?: boolean }>`
	border-radius: 4px;
	border: 1px solid;
	border-color: ${(props) => (props.errorState ? "#e81111" : "#919191")};
	background: #ffffff;
	box-sizing: border-box;
	display: flex;
	height: 40px;
	width: 120px;
	padding: 16px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
	font-family: museo-sans;
`;

const StyledCTAText = styled.p`
	color: #e81111;
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	text-align: right;
	align-self: flex-end;
	align-items: center;
	@media (max-width: 500px) {
		text-align: left;
		align-self: flex-start;
	}
`;

const StyledSaveButton = styled(RedButton)`
	padding: 14px 44.5px;
	align-items: center;
	align-self: flex-end;
`;

const StyledUnionButton = styled.img`
	position: relative;
	top: 2px;
	left: 6px;
`;

export const ErrorText = styled.p`
	color: #e81111;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	max-width: 120px;
`;

export const ErrorHeading = styled(ContentHeading)`
	align-self: flex-start;
	position: relative;
	top: 10.5px;
	@media (max-width: 500px) {
		top: 0px;
	}
`;

const DoubleErrorHeading = styled(ErrorHeading)`
	top: 0px;
`;

const GeneralErrorText = styled(ErrorText)`
	max-width: 100%;
	align-self: flex-end;
	text-align: right;
`;

const Icon = styled.img``;

const AddPurchaseOrderButton = styled.button`
	display: flex;
	align-items: center;
	color: #e81111;
	outline-color: #e81111;
	outline-offset: 3px;
	background: none;
	border: none;
	gap: 5px;
	height: 40px;
	color: #e81111;
	font-family: museo-sans;
	font-size: 11px;
	font-style: normal;
	font-weight: 600;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	${Icon} {
		filter: invert(20%) sepia(32%) saturate(7450%) hue-rotate(348deg)
			brightness(80%) contrast(114%);
	}
	&:hover {
		cursor: pointer;
		color: #b90000;
		${Icon} {
			filter: invert(7%) sepia(86%) saturate(6492%) hue-rotate(359deg)
				brightness(109%) contrast(108%);
		}
	}
	&:focus-visible {
		outline: 2px solid #e81111;
		color: #b90000;
		outline-offset: 2px;
		${Icon} {
			filter: invert(7%) sepia(86%) saturate(6492%) hue-rotate(359deg)
				brightness(109%) contrast(108%);
		}
	}
	&:active {
		outline: none;
		color: #660000;
		${Icon} {
			filter: invert(9%) sepia(49%) saturate(5332%) hue-rotate(352deg)
				brightness(94%) contrast(115%);
		}
	}
	&:disabled {
		filter: grayscale(100%);
		cursor: unset;
		&:hover {
			color: #e81111;
		}
	}
`;
