import React from "react";
import styled from "styled-components";
import ScholasticLogoImage from "images/ScholasticLogoEWallet.svg";
import BookfairLogoImage from "images/BookFairLogo.svg";
import EWalletLogoImage from "images/EWalletLogo.svg";

interface Props {
	schoolName: string;
}

const TopLeftCard = ({ schoolName }: Props) => {
	return (
		<Container className="gridItem">
			<LogoContainer>
				<ScholasticLogo src={ScholasticLogoImage} />
				<BookfairLogo src={BookfairLogoImage} />
			</LogoContainer>
			<SchoolName>{schoolName}</SchoolName>
			<BookFairEwalletText>Book Fair eWallet</BookFairEwalletText>
			<EWalletLogo src={EWalletLogoImage} />
		</Container>
	);
};

const Container = styled.div`
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 1;
	grid-row-end: 2;
	transform: rotate(-180deg);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	position: relative;
	border-left: 2px dotted rgba(51, 51, 51, 0.5);
	margin-bottom: 1px;
	margin-left: 0.5px;
`;

const LogoContainer = styled.div`
	display: flex;
	gap: 11px;
	margin: 28px 0px 9px 0px;
`;

const ScholasticLogo = styled.img`
	width: 125px;
	transform: rotate(-180deg);
`;

const BookfairLogo = styled.img`
	width: 100px;
	transform: rotate(-180deg);
`;

const SchoolName = styled.div`
	color: #333;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19px;
	color: #333;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
`;

const BookFairEwalletText = styled(SchoolName)``;

const EWalletLogo = styled.img`
	transform: rotate(-180deg);
	margin-top: 41px;
`;

export default TopLeftCard;
