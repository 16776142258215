import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import Heading from "components/Heading";
import BodyText from "components/BodyText";
import FFEnvelope from "images/FFEnvelope.svg";
import FFCreditCard from "images/FFCreditCard.svg";
import FFCreditCardDisabled from "images/FFCreditCardDisabled.svg";
import FFComment from "images/FFComment.svg";
import { useReactToPrint } from "react-to-print";
import RegisterInvoice from "../Invoice/RegisterInvoice";
import {
	FinFormUrls,
	FinFormProgress,
	checkFinFormUrlsLocalStorage,
	getFinFormProgress,
	setFinFormCurrentPageLocalStorage,
} from "utils/FinFormLocalStorageUtility";
import { useNavigate } from "react-router-dom";
import { useConfig } from "providers/ConfigContext";
import {
	trackConfirmationPageGiveFeedback,
	trackConfirmationPagePayWithCard,
	trackConfirmationPagePrintInvoiceButton,
} from "DumbleData";

interface Props {
	fairId: string;
	payAmount: number;
	paymentAvailable: boolean;
}

export const FinancialFormConfirmationContent = ({
	fairId,
	payAmount,
	paymentAvailable,
}: Props) => {
	// to be replaced with actual fair id returned from API header
	const { PaymentPortalOrigin, AEMPublishOrigin } = useConfig();
	/**
	 * Logic to make the invoice a printable component
	 */
	const componentRef = useRef<HTMLDivElement>(null);
	const [printClicked, setPrintClicked] = useState(false);

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	const navigate = useNavigate();

	const handlePrint = () => {
		trackConfirmationPagePrintInvoiceButton();
		openPrintDialogue();
		setPrintClicked(false);
	};

	const handleClickGiveFeedback = () => {
		trackConfirmationPageGiveFeedback();
		window.open(
			`${AEMPublishOrigin}/content/fairs/hub/give-feedback.html`,
			"_blank",
			"noopener noreferrer"
		);
	};

	const openPrintDialogue = useReactToPrint({
		content: () => componentRef.current as HTMLElement,
	});

	const handleClickPrint = () => {
		setPrintClicked(true);
	};

	const handleClickPay = () => {
		trackConfirmationPagePayWithCard();
		window.open(
			`${PaymentPortalOrigin}creditcardpayment.html?type=payoff&fairId=${fairId}&amount=${payAmount}`,
			"_blank"
		);
	};

	// if user tries to directly access the /sales page without completing previous steps, redirect them to the first incomplete step
	useEffect(() => {
		const progress = getFinFormProgress(fairId);
		if (progress < FinFormProgress.Confirmation) {
			const savedFinFormLocation = checkFinFormUrlsLocalStorage(fairId);
			if (savedFinFormLocation) {
				navigate(savedFinFormLocation);
			}
		} else {
			setFinFormCurrentPageLocalStorage(fairId, FinFormUrls.Confirmation);
		}
	}, [navigate, fairId]);

	return (
		<>
			<InvoicePDF>
				<div ref={componentRef}>
					{/* render legal copy after print button is clicked */}
					{printClicked && (
						<RegisterInvoice
							handlePrint={handlePrint}
							printClicked={printClicked}
						/>
					)}
				</div>
			</InvoicePDF>
			<FFConfirmation>
				<StyledHeading>
					Thank you for submitting your Financial Form
				</StyledHeading>
				<StyledBodyText>
					Please choose the payment method for your Book Fair invoice.
					Scholastic Dollars profit will be issued once Fair payment
					has been received in full and processed.
				</StyledBodyText>
				<TotalDue>Total due: {formatter.format(payAmount)}</TotalDue>
				<Row>
					<Card onClick={handleClickPrint}>
						<CardImg src={FFEnvelope} />
						<CardText>Print invoice and mail with payment</CardText>
					</Card>
					{paymentAvailable ? (
						<Card onClick={handleClickPay}>
							<CardImg src={FFCreditCard} />
							<CardText>Pay with credit card</CardText>
						</Card>
					) : (
						<Card>
							<CardImg src={FFCreditCardDisabled} />
							<CardTextDisabled>
								Pay with credit card
							</CardTextDisabled>
							<ContactText>
								For questions on completing your Financial Form,
								contact Customer Success at 877-245-0903 or{" "}
								<Email>CPtoolkit@scholasticbookfairs.com</Email>
								.
							</ContactText>
						</Card>
					)}
				</Row>
				<FeedBackButton onClick={handleClickGiveFeedback}>
					<img src={FFComment} alt="" />
					<p>Give feedback</p>
				</FeedBackButton>
			</FFConfirmation>
		</>
	);
};
const InvoicePDF = styled.div`
	display: none;
`;

const FFConfirmation = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 56px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 64px;
	gap: 40px;
	@media (max-width: 675px) {
		flex-direction: column;
		gap: 16px;
	}
`;
const Card = styled.button`
	width: 280px;
	height: 280px;
	flex-shrink: 0;
	border-radius: 16px;
	background: #f5f5f5;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: none;
	cursor: pointer;
`;
const StyledHeading = styled(Heading)`
	text-align: center;
	font-size: 39px;
	font-style: normal;
	font-weight: 300;
	line-height: 42px;
	max-width: 600px;
	margin-top: 56px;
	@media (max-width: 675px) {
		max-width: 300px;
	}
`;
const StyledBodyText = styled(BodyText)`
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	max-width: 397px;
	margin-top: 26px;
	@media (max-width: 675px) {
		max-width: 300px;
	}
`;
const TotalDue = styled(BodyText)`
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	max-width: 397px;
	margin-top: 44px;
`;
const CardImg = styled.img`
	width: 77px;
	height: 48px;
	margin-top: 80px;
`;
const CardText = styled(BodyText)`
	color: #e81111;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
	margin-top: 41px;
	max-width: 159px;
`;

const CardTextDisabled = styled(CardText)`
	color: #666666;
`;

const ContactText = styled.div`
	color: #333;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
	width: 229px;
	height: 60px;
	margin-top: 15px;
	text-align: left;
`;

const Email = styled.div`
	text-decoration: underline;
	color: #333;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;
`;

const FeedBackButton = styled.div`
	color: #e81111;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
	display: flex;
	flex-direction: row;
	gap: 11px;
	border: none;
	background: none;
	margin-top: 56px;
	&:hover {
		cursor: pointer;
	}
`;
