import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
	initHomepageBuilderDumbleData,
	initCPTKDumbleData,
	initializeScripts,
} from "DumbleData";

const cptkPages = new Set([
	"/reactapp/fair-details",
	"/reactapp/confirm-fair",
	"/reactapp/ewallet-roster",
	"/reactapp/sales-history",
	"/reactapp/sd-balance",
]);
if (
	cptkPages.has(window.location.pathname) ||
	window.location.pathname.includes("financial-form")
) {
	initCPTKDumbleData();
} else {
	initHomepageBuilderDumbleData();
}
initializeScripts();

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
