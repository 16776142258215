import { useState, useEffect } from "react";
import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { updateEwalletData, updateFairData } from "DumbleData";
import {
	handleCOASuccess,
	handleGenericErrors,
	handleGenericSuccess,
} from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

export type EWalletRoster = {
	fairId: string;
	unspentFunds: string;
	sales: string;
	ewallets: Array<EWallet>;
};

export type EWallet = {
	id: number;
	balance: number;
	recipientType: string;
	attributes: {
		recipientFirstName: string;
		recipientLastName: string;
		teacherLastName: string;
		grade: string;
		barcode: string;
	};
	transactions: Array<Transaction>;
};
export type Transaction = {
	amount: number;
	createdOn: string;
	orderId: string;
	type: string;
};
const eWalletRosterDefault = {
	fairId: "",
	unspentFunds: "",
	sales: "",
	ewallets: [],
};

const useEWalletRosterData = () => {
	const { bookfairsAPI } = useConfig();

	const [eWalletRosterData, setEWalletRosterData] =
		useState<EWalletRoster>(eWalletRosterDefault);

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const getEWalletRosterData = async () => {
			const currentFair = getFairIdFromUrl();
			if (checkSpsUd()) {
				try {
					await axios
						.get(
							`${bookfairsAPI}/private/fairs/${currentFair}/ewallet/roster`,
							{
								withCredentials: true,
							}
						)
						.then((response) => {
							if (response.status === 200) {
								if (response.data) {
									setEWalletRosterData(response.data);
									updateFairData(
										response.data.fairId,
										response.data.fairType
									); // currently missing fair type
									updateEwalletData(
										response.data.sales,
										response.data.unspentFunds
									);
								}
							} else {
								if (!handleCOASuccess(response)) {
									handleGenericSuccess(response);
								}
							}
						})
						.catch((err) => {
							setIsError(true);
							handleGenericErrors(err);
						});
				} catch (err: any) {
					setIsError(true);
					handleGenericErrors(err);
				} finally {
					setIsLoading(false);
				}
			}
		};
		getEWalletRosterData();
	}, [bookfairsAPI, setEWalletRosterData]);
	return {
		eWalletRosterData,
		isLoading,
		isError,
	};
};
export default useEWalletRosterData;
