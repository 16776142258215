import {
	updateFairData,
	updateSchoolBasicData,
	updateSchoolData,
} from "DumbleData";
import axios from "axios";
import {
	ChairpersonData,
	ConsultantData,
	FairInfoData,
	OrganizationData,
} from "hooks/ConfirmYourFair/useCoaPageData";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";
const fairInfoDefault = {
	fairId: 0,
	name: "",
	startDate: "",
	endDate: "",
	deliveryDate: "",
	pickupDate: "",
	fairType: "",
	remoteRoutingWindowFlag: "",
	coaStatus: "",
	coaAcceptedDate: "",
	taxRate: 0,
};

const chairpersonDataDefault = {
	firstName: "",
	lastName: "",
	email: "",
	phoneNumber: "",
	phoneExtension: "",
};

const consultantDataDefault = {
	firstName: "",
	lastName: "",
	email: "",
	phoneNumber: "",
};

const organizationDataDefault = {
	ucn: "",
	name: "",
	addressLine1: "",
	addressLine2: "",
	city: "",
	state: "",
	zipcode: "",
	schoolType: "",
	taxStatus: "",
	bookfairAccountId: "",
};

interface SalesHistoryButtonProps {
	withToken: boolean;
	fairId?: string;
	schoolId?: string;
}

const useSalesHistoryCOAButtonData = (props: SalesHistoryButtonProps) => {
	const { bookfairsAPI } = useConfig();

	const [fairInfoData, setFairInfoData] =
		useState<FairInfoData>(fairInfoDefault);
	const [chairpersonData, setChairpersonData] = useState<ChairpersonData>(
		chairpersonDataDefault
	);
	const [consultantData, setConsultantData] = useState<ConsultantData>(
		consultantDataDefault
	);
	const [organizationData, setOrganizationData] = useState<OrganizationData>(
		organizationDataDefault
	);

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		if (props.schoolId !== "") {
			const getSalesHistoryCOAButtonData = async () => {
				const currentFair = props.fairId
					? props.fairId
					: getFairIdFromUrl();
				if (checkSpsUd()) {
					try {
						await axios
							.get(
								`${bookfairsAPI}/user/schools/${props.schoolId}/fairs/${currentFair}/coa`,
								{ withCredentials: true }
							)
							.then((response) => {
								if (response.status === 200) {
									const {
										fairInfo,
										chairperson,
										consultant,
										organization,
									} = response.data;

									if (fairInfo) {
										setFairInfoData(fairInfo);
										if (!props.withToken && !props.fairId) {
											updateFairData(
												fairInfo.fairId,
												fairInfo.fairType
											);
										}
									}
									if (chairperson) {
										setChairpersonData(chairperson);
									}
									if (consultant) {
										setConsultantData(consultant);
									}
									if (organization) {
										setOrganizationData(organization);
										if (!props.withToken && !props.fairId) {
											updateSchoolData(
												organization.schoolType
											);
											updateSchoolBasicData(
												organization.name
											);
										}
									}
								} else {
									handleGenericSuccess(response);
								}
							})
							.catch((err) => {
								setIsError(true);
								handleGenericErrors(err);
							});
					} catch (err: any) {
						handleGenericErrors(err);
						setIsError(true);
						console.error(err);
					} finally {
						setIsLoading(false);
					}
				}
			};
			getSalesHistoryCOAButtonData();
		}
	}, [bookfairsAPI, props.withToken, props.fairId, props.schoolId]);
	return {
		fairInfoData,
		chairpersonData,
		consultantData,
		organizationData,
		isLoading,
		isError,
	};
};

export default useSalesHistoryCOAButtonData;
