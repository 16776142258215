import React, { useRef, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import RedBullet from "images/RedBullet.svg";

interface Props {
	children: React.ReactNode;
	to: string;
	handleNextLocation(nextLocation: string): any;
}

const NavElement = ({ children, to, handleNextLocation }: Props) => {
	// Determine if this NavElement's path matches the current path
	const resolved = useResolvedPath(to);
	const match = useMatch({ path: resolved.pathname, end: true });

	//Scroll the nav element into view when focused on mobile
	const navElementRef = useRef<HTMLSpanElement>(null);
	const nextLocation = to;

	const scrollIntoView = useCallback(() => {
		if (navElementRef.current !== null) {
			navElementRef.current.scrollIntoView({
				block: "center",
				behavior: "smooth",
			});
		}
	}, []);

	useEffect(() => {
		if (match) {
			scrollIntoView();
		}
	}, [match, scrollIntoView]);

	return (
		<StyledNavElement
			ref={navElementRef}
			onClick={() => {
				handleNextLocation(nextLocation);
			}}
			onFocus={scrollIntoView}
		>
			<StyledBullet
				src={RedBullet}
				style={{ opacity: match ? "1" : "0" }}
			/>
			<StyledLink to={to} className={match ? "active" : ""}>
				{children}
			</StyledLink>
		</StyledNavElement>
	);
};

const StyledNavElement = styled.span`
	display: inline-flex;
	align-items: center;
	gap: 8px;
`;

const StyledBullet = styled.img`
	height: 8px;
	@media (max-width: 959px) {
		display: none;
	}
`;

const StyledLink = styled(Link)`
	display: inline;
	font-family: museo-sans;
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: #919191;
	text-decoration: none;
	z-index: 1;
	&.active {
		color: #e81111;
		font-weight: 600;
		@media (max-width: 959px) {
			border-bottom: 2px solid #e81111;
		}
	}
	&:hover {
		color: #e81111;
		font-weight: 600;
	}
	&:active {
		color: #b90000;
		font-weight: 600;
	}
	&:focus-visible {
		color: #e81111;
		font-weight: 600;
		border: 2px solid #e81111;
		border-radius: 4px;
		padding: 1px;
		margin: -4px;
		outline: none;
	}
	@media (max-width: 959px) {
		padding-bottom: 4px;
		border-bottom: 2px solid #e8e8e8;
		&:focus-visible {
			margin: -3px;
		}
	}
`;

export default NavElement;
