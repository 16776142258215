import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import "styles/calendar.css";

interface Props {
	value?: Date;
	minDate: Date;
	maxDate: Date;
	activeStartDateChange: Date;
	calendarEnabled: boolean;
	handleSelectDate: (
		date: Date,
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => void;
	tileDisabled: ({ date, view }: { date: Date; view: string }) => boolean;
	getClassNames: ({
		date,
		view,
	}: {
		date: Date;
		view: string;
	}) => string[] | string | undefined | null;
	activeStartDate: Date;
	setActiveStartDate: React.Dispatch<React.SetStateAction<Date>>;
}

const BlackoutCalendarSingleMonth = ({
	value,
	minDate,
	maxDate,
	activeStartDateChange,
	calendarEnabled,
	handleSelectDate,
	tileDisabled,
	getClassNames,
	activeStartDate,
	setActiveStartDate,
}: Props) => {
	return (
		<>
			<StyledCalendar
				calendarEnabled={calendarEnabled}
				value={value}
				minDate={minDate}
				maxDate={maxDate}
				showFixedNumberOfWeeks={false}
				calendarType="gregory"
				showNavigation={false}
				tileDisabled={tileDisabled}
				tileClassName={getClassNames}
				showNeighboringMonth={false}
				formatShortWeekday={(locale, value) => {
					return new Intl.DateTimeFormat(locale, {
						weekday: "narrow",
					}).format(value);
				}}
				onClickDay={(date, event) => {
					if (calendarEnabled) {
						handleSelectDate(date, event);
					}
				}}
				goToRangeStartOnSelect={false}
				activeStartDate={activeStartDate}
				onActiveStartDateChange={(data) => {
					if (data.action !== "onChange") {
						setActiveStartDate(activeStartDateChange);
					}
				}}
			/>
		</>
	);
};

interface CalendarProps {
	calendarEnabled: boolean;
}

const StyledCalendar = styled(Calendar)<CalendarProps>`
	cursor: ${(props) => (props.calendarEnabled ? "auto" : "not-allowed")};
`;

export default BlackoutCalendarSingleMonth;
