import React from "react";
import styled from "styled-components";
import Step from "../../images/NavStep.svg";
import RedStep from "../../images/NavStepRed.svg";
import { useNavigate } from "react-router-dom";
import {
	getFinFormProgress,
	FinFormProgress,
	FinFormUrls,
} from "utils/FinFormLocalStorageUtility";
import useFairBasicInfo from "hooks/FinancialForm/useFairBasicInfo";
import { trackFinFormNavigationClick } from "DumbleData";

interface Props {
	activeLink: number;
	fairId: string;
	setDestination?: any;
}

const Navigation = ({ activeLink, fairId, setDestination }: Props) => {
	const navigate = useNavigate();

	const progress = getFinFormProgress(fairId);

	const { supressEarnings, isLoading } = useFairBasicInfo({ fairId });

	// Navigate to relevant financial form steps
	const navigateToPage = (destination: string) => {
		setDestination?.(destination);
		if (
			destination === FinFormUrls.Intro &&
			progress >= FinFormProgress.Intro
		) {
			trackFinFormNavigationClick("Intro Page");
			navigate(destination);
		} else if (
			destination === FinFormUrls.Sales &&
			progress >= FinFormProgress.Sales
		) {
			trackFinFormNavigationClick("Sales Page");
			navigate(destination);
		} else if (
			destination === FinFormUrls.Spending &&
			progress >= FinFormProgress.Spending
		) {
			trackFinFormNavigationClick("Spending Page");
			navigate(destination);
		} else if (
			destination === FinFormUrls.Earnings &&
			progress >= FinFormProgress.Earnings
		) {
			trackFinFormNavigationClick("Earnings Page");
			navigate(destination);
		} else if (
			destination === FinFormUrls.Review &&
			progress >= FinFormProgress.Review
		) {
			trackFinFormNavigationClick("Review Page");
			navigate(destination);
		}
	};

	const navigateViaKeyPress = (
		e: React.KeyboardEvent,
		destination: string
	) => {
		if (e.key === "Enter") {
			navigateToPage(destination);
		}
	};

	return (
		<>
			{!isLoading && (
				<NavigationCol>
					<Link
						onClick={() => navigateToPage(FinFormUrls.Sales)}
						onKeyDown={(e) =>
							navigateViaKeyPress(e, FinFormUrls.Sales)
						}
						completed={progress >= FinFormProgress.Sales}
						active={activeLink === FinFormProgress.Sales}
						tabIndex={
							progress >= FinFormProgress.Sales
								? FinFormProgress.Sales
								: -1
						}
					>
						{FinFormProgress.Sales}. Sales
					</Link>
					<NavStep
						onClick={() => navigateToPage(FinFormUrls.Sales)}
						onKeyDown={(e) =>
							navigateViaKeyPress(e, FinFormUrls.Sales)
						}
						tabIndex={
							progress >= FinFormProgress.Sales
								? FinFormProgress.Sales
								: -1
						}
					>
						<img
							alt={"Sales Link"}
							src={
								activeLink === FinFormProgress.Sales
									? RedStep
									: Step
							}
						/>
					</NavStep>
					<Link
						onClick={() => navigateToPage(FinFormUrls.Spending)}
						onKeyDown={(e) =>
							navigateViaKeyPress(e, FinFormUrls.Spending)
						}
						completed={progress >= FinFormProgress.Spending}
						active={activeLink === FinFormProgress.Spending}
						tabIndex={
							progress >= FinFormProgress.Spending
								? FinFormProgress.Spending
								: -1
						}
					>
						{FinFormProgress.Spending}. Fair Spending
					</Link>
					<NavStep
						onClick={() => navigateToPage(FinFormUrls.Spending)}
						onKeyDown={(e) =>
							navigateViaKeyPress(e, FinFormUrls.Spending)
						}
						tabIndex={
							progress >= FinFormProgress.Spending
								? FinFormProgress.Spending
								: -1
						}
					>
						<img
							alt={"Spending Link"}
							src={
								activeLink === FinFormProgress.Spending
									? RedStep
									: Step
							}
						/>
					</NavStep>
					{!supressEarnings && (
						<>
							<Link
								onClick={() =>
									navigateToPage(FinFormUrls.Earnings)
								}
								onKeyDown={(e) =>
									navigateViaKeyPress(e, FinFormUrls.Earnings)
								}
								completed={progress >= FinFormProgress.Earnings}
								active={activeLink === FinFormProgress.Earnings}
								tabIndex={
									progress >= FinFormProgress.Earnings
										? FinFormProgress.Earnings
										: -1
								}
							>
								{FinFormProgress.Earnings}. Fair Earnings
							</Link>
							<NavStep
								onClick={() =>
									navigateToPage(FinFormUrls.Earnings)
								}
								onKeyDown={(e) =>
									navigateViaKeyPress(e, FinFormUrls.Earnings)
								}
								tabIndex={
									progress >= FinFormProgress.Earnings
										? FinFormProgress.Earnings
										: -1
								}
							>
								<img
									alt={"Earning Link"}
									src={
										activeLink === FinFormProgress.Earnings
											? RedStep
											: Step
									}
								/>
							</NavStep>
						</>
					)}
					<Link
						onClick={() => navigateToPage(FinFormUrls.Review)}
						onKeyDown={(e) =>
							navigateViaKeyPress(e, FinFormUrls.Review)
						}
						completed={progress >= FinFormProgress.Review}
						active={activeLink === FinFormProgress.Review}
						tabIndex={
							progress >= FinFormProgress.Review
								? FinFormProgress.Review
								: -1
						}
					>
						{supressEarnings ? "3. Review" : "4. Review"}
					</Link>
					<NavStep
						onClick={() => navigateToPage(FinFormUrls.Review)}
						onKeyDown={(e) =>
							navigateViaKeyPress(e, FinFormUrls.Review)
						}
						tabIndex={
							progress >= FinFormProgress.Review
								? FinFormProgress.Review
								: -1
						}
					>
						<img
							alt={"Review Link"}
							src={
								activeLink === FinFormProgress.Review
									? RedStep
									: Step
							}
						/>
					</NavStep>
				</NavigationCol>
			)}
		</>
	);
};

const NavigationCol = styled.div`
	/* test margin */
	margin: 20px 0px;
	display: flex;
	flex-direction: column;
	height: 100%;
	row-gap: 15px;
	color: #333;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: 16px;
	position: fixed;
	left: 107px;
	@media (max-width: 1150px) {
		left: 80px;
	}
	@media (max-width: 1030px) {
		left: 40px;
	}
	@media (max-width: 950px) {
		position: static;
		flex-direction: row;
		gap: 14px;
		justify-content: center;
	}
`;

interface LinkProps {
	active: boolean;
	completed?: boolean;
}

// Determines which color the link should be depending on whether it is active or complete
const determineColor = (active: boolean, completed: boolean | undefined) => {
	if (active) {
		return "#E81111";
	} else if (completed) {
		return "#333333";
	} else {
		return "#919191";
	}
};

const Link = styled.a<LinkProps>`
	color: ${(props) => determineColor(props.active, props.completed)};
	outline-color: #e81111;
	text-decoration: none;
	:hover {
		color: ${(props) =>
			props.active || props.completed ? "#e81111" : "#919191"};
		cursor: ${(props) =>
			props.active || !props.completed ? "default" : "pointer"};
		text-decoration: ${(props) =>
			props.active || !props.completed ? "none" : "underline"};
	}
	@media (max-width: 500px) {
		display: none;
	}
`;

const NavStep = styled.a`
	display: none;
	outline-color: #e81111;
	:active {
		filter: invert(28%) sepia(91%) saturate(4323%) hue-rotate(347deg)
			brightness(84%) contrast(119%);
	}
	@media (max-width: 500px) {
		display: block;
	}
`;

export default Navigation;
