import React, { useEffect, useRef, useState } from "react";
import BodyText from "components/BodyText";
import { RedButton, WhiteButton } from "components/Button";
import Heading from "components/Heading";
import Subheading from "components/Subheading";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navigation";
import { EarningsCalculator } from "./EarningsCalculations";
import { earningsInfo } from "hooks/FinancialForm/useEarningsData";
import {
	FinFormProgress,
	FinFormUrls,
	checkFinFormUrlsLocalStorage,
	getFinFormProgress,
	setFinFormCurrentPageLocalStorage,
	setFinFormUrlsLocalStorage,
} from "utils/FinFormLocalStorageUtility";
import axios from "axios";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { useConfig } from "providers/ConfigContext";
import Prompt from "components/HomepageBuilder/Prompt";
import FinancialFormSaveChangesModal from "../FinancialFormSaveChangesModal";
import useFairBasicInfo from "hooks/FinancialForm/useFairBasicInfo";
import {
	trackFairEarningsBackButton,
	updateFinancialFormEarningsData,
	handleTrackFairEarningsContinueButton,
} from "DumbleData";

interface Props {
	earningsInfo: earningsInfo;
	fairId: string;
}

export const FinancialFormEarningsContent = (earningsInfo: Props) => {
	const { bookfairsAPI } = useConfig();

	const { supressEarnings } = useFairBasicInfo({
		fairId: earningsInfo.fairId,
	});

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	const navigate = useNavigate();
	const [navDestination, setNavDestination] = useState("");

	const firstRender = useRef(true);
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);

	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const [allowNavigation, setAllowNavigation] = useState(false);

	////////////////////////////Start of Calculate Cash Earnings Logic/////////////////////////////////

	//Adds commas after every 3 digits for larger numbers
	const formatNumber = (value: string | null | undefined) => {
		if (
			value === null ||
			value === undefined ||
			value === "" ||
			value === " "
		) {
			return "0.00";
		}
		const num = parseFloat(value);
		if (num <= 0) {
			return "0.00";
		}
		return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const [scholasticDollars, setScholasticDollars] = useState(
		formatNumber("0")
	);
	const [cash, setCash] = useState(formatNumber("0"));
	const [useCash, setUseCash] = useState(false);
	const [maxScholasticDollarValue, setMaxScholasticDollarValue] = useState(0);
	const [maxCashValue, setMaxCashValue] = useState(0);

	const handleScholasticDollars = (dollars: string) => {
		setScholasticDollars(dollars);
	};

	useEffect(() => {
		setUseCash(
			earningsInfo.earningsInfo.cash.selected !== null &&
				earningsInfo.earningsInfo.cash.selected !== 0
		);
		setCash(
			formatNumber(earningsInfo.earningsInfo.cash.selected?.toString())
		);
		setScholasticDollars(
			formatNumber(
				earningsInfo.earningsInfo.scholasticDollars.selected?.toString()
			)
		);
		setMaxCashValue(earningsInfo.earningsInfo.cash.max);
		setMaxScholasticDollarValue(
			earningsInfo.earningsInfo.scholasticDollars.max
		);
	}, [
		earningsInfo.earningsInfo.cash.selected,
		earningsInfo.earningsInfo.scholasticDollars.selected,
		earningsInfo.earningsInfo.cash.max,
		earningsInfo.earningsInfo.scholasticDollars.max,
	]);

	const toggleSetCash = () => {
		if (useCash) {
			setCash(formatNumber("0"));
			setScholasticDollars(
				formatNumber(maxScholasticDollarValue.toString())
			);
		}
		setUseCash(!useCash);
	};

	const handleCash = (cash: string) => {
		setCash(cash);
	};

	////////////////////////////End of Calculate Cash Earnings Logic////////////////////////////
	////////////////////////////////////Start of Navigation Logic////////////////////////////////////////

	const clickBackButton = () => {
		trackFairEarningsBackButton();
		setNavDestination(FinFormUrls.Spending);
		navigate(FinFormUrls.Spending);
	};

	// if user tries to directly access the /earnings page without completing previous steps, redirect them to the first incomplete step
	useEffect(() => {
		const progress = getFinFormProgress(earningsInfo.fairId);
		if (progress === FinFormProgress.Confirmation) {
			navigate(FinFormUrls.Confirmation);
		}
		if (supressEarnings) {
			// if suppress earnings, redirect to spending page
			if (progress === FinFormProgress.Earnings) {
				setFinFormUrlsLocalStorage(
					earningsInfo.fairId,
					FinFormUrls.Spending
				);
			}
			navigate(FinFormUrls.Spending);
			return;
		}
		if (progress < FinFormProgress.Earnings) {
			// if progress less than earnings, route user to furthest step they've completed
			const savedFinFormLocation = checkFinFormUrlsLocalStorage(
				earningsInfo.fairId
			);
			if (savedFinFormLocation) {
				navigate(savedFinFormLocation);
			}
		} else {
			// set current page cookie
			setFinFormCurrentPageLocalStorage(
				earningsInfo.fairId,
				FinFormUrls.Earnings
			);
		}
	}, [navigate, earningsInfo.fairId, supressEarnings]);

	useEffect(() => {
		if (earningsInfo.earningsInfo.scholasticDollars.balance < 0) {
			const savedFinFormProgress = getFinFormProgress(
				earningsInfo.fairId
			);
			if (savedFinFormProgress === FinFormProgress.Review) {
				setNavDestination(FinFormUrls.Earnings);
				setFinFormUrlsLocalStorage(
					earningsInfo.fairId,
					FinFormUrls.Earnings
				);
			}
		}
	}, [
		earningsInfo.fairId,
		earningsInfo.earningsInfo.scholasticDollars.balance,
	]);

	////////////////////////////////////End of Navigation Logic////////////////////////////////////////

	//////////////////////////////Start of Save Changes Modal Logic/////////////////////////////

	const openSaveChangesModal = () => {
		window.parent.postMessage("openModal", window.location.origin);
		document.documentElement.classList.add("bookfairs-modal--open");
	};

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		} else {
			setAllChangesSaved(false);
		}
	}, [useCash, setAllChangesSaved]);

	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			const savedFinFormProgress = getFinFormProgress(
				earningsInfo.fairId
			);
			if (savedFinFormProgress === FinFormProgress.Earnings) {
				setFinFormUrlsLocalStorage(earningsInfo.fairId, navDestination);
			}
			navigate(navDestination);
		}
	}, [
		allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		navDestination,
		earningsInfo.fairId,
	]);

	const saveEarningsData = async () => {
		setAllChangesSaved(true);
		const wrappedEarningsData = {
			cash: {
				selected: parseFloat(cash.replace(/,/g, "")),
			},
			scholasticDollars: {
				selected: parseFloat(scholasticDollars.replace(/,/g, "")),
			},
		};
		handleTrackFairEarningsContinueButton(
			parseFloat(cash.replace(/,/g, "")),
			parseFloat(scholasticDollars.replace(/,/g, ""))
		);
		try {
			await axios
				.put(
					`${bookfairsAPI}/user/fairs/${earningsInfo.fairId}/financials/form/earnings`,
					wrappedEarningsData,
					{ withCredentials: true }
				)
				.then((response) => {
					if (response.status !== 200) {
						handleGenericSuccess(response);
						return;
					} else {
						setAllowNavigation(true);
					}
				});
		} catch (err) {
			handleGenericErrors(err);
		}
	};

	const updateDumbleData = () => {
		updateFinancialFormEarningsData(
			earningsInfo.earningsInfo.scholasticDollars.earned.toString(),
			earningsInfo.earningsInfo.scholasticDollars.due.toString(),
			earningsInfo.earningsInfo.scholasticDollars.balance.toString()
		);
	};

	const clickSaveAndContinue = () => {
		const savedFinFormProgress = getFinFormProgress(earningsInfo.fairId);
		if (savedFinFormProgress === FinFormProgress.Earnings) {
			setFinFormUrlsLocalStorage(earningsInfo.fairId, FinFormUrls.Review);
		}
		setNavDestination(FinFormUrls.Review);
		if (!allChangesSaved) {
			saveEarningsData();
			updateDumbleData();
		} else {
			handleTrackFairEarningsContinueButton(
				earningsInfo.earningsInfo.cash.selected || 0,
				earningsInfo.earningsInfo.scholasticDollars.selected || 0
			);
			updateDumbleData();
			setAllowNavigation(true);
		}
	};

	//////////////////////////////End of Save Changes Modal Logic//////////////////////////////

	return (
		<>
			<Navigation
				activeLink={FinFormProgress.Earnings}
				fairId={earningsInfo.fairId}
				setDestination={setNavDestination}
			/>
			<FinancialPage>
				<EarningsOuterWrapper>
					<StyledHeading>Fair Earnings</StyledHeading>
					<StyledSubHeading>
						See how much you've earned.
					</StyledSubHeading>
					<EarningsInnerWrapper
						balanceNegative={
							earningsInfo.earningsInfo.scholasticDollars
								.balance < 0
						}
					>
						<FinancialEarningsCard>
							<StyledHeader>
								<Title>
									Your total Fair sales are{" "}
									{formatter.format(
										earningsInfo.earningsInfo.sales
									)}
								</Title>
								<StyledBodyText>
									(excluding Scholastic Dollars Redemptions,
									Sales Tax, Teacher Certificates, Unredeemed
									Share the Fair Contributions and Gift
									Certificate Purchases)
								</StyledBodyText>
							</StyledHeader>
							<StyledContents>
								<ContentRow>
									<ContentName>
										<ContentHeading>
											Scholastic Dollars Earned from This
											Fair <br />
											<ContentSubHeading>
												(Fair Sales x{" "}
												{
													earningsInfo.earningsInfo
														.dollarFairLevel
												}
												%)
											</ContentSubHeading>
										</ContentHeading>
									</ContentName>
									<ContentValue>
										{formatter.format(
											earningsInfo.earningsInfo
												.scholasticDollars.earned
										)}
									</ContentValue>
								</ContentRow>
								{earningsInfo.earningsInfo.scholasticDollars
									.due !== null &&
									earningsInfo.earningsInfo.scholasticDollars
										.due !== 0 && (
										<>
											<ContentSeperator />
											<ContentRow>
												<ContentHeading>
													Scholastic Dollars Due
												</ContentHeading>
												<ContentValue>
													{earningsInfo.earningsInfo
														.scholasticDollars.due >
													0
														? "-"
														: ""}

													{formatter.format(
														earningsInfo
															.earningsInfo
															.scholasticDollars
															.due
													)}
												</ContentValue>
											</ContentRow>
											<ContentSeperator />
											<ContentRow>
												<ContentHeading>
													Eligible Scholastic Dollars
													Balance
												</ContentHeading>
												<ContentValue>
													{formatter.format(
														earningsInfo
															.earningsInfo
															.scholasticDollars
															.balance
													)}
												</ContentValue>
											</ContentRow>
										</>
									)}
							</StyledContents>
						</FinancialEarningsCard>
						{earningsInfo.earningsInfo.scholasticDollars.balance >=
							0 && (
							<EarningsCalculator
								earningsInfo={earningsInfo.earningsInfo}
								fairId={earningsInfo.fairId}
								setCash={handleCash}
								setScholasticDollars={handleScholasticDollars}
								cash={cash}
								scholasticDollars={scholasticDollars}
								formatNumber={formatNumber}
								useCash={useCash}
								toggleSetCash={toggleSetCash}
								maxCashValue={maxCashValue}
								maxScholasticDollarValue={
									maxScholasticDollarValue
								}
							/>
						)}
						{earningsInfo.earningsInfo.scholasticDollars.balance <
							0 && (
							<NegativeBalanceCopy>
								For questions on completing your Financial Form,
								contact Customer Success at 877-245-0903 or
								CPtoolkit@scholasticbookfairs.com.
							</NegativeBalanceCopy>
						)}
						<ButtonContainer>
							<StyledWhiteButton
								handleClick={clickBackButton}
								whiteBackground={true}
								buttonWidth={170}
							>
								Back
							</StyledWhiteButton>
							{earningsInfo.earningsInfo.scholasticDollars
								.balance >= 0 && (
								<StyledRedButton
									handleClick={() => {
										clickSaveAndContinue();
									}}
								>
									Save & Continue
								</StyledRedButton>
							)}
						</ButtonContainer>
					</EarningsInnerWrapper>
				</EarningsOuterWrapper>
			</FinancialPage>
			<Prompt
				message={() => {
					setSaveChangesModalOpen(true);
					openSaveChangesModal();
				}}
				when={!allChangesSaved}
			/>
			<FinancialFormSaveChangesModal
				saveAndPublish={() => {
					saveEarningsData();
					setSaveChangesModalOpen(false);
				}}
				discardChanges={() => {
					setAllChangesSaved(true);
					setAllowNavigation(true);
					setSaveChangesModalOpen(false);
				}}
				modalIsOpen={saveChangesModalOpen}
				handleModal={() => {
					setSaveChangesModalOpen(!saveChangesModalOpen);
				}}
			/>
		</>
	);
};

const FinancialPage = styled.div`
	max-width: 100%;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

interface NegativeBalanceProps {
	balanceNegative: boolean;
}
const EarningsInnerWrapper = styled.div<NegativeBalanceProps>`
	display: flex;
	flex-direction: column;
	gap: ${(props) => (props.balanceNegative ? "17px" : "27px")};
	width: 600px;
	margin-bottom: 56px;
	@media (max-width: 700px) {
		width: 100%;
	}
`;

const EarningsOuterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 56px;
	@media (max-width: 700px) {
		width: calc(100% - 40px);
	}
	@media (max-width: 375px) {
		padding: 0px 20px;
		margin-top: 29px;
		gap: 0px;
	}
`;

const FinancialEarningsCard = styled.div`
	display: inline-flex;
	padding: 48px 80px;
	flex-direction: column;
	align-items: flex-start;
	gap: 35px;
	border-radius: 16px;
	background: #f5f5f5;
	margin-top: 33px;
	@media (max-width: 700px) {
		padding: 48px 25px;
	}
	@media (max-width: 375px) {
		padding: 48px 25px 80px 25px;
		margin-bottom: 24px;
	}
`;
const StyledHeading = styled(Heading)`
	display: flex;
	position: relative;
	font-size: 39px;
	font-style: normal;
	font-weight: 300;
	line-height: 42px;
	padding-bottom: 21px;
	@media (max-width: 375px) {
		padding-bottom: 0px;
	}
`;

const StyledHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 35px;
	width: 100%;
`;

const Title = styled(BodyText)`
	font-size: 27px;
	font-style: normal;
	font-weight: 300;
	line-height: 32px;
	text-align: left;
	@media (max-width: 375px) {
		margin-bottom: 23px;
		font-size: 24px;
		line-height: 28px;
	}
`;
const StyledSubHeading = styled(Subheading)`
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	padding-bottom: 11px;
	@media (max-width: 375px) {
		padding-bottom: 0px;
		margin-top: 17px;
	}
`;

const StyledBodyText = styled(BodyText)`
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 15px;
	text-align: left;
	@media (max-width: 375px) {
		text-align: left;
		margin-bottom: 23px;
	}
`;

const StyledContents = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	width: 100%;
`;

const ContentSeperator = styled.div`
	width: 100%;
	height: 1px;
	background: #d7d7d7;
`;

const ContentRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
	@media (max-width: 420px) {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 4px;
	}
`;

const ContentName = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 4px;
`;

const ContentHeading = styled(BodyText)`
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
	text-align: left;
`;

const ContentSubHeading = styled.span`
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
	text-align: left;
`;

const ContentValue = styled.p`
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
	@media (max-width: 350px) {
		flex-direction: column;
		flex-direction: column-reverse;
		gap: 32px;
		align-items: flex-start;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	height: 40px;
	padding: 9px 30px;
	width: 179px;
`;

const StyledRedButton = styled(RedButton)`
	height: 40px;
	padding: 9px 30px;
`;

const NegativeBalanceCopy = styled(BodyText)`
	text-align: left;
	width: 428px;
`;
