import React from "react";
import styled from "styled-components/macro";
import { RedButton } from "components/Button";
import TextLink from "components/TextLink";
import CloseIcon from "images/Close.svg";

interface Props {
	handleDelete: Function;
	closeModal: any;
	id: string;
}

export const PurchaseOrderDeleteConfirmation = ({
	handleDelete,
	closeModal,
	id,
}: Props) => {
	const closeModalAEM = () => {
		window.parent.postMessage("closeModal", window.location.origin);
		document.documentElement.classList.remove("bookfairs-modal--open");
	};

	return (
		<StyledModal>
			<Container>
				<UpperContainer>
					<StyledCloseButton
						src={CloseIcon}
						onClick={() => {
							closeModalAEM();
							closeModal();
						}}
					/>
				</UpperContainer>
				<LowerContainer>
					<StyledHeading>
						Are you sure you want to delete?
					</StyledHeading>
					<StyledButtonContainer>
						<StyledRedButton
							handleClick={() => {
								handleDelete(id);
								closeModalAEM();
							}}
						>
							Delete
						</StyledRedButton>
						<StyledTextLink
							handleClick={() => {
								closeModalAEM();
								closeModal();
							}}
						>
							Cancel
						</StyledTextLink>
					</StyledButtonContainer>
				</LowerContainer>
			</Container>
		</StyledModal>
	);
};

const StyledModal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2000;
`;

const Container = styled.div`
	display: flex;
	position: fixed;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
	width: 560px;
	height: fit-content;
	padding: 16px 16px 20px 16px;
	flex-direction: column;
	align-items: center;
	border-radius: 8px;
	background: #fff;
	box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.2);
	@media (max-width: 700px) {
		width: calc(100% - 80px);
	}
`;

const UpperContainer = styled.div`
	display: flex;
	padding-bottom: 20px;
	flex-direction: column;
	align-items: flex-end;
	gap: 8px;
`;

const LowerContainer = styled.div`
	display: flex;
	width: 568px;
	padding: 4px 0px 24px 0px;
	flex-direction: column;
	align-items: center;
	gap: 60px;
	@media (max-width: 440px) {
		gap: 40px;
	}
`;

const StyledHeading = styled.p`
	color: #333333;
	text-align: center;
	font-size: 28px;
	font-style: normal;
	font-weight: 300;
	line-height: 32px;
	width: 271px;
`;

const StyledCloseButton = styled.img`
	position: absolute;
	right: 16px;
	top: 16px;
	background: none;
	border: none;
	cursor: pointer;
	height: 16px;
	width: 16px;
`;

const StyledButtonContainer = styled.div`
	display: flex;
	width: 280px;
	justify-content: center;
	align-items: center;
	gap: 32px;
	@media (max-width: 440px) {
		gap: 10px;
	}
`;

const StyledRedButton = styled(RedButton)`
	width: 157px;
	@media (max-width: 440px) {
		width: 130px;
	}
`;

const StyledTextLink = styled(TextLink)`
	width: 91px;
	color: #000000;
`;
