import React, { useState } from "react";
import styled from "styled-components/macro";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "styles/DatePicker.css";
import { ReactComponent as Calendar } from "images/Calendar.svg";
import { ReactComponent as DisabledCalendar } from "images/CalendarDisabled.svg";

interface Props {
	value: Date;
	label: string;
	onChange: any;
	defaultStart: Date;
	defaultEnd: Date;
	error?: string;
	isOpen: boolean;
	onClick: any;
	disabled?: boolean;
	className?: string;
	afterFair?: boolean;
}

export const DatePickerComponent = ({
	value,
	onChange,
	label,
	defaultStart,
	defaultEnd,
	error,
	isOpen,
	onClick,
	disabled,
	className,
	afterFair,
}: Props) => {
	const [forceWindowClosed, setForceWindowClosed] = useState<boolean>(false);

	const handleKeyboardEvent = (e: any) => {
		if (e.key === "Tab") {
			setForceWindowClosed(true);
		}
	};

	return (
		<DatePickerContainer data-error={error} className={className}>
			<StyledLabel
				id="inputLabel"
				afterFair={afterFair}
				className={className}
			>
				{label}
			</StyledLabel>
			<InputContainer className={className} afterFair={afterFair}>
				{!afterFair && (
					<StyledCalendar className={className} onClick={onClick} />
				)}
				{afterFair && <StyledDisabledCalendar className={className} />}
				<DatePicker
					tabIndex={afterFair ? -1 : 0}
					selected={value}
					onChange={(date: Date) => onChange(date)}
					formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
					minDate={defaultStart}
					maxDate={defaultEnd}
					open={isOpen && !forceWindowClosed}
					onFocus={() => setForceWindowClosed(false)}
					onClickOutside={() => setForceWindowClosed(true)}
					onSelect={() => setForceWindowClosed(true)}
					onKeyDown={handleKeyboardEvent}
					className={className}
					disabled={disabled}
				/>
			</InputContainer>
			{error && <ErrorLabel id="textInputErrorLabel">{error}</ErrorLabel>}
		</DatePickerContainer>
	);
};
interface AfterFairProps {
	afterFair?: boolean;
}
const StyledCalendar = styled(Calendar)`
	position: absolute;
	z-index: 10;
	left: 14px;
	top: 10px;
	&.virtual {
		display: none;
	}
`;
const StyledDisabledCalendar = styled(DisabledCalendar)`
	position: absolute;
	z-index: 10;
	left: 14px;
	top: 10px;
	&.virtual {
		display: none;
	}
`;
const InputContainer = styled.div<AfterFairProps>`
	height: 40px;
	background: #ffffff;
	box-sizing: border-box;
	border-radius: 5px;
	font-family: museo-sans;
	font-size: 16px;
	line-height: 19px;
	display: inline-flex;
	width: 100%;
	position: relative;
	.react-datepicker__input-container input {
		border: ${(props) =>
			props.afterFair ? "1px solid #D7D7D7" : "1px solid #919191"};
		color: ${(props) => (props.afterFair ? " #D7D7D7" : "#333333")};
	}
	.react-datepicker__input-container input:focus {
		outline: ${(props) => (props.afterFair ? "none" : "")};
	}
	&.virtual {
		right: 45px;
	}
	&.virtual input {
		border: none;
	}
`;

const DatePickerContainer = styled.div<AfterFairProps>`
	margin-bottom: 17px;
	cursor: ${(props) => (props.afterFair ? "pointer" : "unset")};
	&[data-error] .react-datepicker__input-container input {
		border: 1px solid #e81111;
	}
	.virtual {
		border: none;
	}
`;

const StyledLabel = styled.label<AfterFairProps>`
	display: block;
	font-family: museo-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 15px;
	color: ${(props) => (props.afterFair ? "#D7D7D7" : "#333333")};
	padding-bottom: 8px;
	&.virtual {
		padding: 0px;
	}
`;

const ErrorLabel = styled(StyledLabel)`
	color: #e81111;
	margin-top: 4px;
	padding-bottom: 0;
`;
