import React from "react";
import useSalesHistoryCOAButtonData from "hooks/SalesHistory/useSalesHistoryCoaButtonData";
import LegalCopyContent from "components/ConfirmYourFair/LegalCopy/LegalCopyContent";

interface Props {
	emailedPage: boolean;
	handlePrint?: Function;
	printClicked?: boolean;
	fairId?: string;
	schoolId?: string;
}
const SalesHistoryLegalCopy = ({
	emailedPage,
	handlePrint,
	printClicked,
	fairId,
	schoolId,
}: Props) => {
	const {
		fairInfoData,
		chairpersonData,
		consultantData,
		organizationData,
		isLoading,
	} = useSalesHistoryCOAButtonData({
		withToken: emailedPage,
		fairId: fairId,
		schoolId: schoolId,
	});

	return (
		<LegalCopyContent
			handlePrint={handlePrint}
			printClicked={printClicked}
			emailedPage={emailedPage}
			fairInfoData={fairInfoData}
			chairpersonData={chairpersonData}
			consultantData={consultantData}
			organizationData={organizationData}
			isLoading={isLoading}
		></LegalCopyContent>
	);
};

export default SalesHistoryLegalCopy;
