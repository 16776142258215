import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import BodyText from "components/BodyText";
import { RedButton } from "components/Button";
import RedArrow from "images/RedArrow.svg";
import { DatePickerComponent } from "components/DatePicker";
import TextInput from "../../TextInput";
import TimeSelect from "components/DropDownSelect";
import LimitedTextArea from "components/LimitedTextArea";
import TextLink from "../../TextLink";
import { useConfig } from "providers/ConfigContext";
import TimeList from "components/HomepageBuilder/AddEvent/TimeList.json";
import EventCategories from "components/HomepageBuilder/AddEvent/EventCategories.json";
import {
	convertToMilitaryTime,
	convertToStandardTime,
} from "components/HomepageBuilder/AddEvent/CleanTime";
import { convertStringToDate } from "utils/DateConversion";
import { trackAddEvent } from "DumbleData";

interface Props {
	setMenuOpen(): any;
	setAddEventOpen(): any;
	fairStartDate: Date;
	fairEndDate: Date;
	eventTypeIndex?: number;
	isMenuOpen: boolean;
	saveEvent(newEvent: object, eventPreEdit?: object, index?: number): any;
	//list of two elements that are defined if editing an existing event
	//editEventInfo[0] is object of event that is being edited
	//editEventInfo[1] is index of event in schedule list
	editEventInfo?: any;
}

export const AddEventComponent = ({
	setMenuOpen,
	setAddEventOpen,
	fairStartDate,
	fairEndDate,
	eventTypeIndex,
	isMenuOpen,
	saveEvent,
	editEventInfo,
}: Props) => {
	const { damAssetsOrigin } = useConfig();

	const calculatedIndex = editEventInfo
		? EventCategories.findIndex(
				(item) => item.type === editEventInfo[0].eventCategory
		  )
		: 0;
	//If creating a new event, use eventTypeIndex to find event type, if updating an event, use the calculated index to find eventType
	const index = eventTypeIndex ? eventTypeIndex : calculatedIndex;

	let tempDate;
	if (editEventInfo) {
		//If updating event then use the event date from the save event info.
		tempDate = convertStringToDate(editEventInfo[0].scheduleDate);
	} else {
		if (fairStartDate <= new Date()) {
			//If new event, use current date as default if fair is active.
			tempDate = new Date();
		} else {
			//If fair has not started yet, default date is first day of the fair.
			tempDate = fairStartDate;
		}
	}
	const [eventDate, setEventDate] = useState<Date>(tempDate);
	const [title, setTitle] = useState<string>(
		editEventInfo ? editEventInfo[0].eventName : EventCategories[index].type
	);
	const [startTime, setStartTime] = useState<string>(
		editEventInfo ? convertToStandardTime(editEventInfo[0].startTime) : ""
	);
	const [endTime, setEndTime] = useState<string>(
		editEventInfo ? convertToStandardTime(editEventInfo[0].endTime) : ""
	);
	const [detailsMessage, setDetailsMessage] = useState<string>(
		editEventInfo ? editEventInfo[0].description : ""
	);
	const [titleError, setTitleError] = useState<boolean>(false);
	const [startTimeError, setStartTimeError] = useState<string>();
	const [endTimeError, setEndTimeError] = useState<string>();
	const [attemptedToSubmit, setAttemptedToSubmit] = useState<boolean>(false);
	const addEventRef = useRef<HTMLDivElement>(null);
	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

	//When opening modal, modal scrolls into center of screen
	useEffect(() => {
		if (addEventRef.current !== null) {
			addEventRef.current.scrollIntoView({
				behavior: "smooth",
			});
		}
	}, []);

	//callback to validate data
	//will first be triggered by first submit and then will run each time an input field is changed
	const dataValidate = useCallback(() => {
		let isAllValid = true;
		let startMilitaryTime;
		let endMilitaryTime;
		if (title === null || title === "" || title.match(/^ *$/) !== null) {
			setTitleError(true);
			isAllValid = false;
		} else {
			setTitleError(false);
		}
		if (startTime === "") {
			setStartTimeError("The start time is left blank.");
			isAllValid = false;
			return;
		} else if (endTime === "") {
			setEndTimeError("The end time is left blank.");
			isAllValid = false;
			return;
		} else {
			startMilitaryTime = convertToMilitaryTime(startTime);
			endMilitaryTime = convertToMilitaryTime(endTime);
			if (startMilitaryTime >= endMilitaryTime) {
				setStartTimeError(
					"Event start time should be before the end time."
				);
				setEndTimeError(
					"Event end time should be after the start time."
				);
				isAllValid = false;
				return;
			} else {
				setStartTimeError("");
				setEndTimeError("");
			}
		}
		return isAllValid;
	}, [startTime, endTime, title]);

	//Adds or edits event in the local scheduleEventList
	const submitEvent = () => {
		if (dataValidate()) {
			const hasCreateDate = editEventInfo
				? editEventInfo[0].hasOwnProperty("createDate")
				: false;
			//creates Json of new event object
			const eventId = editEventInfo ? editEventInfo[0].id : -1;
			const newEventObj = {
				scheduleDate: eventDate.toISOString().split("T")[0],
				eventCategory: EventCategories[index].type,
				eventName: title,
				startTime: convertToMilitaryTime(startTime),
				endTime: convertToMilitaryTime(endTime),
				description: detailsMessage,
				...(hasCreateDate && {
					createDate: editEventInfo[0].createDate,
				}),
				id: eventId,
			};
			trackAddEvent(newEventObj.eventCategory);
			if (editEventInfo) {
				//first parameter is passing in event object
				//second is passing in index of event in event list if being updated
				saveEvent(newEventObj, editEventInfo[0], editEventInfo[1]);
			} else {
				saveEvent(newEventObj);
			}
			setAddEventOpen();
		} else {
			setAttemptedToSubmit(true);
		}
	};

	//Allows for the dataValidate callback to be executed anytime the inputs are changed after first submission attempt
	useEffect(() => {
		if (attemptedToSubmit) {
			dataValidate();
		}
	}, [attemptedToSubmit, dataValidate, title]);

	//Changes default title each time a new event type is selected
	useEffect(() => {
		if (!editEventInfo) {
			setTitle(EventCategories[index].type);
		}
	}, [index, editEventInfo]);

	const submitButtonText = editEventInfo ? "Save Event" : "Add Event";

	return (
		<AddEventContainer id="AddEvent" ref={addEventRef}>
			<InnerContainer>
				<EventHeading onClick={setMenuOpen}>
					{EventCategories[index].type}
					<EventDownArrow src={RedArrow} />
				</EventHeading>
				<CenteredContainer>
					<StyledBodyText>
						{EventCategories[index].description}
					</StyledBodyText>
				</CenteredContainer>
				<CenteredContainer>
					<StyledImg
						src={`${damAssetsOrigin}HomepageBuilder/ScheduleActivities/440x176px${EventCategories[index].imageLink}`}
					/>
				</CenteredContainer>
				<FlexRow>
					<InlineElement onFocus={() => setIsCalendarOpen(false)}>
						<TextInput
							label="Event Title"
							value={title}
							onChange={setTitle}
							error={
								titleError
									? "The event title is left blank."
									: undefined
							}
						/>
					</InlineElement>
					<InlineElement onFocus={() => setIsCalendarOpen(true)}>
						<DatePickerComponent
							label="Event Date"
							value={eventDate}
							onChange={setEventDate}
							defaultStart={fairStartDate}
							defaultEnd={fairEndDate}
							isOpen={isCalendarOpen}
							onClick={() => setIsCalendarOpen(true)}
						/>
					</InlineElement>
				</FlexRow>
				<FlexRow onFocus={() => setIsCalendarOpen(false)}>
					<InlineElement>
						<TimeSelect
							label="Start Time"
							defaultText={"Select"}
							onChange={setStartTime}
							disabled={false}
							options={TimeList}
							optionChosen={startTime}
							error={startTimeError}
							defaultValue={"7:00 AM"}
						/>
					</InlineElement>
					<InlineElement>
						<TimeSelect
							label="End Time"
							defaultText={"Select"}
							onChange={setEndTime}
							disabled={false}
							options={TimeList}
							optionChosen={endTime}
							error={endTimeError}
							defaultValue={"7:30 AM"}
						/>
					</InlineElement>
				</FlexRow>
				<LimitedTextArea
					value={detailsMessage}
					onChange={setDetailsMessage}
					label={
						"Add more details to display on homepage (optional) "
					}
					limit={115}
				/>
				<CenteredContainer>
					<StyledRedButton handleClick={submitEvent}>
						{submitButtonText}
					</StyledRedButton>
				</CenteredContainer>
				<CenteredContainer
					onClick={() => {
						setAddEventOpen();
						if (isMenuOpen) {
							setMenuOpen();
						}
					}}
				>
					<StyledTextLink>cancel</StyledTextLink>
				</CenteredContainer>
			</InnerContainer>
		</AddEventContainer>
	);
};

/*
	Page Specific Styles
*/

const CenteredContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const InnerContainer = styled.div`
	padding: 34px 80px 31px;
	@media (max-width: 582px) {
		padding: 37px 20px 23px;
	}
`;
const FlexRow = styled.div`
	display: inline-flex;
	width: 100%;
	gap: 40px;
	@media (max-width: 734px) {
		gap: 14px;
	}
`;
const StyledTextLink = styled(TextLink)`
	margin-top: 23px;
`;
const InlineElement = styled.div`
	flex: 1 auto;
	width: calc(50% - 20px);
`;
const StyledImg = styled.img`
	height: 176px;
	max-width: 440px;
	border-radius: 0px;
	margin-bottom: 33px;
	@media (max-width: 582px) {
		height: 133.6px;
		max-width: 334px;
	}
`;
const EventHeading = styled.button`
	display: block;
	border: none;
	background: none;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	text-align: center;
	width: 100%;
	color: #e81111;
	text-transform: uppercase;
	position: relative;
	padding-bottom: 14px;
	@media (max-width: 734px) {
		font-size: 21px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		letter-spacing: 0px;
		text-align: center;
	}
`;

const AddEventContainer = styled.div`
	max-width: 600px;
	background: #fff5f5;
	border-radius: 16px;
	margin-top: 40px;
	margin-bottom: 32px;
	align-self: center;
	@media (max-width: 375px) {
		max-width: 375px;
		border-radius: 0;
	}
`;

const StyledBodyText = styled(BodyText)`
	max-width: 440px;
	margin-bottom: 31px;
`;
const StyledRedButton = styled(RedButton)`
	width: 181px;
	margin-top: 32px;
`;

const EventDownArrow = styled.img`
	padding-left: 6px;
	position: absolute;
	top: 10px;
`;
