import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import styled from "styled-components/macro";
import { validateEmail, validatePhoneNumber } from "utils/InputValidations";
import { useNavigate } from "react-router-dom";
import { useConfig } from "providers/ConfigContext";
import { Page } from "components/HomepageBuilder/Page";
import Heading from "components/Heading";
import PageIcon from "components/HomepageBuilder/PageIcon";
import ContactIcon from "images/Contact.svg";
import BodyText from "components/BodyText";
import TextInput from "components/TextInput";
import Checkbox from "components/Checkbox";
import { RedButton } from "components/Button";
import TextLink from "components/TextLink";
import { useContactData } from "./Builder";
import SaveChangesModal from "components/HomepageBuilder/SaveChangesModal";
import Prompt from "components/HomepageBuilder/Prompt";
import { trackSaveAndPublish } from "DumbleData";
import IframeContainer from "components/IframeContainer";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

export const ContactPage = () => {
	const { bookfairsAPI, damAssetsOrigin } = useConfig();
	const { fairId, contactData, nextLocation, setNextLocation } =
		useContactData();
	const navigate = useNavigate();
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const [firstNameError, setFirstNameError] = useState<string>();
	const [lastNameError, setLastNameError] = useState<string>();
	const [emailError, setEmailError] = useState<string>();
	const [phoneNumberError, setPhoneNumberError] = useState<string>();
	const [allowNavigation, setAllowNavigation] = useState(false);
	const firstRender = useRef(true);
	const [savedData] = useState({ contactData });

	const dataValidate = () => {
		let valid = true;
		if (!contactData.firstName) {
			setFirstNameError("Please enter a first name.");
			valid = false;
		} else {
			setFirstNameError(undefined);
		}
		if (!contactData.lastName) {
			setLastNameError("Please enter a last name.");
			valid = false;
		} else {
			setLastNameError(undefined);
		}
		if (!validateEmail(contactData.email)) {
			setEmailError("Please enter a valid email address.");
			valid = false;
		} else {
			setEmailError(undefined);
		}
		if (contactData.displayPhoneNumber || contactData.phoneNumber.length) {
			if (!validatePhoneNumber(contactData.phoneNumber)) {
				setPhoneNumberError(
					"Please enter a valid 10 digit phone number."
				);
				valid = false;
			} else {
				setPhoneNumberError(undefined);
			}
		}
		return valid;
	};

	const submitContactData = async () => {
		const currentFair = getFairIdFromUrl();
		setAllChangesSaved(true);

		if (dataValidate()) {
			if (checkSpsUd()) {
				try {
					await axios
						.put(
							`${bookfairsAPI}/user/fairs/${currentFair}/homepage`,
							{
								fairId: fairId,
								contactFirstName: contactData.firstName,
								contactLastName: contactData.lastName,
								contactEmail: contactData.email,
								contactPhoneNumber: contactData.phoneNumber,
								contactPhoneNumberCkbox:
									contactData.displayPhoneNumber ? "Y" : "N",
							},
							{ withCredentials: true }
						)
						.then((response) => {
							if (response.status === 200) {
								trackSaveAndPublish(
									"contact-information",
									"Your Contact Information"
								);
								setAllowNavigation(true);
							} else {
								handleGenericSuccess(response);
							}
						})
						.catch((err) => {
							handleGenericErrors(err);
						});
				} catch (err) {
					handleGenericErrors(err);
				}
			}
		} else {
			setSaveChangesModalOpen(false);
		}
	};

	//
	// Logic to handle navigation and rendering the SaveChangesModal
	//

	//Tracks changes made
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		} else {
			setAllChangesSaved(false);
		}
	}, [
		contactData.firstName,
		contactData.lastName,
		contactData.email,
		contactData.phoneNumber,
		contactData.displayPhoneNumber,
	]);

	//Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			navigate(nextLocation);
		}
	}, [
		allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		nextLocation,
	]);

	////////////////////////

	return (
		<IframeContainer
			renderItems={() => (
				<>
					<Page>
						<PageIcon
							src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/Contact.svg`}
							alt="Contact Form Icon"
							defaultImg={ContactIcon}
						/>
						<Heading>Your contact information</Heading>
						<Wrapper>
							<StyledBodyText>
								Visitors to your Book Fair homepage will not see
								your private contact information, but the site
								will allow them to send email messages to you.
							</StyledBodyText>
							<FlexRow>
								<InlineElement>
									<TextInput
										label="First Name"
										value={contactData.firstName}
										onChange={contactData.setFirstName}
										error={firstNameError}
									></TextInput>
								</InlineElement>
								<InlineElement>
									<TextInput
										label="Last Name"
										value={contactData.lastName}
										onChange={contactData.setLastName}
										error={lastNameError}
									></TextInput>
								</InlineElement>
							</FlexRow>
							<TextInput
								label="Email"
								type="email"
								value={contactData.email}
								onChange={contactData.setEmail}
								error={emailError}
							></TextInput>
							<TextInput
								label="Phone Number (optional)"
								value={contactData.phoneNumber}
								onChange={contactData.setPhoneNumber}
								error={phoneNumberError}
							></TextInput>
							<LeftAlignWrapper>
								<Checkbox
									label="Display phone number on homepage"
									checked={contactData.displayPhoneNumber}
									onChange={contactData.setDisplayPhoneNumber}
								/>
							</LeftAlignWrapper>
						</Wrapper>
						<StyledRedButton
							handleClick={() => {
								setNextLocation("/reactapp/customize/goals");
								submitContactData();
							}}
						>
							save & publish
						</StyledRedButton>
						<StyledTextLink
							handleClick={() => {
								if (!allChangesSaved) {
									setNextLocation(
										"/reactapp/customize/goals"
									);
									setSaveChangesModalOpen(
										!saveChangesModalOpen
									);
								} else {
									navigate("/reactapp/customize/goals");
								}
							}}
						>
							Skip
						</StyledTextLink>
					</Page>

					<SaveChangesModal
						saveAndPublish={submitContactData}
						discardChanges={() => {
							contactData.setFirstName(
								savedData.contactData.firstName
							);
							contactData.setLastName(
								savedData.contactData.lastName
							);
							contactData.setEmail(savedData.contactData.email);
							contactData.setPhoneNumber(
								savedData.contactData.phoneNumber
							);
							contactData.setDisplayPhoneNumber(
								savedData.contactData.displayPhoneNumber
							);
							setAllChangesSaved(true);
							setAllowNavigation(true);
						}}
						modalIsOpen={saveChangesModalOpen}
						handleModal={() => {
							setSaveChangesModalOpen(!saveChangesModalOpen);
						}}
					/>
					<Prompt
						message={() => {
							setSaveChangesModalOpen(true);
						}}
						when={!allChangesSaved}
					/>
				</>
			)}
		/>
	);
};

/*
	Page Specific Styles
*/
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 375px;
`;

const FlexRow = styled.div`
	display: inline-flex;
	width: 100%;
	gap: 40px;
`;

const InlineElement = styled.div`
	flex: 1 auto;
	width: calc(50% - 20px);
`;

const LeftAlignWrapper = styled.div`
	align-self: flex-start;
`;

const StyledBodyText = styled(BodyText)`
	padding-bottom: 32px;
	@media (max-width: 719px) {
		padding-bottom: 47px;
	}
	@media (max-width: 374px) {
		padding-bottom: 40px;
	}
`;

const StyledRedButton = styled(RedButton)`
	width: 181px;
	margin-top: 71.5px;
`;

const StyledTextLink = styled(TextLink)`
	margin-top: 23px;
`;
