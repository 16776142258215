import React from "react";
import IframeContainer from "components/IframeContainer";
import EWalletRosterPageContent from "./EWalletRosterPageContent";
import { FairInfoBar } from "../FairInfoBar";
import useEWalletRosterData from "hooks/EWalletRoster/useEWalletRosterData";

export const EWalletRosterPage = () => {
	const { eWalletRosterData } = useEWalletRosterData();
	return (
		<IframeContainer
			renderItems={() => (
				<>
					{/* wait to render components until primary request (useEWalletRosterData) is fulfilled */}
					{eWalletRosterData.fairId !== "" && (
						<>
							<FairInfoBar />
							<EWalletRosterPageContent
								eWalletRosterData={eWalletRosterData}
							/>
						</>
					)}
				</>
			)}
		/>
	);
};

export default EWalletRosterPage;
