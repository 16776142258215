import React from "react";
import styled from "styled-components";
import Heading from "components/Heading";
import { RedButton } from "components/Button";
import { IconList } from "components/HomepageBuilder/IconList";
import BodyText from "components/BodyText";
import { Page } from "components/HomepageBuilder/Page";
import useHomepageData from "hooks/HomepageBuilder/useHomepageData";
import IframeContainer from "components/IframeContainer";

export const CustomizePage = () => {
	const { isVirtual, fairType, isLoading } = useHomepageData({
		isPublic: false,
	});

	return (
		<IframeContainer
			renderItems={() => (
				<>
					{!isLoading && (
						<StyledCustomizePage>
							<StyledHeading>
								<Heading>Customize your homepage</Heading>
							</StyledHeading>
							<StyledBodyText>
								<BodyText>
									You’re just a few clicks away from
									customizing your Book Fair homepage, which
									you’ll use in promoting and sharing key
									information about your Fair. Update each
									section of your homepage and publish when
									you’re done.
								</BodyText>
							</StyledBodyText>
							<IconList
								virtualFair={isVirtual}
								afterFair={fairType === "after"}
							/>
							<a href="/reactapp/customize/contact">
								<StyledRedButton>Continue</StyledRedButton>
							</a>
						</StyledCustomizePage>
					)}
				</>
			)}
		/>
	);
};

const StyledHeading = styled.div`
	padding-top: 46px;
	@media (max-width: 979px) {
		padding-top: 40px;
		max-width: 449px;
	}
	@media (max-width: 719px) {
		padding-top: 0;
		margin-top: 72px;
	}
`;
const StyledRedButton = styled(RedButton)`
	width: 200px;
`;
const StyledBodyText = styled.div`
	max-width: 349px;
`;
const StyledCustomizePage = styled(Page)`
	padding-bottom: 106px;
	@media (max-width: 959px) {
		padding-top: 0px;
	}
	@media (max-width: 375px) {
		padding-left: 40px;
		padding-right: 40px;
	}
`;
