import React from "react";
import IframeContainer from "components/IframeContainer";
import FairDetailsPageContent from "./FairDetailsPageContent";
import { FairInfoBar } from "../../FairInfoBar";
import useFairDetailsPageData from "hooks/FairDetails/useFairDetailsData";

const FairDetailsPage = () => {
	const {
		fairInfo,
		eWallet,
		onlineFair,
		orgStfBalance,
		consultant,
		coChairs,
		loggedInEmail,
		iamUcn,
	} = useFairDetailsPageData();
	return (
		<IframeContainer
			renderItems={() => (
				<>
					{/* wait to render components until primary request (useFairDetailsPageData) is fulfilled */}
					{fairInfo.fairId !== "" && (
						<>
							<FairInfoBar />
							<FairDetailsPageContent
								fairInfo={fairInfo}
								eWallet={eWallet}
								orgStfBalance={orgStfBalance}
								onlineFair={onlineFair}
								consultant={consultant}
								coChairs={coChairs}
								loggedInEmail={loggedInEmail}
								iamUcn={iamUcn}
								fairId={fairInfo.fairId}
							/>
						</>
					)}
				</>
			)}
		/>
	);
};

export default FairDetailsPage;
