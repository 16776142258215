import React from "react";
import IframeContainer from "components/IframeContainer";
import IndividualScansheet from "./IndividualScansheet";
import { useIndividualScansheetPageData } from "hooks/ScanSheet/useScanSheetData";

export const IndividualScansheetPage = () => {
	sessionStorage.setItem("bfDestinationLogin", window.location.href);
	const {
		schoolName,
		balance,
		recipientFirstName,
		recipientLastName,
		teacherLastName,
		grade,
		barcode,
		isLoading,
	} = useIndividualScansheetPageData();

	return (
		<IframeContainer
			renderItems={() => (
				<>
					{!isLoading && (
						<IndividualScansheet
							barcodeValue={barcode}
							recipientFirstName={recipientFirstName}
							recipientLastName={recipientLastName}
							teacherLastName={teacherLastName}
							grade={grade}
							balance={balance}
							schoolName={schoolName}
						/>
					)}
				</>
			)}
		/>
	);
};

export default IndividualScansheetPage;
