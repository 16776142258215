import React, { useState } from "react";
import styled from "styled-components/macro";
import BodyText from "components/BodyText";
import EditButton from "images/Edit.svg";
import DeleteButton from "images/Delete.svg";
import DeleteModal from "./DeleteModal";
import { convertToStandardTime } from "components/HomepageBuilder/AddEvent/CleanTime";

interface Props {
	eventDate: string;
	startTime: string;
	endTime: string;
	title: string;
	description?: string;
	eventType?: string;
	editEvent(x: any): any;
	index: number;
	category: string;
	createDate: Date;
	deleteEvent: (eventData: Object) => void;
	eventData: Object;
}

export const ScheduledEvent = ({
	eventDate,
	startTime,
	endTime,
	title,
	description,
	editEvent,
	index,
	deleteEvent,
	eventData,
}: Props) => {
	const [clickedDelete, setClickedDelete] = useState<boolean>(false);
	let dateArray: any[] = [];
	const startTimeClean = convertToStandardTime(startTime);
	const endTimeClean = convertToStandardTime(endTime);
	if (eventDate) {
		dateArray = eventDate.split("-");
	}
	const cleanedDate = `${parseInt(dateArray[1])}/${parseInt(dateArray[2])}/${
		dateArray[0]
	}`;

	// Opens delete modal
	const handleDeleteClick = () => {
		setClickedDelete(true);
	};

	// Removes event from UI
	const confirmDelete = () => {
		deleteEvent(eventData);
	};

	return (
		<ScheduledEventContainer>
			{clickedDelete ? (
				<DeleteModal
					clickCancel={setClickedDelete}
					clickDelete={confirmDelete}
				/>
			) : (
				<>
					<EventDetailsLeft>
						<StyledBodyText>{cleanedDate}</StyledBodyText>
						<StyledBodyTextTime>
							{startTimeClean} - {endTimeClean}
						</StyledBodyTextTime>
					</EventDetailsLeft>
					<EventDetailsRight>
						<StyledBodyTextTitle>{title}</StyledBodyTextTitle>
						{description && (
							<StyledBodyText>{description}</StyledBodyText>
						)}
					</EventDetailsRight>
					<EventButtons>
						<Edit
							onClick={() => {
								editEvent(index);
							}}
						>
							<img
								alt={`Edit ${title} event`}
								src={EditButton}
							></img>
						</Edit>
						<Delete onClick={handleDeleteClick}>
							<img
								alt={`Remove ${title} from schedule`}
								src={DeleteButton}
							></img>
						</Delete>
					</EventButtons>
				</>
			)}
		</ScheduledEventContainer>
	);
};
const EventButtons = styled.div`
	position: absolute;
	width: 100px;
	left: 500px;
	top: 50.09px;
	@media (max-width: 582px) {
		left: 250px;
		top: 22.09px;
	}
`;
const Edit = styled.button`
	margin-right: 20px;
	cursor: pointer;
	border: none;
	background-color: transparent;
`;
const Delete = styled.button`
	margin-right: 32px;
	cursor: pointer;
	border: none;
	background-color: transparent;
`;
const StyledBodyTextTime = styled(BodyText)`
	text-align: left;
	line-height: 15px;
	width: 125px;
`;
const StyledBodyText = styled(BodyText)`
	text-align: left;
	line-height: 15px;
`;
const StyledBodyTextTitle = styled(BodyText)`
	text-align: left;
	font-weight: bold;
	line-height: 15px;
`;
const EventDetailsLeft = styled.div`
	position: relative;
	width: 120px;
	left: 40px;
	top: 29px;
	@media (max-width: 582px) {
		top: 21px;
		left: 29px;
	}
`;
const EventDetailsRight = styled.div`
	position: relative;
	width: 257px;
	left: 200px;
	@media (max-width: 582px) {
		top: 30px;
		left: 29px;
		width: 276px;
	}
`;
const ScheduledEventContainer = styled.div`
	width: 600px;
	height: 121px;
	position: relative;
	border: 1px solid #949494;
	box-sizing: border-box;
	border-radius: 16px;
	margin-top: 24px;

	@media (max-width: 582px) {
		width: 334px;
		height: 153px;
	}
`;
