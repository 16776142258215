import React from "react";
import { Outlet } from "react-router-dom";
import IframeContainer from "components/IframeContainer";
import { FinFormLiveChat } from "./FinFormLiveChat";

export const FinFormBuilder = () => {
	return (
		<IframeContainer
			renderItems={() => (
				<React.Fragment>
					<Outlet />
					<FinFormLiveChat />
				</React.Fragment>
			)}
		/>
	);
};
