import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { SavedFairBasicInfo } from "components/FairInfoBar";

interface Props {
	fairId: string;
}

const useFairBasicInfo = ({ fairId }: Props) => {
	const { bookfairsAPI } = useConfig();

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [fairBasicInfo, setFairBasicInfo] =
		useState<SavedFairBasicInfo | null>(null);
	const [supressEarnings, setSupressEarnings] = useState<boolean>(false);

	useEffect(() => {
		const fetchBasicInfo = async () => {
			if (!fairId || fairId === "") {
				return;
			}
			try {
				const item = localStorage.getItem(`fairBasicInfo-${fairId}`);
				let tempfairBasicInfo = item
					? (JSON.parse(item) as SavedFairBasicInfo)
					: null;

				if (!tempfairBasicInfo) {
					const response = await axios.get(
						`${bookfairsAPI}/user/fairs/${fairId}/basic-info`,
						{ withCredentials: true }
					);
					if (response.status !== 200) {
						handleGenericSuccess(response);
						return;
					}

					tempfairBasicInfo = {
						type: response.data.fair.type,
						name: response.data.fair.name,
						startDate: response.data.fair.startDate,
						endDate: response.data.fair.endDate,
					};
					localStorage.setItem(
						`fairBasicInfo-${fairId}`,
						JSON.stringify(tempfairBasicInfo)
					);
				}

				setFairBasicInfo(tempfairBasicInfo);
				setSupressEarnings(
					tempfairBasicInfo.type === "bogo tabletop" ||
						tempfairBasicInfo.type === "bogo case" ||
						tempfairBasicInfo.type === "discounted 25%"
				);
			} catch (err) {
				setIsError(true);
				handleGenericErrors(err);
			} finally {
				setIsLoading(false);
			}
		};
		fetchBasicInfo();
	}, [bookfairsAPI, fairId]);

	return {
		fairBasicInfo,
		supressEarnings,
		isError,
		isLoading,
	};
};

export default useFairBasicInfo;
