import React, { useState } from "react";
import styled from "styled-components/macro";
import Heading from "components/Heading";
import BodyText from "components/BodyText";
import { WhiteButton } from "components/Button";
import ContactChairpersonForm from "./ContactChairpersonForm";
import { disableScrolling, enableScrolling } from "utils/ToggleScrolling";

interface Props {
	afterFair: boolean;
	isVirtual: boolean;
	contactData: {
		firstName: string;
		lastName: string;
		displayPhoneNumber: boolean;
		phoneNumber: string;
		email: string;
	};
	fairInfoData: {
		cash: boolean;
		checks: boolean;
		creditCard: boolean;
		payableTo: string;
		schoolName: string;
		startDate: Date;
		endDate: Date;
	};
	fairId: number;
	eWalletFlag: boolean;
}

const ChairpersonContactComponent = ({
	contactData,
	fairInfoData,
	afterFair,
	fairId,
	isVirtual,
	eWalletFlag,
}: Props) => {
	const [contactFormOpen, setContactFormOpen] = useState(false);

	const handleModal = () => {
		if (contactFormOpen) {
			enableScrolling();
		} else {
			disableScrolling();
		}
		setContactFormOpen(!contactFormOpen);
	};

	// Create a list of payment methods
	const paymentMethods: string[] = [];
	if (fairInfoData.cash) {
		paymentMethods.push("cash");
	}
	if (fairInfoData.creditCard) {
		paymentMethods.push("credit cards");
	}
	if (eWalletFlag) {
		paymentMethods.push("eWallet");
	}
	if (fairInfoData.checks) {
		paymentMethods.push(`checks ${fairInfoData.payableTo}`);
	}

	// Build a string based on number of payment methods
	const generatePaymentText = () => {
		let paymentText = "We accept the following as payment at our fair: ";
		for (let i = 0; i < paymentMethods.length; i++) {
			paymentText += paymentMethods[i];
			if (i < paymentMethods.length - 1) {
				paymentText += ", ";
			}
		}
		return paymentText;
	};

	return (
		<ChairpersonContact>
			<SectionContainer>
				<FlexRow>
					<InlineElement>
						<StyledHeading>{`${contactData.firstName} ${contactData.lastName}`}</StyledHeading>
					</InlineElement>
					<InlineElement>
						<StyledWhiteButton handleClick={handleModal}>
							Contact Chairperson
						</StyledWhiteButton>
					</InlineElement>
				</FlexRow>
				{contactData.displayPhoneNumber && (
					<FlexRow>
						<PhoneNumber>{contactData.phoneNumber}</PhoneNumber>
					</FlexRow>
				)}
				{!(afterFair || isVirtual) && paymentMethods.length > 0 && (
					<FlexRow>
						<StyledBodyText>{generatePaymentText()}</StyledBodyText>
					</FlexRow>
				)}
				<StyledLegal>
					©2022 Scholastic Inc. All rights reserved. DOG MAN Art © Dav
					Pilkey. CAT KID COMIC CLUB Art © Dav Pilkey. The
					Baby-sitters Club Art ® 2020 by Gale Galligan. TM/®
					Scholastic Inc. Fly Guy Art © Tedd Arnold.DIARY OF A PUG Art
					© by Kyla May
				</StyledLegal>
			</SectionContainer>
			{contactFormOpen && (
				<ContactChairpersonForm
					contactData={contactData}
					fairInfoData={fairInfoData}
					fairId={fairId}
					handleModal={handleModal}
				/>
			)}
		</ChairpersonContact>
	);
};

const StyledBodyText = styled(BodyText)`
	color: white;
	align-items: center;
	margin-top: 36px;
	width: 485.7px;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0px;
	@media (max-width: 550px) {
		width: 302.29px;
		margin-top: 45px;
	}
`;

const PhoneNumber = styled(StyledBodyText)`
	margin-top: 24px;
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0px;
`;

const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 492.3px;
	width: 100%;
	margin-bottom: 27px;
	@media (max-width: 750px) {
		margin-bottom: 50px;
	}
`;

const FlexRow = styled.div`
	display: inline-flex;
	width: 100%;
	gap: 32px;
	@media (max-width: 550px) {
		flex-direction: column;
		width: 100%;
		gap: unset;
		align-items: center;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 201px;
	margin-top: 43px;
	@media (max-width: 550px) {
		margin-top: 24px;
	}
`;

const InlineElement = styled.div`
	flex: 1 auto;
`;

const ChairpersonContact = styled.div`
	width: 100%;
	position: relative;
	background: #681032;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledHeading = styled(Heading)`
	font-size: 21px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: right;
	color: white;
	margin-top: 51px;
	@media (max-width: 550px) {
		margin-top: 32px;
	}
`;

const StyledLegal = styled(BodyText)`
	font-family: museo-sans;
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 12px;
	text-align: center;
	color: #ffffff;
	margin-top: 60px;
	width: 925px;
	@media (max-width: 950px) {
		width: calc(100vw - 75px);
	}
`;
export default ChairpersonContactComponent;
