import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { useConfig } from "providers/ConfigContext";
import { Page } from "components/HomepageBuilder/Page";
import Heading from "components/Heading";
import PageIcon from "components/HomepageBuilder/PageIcon";
import BodyText from "components/BodyText";
import { RedButton } from "components/Button";
import TextLink from "components/TextLink";
import RedArrow from "images/RedArrow.svg";
import ActivitiesIcon from "images/Activities.svg";
import BlackArrow from "images/BlackDropdownArrow.svg";
import { AddEventComponent } from "components/HomepageBuilder/AddEvent/AddEvent";
import { ScheduledEvent } from "components/HomepageBuilder/AddEvent/ScheduledEvent";
import { useEventsData } from "./Builder";
import axios from "axios";
import SaveChangesModal from "components/HomepageBuilder/SaveChangesModal";
import Prompt from "components/HomepageBuilder/Prompt";
import { trackSaveAndPublish } from "DumbleData";
import IframeContainer from "components/IframeContainer";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { checkSpsUd, getFairIdFromUrl } from "utils/HubUtility";

export const ScheduleActivitiesPage = () => {
	const { eventsData, fairInfoData, nextLocation, setNextLocation } =
		useEventsData();
	const { damAssetsOrigin, bookfairsAPI } = useConfig();
	const [event, setEvent] = useState(0);
	const [eventMenuIsOpen, setEventMenuIsOpen] = useState(false);
	const [addEventOpened, setAddEventOpened] = useState(false);
	const [saveChangesModalOpen, setSaveChangesModalOpen] = useState(false);
	const [editEventOpened, setEditEventOpened] = useState(false);
	const [editEventInfo, setEditEventInfo] = useState<any>([{}, 0]);
	const navigate = useNavigate();
	const isActivities = eventsData.schedule.length > 0;
	//keps track of updated events that are to be PUT to backend
	const [updatedEventsList, setUpdatedEventsList] = useState<
		{ id?: number }[]
	>([]);
	//keeps track of new events that are to be POST to backend
	const [newEventsList, setNewEventsList] = useState<{ id?: number }[]>([]);
	const [eventsToDelete, setEventsToDelete] = useState<{ id?: number }[]>([]);
	const [allChangesSaved, setAllChangesSaved] = useState(true);
	const [allowNavigation, setAllowNavigation] = useState(false);
	const firstRender = useRef(true);
	const [savedData] = useState<any>([...eventsData.schedule]);

	const EventOptionsList = [
		"Family Events",
		"Book Fair Preview",
		"Booktalks",
		"Faculty-Led Events",
		"Share the Fair",
	];

	const menuToggle = () => {
		setEventMenuIsOpen(!eventMenuIsOpen);
	};

	const eventChosen = (index: number) => {
		setAddEventOpened(true);
		setEventMenuIsOpen(false);
		setEvent(index);
	};

	const editEvent = (index: number) => {
		setEditEventOpened(true);
		setEditEventInfo([eventsData.schedule[index], index]);
	};

	//Adds newly created event to local schedule list and new events list
	const addNewEvent = (newEvent: any) => {
		eventsData.setSchedule([...eventsData.schedule, newEvent]);
		setNewEventsList([...newEventsList, newEvent]);
	};

	const updateEvent = (newEvent: any, eventPreEdit: any, index: number) => {
		//Adds updated event to schedule so appears in ui
		eventsData.schedule[index] = newEvent;
		eventsData.setSchedule([...eventsData.schedule]);

		//Checks if updated event was created in this session
		const newEventsListIndex = newEventsList.findIndex(
			(event) => JSON.stringify(event) === JSON.stringify(eventPreEdit)
		);
		//If this is a newly created event, not yet posted to backend, update what will be sent in new event POST request
		if (newEventsListIndex > -1) {
			newEventsList[newEventsListIndex] = newEvent;
		} else {
			//If the event had already existed in backend, update list that will be sent with PUT request
			const updatedEventIndex = updatedEventsList.findIndex(
				(event) => event.id === newEvent.id
			);
			if (updatedEventIndex > -1) {
				const tempUpdatedEventsList = updatedEventsList;
				tempUpdatedEventsList[updatedEventIndex] = newEvent;
				setUpdatedEventsList(tempUpdatedEventsList);
			} else {
				setUpdatedEventsList([...updatedEventsList, newEvent]);
			}
		}
		setEditEventOpened(false);
	};

	// Removes event from UI
	const deleteEvent = (eventData: any) => {
		const eventIndex = eventsData.schedule.findIndex(
			(event) => JSON.stringify(event) === JSON.stringify(eventData)
		);
		const eventToDelete = eventsData.schedule.splice(eventIndex, 1)[0];
		eventsData.setSchedule([...eventsData.schedule]);
		const newEventsListIndex = newEventsList.findIndex(
			(event) => JSON.stringify(event) === JSON.stringify(eventData)
		);
		// If event has not been posted yet, no need to add it
		if (newEventsListIndex >= 0) {
			const tempNewEventsList = newEventsList;
			tempNewEventsList.splice(newEventsListIndex, 1);
			setNewEventsList(tempNewEventsList);
		} else {
			setEventsToDelete([...eventsToDelete, eventToDelete]);
		}
	};

	//submits new events list to backend and deletes events
	const postEvents = async () => {
		const currentFair = getFairIdFromUrl();
		let isAllSuccessful = true;
		//Adding new events to backend
		if (newEventsList.length > 0) {
			if (checkSpsUd()) {
				try {
					await axios
						.post(
							`${bookfairsAPI}/user/fairs/${currentFair}/homepage/events`,
							newEventsList,
							{ withCredentials: true }
						)
						.then((response) => {
							if (response.status === 200) {
								//updating local event list with updated event response from API
								eventsData.setSchedule([
									...eventsData.schedule.filter(
										(event) => event.id !== -1
									),
									...response.data,
								]);
							} else {
								handleGenericSuccess(response);
							}
						});
				} catch (err) {
					handleGenericErrors(err);
					isAllSuccessful = false;
				}
			}
		}
		//Sending updated events to backend
		if (updatedEventsList.length > 0) {
			if (checkSpsUd()) {
				try {
					await axios
						.put(
							`${bookfairsAPI}/user/fairs/${currentFair}/homepage/events`,
							updatedEventsList,
							{ withCredentials: true }
						)
						.then((response) => {
							handleGenericSuccess(response);
						});
				} catch (err) {
					isAllSuccessful = false;
					handleGenericErrors(err);
				}
			}
		}
		//Deleting events from backend
		if (eventsToDelete.length) {
			if (checkSpsUd()) {
				try {
					await axios
						.delete(
							`${bookfairsAPI}/user/fairs/${currentFair}/homepage/events`,
							{ data: eventsToDelete, withCredentials: true }
						)
						.then((response) => {
							handleGenericSuccess(response);
						})
						.catch((err) => {
							handleGenericErrors(err);
						});
				} catch (err) {
					isAllSuccessful = false;
					handleGenericErrors(err);
				}
			}
		}
		if (isAllSuccessful) {
			trackSaveAndPublish("schedule-activities", "Schedule Activities");
			setAllChangesSaved(true);
			setAllowNavigation(true);
		}
	};

	useEffect(() => {
		const handleFocusOut = (event: any) => {
			const target = event.relatedTarget;
			if (target === null || !collection.includes(target)) {
				setEventMenuIsOpen(false);
			}
		};
		const eventMenu = document.getElementById("eventOption0");
		const eventMenuContainer = document.getElementById("eventOptions");

		if (eventMenu) {
			eventMenu.focus();
		}

		const collection = Array.from(
			document.getElementsByClassName("eventOptions")
		);
		eventMenuContainer &&
			eventMenuContainer.addEventListener("focusout", handleFocusOut);
		return () => {
			// Unbind the event listener on clean up
			eventMenuContainer &&
				eventMenuContainer.removeEventListener(
					"focusout",
					handleFocusOut
				);
		};
	}, [eventMenuIsOpen]);

	//
	// Logic to handle navigation and rendering the SaveChangesModal
	//

	//Tracks changes made
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		} else {
			setAllChangesSaved(false);
		}
	}, [newEventsList, updatedEventsList, eventsToDelete]);

	//Allows for navigation to next page after clicking discard changes on save changes modal
	useEffect(() => {
		if (allChangesSaved && allowNavigation) {
			navigate(nextLocation);
		}
	}, [
		allChangesSaved,
		saveChangesModalOpen,
		allowNavigation,
		navigate,
		nextLocation,
	]);

	/////////////////////////////////////////

	return (
		<IframeContainer
			renderItems={() => (
				<>
					<StyledScheduleActivitiesPage>
						<PageIcon
							src={`${damAssetsOrigin}HomepageBuilder/Icons/Section/Activities.svg`}
							alt="Schedule Activities Icon"
							defaultImg={ActivitiesIcon}
						/>
						<Heading>Schedule activities</Heading>

						<StyledBodyText>
							Holding Book Fair events is a great way to generate
							excitement and invite additional shoppers to your
							Fair. See below for a variety of activities to host
							as well as planning tips and ideas.
						</StyledBodyText>

						{isActivities && !editEventOpened && (
							<ScheduleSection>
								<StyledHeading>your schedule</StyledHeading>
								{eventsData.schedule.map(
									(event: any, index: number) => (
										<ScheduledEvent
											eventDate={event.scheduleDate}
											startTime={event.startTime}
											endTime={event.endTime}
											title={event.eventName}
											description={event.description}
											index={index}
											editEvent={editEvent}
											category={event.eventCategory}
											createDate={event.createDate}
											key={event.id}
											deleteEvent={deleteEvent}
											eventData={event}
										/>
									)
								)}
							</ScheduleSection>
						)}
						<AddEventSection>
							{!addEventOpened && !editEventOpened && (
								<AddEvent onClick={menuToggle}>
									Add Event <DownArrow src={RedArrow} />
								</AddEvent>
							)}
							<CenteredContainer>
								{eventMenuIsOpen && (
									<EventSelector>
										<DropDownArrow src={BlackArrow} />
										<EventsContainer id="eventOptions">
											{EventOptionsList.map(
												(
													option: string,
													index: number
												) => (
													<EventOption
														id={`eventOption${index}`}
														onClick={() => {
															eventChosen(index);
														}}
														onKeyPress={() => {
															eventChosen(index);
														}}
														tabIndex={0}
														className={
															"eventOptions"
														}
														key={index}
													>
														{option}
													</EventOption>
												)
											)}
										</EventsContainer>
									</EventSelector>
								)}
							</CenteredContainer>
							{addEventOpened && (
								<AddEventComponent
									setMenuOpen={menuToggle}
									setAddEventOpen={() => {
										setAddEventOpened(!addEventOpened);
									}}
									fairStartDate={fairInfoData.startDate}
									fairEndDate={fairInfoData.endDate}
									eventTypeIndex={event}
									isMenuOpen={eventMenuIsOpen}
									saveEvent={addNewEvent}
								/>
							)}
							{editEventOpened && (
								<AddEventComponent
									setMenuOpen={menuToggle}
									setAddEventOpen={() => {
										setEditEventOpened(!editEventOpened);
									}}
									fairStartDate={fairInfoData.startDate}
									fairEndDate={fairInfoData.endDate}
									isMenuOpen={eventMenuIsOpen}
									saveEvent={updateEvent}
									editEventInfo={editEventInfo}
								/>
							)}
						</AddEventSection>
						<StyledRedButton
							disabled={addEventOpened || editEventOpened}
							handleClick={() => {
								setNextLocation(
									"/reactapp/customize/volunteer"
								);
								postEvents();
							}}
						>
							save & publish
						</StyledRedButton>
						<StyledTextLink
							disabled={addEventOpened}
							handleClick={() => {
								if (!allChangesSaved) {
									setNextLocation(
										"/reactapp/customize/volunteer"
									);
									setSaveChangesModalOpen(
										!saveChangesModalOpen
									);
								} else {
									navigate("/reactapp/customize/volunteer");
								}
							}}
						>
							Skip
						</StyledTextLink>
					</StyledScheduleActivitiesPage>

					<Prompt
						message={() => {
							setSaveChangesModalOpen(true);
						}}
						when={!allChangesSaved}
					/>
					<SaveChangesModal
						saveAndPublish={postEvents}
						discardChanges={() => {
							setAllChangesSaved(true);
							setAllowNavigation(true);
							eventsData.setSchedule(savedData);
						}}
						modalIsOpen={saveChangesModalOpen}
						handleModal={() => {
							setSaveChangesModalOpen(!saveChangesModalOpen);
						}}
					/>
				</>
			)}
		/>
	);
};

/*
	Page Specific Styles
*/

const CenteredContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
const ScheduleSection = styled.div`
	margin-top: 34px;
`;
const StyledHeading = styled(Heading)`
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0px;
	text-align: center;
	text-transform: uppercase;
`;
const AddEventSection = styled.div`
	position: relative;
`;
const EventOption = styled.div`
	height: 35px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0px;
	cursor: pointer;
`;
const EventSelector = styled.div`
	background: #ffffff;
	border: 1px solid #919191;
	box-sizing: border-box;
	border-radius: 5px;
	width: 200px;
	position: absolute;
	top: 60px;
	z-index: 2;
`;
const EventsContainer = styled.div`
	width: 162px;
	height: 200px;
	padding: 10px 22px 0 16px;
`;
const StyledBodyText = styled(BodyText)`
	max-width: 310px;
`;
const StyledRedButton = styled(RedButton)`
	width: 181px;
`;
const StyledTextLink = styled(TextLink)`
	margin-top: 23px;
`;
const StyledScheduleActivitiesPage = styled(Page)`
	padding-bottom: 95px;
	align-self: center;
	@media (max-width: 719px) {
		padding-bottom: 103px;
	}
	@media (max-width: 582px) {
		padding: 135px 0 74px;
	}
`;
const AddEvent = styled.button`
	width: 600px;
	height: 96px;
	background: #fff5f5;
	border-radius: 16px;
	border: none;
	margin: 40px 21px 56px 21px;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0px;
	text-align: center;
	color: #e81111;
	text-transform: uppercase;
	position: relative;
	@media (max-width: 582px) {
		margin: 64px 21px 80px 21px;
		width: 333px;
		font-size: 21px;
		line-height: 24px;
	}
`;
const DownArrow = styled.img`
	padding-left: 8px;
	position: absolute;
	bottom: 43.29px;
`;
const DropDownArrow = styled.img`
	position: absolute;
	right: 19px;
	top: 17px;
`;
