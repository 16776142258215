import { useEffect, useState } from "react";

interface Props {
	coaAcceptedDate: string;
}

const useCurrentServiceAgreement = ({ coaAcceptedDate }: Props) => {
	const [isCurrentServiceAgreement, setIsCurrentServiceAgreement] =
		useState(true);

	useEffect(() => {
		if (coaAcceptedDate === null) {
			// if COA has not been accepted, show current agrement
			setIsCurrentServiceAgreement(true);
		} else {
			// If coaAcceptedDate is before 8/15/2024, use old agreement
			const augustSixteenth = new Date("2024-8-16");
			if (new Date(coaAcceptedDate) < augustSixteenth) {
				setIsCurrentServiceAgreement(false);
			}
		}
	}, [coaAcceptedDate]);
	return isCurrentServiceAgreement;
};

export default useCurrentServiceAgreement;
