import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import { handleGenericErrors, handleGenericSuccess } from "utils/ErrorUtility";
import { getSchoolIdFromUrl } from "utils/HubUtility";

export type SDBalance = {
	balance: Balance;
	transactions: Array<Transaction>;
};

type Balance = {
	profit: number;
	rewards: number;
};

export type Transaction = {
	date: string;
	description: string;
	transactionAmount: number;
	balance: number;
};

const balanceDefault = {
	profit: 0,
	rewards: 0,
};

const transactionsDefault = [
	{
		date: "",
		description: "",
		transactionAmount: 0,
		balance: 0,
	},
];

const useSDBalanceData = () => {
	const [balance, setBalance] = useState<Balance>(balanceDefault);
	const [transactions, setTransactions] =
		useState<Array<Transaction>>(transactionsDefault);
	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { bookfairsAPI } = useConfig();

	useEffect(() => {
		const getSDBalancePageData = async () => {
			const schoolIdOrSelected = getSchoolIdFromUrl();
			try {
				await axios
					.get(
						`${bookfairsAPI}/user/schools/${schoolIdOrSelected}/scholastic-dollars/account`,
						{
							withCredentials: true,
						}
					)
					.then((response) => {
						if (response.status === 200) {
							if (response.data) {
								setTransactions(response.data.transactions);
								setBalance(response.data.balance);
							}
						} else {
							handleGenericSuccess(response);
						}
					})
					.catch((err) => {
						setIsError(true);
						handleGenericErrors(err);
					});
			} catch (err: any) {
				setIsError(true);
				handleGenericErrors(err);
			} finally {
				setIsLoading(false);
			}
		};
		getSDBalancePageData();
	}, [bookfairsAPI, setBalance, setTransactions]);

	return {
		balance,
		transactions,
		isLoading,
		isError,
	};
};

export default useSDBalanceData;
