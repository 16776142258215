import axios from "axios";
import { useConfig } from "providers/ConfigContext";
import { useEffect, useState } from "react";
import {
	handleCOASuccess,
	handleGenericErrors,
	handleGenericSuccess,
} from "utils/ErrorUtility";
import { getFairIdFromUrl } from "utils/HubUtility";

const useIntroPageData = () => {
	const { bookfairsAPI } = useConfig();

	const [availabilityState, setAvailabilityState] = useState("");
	const [fairId, setFairId] = useState("");

	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetchAvailabilityState = async () => {
			// if fair id in url, returns fair id, otherwise returns 'current'
			const currentFair = getFairIdFromUrl();
			try {
				const response = await axios.get(
					`${bookfairsAPI}/user/fairs/${currentFair}/financials/form/status`,
					{ withCredentials: true }
				);
				if (response.status !== 200) {
					if (!handleCOASuccess(response)) {
						handleGenericSuccess(response);
					}
					handleGenericSuccess(response);
					return;
				}
				setAvailabilityState(response.data["type"]);
				setFairId(response.headers["sbf-jarvis-resource-id"]);
			} catch (err) {
				setIsError(true);
				handleGenericErrors(err);
			} finally {
				setIsLoading(false);
			}
		};
		fetchAvailabilityState();
	}, [bookfairsAPI]);

	return {
		availabilityState,
		fairId,
		isError,
		isLoading,
	};
};

export default useIntroPageData;
