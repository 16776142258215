import Cookies from "js-cookie";

declare global {
	interface Window {
		dumbleData: any;
		_satellite: any;
		embeddedservice_bootstrap: any;
	}
}

const isIframed = () => {
	return window.location !== window.parent.location;
};

export function initializeScripts() {
	const scriptSrc = process.env.REACT_APP_ADOBE_HEADER_SCRIPT || "";
	const headerScript = document.createElement("script");
	headerScript.setAttribute("src", scriptSrc);
	headerScript.addEventListener("load", function () {
		if (window.top) {
			window.top._satellite.pageBottom();
		}
	});
	document.head.appendChild(headerScript);
}

export function initHomepageBuilderDumbleData() {
	const sps_ud = Cookies.get("SPS_UD");

	// omniture is not used in AEM
	// if page is iframed define omniture object
	if (isIframed() && window.top) {
		// set dumbleData version to 3
		window.top.dumbleData.version = 3;
		window.top.dumbleData.omniture = {
			page: {
				access: "BFC",
				name: document.title,
				pageInfo: {
					domain: document.domain,
					title: "",
					isError: false,
					pageType: "",
				},
			},
			siteInfo: {
				environment: process.env.REACT_APP_ENV,
				audience: "BFC",
				language: "en",
				name: "Homepage Builder",
			},
			user: {
				loginStatus: sps_ud ? true : false,
				profile: {
					userType: "",
					SPSID: sps_ud ? sps_ud.split("|")[0] : "",
				},
			},
			account: {
				fairNum: "",
				schoolUcn: "",
			},
			domain: {
				audience: "BFC",
				channel: "Book Fairs",
				experienceType: "Commerce",
				name: "Live Homepage",
			},
			version: 3,
		};
	} else {
		// if not iframed define dumbleData object
		window.dumbleData = {
			omniture: {
				page: {
					access: "BFC",
					name: document.title,
					pageInfo: {
						domain: document.domain,
						title: "",
						isError: false,
						pageType: "",
					},
				},
				siteInfo: {
					environment: process.env.REACT_APP_ENV,
					audience: "BFC",
					language: "en",
					name: "Homepage Builder",
				},
				user: {
					loginStatus: sps_ud ? true : false,
					profile: {
						userType: "",
						SPSID: sps_ud ? sps_ud.split("|")[0] : "",
					},
				},
				account: {
					fairNum: "",
					schoolUcn: "",
				},
				domain: {
					audience: "BFC",
					channel: "Book Fairs",
					experienceType: "Commerce",
					name: "Live Homepage",
				},
				version: 3,
			},
		};
	}
}

export function initCPTKDumbleData() {
	const sps_ud = Cookies.get("SPS_UD");

	const isMobile =
		navigator.userAgent.match(/Android/i) ||
		navigator.userAgent.match(/webOS/i) ||
		navigator.userAgent.match(/iPhone/i) ||
		navigator.userAgent.match(/iPad/i) ||
		navigator.userAgent.match(/iPod/i) ||
		navigator.userAgent.match(/BlackBerry/i) ||
		navigator.userAgent.match(/Windows Phone/i);

	let pageName = "";
	switch (window.location.pathname) {
		case "/reactapp/confirm-fair":
			pageName = "COA";
			break;
		case "/reactapp/fair-details":
			pageName = "Fair Details & Settings";
			break;
		case "/reactapp/ewallet-roster":
			pageName = "eWallet Roster";
			break;
		case "/reactapp/sd-balance":
			pageName = "SD Balance";
			break;
		case "/reactapp/sales-history":
			pageName = "Sales History";
			break;
	}
	if (window.location.pathname.includes("/financial-form")) {
		pageName = "Financial Form";
	}

	// dumbleData object initially defined in AEM
	if (window.top) {
		// window.top is used to pull the dumbleData object from the top window
		// save object values defined in AEM to avoid overwriting
		const aemId = window.top?.dumbleData?.user?.id;
		const aemType = window.top?.dumbleData?.user?.type;
		const aemDomain = window.top?.dumbleData?.domain;
		const aemSiteInfoName = window.top?.dumbleData?.siteInfo?.name;
		const aemSiteInfoAudience = window.top?.dumbleData?.siteInfo?.audience;
		const aemSiteInfoChannel = window.top?.dumbleData?.siteInfo?.channel;
		const aemName = window.top?.dumbleData?.page?.name;
		const aemAccess = window.top?.dumbleData?.page?.access;
		const aemSiteSection = window.top?.dumbleData?.page?.siteSection;
		const aemTemplate = window.top?.dumbleData?.page?.template;
		const aemFairNum = window.top?.dumbleData?.account?.fairNum;
		const aemOrgUcn = window.top?.dumbleData?.account?.orgUcn;

		window.top.dumbleData = {
			version: 3,
			user: {
				id: aemId !== undefined ? aemId : "",
				type: aemType !== undefined ? aemType : "",
				loginStatus: true,
				userRole: null,
				classId: null,
				grade: null,
				profile: {
					SPSID: sps_ud ? sps_ud.split("|")[0] : "",
					fairId: "",
					fairType: "",
					schoolType: pageName === "COA" ? "" : undefined,
				},
				authenticationType: "site auth",
				salesAmount: pageName !== "COA" ? "" : undefined,
				unspentFunds: pageName !== "COA" ? "" : undefined,
				onlineShopping: {
					amount:
						pageName === "Fair Details & Settings" ? "" : undefined,
				},
				/* Financial Form */
				schoolName: "",
				schoolDate: "",
				fairStartDate: "",
				fairEndDate: "",
				fairName: "",
				grossSalesTotal: "",
				taxExemptSales: "",
				taxTotal: "",
				totalScholasticDollarsRedeemed: "",
				purchaseOrdersAmount: "",
				applyExistingScholasticDollarBalance: "",
				totalScholasticDollarsDue: "",
				scholasticDollarsDue: "",
				scholasticDollarsEarnedFromFair: "",
				eligibleScholasticDollarsBalance: "",
				/* SD Balance - Sales History */
				account: {
					accountID: "",
					profitBalanceAmount: "",
					rewardBalanceAmount: "",
					transactiontotalCount: "",
					lifetimeSales: "",
					totalFairCount: "",
					completeFairDetails: [],
				},
			},
			siteInfo: {
				name:
					aemSiteInfoName !== undefined
						? aemSiteInfoName
						: "CPTK 2.0",
				audience:
					aemSiteInfoAudience !== undefined
						? aemSiteInfoAudience
						: null,
				channel:
					aemSiteInfoChannel !== undefined
						? aemSiteInfoChannel
						: "UCOM",
				environment:
					process.env.REACT_APP_ENV !== "prod" ? "dev" : "production",
				currency: "USD",
				language: "en",
				lens: null,
				partnerName: "",
				sysEnv: isMobile ? "Mobile" : "Desktop",
			},
			domain: aemDomain !== undefined ? aemDomain : {},
			page: {
				name: aemName !== undefined ? aemName : "",
				access: aemAccess !== undefined ? aemAccess : "",
				siteSection: aemSiteSection !== undefined ? aemSiteSection : "",
				template: aemTemplate !== undefined ? aemTemplate : "",
				domain: window.location.hostname,
				destinationURL: window.location.href,
				isError: false,
				language: "english",
				pageName: pageName,
				pageInfo: {
					breadcrumb: window.location.pathname,
					pageID: "",
					pageTemplate: "Blank Book Fairs Template",
					title: pageName,
				},
			},
			account: {
				accountNum: "",
				fairNum: aemFairNum !== undefined ? aemFairNum : "",
				orgUcn: aemOrgUcn !== undefined ? aemOrgUcn : "",
			},
		};
	}
}

// Date Layer Functions

// window.top used to access the top window's dumbleData object
export function updateFairData(fairId: string, fairType: string) {
	if (window.top) {
		window.top.dumbleData.user.profile.fairId = fairId;
		window.top.dumbleData.user.profile.fairType = fairType;
	}
}

export function updateEwalletData(ewalletSales: string, unspentFunds: string) {
	if (window.top) {
		window.top.dumbleData.user.salesAmount = ewalletSales;
		window.top.dumbleData.user.unspentFunds = unspentFunds;
	}
}

export function updateOnlineShoppingData(onlineShoppingSales: string) {
	if (window.top) {
		window.top.dumbleData.user.onlineShopping.amount = onlineShoppingSales;
	}
}

export function updateSchoolData(schoolType: string) {
	if (window.top) {
		window.top.dumbleData.user.profile.schoolType = schoolType;
	}
}

export function updateSchoolBasicData(schoolName: string) {
	if (window.top) {
		window.top.dumbleData.user.schoolName = schoolName;
	}
}

export function updateFairBarData(
	fairName: string,
	fairStartDate: string,
	fairEndDate: string
) {
	if (window.top) {
		window.top.dumbleData.user.fairName = fairName;
		window.top.dumbleData.user.fairStartDate = fairStartDate;
		window.top.dumbleData.user.fairEndDate = fairEndDate;
		window.top.dumbleData.user.schoolDate =
			fairStartDate + " - " + fairEndDate;
	}
}

export function updateSchoolBarData(schoolName: string, accountNum: string) {
	if (window.top) {
		window.top.dumbleData.user.schoolName = schoolName;
		window.top.dumbleData.user.account.accountID = accountNum;
	}
}

export function updateFinancialFormSalesData(
	grossSalesTotal: string,
	taxExemptSales: string,
	taxTotal: string,
	totalScholasticDollarsRedeemed: string,
	purchaseOrdersAmount: string
) {
	if (window.top) {
		window.top.dumbleData.user.grossSalesTotal = grossSalesTotal;
		window.top.dumbleData.user.taxExemptSales = taxExemptSales;
		window.top.dumbleData.user.taxTotal = taxTotal;
		window.top.dumbleData.user.totalScholasticDollarsRedeemed =
			totalScholasticDollarsRedeemed;
		window.top.dumbleData.user.purchaseOrdersAmount = purchaseOrdersAmount;
	}
}

export function updateFinancialFormSpendingData(
	totalScholasticDollarsRedeemed: string,
	applyExistingScholasticDollarBalance: string,
	totalScholasticDollarsDue: string
) {
	if (window.top) {
		window.top.dumbleData.user.totalScholasticDollarsRedeemed =
			totalScholasticDollarsRedeemed;
		window.top.dumbleData.user.applyExistingScholasticDollarBalance =
			applyExistingScholasticDollarBalance;
		window.top.dumbleData.user.totalScholasticDollarsDue =
			totalScholasticDollarsDue;
	}
}

export function updateFinancialFormEarningsData(
	scholasticDollarsEarnedFromFair: string,
	scholasticDollarsDue: string,
	eligibleScholasticDollarsBalance: string
) {
	if (window.top) {
		window.top.dumbleData.user.scholasticDollarsEarnedFromFair =
			scholasticDollarsEarnedFromFair;
		window.top.dumbleData.user.scholasticDollarsDue = scholasticDollarsDue;
		window.top.dumbleData.user.eligibleScholasticDollarsBalance =
			eligibleScholasticDollarsBalance;
	}
}

export function updateSDBalanceData(
	profitBalanceAmount: string,
	rewardBalanceAmount: string,
	transactiontotalCount: string
) {
	if (window.top) {
		window.top.dumbleData.user.account.profitBalanceAmount =
			profitBalanceAmount;
		window.top.dumbleData.user.account.rewardBalanceAmount =
			rewardBalanceAmount;
		window.top.dumbleData.user.account.transactiontotalCount =
			transactiontotalCount;
	}
}

export function updateSalesHistoryData(
	lifetimeSales: string,
	totalFairCount: string,
	completeFairDetails: any
) {
	if (window.top) {
		window.top.dumbleData.user.account.lifetimeSales = lifetimeSales;
		window.top.dumbleData.user.account.totalFairCount = totalFairCount;
		window.top.dumbleData.user.account.completeFairDetails =
			completeFairDetails;
	}
}

/* Homepage Builder*/
export function trackPageLoad(
	pathname: string,
	fairId: string,
	schoolUcn: number
) {
	if (window.top) {
		window.top._satellite.track("page-view", {
			pageName: pathname,
			fairNum: fairId,
			schoolUcn: schoolUcn,
		});
	}
}

export function trackPageName(pageName: string) {
	if (window.top) {
		window.top.dumbleData.omniture.page.name = pageName;
	}
}

export function trackAccount(fairId: string, schoolUcn: number) {
	if (window.top) {
		window.top.dumbleData.omniture.account.fairNum = fairId;
		window.top.dumbleData.omniture.account.schoolUcn = schoolUcn;
	}
}

// Direct Call Rule Functions

// Schedule Activities page
export function trackAddEvent(activityType: string) {
	if (window.top) {
		window.top._satellite.track("schedule-activities", {
			linkText: "Add Event",
			sectionHeader: activityType,
			activityType: activityType,
			linkSrc: document.location.href,
		});
	}
}

// Recruit Volunteers page
export function trackDownloadPaperSignUpForm() {
	if (window.top) {
		window.top._satellite.track("recruit-volunteers", {
			linkText: "Download a paper sign-up form",
			sectionHeader: "Recruit Volunteers",
			linkSrc:
				"https://shop-stage.scholastic.com/content/dam/scholastic/ChairpersonToolkit/HomepageBuilder/Volunteers/Signup_Form.pdf",
		});
	}
}

// Tracks whenever the user clicks Save & Publish
export function trackSaveAndPublish(pageID: string, sectionHeader: string) {
	if (window.top) {
		window.top._satellite.track(pageID, {
			linkText: "Save & Publish",
			sectionHeader: sectionHeader,
			linkSrc: document.location.href,
		});
	}
}

// View & Share Homepage
export function trackShareBuilder(shareMethod: string) {
	if (window.top) {
		window.top._satellite.track("share-homepage-builder", {
			method: shareMethod,
			linkSrc: document.location.href,
		});
	}
}

// View & Share Homepage
export function trackCustomizeUrl(customUrl: string) {
	if (window.top) {
		window.top._satellite.track("share-homepage-builder", {
			linkText: "Save",
			sectionHeader: "Customize URL",
			linkSrc: customUrl,
		});
	}
}

// View & Share Homepage
export function trackFindYourFairToggleOn() {
	if (window.top) {
		window.top._satellite.track("share-homepage-builder", {
			linkText:
				"Share your homepage in the Find Your Fair search results",
			sectionHeader: "Share your homepage",
			linkSrc: document.location.href,
		});
	}
}

/* Live Homepage */
export function trackAddToCalendar(eventCategory: string) {
	if (window.top) {
		window.top._satellite.track("activities-add", {
			linkText: "Add to Calendar",
			sectionHeader: "Schedule",
			activityType: eventCategory,
			linkSrc: document.location.href,
		});
	}
}

export function trackMoreDetails(eventCategory: string) {
	if (window.top) {
		window.top._satellite.track("activities-more-details", {
			linkText: "More Details",
			sectionHeader: "Schedule",
			activityType: eventCategory,
			linkSrc: document.location.href,
		});
	}
}

export function trackCopyActivityUrl(eventCategory: string) {
	if (window.top) {
		window.top._satellite.track("activities-copy-url", {
			linkText: "Copy URL",
			sectionHeader: "Schedule",
			activityType: eventCategory,
			linkSrc: document.location.href,
		});
	}
}

export function trackEWalletGetStarted(eWalletUrl: string) {
	if (window.top) {
		window.top._satellite.track("Link Clicks", {
			linkText: "Get Started",
			sectionHeader: "Before the Fair: eWallet",
			linkSrc: eWalletUrl,
		});
	}
}

export function trackPrivacyPolicy() {
	if (window.top) {
		window.top._satellite.track("Link Clicks", {
			linkText: "Privacy Policy",
			sectionHeader: "Before the Fair: Notifications",
			linkSrc: "https://www.scholastic.com/privacy.htm",
		});
	}
}

export function trackEmailSignUp() {
	if (window.top) {
		window.top._satellite.track("Email-SignUp", {
			linkText: "Sign Up",
			sectionHeader: "Before the Fair: Notifications",
			linkSrc: document.location.href,
		});
	}
}

export function trackExploreDigitalFlyer(digitalFlyerUrl: string) {
	if (window.top) {
		window.top._satellite.track("explore-flyer", {
			linkText: "Explore Digital Flyer",
			sectionHeader: "Preview Books From the Fair",
			linkSrc: digitalFlyerUrl,
		});
	}
}

export function trackVolunteerSignup() {
	if (window.top) {
		window.top._satellite.track("volunteer-sign-up-continue", {
			linkText: "Continue",
			sectionHeader: "We're looking for volunteers!",
			linkSrc: document.location.href,
		});
	}
}

export function trackOnlineShopping(onlineShopUrl: string) {
	if (window.top) {
		window.top._satellite.track("SSO-shop-now", {
			linkText: "Shop Now",
			sectionHeader: "Shop Online",
			linkSrc: onlineShopUrl,
		});
	}
}

export function trackContactChairperson() {
	if (window.top) {
		window.top._satellite.track("contact-chairperson-send-message", {
			linkText: "Contact Chairperson",
			sectionHeader: "Contact BFC",
			linkSrc: document.location.href,
		});
	}
}

export function trackShareHomepage(shareMethod: string) {
	if (window.top) {
		window.top._satellite.track("share-homepage", {
			method: shareMethod,
			linkSrc: document.location.href,
		});
	}
}

export function trackFairFinderSearch(resultCount: number) {
	if (window.top) {
		window.top._satellite.track("fair-finder-search", {
			results: resultCount,
		});
	}
}

export function trackFairFinderVisitFair() {
	if (window.top) {
		window.top._satellite.track("fair-finder-go-to-fair");
	}
}

// School Selector

export function trackSelectSchoolButton(schoolName: string) {
	if (window.top) {
		window.top._satellite.track("select-school-button", {
			name: schoolName,
		});
	}
}

export function trackChangeSchoolLink() {
	if (window.top) {
		window.top._satellite.track("change-school-link", {
			name: "Change School",
		});
	}
}

// Scholastic Dollar Balance

export function trackShopSDCOnline() {
	if (window.top) {
		window.top._satellite.track("shop-sdc-online", {
			name: "Shop SDC Online",
		});
	}
}

// Sales History

export function trackCertificateOfAgreement() {
	if (window.top) {
		window.top._satellite.track("certificate-of-agreement", {
			name: "Certificate of Agreement",
		});
	}
}

export function trackViewInvoice() {
	if (window.top) {
		window.top._satellite.track("view-invoice", {
			name: "View Invoice",
		});
	}
}

// Fair Switcher Bar

export function trackChangeFair() {
	if (window.top) {
		window.top._satellite.track("change-fair", {
			name: "Change Fair",
		});
	}
}

// Fin Form Navigation

export function trackFinFormNavigationClick(destination: string) {
	if (window.top) {
		window.top._satellite.track("left-nav-click", {
			name: "Left Nav Button Click",
			linkName: destination,
		});
	}
}

// Fin Form: Sales

export function trackViewSalesDetails() {
	if (window.top) {
		window.top._satellite.track("view-sales-details", {
			name: "View Sales Details",
		});
	}
}

export function trackSaveAndContinue() {
	if (window.top) {
		window.top._satellite.track("save-and-continue", {
			name: "Save and Continue",
		});
	}
}

// Fin Form: Sales: Details

export function trackAddPurchaseOrder() {
	if (window.top) {
		window.top._satellite.track("add-purchase-order", {
			name: "Add Purchase Order",
		});
	}
}

export function trackSaveSalesDetailsButton() {
	if (window.top) {
		window.top._satellite.track("sales-details-save-button", {
			name: "Sales Details Save Button",
		});
	}
}

export function trackEditPurchaseOrdersButton() {
	if (window.top) {
		window.top._satellite.track("edit-purchase-orders-button", {
			name: "Edit Purchase Orders Button",
		});
	}
}

// Fin Form: Sales: Purchase orders

export function trackDeletePurchaseOrder() {
	if (window.top) {
		window.top._satellite.track("delete-purchase-order", {
			name: "Delete Purchase Order",
		});
	}
}

export function trackAddAnotherPurchaseOrder() {
	if (window.top) {
		window.top._satellite.track("add-another-purchase-order", {
			name: "Add Another Purchase Order",
		});
	}
}

// Fin Form: Review
export function trackReviewSubmitBackButton() {
	if (window.top) {
		window.top._satellite.track("review-submit-back-button", {
			name: "Review and Submit Back Button",
		});
	}
}

export function trackReviewSubmitSubmitButton() {
	if (window.top) {
		window.top._satellite.track("review-submit-submit-button", {
			name: "Review and Submit Submit Button",
		});
	}
}

// Fin Form: Confirmation

export function trackConfirmationPagePrintInvoiceButton() {
	if (window.top) {
		window.top._satellite.track("confirmation-page-print-invoice-button", {
			name: "Print Invoice Button",
		});
	}
}

export function trackConfirmationPagePayWithCard() {
	if (window.top) {
		window.top._satellite.track("confirmation-page-pay-with-card", {
			name: "Pay With Credit Card",
		});
	}
}

export function trackConfirmationPageGiveFeedback() {
	if (window.top) {
		window.top._satellite.track("confirmation-page-give-feedback", {
			name: "Give Feedback Button",
		});
	}
}

export function trackSavePurchaseOrderButton() {
	if (window.top) {
		window.top._satellite.track("save-purchase-order-button", {
			name: "Save Purchase Order Button",
		});
	}
}

// Fair Spending

export function trackSpendingBackButton() {
	if (window.top) {
		window.top._satellite.track("fair-spending-back-button", {
			name: "Fair Spending Back Button",
		});
	}
}

export function trackSpendingSaveAndContinue() {
	if (window.top) {
		window.top._satellite.track("fair-spending-save-continue-button", {
			name: "Fair Spending Save and Continue",
		});
	}
}

// Fair Earnings

export function trackFairEarningsBackButton() {
	if (window.top) {
		window.top._satellite.track("fair-earnings-back-button", {
			name: "Fair Earnings Back Button",
		});
	}
}

export function handleTrackFairEarningsContinueButton(
	cash: number,
	scholasticDollars: number
) {
	const splitStatus = cash > 0 ? "Split" : "No Split";
	const cashValue = cash > 0 ? cash : null;
	const scholasticDollarValue =
		scholasticDollars > 0 ? scholasticDollars : null;
	if (window.top) {
		window.top._satellite.track("fair-earnings-save-continue-button", {
			name: "Fair Earnings Save and Continue",
			split: splitStatus,
			cash: cashValue,
			sdb: scholasticDollarValue,
		});
	}
}
