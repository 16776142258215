import { disableScrolling, enableScrolling } from "utils/ToggleScrolling";
import { WhiteButton } from "components/Button";
import Heading from "components/Heading";
import { useState } from "react";
import styled from "styled-components/macro";
import LeavingScholasticModal from "./LeavingScholasticModal";

interface Props {
	volunteerForm: string;
}

const VolunteerSection = ({ volunteerForm }: Props) => {
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	// Opens the modal
	const openModal = (e: any) => {
		e.preventDefault();
		setModalOpen(true);
		disableScrolling();
	};

	// Closes the modal
	const closeModal = () => {
		setModalOpen(false);
		enableScrolling();
	};

	return (
		<>
			<StyledVolunteerSection>
				<StyledHeading>We're looking for volunteers!</StyledHeading>
				<StyledWhiteButton
					href={volunteerForm}
					handleClick={openModal}
					target="_blank"
				>
					Sign up
				</StyledWhiteButton>
			</StyledVolunteerSection>
			{modalOpen && (
				<LeavingScholasticModal
					closeModal={closeModal}
					volunteerForm={volunteerForm}
				/>
			)}
		</>
	);
};

const StyledVolunteerSection = styled.div`
	height: 160px;
	background-color: #1226aa;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 719px) {
		flex-direction: column;
		height: 192px;
	}
`;

const StyledHeading = styled(Heading)`
	color: #ffffff;
	font-size: 32px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding-bottom: 0;
	@media (max-width: 959px) {
		max-width: 337px;
	}
	@media (max-width: 719px) {
		font-size: 28px;
		letter-spacing: 1.5px;
	}
`;

const StyledWhiteButton = styled(WhiteButton)`
	width: 143px;
	margin-left: 57px;
	@media (max-width: 959px) {
		margin-left: 80px;
	}
	@media (max-width: 719px) {
		margin-left: 0;
		margin-top: 24px;
	}
`;

export default VolunteerSection;
