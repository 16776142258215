import React from "react";
import styled from "styled-components/macro";
import { Transaction } from "hooks/EWalletRoster/useEWalletRosterData";
import { convertStringToDate } from "utils/DateConversion";
import { convertDateToMDY } from "utils/DateConversion";

type Props = {
	transactions: Array<Transaction>;
	isExpanded: boolean;
};

const DataTableTransactions = ({ transactions, isExpanded }: Props) => {
	return (
		<tr>
			<Container colSpan={6} isExpanded={isExpanded}>
				<InnerContainer>
					<Heading>Transaction Details</Heading>
					<FlexParent>
						<Column>
							{transactions.map((transaction) => {
								return (
									<div
										key={
											transaction.createdOn +
											transaction.orderId
										}
									>
										{convertDateToMDY(
											convertStringToDate(
												transaction.createdOn
											)
										)}
									</div>
								);
							})}
						</Column>
						<Column>
							{transactions.map((transaction) => {
								return (
									<div
										key={
											transaction.type +
											transaction.orderId
										}
									>
										{transaction.type}
									</div>
								);
							})}
						</Column>
						<Column>
							{transactions.map((transaction) => {
								return (
									<>
										{transaction.amount ? (
											<>
												{transaction.type ===
												"Charged" ? (
													<RightAlign
														key={
															transaction.amount +
															transaction.orderId
														}
													>
														-${transaction.amount}
													</RightAlign>
												) : (
													<RightAlign
														key={
															transaction.amount +
															transaction.orderId
														}
													>
														${transaction.amount}
													</RightAlign>
												)}
											</>
										) : (
											<WhiteTextDiv>-</WhiteTextDiv>
										)}
									</>
								);
							})}
						</Column>
					</FlexParent>
				</InnerContainer>
			</Container>
		</tr>
	);
};

type ContainerProps = {
	isExpanded: boolean;
};
const Container = styled.td<ContainerProps>`
	display: ${(props) => !props.isExpanded && "none"};
`;

const InnerContainer = styled.div`
	padding: 40px 0px;
	max-width: 450px;
	position: sticky;
	left: 0;
	@media (max-width: 500px) {
		max-width: 310px;
	}
`;

const FlexParent = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 45px;
`;
const Heading = styled.div`
	font-family: "museo-sans";
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 15px;
	display: flex;
	align-items: center;
	color: #333333;
	margin-bottom: 8px;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	font-family: "museo-sans";
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 12px;
	color: #333333;
	margin-top: 4px;
`;

const RightAlign = styled.div`
	text-align: right;
`;

const WhiteTextDiv = styled.div`
	color: #ffffff;
`;

export default DataTableTransactions;
