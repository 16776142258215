// Sorting functions, sortedBy controls up/down arrow styling

import { Transaction } from "hooks/SDBalance/useSDBalanceData";

const sortDateAscend = (sortedData: Array<Transaction>) => {
	const tempSort = sortedData;
	tempSort.sort((a, b) => {
		const x = a.date;
		const y = b.date;
		if (x < y) {
			return 1;
		}
		if (x > y) {
			return -1;
		}
		return 0;
	});
	return tempSort;
};

const sortDateDescend = (sortedData: Array<Transaction>) => {
	const tempSort = sortedData;
	tempSort.sort((a, b) => {
		const x = a.date;
		const y = b.date;
		if (x > y) {
			return 1;
		}
		if (x < y) {
			return -1;
		}
		return 0;
	});
	return tempSort;
};

export { sortDateAscend, sortDateDescend };
