import React from "react";
import { FairSelectorContent } from "./FairSelectorContent";
import { AEMModalContainer } from "components/AEMModalContainer";

interface Props {
	closeFairSelectorModal: Function;
	StandAlone?: boolean;
}

export const FairSelectorModal = ({ closeFairSelectorModal }: Props) => {
	return (
		<AEMModalContainer closeModal={closeFairSelectorModal}>
			<FairSelectorContent isModal={true} />
		</AEMModalContainer>
	);
};
