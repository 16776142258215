import IframeContainer from "components/IframeContainer";
import { FinancialFormConfirmationContent } from "./FinancialFormConfirmationContent";
import FairInfoBar from "components/FairInfoBar";
import useConfirmationPageData from "hooks/FinancialForm/useConfirmationData";
export const FinancialFormConfirmation = () => {
	const { fairId, amountDue, paymentAvailable } = useConfirmationPageData();
	return (
		<IframeContainer
			renderItems={() => (
				<>
					<FairInfoBar />
					<FinancialFormConfirmationContent
						fairId={fairId}
						payAmount={amountDue}
						paymentAvailable={paymentAvailable}
					/>
				</>
			)}
		/>
	);
};
