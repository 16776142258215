import React from "react";
import styled from "styled-components/macro";
import Heading from "components/Heading";
import { FinancialFormRegisterCard } from "./FinancialFormRegisterCard";
import logo from "images/ScholasticLogoWhiteOutline.svg";
import { IndividualRegisterInfo } from "hooks/FinancialForm/useSummaryPageData";

interface Props {
	registerData: Array<IndividualRegisterInfo>;
	registerTotalsData: IndividualRegisterInfo;
}

export const FinancialFormSummaryContent = ({
	registerData,
	registerTotalsData,
}: Props) => {
	return (
		<SummaryPage>
			<BlankNav>
				<Styledimg src={logo} />
			</BlankNav>
			<SummaryWrapper>
				<ReportHeading>Financial Summary Report</ReportHeading>
				<FinancialFormRegisterCard
					isFirst={true}
					registerInfo={registerTotalsData}
				/>
				{registerData.map((individualRegisterInfo) => {
					return (
						<FinancialFormRegisterCard
							isFirst={false}
							registerInfo={individualRegisterInfo}
						/>
					);
				})}
			</SummaryWrapper>
		</SummaryPage>
	);
};

const SummaryPage = styled.div`
	max-width: 100%;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;
const SummaryWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 612px;
	margin-top: 90px;
	margin-bottom: 90px;
	@media (max-width: 700px) {
		width: calc(100% - 40px);
	}
`;

const ReportHeading = styled(Heading)`
	font-size: 39px;
	font-style: normal;
	font-weight: 300;
	line-height: 42px;
	padding-bottom: 42px;
	@media (max-width: 520px) {
		padding-left: 20px;
	}
`;

const BlankNav = styled.div`
	display: flex;
	width: 100%;
	height: 70px;
	align-items: center;
	flex-shrink: 0;
	background: var(--red-clifford-red, #e81111);
`;

const Styledimg = styled.img`
	margin-left: 40px;
`;
